import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { makeStyles, Divider, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';

import { ROUTES } from 'constants/constants';
import { DATE_FORMAT, MONTH_YEAR_FORMAT } from 'constants/formats';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import withRelativeRoutes from 'utils/withRelativeRoutes';
import { getShowFees } from 'utils/leaseTransaction';
import * as dateUtils from 'utils/date';

import { fetchPaymentBreakdown } from 'reducers/paymentBreakdown';

import Page from 'common-components/Page/Page';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { PaymentDetailsRows } from 'common-components/PaymentDetails';
import { StyledComponents, styles } from 'common-components/PaymentDetails/styles';

const useStyles = makeStyles(() => ({
    formControl: {
        margin: '32px 0',
        textAlign: 'left',
    },
}));

export const getMonthOptions = (startDate = null, endDate = null) => {
    return dateUtils
        .getRange(moment(startDate).startOf('month'), moment(endDate).add({ month: 1 }), 'month')
        .map((date) => {
            return {
                value: date.format(DATE_FORMAT),
                label: date.format(MONTH_YEAR_FORMAT),
            };
        });
};

export const ChargesPage = ({ leaseTransaction = {}, paymentBreakdown = {}, fetchPaymentBreakdown = () => {} }) => {
    const classes = useStyles();
    const history = useHistory();
    const [error, setError] = useState(null);

    const { data: paymentData } = paymentBreakdown;
    const showDeposits = getShowFees(paymentData?.deposits);
    const showOneTimeFees = getShowFees(paymentData?.one_time_fees);
    const showMonthlyFees = getShowFees(paymentData?.monthly_fees);

    const { lease_start_date: startDate, breakdown_lease_end_date: endDate } = leaseTransaction;
    const now = moment();
    const monthOptions = getMonthOptions(startDate, endDate);

    const getDefaultMonth = () => {
        const start = moment(startDate);
        const end = moment(monthOptions[monthOptions.length - 1].value);

        if (now < start) {
            return start;
        }

        if (now > end) {
            return end;
        }

        return now;
    };

    const initialMonth = monthOptions.find((option) => option.label === getDefaultMonth().format(MONTH_YEAR_FORMAT));
    const [date, setDate] = useState(initialMonth?.value || null);

    useEffect(() => {
        if (paymentBreakdown?.error) {
            setError(GENERIC_ERROR_MESSAGE);
        }
    }, [paymentBreakdown]);

    useEffect(() => {
        if (date) {
            const momentDate = moment(date, DATE_FORMAT);
            const month = momentDate.month() + 1;
            const year = momentDate.year();
            fetchPaymentBreakdown(month, year);
        }
    }, [fetchPaymentBreakdown, date]);

    const handleDateValueChange = (event) => {
        setDate(event.target.value);
    };

    const goBack = () => {
        history.goBack();
    };

    const notifications = [];
    if (error) {
        notifications.push({
            type: 'error',
            messages: error,
        });
    }

    return (
        <Page title="Charges" notifications={notifications}>
            <Divider />
            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Month</InputLabel>
                <Select data-testid="date-select" value={date} onChange={handleDateValueChange}>
                    {monthOptions.map((o, i) => (
                        <MenuItem key={i} value={o.value}>
                            {o.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <StyledComponents.PaymentCard>
                {showDeposits && (
                    <PaymentDetailsRows
                        data-testid="deposits"
                        label="Deposits"
                        paymentType="deposits"
                        paymentObject={paymentData.deposits}
                        showTotal={false}
                        rowStyle={{ fontSize: 12 }}
                        className={styles.itemRowNoBorder}
                    />
                )}
                {showOneTimeFees && (
                    <PaymentDetailsRows
                        data-testid="fees"
                        label="One-time Fees"
                        paymentType="one_time_fees"
                        paymentObject={paymentData.one_time_fees}
                        showTotal={false}
                        rowStyle={{ fontSize: 12 }}
                        className={styles.itemRowNoBorder}
                    />
                )}
                {showMonthlyFees && (
                    <PaymentDetailsRows
                        data-testid="rent"
                        label="Monthly Rent"
                        paymentObject={paymentData.monthly_fees}
                        paymentType="monthly_fees"
                        showTotal={false}
                        rowStyle={{ fontSize: 12 }}
                        className={styles.itemRowNoBorder}
                    />
                )}
                <div className={styles.paymentDetailRowTotal}>
                    <div>{`${moment(date).format(MONTH_YEAR_FORMAT)} Total`}</div>
                    <div>${paymentData.total_fees_per_month}</div>
                </div>
            </StyledComponents.PaymentCard>
            <ActionButton onClick={goBack} marginTop={30}>
                Close
            </ActionButton>
        </Page>
    );
};

ChargesPage.propTypes = {
    leaseTransaction: PropTypes.object,
    paymentBreakdown: PropTypes.object,
    fetchPaymentBreakdown: PropTypes.func,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    paymentBreakdown: state.paymentBreakdown,
});

const mapActionsToProps = {
    fetchPaymentBreakdown,
};

export default connect(mapStateToProps, mapActionsToProps)(withRelativeRoutes(ChargesPage, ROUTES.CHARGES));
