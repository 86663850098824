import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { clearFinancialSources, refreshFinancialSources } from 'reducers/banking';
import { ROUTES } from 'constants/constants';
import withRelativeRoutes from 'utils/withRelativeRoutes';
import { fetchTransaction } from 'reducers/transaction';
import { actions as loaderActions } from 'reducers/loader';

import BankingContext from './BankingContext';
import ConnectBankPage from './pages/ConnectBankPage';
import PlaidAddPayroll from './pages/PlaidAddPayroll';
import IncomeVerificationSummaryPage from './pages/IncomeVerificationSummaryPage';
import StatedIncomeAndAssetsPage from './pages/StatedIncomeAndAssetsPage';
import EmployerDetailsPage from './pages/EmployerDetailsPage';
import AddAssetSourcePage from './pages/AddAssetSourcePage';
import AddIncomeSourcePage from './pages/AddIncomeSourcePage';
import EditFinancialSourcePage from './pages/EditFinancialSourcePage';
import RemoveFinancialSourcePage from './pages/RemoveFinancialSourcePage';

function BankingContainer({
    history,
    bankingData,
    fetchTransaction,
    refreshFinancialSources,
    clearFinancialSources,
    toggleLoader,
}) {
    return (
        <BankingContext.Provider
            value={{
                history,
                bankingData,
                fetchTransaction,
                refreshFinancialSources,
                clearFinancialSources,
                toggleLoader,
            }}
        >
            <Switch>
                <Route path={ROUTES.INCOME_VERIFICATION_CONNECT} component={ConnectBankPage} exact />
                <Route path={ROUTES.INCOME_VERIFICATION_SUMMARY} component={IncomeVerificationSummaryPage} />
                <Route path={ROUTES.STATED_INCOME_AND_ASSETS} component={StatedIncomeAndAssetsPage} />
                <Route path={ROUTES.EMPLOYER_DETAILS} component={EmployerDetailsPage} />
                <Route path={ROUTES.MANUAL_INCOME_ENTRY_ADD_INCOME} component={AddIncomeSourcePage} />
                <Route path={ROUTES.PLAID_INCOME_ENTRY_ADD_INCOME} component={PlaidAddPayroll} />
                <Route path={ROUTES.MANUAL_ASSET_ENTRY_ADD_ASSET} component={AddAssetSourcePage} />
                <Route path={ROUTES.EDIT_MANUAL_FINANCIAL_SOURCE} component={EditFinancialSourcePage} />
                <Route path={ROUTES.REMOVE_FINANCIAL_SOURCE} component={RemoveFinancialSourcePage} />
            </Switch>
        </BankingContext.Provider>
    );
}

BankingContainer.propTypes = {
    history: PropTypes.object,
    bankingData: PropTypes.object,
    fetchTransaction: PropTypes.func,
    refreshFinancialSources: PropTypes.func,
    clearFinancialSources: PropTypes.func,
    toggleLoader: PropTypes.func,
};

const mapStateToProps = (state) => ({
    bankingData: state.banking,
});

const mapDispatchToProps = {
    fetchTransaction,
    refreshFinancialSources,
    clearFinancialSources,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRelativeRoutes(BankingContainer, ROUTES.BANKING));
