import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import hsclient from 'utils/hsclient';
import useLeaseSignEvent from 'pages/Application/components/SignLeaseView/hooks/useLeaseSignEvent';

const HelloSignLeaseView = ({
    applicantUpdated,
    getTransactionPath,
    history,
    leaseTransaction,
    setLoading,
    test_mode,
    url,
}) => {
    const isOpened = useRef(false);

    const signHandler = () => {
        if (url && !isOpened.current) {
            const testMode = test_mode !== false;
            hsclient.open(url, {
                testMode: testMode,
                skipDomainVerification: testMode,
                allowDecline: false,
                allowCancel: true,
            });
            isOpened.current = true;
        }
    };

    const { handleSignEvent, handleCloseEvent } = useLeaseSignEvent({
        applicantUpdated,
        getTransactionPath,
        history,
        leaseTransaction,
        setLoading,
        signHandler,
    });

    useEffect(() => {
        hsclient.on('sign', handleSignEvent);
        hsclient.on('close', handleCloseEvent);

        return () => {
            hsclient.off('sign', handleSignEvent);
            hsclient.off('close', handleCloseEvent);
        };
    }, [handleSignEvent, handleCloseEvent]);

    return null;
};

HelloSignLeaseView.propTypes = {
    applicantUpdated: PropTypes.func.isRequired,
    getTransactionPath: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    leaseTransaction: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    test_mode: PropTypes.bool,
    url: PropTypes.string,
};

export default HelloSignLeaseView;
