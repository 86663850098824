import { Button, styled } from '@material-ui/core';
import emotionStyled from '@emotion/styled';

export const ButtonContainer = emotionStyled.div((props) => ({
    marginTop: props.marginTop || 0,
    marginBottom: props.marginBottom || 0,
    textDecoration: 'none',
    display: 'block',
}));

export const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: 8,
    borderWidth: 2,
    height: 61,

    '&:hover': {
        borderWidth: 2,
    },

    '&.Mui-disabled': {
        backgroundColor: '#d5d8de',
        color: '#ffffff',
    },

    '& .MuiButton-label': {
        textTransform: 'none',
        ...theme.typography.h3,
    },
}));
