import { css } from 'emotion';

export const existingItemRow = css`
    border-top: 1px solid #eeeeee;
    padding: 10px 0;
    display: flex;
    text-align: left;
    justify-content: space-between;
`;

export const priceBreakdownContainer = css`
    .MuiExpansionPanelSummary-root {
        padding: 0;
        border-top: 1px solid #eeeeee;
    }
`;
