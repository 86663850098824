import { configureStore } from '@reduxjs/toolkit';
import ldRedux from 'ld-redux';

import renterProfile from 'reducers/renter-profile';
import userProfile from 'reducers/user-profile';
import configuration from 'reducers/configuration';
import siteConfig from 'reducers/site-config';
import applicant from 'reducers/applicant';
import payments from 'reducers/payments';
import paymentBreakdown from 'reducers/paymentBreakdown';
import banking from 'reducers/banking';
import loader from 'reducers/loader';
import snackbar from 'reducers/snackbar';
import transaction from 'reducers/transaction';
import lease from 'reducers/lease';
import vacateForm from 'reducers/forms/vacate-form';

const reducer = {
    launchDarkly: ldRedux.reducer(),
    applicant,
    userProfile,
    renterProfile,
    configuration,
    siteConfig,
    payments,
    paymentBreakdown,
    banking,
    loader,
    snackbar,
    transaction,
    lease,
    vacateForm,
};

export const actions = {};

actions.logout = () => ({
    type: 'USER_LOGOUT',
});

export default configureStore({
    reducer,
});
