import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROUTES, SMS_OPT_IN_MARKETING_ENABLED } from 'constants/constants';
import auth from 'utils/auth';
import { getValidationErrors } from 'utils/fieldvalidation';
import { serializeDate, parseDateISOString, formatExistingPhoneNumber } from 'utils/misc';
import { getTermsDataFromLocalStorage } from 'utils/terms';

import { selectors } from 'reducers/renter-profile';
import { clearSiteConfigHash } from 'reducers/site-config';
import { fetchApplicant } from 'reducers/applicant';
import { fetchUserProfile } from 'reducers/user-profile';
import { actions as loaderActions } from 'reducers/loader';
import { fetchTransaction } from 'reducers/transaction';

import AccountForm from 'common-components/AccountForm';
import UnauthenticatedPage from 'pages/Unauthenticated';
import { H1, P, link } from 'assets/styles';

export class SignupPage extends Component {
    state = { errors: null };

    constructor(props) {
        super(props);
        const viewedTerms = localStorage?.getItem(`accepted-platform-terms-${props.leaseSettingsId}`);
        if (!viewedTerms) {
            props.history.push(ROUTES.TERMS);
        }
    }

    get applicantInfo() {
        const { person, invitee, client } = this.props.configuration;
        const baseInitialValues = {
            first_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            date_of_birth: '',
            password: '',
            sms_opt_in: false,
        };

        if (person) {
            const { first_name, last_name, email, phone_1 } = person;
            let { date_of_birth } = person;
            // dates are tricky... https://stackoverflow.com/questions/33908299/javascript-parse-a-string-to-date-as-local-time-zone
            if (date_of_birth) {
                date_of_birth = parseDateISOString(date_of_birth);
            }
            const formattedPhoneNumber = formatExistingPhoneNumber(phone_1);
            const personValues = Object.assign({}, baseInitialValues, {
                first_name,
                last_name,
                email,
                phone_number: formattedPhoneNumber,
                date_of_birth: date_of_birth,
            });
            if (client) {
                personValues.id = client.id;
            }
            return personValues;
        } else if (invitee && invitee.first_name) {
            const { first_name, last_name, phone_number, email } = invitee;
            const formattedPhoneNumber = formatExistingPhoneNumber(phone_number);
            return Object.assign({}, baseInitialValues, { first_name, last_name, formattedPhoneNumber, email });
        } else {
            return baseInitialValues;
        }
    }

    auth = auth;
    onSubmit = (values, { setSubmitting }) => {
        const { history, hash } = this.props;

        const terms = getTermsDataFromLocalStorage(this.props.leaseSettingsId, this.props.configuration);
        const serialized = { ...terms, ...values };
        serialized.date_of_birth = serializeDate(serialized.date_of_birth);

        this.props.toggleLoader(true);

        // particularly need this for guarantor and co-applicant to associate with existing application
        return auth
            .register(serialized, this.props.leaseSettingsId, hash)
            .then(async (res) => {
                auth.setSession(res.token, this.props.leaseSettingsId);
                const profile = await this.props.fetchUserProfile();
                const [firstAndOnlyTransaction] = profile.transactions;
                await this.props.fetchApplicant(firstAndOnlyTransaction.id);
                await this.props.fetchTransaction(firstAndOnlyTransaction.id);
                // Clear site config hash will prevent applicant from applying multiple time for the same additional application
                await this.props.clearSiteConfigHash();
                history.replace(this.props.initialPage);
            })
            .catch((e) => {
                const formatAccountExistsError = (error) => (
                    <span>
                        {' '}
                        {error.message} <a href={error.sign_in_url}>Sign In</a>{' '}
                    </span>
                );
                const isAccountExistsError = (error) => error.message && error.sign_in_url;

                if (e.response && e.response.status === 400 && e.responseError && e.responseError.errors) {
                    const validationErrors = getValidationErrors(e.responseError.errors);
                    const fieldErrors = validationErrors.fieldErrors.reduce(
                        (acc, error) => [...acc, ...error.messages],
                        []
                    );
                    const schemaErrors = validationErrors.schemaErrors
                        .filter((error) => isAccountExistsError(error))
                        .map(formatAccountExistsError);
                    this.setState({ errors: [...fieldErrors, ...schemaErrors] });
                } else {
                    this.setState({ errors: ['Oops, something went wrong. Try again.'] });
                }
            })
            .finally(() => {
                this.props.toggleLoader(false);
                setSubmitting(false);
            });
    };

    get status() {
        return this.state.errors
            ? {
                  type: 'error',
                  detail: this.state.errors,
              }
            : null;
    }

    render() {
        if (!this.props.configuration) return;
        const optedIn = this.props.configuration.client?.sms_opted_in === SMS_OPT_IN_MARKETING_ENABLED;
        return (
            <UnauthenticatedPage>
                <H1>Start Your Rental Application by Creating an Account Below</H1>
                <AccountForm
                    submitText="Create Account"
                    withPassword
                    status={this.status}
                    initialValues={this.applicantInfo}
                    onSubmit={this.onSubmit}
                    showConsentInput={!optedIn && !this.props.configuration.invitee}
                    configuration={this.props.configuration}
                />
                <P className="already-have-account" fontSize={14}>
                    Already have an account?{' '}
                    <Link to={ROUTES.LOGIN} className={link}>
                        Sign in here
                    </Link>
                </P>
            </UnauthenticatedPage>
        );
    }
}

SignupPage.propTypes = {
    leaseSettingsId: PropTypes.string,
    hash: PropTypes.string,
    configuration: PropTypes.object,
    history: PropTypes.object,
    initialPage: PropTypes.string,
    fetchTransaction: PropTypes.func,
    clearSiteConfigHash: PropTypes.func,
    fetchUserProfile: PropTypes.func,
    fetchApplicant: PropTypes.func,
    toggleLoader: PropTypes.func,
};

const mapStateToProps = (state) => ({
    initialPage: selectors.selectInitialPage(state),
    leaseSettingsId: state.siteConfig.basename,
    hash: state.siteConfig.hash,
    configuration: state.configuration,
});

const mapDispatchToProps = {
    fetchTransaction,
    clearSiteConfigHash,
    fetchUserProfile,
    fetchApplicant,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
