import React from 'react';
import PropTypes from 'prop-types';

import { vacateAddressFieldsValidation } from 'utils/validation';
import AddressForm from 'common-components/AddressForm/AddressForm';

export const VacateAddressForm = ({ initialValues, showAutomatedAddress, errors, onSubmit }) => {
    const submitHandler = (values, formikBag) => {
        const vacateValues = {
            forwarding_address_street: values.address_street,
            forwarding_address_city: values.address_city,
            forwarding_address_state: values.address_state,
            forwarding_address_postal_code: values.address_postal_code,
            forwarding_address_line_2: values.address_line_2,
        };
        return onSubmit(vacateValues, formikBag);
    };
    const address = {
        address_street: initialValues.forwarding_address_street || '',
        address_city: initialValues.forwarding_address_city || '',
        address_state: initialValues.forwarding_address_state || '',
        address_postal_code: initialValues.forwarding_address_postal_code || '',
        address_line_2: initialValues.forwarding_address_line_2 || '',
    };

    return (
        <AddressForm
            address={address}
            errors={errors}
            onSubmit={submitHandler}
            showAutomatedAddress={showAutomatedAddress}
            validationSchema={vacateAddressFieldsValidation}
            isInitialValid={true}
            buttonLabel={'Confirm'}
        />
    );
};

VacateAddressForm.propTypes = {
    initialValues: PropTypes.object,
    errors: PropTypes.array,
    showAutomatedAddress: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
};

export default VacateAddressForm;
