import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from 'emotion';

import { SpacedH1 } from 'assets/styles';
import ActionButton from 'common-components/ActionButton/ActionButton';

const Header = styled.div`
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
`;

const body = css`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 46px;
    margin-bottom: 100px;
`;

const line = css`
    border-top: 1px solid #eeeeee;
`;

export const BankAccountPendingVerification = ({ clickToAction, titleContent, bodyContent, clickToActionContent }) => {
    return (
        <div>
            <Header>
                <SpacedH1>{titleContent}</SpacedH1>
            </Header>
            <div className={line} />
            <div className={body}>{bodyContent}</div>
            <ActionButton onClick={clickToAction}>{clickToActionContent}</ActionButton>
        </div>
    );
};

BankAccountPendingVerification.propTypes = {
    clickToAction: PropTypes.func.isRequired,
    clickToActionContent: PropTypes.string.isRequired,
    titleContent: PropTypes.string.isRequired,
    bodyContent: PropTypes.string.isRequired,
};

export default BankAccountPendingVerification;
