import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from 'emotion';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Info from '@material-ui/icons/Info';
import groupBy from 'lodash/groupBy';

import { prettyCurrency } from 'utils/misc';
import SimplePopover from 'common-components/SimplePopover/SimplePopover';
import PaidText from 'common-components/PaidText/PaidText';
import { CardRowBorderless, P, infoIconRoot, personName } from 'assets/styles';
import { LINE_ITEM_TYPE_TRANSFER_FEE } from 'constants/constants';

const SelfSelectedAmountContainer = styled.div`
    margin: auto 0;
`;

const IndentedRow = styled(CardRowBorderless)`
    margin: 0 0 5px 32px;
`;

const root = css`
    width: 100%;
    margin-top: 10px !important;
`;

const ApplicationFeesContainer = styled.div`
    padding: 15px 0;
    border-bottom: 1px solid #eeeeee;
    &:last-of-type {
        border-bottom: none;
    }
`;

export const ApplicationFees = ({
    name,
    allPayersFees,
    canPayForEveryone = false,
    didPayForEveryone = false,
    payingForEveryone,
    setPayingForEveryone,
    applicationFeeTooltipCopy,
    currentPayerFeesRepresentation,
    disclaimer,
}) => {
    const title = name
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    let infoText = disclaimer
        ? disclaimer
        : applicationFeeTooltipCopy ||
          `${name.charAt(0).toUpperCase()}${name
              .substring(1)
              .toLowerCase()} is per person to run a credit check and background screening.`;

    if (allPayersFees[0].type === LINE_ITEM_TYPE_TRANSFER_FEE) {
        infoText = disclaimer ? disclaimer : 'Transfers during a lease term are charged a fee per household';
    }

    return (
        <ApplicationFeesContainer>
            <CardRowBorderless>
                <div>
                    {title}{' '}
                    <SimplePopover text={infoText}>
                        <Info classes={{ root: infoIconRoot }} style={{ color: '#828796', width: 16 }} />
                    </SimplePopover>
                </div>
                {!canPayForEveryone && !didPayForEveryone && currentPayerFeesRepresentation && (
                    <AmountOrPaidStatus fee={currentPayerFeesRepresentation} />
                )}
            </CardRowBorderless>
            {canPayForEveryone && (
                <CardRowBorderless style={{ border: 'none', paddingBottom: 0 }}>
                    <FormControl component="fieldset" classes={{ root }}>
                        <RadioGroup
                            aria-label="payment-options"
                            name="payment-options"
                            value={payingForEveryone ? 'everyone' : 'self'}
                            onChange={(event) => setPayingForEveryone(event.target.value === 'everyone')}
                        >
                            <CardRowBorderless>
                                <FormControlLabel
                                    value="self"
                                    control={<Radio />}
                                    label={
                                        currentPayerFeesRepresentation
                                            ? 'Pay just for myself'
                                            : 'Other applicant(s) will pay for themselves'
                                    }
                                />
                                <SelfSelectedAmountContainer>
                                    {!payingForEveryone && currentPayerFeesRepresentation && (
                                        <AmountOrPaidStatus fee={currentPayerFeesRepresentation} />
                                    )}
                                </SelfSelectedAmountContainer>
                            </CardRowBorderless>
                            <FormControlLabel
                                value="everyone"
                                control={<Radio />}
                                label={
                                    currentPayerFeesRepresentation
                                        ? 'Pay for everyone'
                                        : "Pay for all other applicant(s)' fees"
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </CardRowBorderless>
            )}
            {((payingForEveryone && canPayForEveryone) || didPayForEveryone) &&
                Object.values(groupBy(allPayersFees, 'payer.id')).map((fees, index) => (
                    <EveryoneRow key={index} fees={fees} />
                ))}
        </ApplicationFeesContainer>
    );
};

ApplicationFees.propTypes = {
    currentPayerFeesRepresentation: PropTypes.object,
    allPayersFees: PropTypes.array.isRequired,
    applicationFeeTooltipCopy: PropTypes.string,
    canPayForEveryone: PropTypes.bool,
    didPayForEveryone: PropTypes.bool,
    payingForEveryone: PropTypes.bool,
    setPayingForEveryone: PropTypes.func,
    name: PropTypes.string,
    disclaimer: PropTypes.string,
};

export const EveryoneRow = ({ fees }) => (
    <IndentedRow>
        <div className={personName} color="#454B57">
            {fees[0].payer.first_name} {fees[0].payer.last_name}
        </div>
        <div>
            <AmountOrPaidStatus
                fee={{
                    amount: fees.reduce(
                        (sum, f) => (f.paidInCurrentReceipt || (!f.waived && !f.paid) ? sum + f.amount : sum),
                        0
                    ),
                    // We show the waived mark when all fees for the payer for a particular fee type are waived
                    waived: fees.filter((f) => f.waived).length === fees.length,
                    // We show the paid mark only when all fees were paid by another applicant
                    paid: fees.filter((f) => f.paid && !f.paidInCurrentReceipt).length === fees.length,
                }}
            />
        </div>
    </IndentedRow>
);

EveryoneRow.propTypes = {
    fees: PropTypes.array,
};

const AmountOrPaidStatus = ({ fee }) =>
    fee.paid || fee.waived ? <PaidText waived={fee.waived} /> : <P>{prettyCurrency(fee.amount)}</P>;

AmountOrPaidStatus.propTypes = {
    fee: PropTypes.object,
};

export default ApplicationFees;
