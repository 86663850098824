import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    ROUTES,
    RENTER_PROFILE_TYPE_PARKING,
    RENTER_PROFILE_TYPE_STORAGE,
    RENTER_PROFILE_TYPE_WINE_COOLER,
} from 'constants/constants';
import RenterProfileOptionsPage from './pages/RenterProfileOptionsPage';
import GuarantorPage from './pages/GuarantorPage';
import InviteRoommatesPage from './pages/InviteRoommatesPage';
import PetsPage from './pages/PetsPage';
import EditDependentPage from './pages/EditDependentPage';
import RemovePersonPage from './pages/RemovePersonPage';
import RentalOptionPage from './pages/RentalOptionsPage';

import parkingImage from 'assets/new-icons/streamlined-parking.svg';
import storageImage from 'assets/new-icons/warehouse-storage-streamlined.svg';
import wineCoolerImage from 'assets/new-icons/champagne-bottle-glass.svg';

class RentalProfileContainer extends Component {
    render() {
        return (
            <Switch>
                <Route path={ROUTES.PROFILE_OPTIONS} component={RenterProfileOptionsPage} />
                <Route path={ROUTES.GUARANTOR} component={GuarantorPage} />
                <Route path={ROUTES.CO_APPLICANTS} component={InviteRoommatesPage} />
                <Route path={ROUTES.EDIT_DEPENDANT} component={EditDependentPage} />
                <Route path={ROUTES.REMOVE_PERSON} component={RemovePersonPage} />
                <Route path={ROUTES.PETS} component={PetsPage} />

                <Route
                    path={ROUTES.PARKING}
                    render={(props) => (
                        <RentalOptionPage
                            {...props}
                            categoryKey={RENTER_PROFILE_TYPE_PARKING}
                            title="Parking"
                            subTitle="This is a request for parking. All parking is based on availability."
                            subtitleSuffix="parking space"
                            addButtonLabel="Add Parking"
                            imageSrc={parkingImage}
                            priceBreakdownProps={{
                                category: 'Parking',
                                categoryHelperText: 'parking spaces',
                            }}
                        />
                    )}
                />
                <Route
                    path={ROUTES.STORAGE}
                    render={(props) => (
                        <RentalOptionPage
                            {...props}
                            categoryKey={RENTER_PROFILE_TYPE_STORAGE}
                            title="Storage"
                            subTitle="We help you make room for what matters most."
                            subtitleSuffix="storage space"
                            addButtonLabel="Add Storage"
                            imageSrc={storageImage}
                            priceBreakdownProps={{
                                category: 'Storage',
                                categoryHelperText: 'storage spaces',
                            }}
                        />
                    )}
                />
                <Route
                    path={ROUTES.WINE_COOLER}
                    render={(props) => (
                        <RentalOptionPage
                            {...props}
                            categoryKey={RENTER_PROFILE_TYPE_WINE_COOLER}
                            title="Wine Cooler Request"
                            subTitle="Wine coolers are based on availability, but we’ll do our best to get you one."
                            subtitleSuffix="wine-cooler"
                            addButtonLabel="Add Wine Cooler"
                            imageSrc={wineCoolerImage}
                            priceBreakdownProps={{
                                category: 'Wine Cooler',
                                categoryHelperText: 'wine coolers',
                            }}
                        />
                    )}
                />
            </Switch>
        );
    }
}

export default RentalProfileContainer;
