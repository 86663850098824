import { createSlice } from '@reduxjs/toolkit';

import API from 'api/api';
import { serializeError } from 'utils/error';

const initialState = {
    data: null,
    error: null,
    isLoading: false,
};

const paymentBreakdown = createSlice({
    name: 'paymentBreakdown',
    initialState: initialState,
    reducers: {
        fetchPaymentBreakdownPending: (state) => ({
            ...state,
            isLoading: true,
            error: null,
        }),
        fetchPaymentBreakdownFailure: (state, { payload }) => ({
            ...state,
            isLoading: false,
            error: payload,
        }),
        fetchPaymentBreakdownSuccess: (state, { payload }) => ({
            ...state,
            isLoading: false,
            data: payload,
        }),
    },
});

const { actions, reducer } = paymentBreakdown;
export default reducer;

export const fetchPaymentBreakdown = (month = null, year = null) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { transaction } = state;
        if (!transaction) return;

        dispatch(actions.fetchPaymentBreakdownPending());
        try {
            const data = await API.getCurrentQuoteMonthly(transaction.current_quote.id, month, year);
            if (data.errors) {
                throw data.errors;
            }
            dispatch(actions.fetchPaymentBreakdownSuccess(data));
        } catch (e) {
            dispatch(actions.fetchPaymentBreakdownFailure(serializeError(e)));
        }
    };
};

// selectors
export const selectors = {};
