export const STATE_ZIP_CODES = [
    {
        id: '6',
        state: 'Alaska',
        stateAbbrv: 'AK',
        zipCodeMin: 99501,
        zipCodeMax: 99950,
    },
    {
        id: '5',
        state: 'Alabama',
        stateAbbrv: 'AL',
        zipCodeMin: 35004,
        zipCodeMax: 36925,
    },
    {
        id: '69',
        state: 'Arkansas',
        stateAbbrv: 'AR',
        zipCodeMin: 71601,
        zipCodeMax: 72959,
    },
    {
        id: '8',
        state: 'Arkansas (Texarkana)',
        stateAbbrv: 'AR',
        zipCodeMin: 75502,
        zipCodeMax: 75502,
    },
    {
        id: '7',
        state: 'Arizona',
        stateAbbrv: 'AZ',
        zipCodeMin: 85001,
        zipCodeMax: 86556,
    },
    {
        id: '9',
        state: 'California',
        stateAbbrv: 'CA',
        zipCodeMin: 90001,
        zipCodeMax: 96162,
    },
    {
        id: '10',
        state: 'Colorado',
        stateAbbrv: 'CO',
        zipCodeMin: 80001,
        zipCodeMax: 81658,
    },
    {
        id: '11',
        state: 'Connecticut',
        stateAbbrv: 'CT',
        zipCodeMin: 6001,
        zipCodeMax: 6389,
    },
    {
        id: '73',
        state: 'Connecticut',
        stateAbbrv: 'CT',
        zipCodeMin: 6401,
        zipCodeMax: 6928,
    },
    {
        id: '13',
        state: 'Dist of Columbia',
        stateAbbrv: 'DC',
        zipCodeMin: 20001,
        zipCodeMax: 20039,
    },
    {
        id: '74',
        state: 'Dist of Columbia',
        stateAbbrv: 'DC',
        zipCodeMin: 20042,
        zipCodeMax: 20599,
    },
    {
        id: '63',
        state: 'Dist of Columbia',
        stateAbbrv: 'DC',
        zipCodeMin: 20799,
        zipCodeMax: 20799,
    },
    {
        id: '12',
        state: 'Delaware',
        stateAbbrv: 'DE',
        zipCodeMin: 19701,
        zipCodeMax: 19980,
    },
    {
        id: '14',
        state: 'Florida',
        stateAbbrv: 'FL',
        zipCodeMin: 32004,
        zipCodeMax: 34997,
    },
    {
        id: '15',
        state: 'Georgia',
        stateAbbrv: 'GA',
        zipCodeMin: 30001,
        zipCodeMax: 31999,
    },
    {
        id: '65',
        state: 'Georga (Atlanta)',
        stateAbbrv: 'GA',
        zipCodeMin: 39901,
        zipCodeMax: 39901,
    },
    {
        id: '16',
        state: 'Hawaii',
        stateAbbrv: 'HI',
        zipCodeMin: 96701,
        zipCodeMax: 96898,
    },
    {
        id: '20',
        state: 'Iowa',
        stateAbbrv: 'IA',
        zipCodeMin: 50001,
        zipCodeMax: 52809,
    },
    {
        id: '66',
        state: 'Iowa (OMAHA)',
        stateAbbrv: 'IA',
        zipCodeMin: 68119,
        zipCodeMax: 68120,
    },
    {
        id: '17',
        state: 'Idaho',
        stateAbbrv: 'ID',
        zipCodeMin: 83201,
        zipCodeMax: 83876,
    },
    {
        id: '18',
        state: 'Illinois',
        stateAbbrv: 'IL',
        zipCodeMin: 60001,
        zipCodeMax: 62999,
    },
    {
        id: '19',
        state: 'Indiana',
        stateAbbrv: 'IN',
        zipCodeMin: 46001,
        zipCodeMax: 47997,
    },
    {
        id: '21',
        state: 'Kansas',
        stateAbbrv: 'KS',
        zipCodeMin: 66002,
        zipCodeMax: 67954,
    },
    {
        id: '22',
        state: 'Kentucky',
        stateAbbrv: 'KY',
        zipCodeMin: 40003,
        zipCodeMax: 42788,
    },
    {
        id: '68',
        state: 'Louisiana',
        stateAbbrv: 'LA',
        zipCodeMin: 70001,
        zipCodeMax: 71232,
    },
    {
        id: '23',
        state: 'Louisiana',
        stateAbbrv: 'LA',
        zipCodeMin: 71234,
        zipCodeMax: 71497,
    },
    {
        id: '26',
        state: 'Massachusetts',
        stateAbbrv: 'MA',
        zipCodeMin: 1001,
        zipCodeMax: 2791,
    },
    {
        id: '58',
        state: 'Massachusetts (Andover)',
        stateAbbrv: 'MA',
        zipCodeMin: 5501,
        zipCodeMax: 5544,
    },
    {
        id: '77',
        state: 'Maryland',
        stateAbbrv: 'MD',
        zipCodeMin: 20331,
        zipCodeMax: 20331,
    },
    {
        id: '78',
        state: 'Maryland',
        stateAbbrv: 'MD',
        zipCodeMin: 20335,
        zipCodeMax: 20797,
    },
    {
        id: '25',
        state: 'Maryland',
        stateAbbrv: 'MD',
        zipCodeMin: 20812,
        zipCodeMax: 21930,
    },
    {
        id: '24',
        state: 'Maine',
        stateAbbrv: 'ME',
        zipCodeMin: 3901,
        zipCodeMax: 4992,
    },
    {
        id: '27',
        state: 'Michigan',
        stateAbbrv: 'MI',
        zipCodeMin: 48001,
        zipCodeMax: 49971,
    },
    {
        id: '28',
        state: 'Minnesota',
        stateAbbrv: 'MN',
        zipCodeMin: 55001,
        zipCodeMax: 56763,
    },
    {
        id: '57',
        state: 'kc96 DataMO',
        stateAbbrv: 'MO',
        zipCodeMin: 63001,
        zipCodeMax: 65899,
    },
    {
        id: '29',
        state: 'Mississippi',
        stateAbbrv: 'MS',
        zipCodeMin: 38601,
        zipCodeMax: 39776,
    },
    {
        id: '59',
        state: 'Mississippi(Warren)',
        stateAbbrv: 'MS',
        zipCodeMin: 71233,
        zipCodeMax: 71233,
    },
    {
        id: '31',
        state: 'Montana',
        stateAbbrv: 'MT',
        zipCodeMin: 59001,
        zipCodeMax: 59937,
    },
    {
        id: '38',
        state: 'North Carolina',
        stateAbbrv: 'NC',
        zipCodeMin: 27006,
        zipCodeMax: 28909,
    },
    {
        id: '39',
        state: 'North Dakota',
        stateAbbrv: 'ND',
        zipCodeMin: 58001,
        zipCodeMax: 58856,
    },
    {
        id: '67',
        state: 'Nebraska',
        stateAbbrv: 'NE',
        zipCodeMin: 68001,
        zipCodeMax: 68118,
    },
    {
        id: '32',
        state: 'Nebraska',
        stateAbbrv: 'NE',
        zipCodeMin: 68122,
        zipCodeMax: 69367,
    },
    {
        id: '34',
        state: 'New Hampshire',
        stateAbbrv: 'NH',
        zipCodeMin: 3031,
        zipCodeMax: 3897,
    },
    {
        id: '35',
        state: 'New Jersey',
        stateAbbrv: 'NJ',
        zipCodeMin: 7001,
        zipCodeMax: 8989,
    },
    {
        id: '36',
        state: 'New Mexico',
        stateAbbrv: 'NM',
        zipCodeMin: 87001,
        zipCodeMax: 88441,
    },
    {
        id: '33',
        state: 'Nevada',
        stateAbbrv: 'NV',
        zipCodeMin: 88901,
        zipCodeMax: 89883,
    },
    {
        id: '60',
        state: 'New York (Fishers Is)',
        stateAbbrv: 'NY',
        zipCodeMin: 6390,
        zipCodeMax: 6390,
    },
    {
        id: '37',
        state: 'New York',
        stateAbbrv: 'NY',
        zipCodeMin: 10001,
        zipCodeMax: 14975,
    },
    {
        id: '40',
        state: 'Ohio',
        stateAbbrv: 'OH',
        zipCodeMin: 43001,
        zipCodeMax: 45999,
    },
    {
        id: '70',
        state: 'Oklahoma',
        stateAbbrv: 'OK',
        zipCodeMin: 73001,
        zipCodeMax: 73199,
    },
    {
        id: '41',
        state: 'Oklahoma',
        stateAbbrv: 'OK',
        zipCodeMin: 73401,
        zipCodeMax: 74966,
    },
    {
        id: '42',
        state: 'Oregon',
        stateAbbrv: 'OR',
        zipCodeMin: 97001,
        zipCodeMax: 97920,
    },
    {
        id: '43',
        state: 'Pennsylvania',
        stateAbbrv: 'PA',
        zipCodeMin: 15001,
        zipCodeMax: 19640,
    },
    {
        id: '44',
        state: 'Puerto Rico',
        stateAbbrv: 'PR',
        zipCodeMin: 0,
        zipCodeMax: 0,
    },
    {
        id: '45',
        state: 'Rhode Island',
        stateAbbrv: 'RI',
        zipCodeMin: 2801,
        zipCodeMax: 2940,
    },
    {
        id: '46',
        state: 'South Carolina',
        stateAbbrv: 'SC',
        zipCodeMin: 29001,
        zipCodeMax: 29948,
    },
    {
        id: '47',
        state: 'South Dakota',
        stateAbbrv: 'SD',
        zipCodeMin: 57001,
        zipCodeMax: 57799,
    },
    {
        id: '48',
        state: 'Tennessee',
        stateAbbrv: 'TN',
        zipCodeMin: 37010,
        zipCodeMax: 38589,
    },
    {
        id: '49',
        state: 'Texas (Austin)',
        stateAbbrv: 'TX',
        zipCodeMin: 73301,
        zipCodeMax: 73301,
    },
    {
        id: '71',
        state: 'Texas',
        stateAbbrv: 'TX',
        zipCodeMin: 75001,
        zipCodeMax: 75501,
    },
    {
        id: '72',
        state: 'Texas',
        stateAbbrv: 'TX',
        zipCodeMin: 75503,
        zipCodeMax: 79999,
    },
    {
        id: '61',
        state: 'Texas (El Paso)',
        stateAbbrv: 'TX',
        zipCodeMin: 88510,
        zipCodeMax: 88589,
    },
    {
        id: '50',
        state: 'Utah',
        stateAbbrv: 'UT',
        zipCodeMin: 84001,
        zipCodeMax: 84784,
    },
    {
        id: '64',
        state: 'Virginia',
        stateAbbrv: 'VA',
        zipCodeMin: 20040,
        zipCodeMax: 20041,
    },
    {
        id: '75',
        state: 'Virginia',
        stateAbbrv: 'VA',
        zipCodeMin: 20040,
        zipCodeMax: 20167,
    },
    {
        id: '76',
        state: 'Virginia',
        stateAbbrv: 'VA',
        zipCodeMin: 20042,
        zipCodeMax: 20042,
    },
    {
        id: '52',
        state: 'Virginia',
        stateAbbrv: 'VA',
        zipCodeMin: 22001,
        zipCodeMax: 24658,
    },
    {
        id: '51',
        state: 'Vermont',
        stateAbbrv: 'VT',
        zipCodeMin: 5001,
        zipCodeMax: 5495,
    },
    {
        id: '62',
        state: 'Vermont',
        stateAbbrv: 'VT',
        zipCodeMin: 5601,
        zipCodeMax: 5907,
    },
    {
        id: '53',
        state: 'Washington',
        stateAbbrv: 'WA',
        zipCodeMin: 98001,
        zipCodeMax: 99403,
    },
    {
        id: '55',
        state: 'Wisconsin',
        stateAbbrv: 'WI',
        zipCodeMin: 53001,
        zipCodeMax: 54990,
    },
    {
        id: '54',
        state: 'West Virginia',
        stateAbbrv: 'WV',
        zipCodeMin: 24701,
        zipCodeMax: 26886,
    },
    {
        id: '56',
        state: 'Wyoming',
        stateAbbrv: 'WY',
        zipCodeMin: 82001,
        zipCodeMax: 83128,
    },
];
