import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import API from 'api/api';
import {
    ROUTES,
    IDENTITY_VERIFICATION_PROVIDER_TRANSUNION,
    IDENTITY_VERIFICATION_PROVIDER_PLAID,
} from 'constants/constants';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import { VERIFICATION_METHOD_PIN, VERIFICATION_METHOD_QUESTIONS } from 'constants/residentIdVerification';

import * as transunitionUtils from 'utils/transunition';

import usePrevious from 'hooks/usePrevious';
import { useMayorLoader } from 'common-components/MayorLoader/MayorLoaderProvider';
import { useSentryUtils } from 'common-components/SentryUtils/SentryUtilsProvider';
import { fetchApplicant } from 'reducers/applicant';
import { fetchTransaction } from 'reducers/transaction';
import withTransactionPath from 'utils/withTransactionPath';

export const IdentityVerificationContext = createContext({});

export const useIdentityVerification = () => {
    return useContext(IdentityVerificationContext);
};

export function IdentityVerificationProvider({
    children,
    deviceVerificationApiKey,
    identityVerificationProvider,
    leaseTransaction,
    applicant,
    history,
    location,
    fetchApplicant,
    fetchTransaction,
    getTransactionPath,
}) {
    const { toggleLoader } = useMayorLoader();
    const { logToSentry } = useSentryUtils();
    const [identityVerification, setIdentityVerification] = useState({});
    const [error, setError] = useState(null);
    const [didInitiate, setDidInitiate] = useState(false);
    const prevPathname = usePrevious(location.pathname);
    const shouldInitiate = leaseTransaction && applicant && !applicant.is_id_verification_completed;
    const useTransunion = identityVerificationProvider === IDENTITY_VERIFICATION_PROVIDER_TRANSUNION;
    const usePlaid =
        identityVerificationProvider === IDENTITY_VERIFICATION_PROVIDER_PLAID &&
        leaseTransaction &&
        location.pathname !==
            getTransactionPath(ROUTES.MANUAL_IDENTITY_VERIFICATION, {
                lease_transaction_id: leaseTransaction.id,
            });

    const handleCompletedVerification = useCallback(async () => {
        await fetchApplicant();
        await fetchTransaction();
        const nextRoute = getTransactionPath(ROUTES.APP_COMPLETE, {
            lease_transaction_id: leaseTransaction.id,
        });
        history.push(nextRoute);
    }, [leaseTransaction, fetchTransaction, fetchApplicant, getTransactionPath, history]);

    const showLaunchPlaidPage = useCallback(async () => {
        try {
            toggleLoader(true);
            setDidInitiate(false);

            const initialVerificationPage = getTransactionPath(ROUTES.IDENTITY_VERIFICATION_LAUNCH_PLAID, {
                lease_transaction_id: leaseTransaction.id,
            });
            history.push(initialVerificationPage);
        } catch (e) {
            setError(GENERIC_ERROR_MESSAGE);
            logToSentry(e);
        } finally {
            toggleLoader(false);
        }
    }, [getTransactionPath, history, leaseTransaction, logToSentry, toggleLoader]);

    const initiateTransunionVerification = useCallback(async () => {
        try {
            toggleLoader(true);
            setDidInitiate(false);
            const sessionId = await transunitionUtils.initTrustedAPI(deviceVerificationApiKey);
            const initialIdentityVerification = await API.postVerifyResidentId(leaseTransaction.id, {
                session_id: sessionId,
            });

            setIdentityVerification(initialIdentityVerification);

            /**
             * 1. When the initial verification method is to verify the PIN,
             *    then skip the request PIN page and redirect straight to the verify PIN page.
             * 2. When the initial verification method is to verify the questions,
             *    then we redirect to the questions page.
             * 3. When neither of the above,
             *    then we fallback to request PIN page starting the OTP flow.
             */
            let initialVerificationPage;
            switch (initialIdentityVerification.verification_method) {
                case VERIFICATION_METHOD_PIN: {
                    initialVerificationPage = getTransactionPath(ROUTES.IDENTITY_VERIFICATION_OTP_VERIFY, {
                        lease_transaction_id: leaseTransaction.id,
                    });
                    break;
                }
                case VERIFICATION_METHOD_QUESTIONS:
                    initialVerificationPage = getTransactionPath(ROUTES.IDENTITY_VERIFICATION_QUESTIONS, {
                        lease_transaction_id: leaseTransaction.id,
                    });
                    break;
                default:
                    initialVerificationPage = getTransactionPath(ROUTES.IDENTITY_VERIFICATION_OTP_REQUEST, {
                        lease_transaction_id: leaseTransaction.id,
                    });
                    break;
            }

            setDidInitiate(true);
            if (initialIdentityVerification.is_completed) {
                await handleCompletedVerification();
            } else {
                history.push(initialVerificationPage);
            }
        } catch (e) {
            setError(GENERIC_ERROR_MESSAGE);
            logToSentry(e);
        } finally {
            toggleLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleLoader, deviceVerificationApiKey, handleCompletedVerification]);

    const evaluateQuestion = useCallback(
        async ({ answers, errorMessage = GENERIC_ERROR_MESSAGE }, nextRoute) => {
            toggleLoader(true);

            try {
                const sessionId = transunitionUtils.getSessionId();
                const data = {
                    reference_number: identityVerification.reference_number,
                    session_id: sessionId,
                    answers,
                };

                const updatedVerification = await API.patchEvaluateResidentId(leaseTransaction.id, data);
                setIdentityVerification(updatedVerification);

                if (updatedVerification.is_completed) {
                    await handleCompletedVerification();
                } else if (nextRoute) {
                    history.push(
                        getTransactionPath(nextRoute, {
                            lease_transaction_id: leaseTransaction.id,
                        })
                    );
                }
            } catch (e) {
                setError(errorMessage);
                logToSentry(e);
            } finally {
                toggleLoader(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [toggleLoader, identityVerification, handleCompletedVerification]
    );

    useEffect(() => {
        if (shouldInitiate) {
            if (useTransunion) {
                initiateTransunionVerification();
            } else if (usePlaid) {
                showLaunchPlaidPage();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldInitiate, useTransunion]);

    useEffect(() => {
        if (
            shouldInitiate &&
            useTransunion &&
            prevPathname ===
                getTransactionPath(ROUTES.IDENTITY_VERIFICATION_OTP_VERIFY, {
                    lease_transaction_id: leaseTransaction.id,
                }) &&
            location.pathname ===
                getTransactionPath(ROUTES.IDENTITY_VERIFICATION, {
                    lease_transaction_id: leaseTransaction.id,
                })
        ) {
            initiateTransunionVerification();
        } else if (shouldInitiate && usePlaid) {
            showLaunchPlaidPage();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const contextValue = {
        leaseTransaction,
        applicant,
        identityVerification,
        error,
        didInitiate,
        toggleLoader,
        GENERIC_ERROR_MESSAGE,
        initiateTransunionVerification,
        evaluateQuestion,
        setDidInitiate,
        showLaunchPlaidPage,
        handleCompletedVerification,
        setError,
    };

    if (!leaseTransaction) {
        return null;
    }

    return (
        <IdentityVerificationContext.Provider value={contextValue}>
            {typeof children === 'function' ? children(contextValue) : children}
        </IdentityVerificationContext.Provider>
    );
}

IdentityVerificationProvider.propTypes = {
    children: PropTypes.any.isRequired,
    deviceVerificationApiKey: PropTypes.string.isRequired,
    identityVerificationProvider: PropTypes.number.isRequired,
    leaseTransaction: PropTypes.object,
    applicant: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    fetchTransaction: PropTypes.func.isRequired,
    fetchApplicant: PropTypes.func.isRequired,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    applicant: state.applicant,
    deviceVerificationApiKey: state.configuration.transunition_device_verification_api_key,
    identityVerificationProvider: state.configuration.identity_verification_provider,
});

const mapDispatchToProps = {
    fetchTransaction,
    fetchApplicant,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTransactionPath(IdentityVerificationProvider)));
