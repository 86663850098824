import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import ReduxSnackbar, { Snackbar } from 'common-components/Snackbar/Snackbar';

export const SnackbarContext = createContext({});

export const useSnackbar = () => {
    return useContext(SnackbarContext);
};

// TODO: backfill tests | created by @JimVercoelen | ticket: NESTIO-20504
const initialState = {
    isOpen: false,
    message: '',
    props: {
        /** https://material-ui.com/api/snackbar/#component-name */
        // can be used to overwrite any of the Material UI's Snackbar's props
    },
};

// Snackbar provider usable either by useSnackbar hook or our redux actions
export const SnackbarProvider = ({ children }) => {
    const [state, setState] = useState(initialState);
    const showSnackbar = useCallback((message, props = {}) => {
        setState({ message, props, isOpen: true });
    }, []);

    const hideSnackbar = useCallback((event, reason) => {
        if (reason === 'clickaway') return;
        setState(initialState);
    }, []);

    const contextValue = useMemo(
        () => ({
            showSnackbar,
            hideSnackbar,
        }),
        [showSnackbar, hideSnackbar]
    );

    return (
        <SnackbarContext.Provider value={contextValue}>
            {children}
            <Snackbar onClose={hideSnackbar} {...state} />
            <ReduxSnackbar />
        </SnackbarContext.Provider>
    );
};

SnackbarProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SnackbarProvider;
