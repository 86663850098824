import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';

import { ROUTES } from 'constants/constants';
import captureRoute from 'utils/captureRoute';
import { prettyFormatPhoneNumber } from 'utils/misc';
import API from 'api/api';

import { ACTION_BUTTON, DocumentNoticeButton } from 'common-components/DocumentNoticeButton/DocumentNoticeButton';
import Page from 'common-components/Page/Page';
import PageImg from 'assets/new-icons/task-checklist-writing.svg';

const useStyles = makeStyles(() => ({
    page: {},
    pageImage: {
        width: '144px !important',
        height: '93px !important',
    },
    viewLetter: {
        margin: '40px 0 30px',
    },
    contact: {
        fontWeight: 600,
        fontSize: 16,
        textAlign: 'left',
    },
}));

export const ApplicationPendingCriminalReviewPage = (props) => {
    const [notifications, setNotifications] = useState([]);
    const classes = useStyles();
    const { leaseTransaction, configuration, applicant } = props;

    if (!leaseTransaction || !configuration || !applicant) return null;

    const { unit } = leaseTransaction;
    const { community } = configuration;

    const buildingName = community.building_name || community.normalized_street_address;
    const unitNumber = !!unit && !!unit.unit_number ? ` Unit ${unit.unit_number}` : '';
    const contactPhone = prettyFormatPhoneNumber(community.contact_phone);

    const setPageError = (error) => {
        if (!error) {
            return setNotifications([]);
        }

        setNotifications([
            {
                type: 'error',
                messages: [error],
            },
        ]);
    };

    return (
        <Page
            className={classes.page}
            title="Application Pending Review"
            subTitle="Your application is pending criminal background review. We’ll need some information to move forward with your application."
            notifications={notifications}
            image={{
                src: PageImg,
                className: classes.pageImage,
            }}
        >
            <Typography gutterBottom>{`${buildingName} ${unitNumber}`}</Typography>
            {configuration.criminal_review_letter_template_id && (
                <DocumentNoticeButton
                    componentType={ACTION_BUTTON}
                    className={classes.viewLetter}
                    fetchDocument={() => {
                        return API.fetchCriminalReviewLetterDocument(leaseTransaction.id);
                    }}
                    setPageError={setPageError}
                >
                    View Next Steps
                </DocumentNoticeButton>
            )}

            {contactPhone && (
                <Typography className={classes.contact}>
                    Call us at <a href={`tel:${contactPhone}`}>{contactPhone}</a> if you have any questions.
                </Typography>
            )}
        </Page>
    );
};

ApplicationPendingCriminalReviewPage.propTypes = {
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,
    applicant: PropTypes.object,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    configuration: state.configuration,
    applicant: state.applicant,
});

export default connect(mapStateToProps)(
    captureRoute(ApplicationPendingCriminalReviewPage, ROUTES.APP_PENDING_CRIMINAL_REVIEW)
);
