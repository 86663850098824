import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import API from 'api/api';
import { ROUTES, FINANCIAL_STREAM_INCOME, ERROR_UPLOAD } from 'constants/constants';
import { useSentryUtils } from 'common-components/SentryUtils/SentryUtilsProvider';
import { getFinancialSourceRequestBody } from 'utils/misc';
import withTransactionPath from 'utils/withTransactionPath';

import { BackLink } from 'common-components/BackLink/BackLink';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import AddFinancialSourceForm from 'pages/Banking/components/AddFinancialSourceForm';
import BankingContext from 'pages/Banking/BankingContext';
import { SpacedH1, SpacedH6, Spacer } from 'assets/styles';
import finance from 'assets/new-icons/currency-dollar-circle.svg';

// TODO: refactor component usable for both income and assets? created by: @JimVercoelen | Ticket: NESTIO-19929
export function AddIncomeSourcePage(props) {
    const context = useContext(BankingContext);
    const { logToSentry } = useSentryUtils();
    const [errors, setErrors] = useState([]);

    const nextOrBackUrl = props.getTransactionPath(`${ROUTES.INCOME_VERIFICATION_SUMMARY}#income`);

    const onSubmit = async (values, { setSubmitting }) => {
        context.toggleLoader(true);
        setSubmitting(true);
        setErrors([]);
        try {
            await API.submitFinancialSource(
                props.leaseTransaction.id,
                getFinancialSourceRequestBody(values, FINANCIAL_STREAM_INCOME)
            );
            await context.refreshFinancialSources();
            await context.fetchTransaction();
            props.history.push(nextOrBackUrl);
        } catch (e) {
            logToSentry(e);
            setErrors([ERROR_UPLOAD]);
        } finally {
            context.toggleLoader(false);
            setSubmitting(false);
        }
    };

    return (
        <>
            <SpacedH1>Add an Income Source</SpacedH1>
            <SpacedH6>Fill in the details below to add your income source.</SpacedH6>
            {errors.length > 0 && <GenericFormMessage type="error" messages={errors} />}
            <img alt="coin" src={finance} />
            <Spacer height={30} />
            <AddFinancialSourceForm
                initialValues={props.initialValues}
                financialType={FINANCIAL_STREAM_INCOME}
                onSubmit={onSubmit}
                setError={(err) => setErrors(err)}
            />
            <BackLink to={nextOrBackUrl} />
        </>
    );
}

AddIncomeSourcePage.propTypes = {
    history: PropTypes.object,
    initialValues: PropTypes.object,
    vgsEnabled: PropTypes.bool,
    leaseTransaction: PropTypes.object.isRequired,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    vgsEnabled: state.configuration.enable_encryption,
});

export default connect(mapStateToProps)(withTransactionPath(AddIncomeSourcePage));
