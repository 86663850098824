import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { logToDataDog } from 'utils/datadog';
import { logToSentry } from 'utils/sentry';
import CriticalErrorPage from 'pages/CriticalError';

import { ENV } from 'config';

const initialState = {
    hasError: false,
    showError: false,
};

export default class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    state = initialState;

    componentDidCatch(error, errorInfo) {
        if (ENV === 'development') {
            console.error(error);
        }

        logToSentry(error);

        logToDataDog(error, {
            context: {
                componentStack: errorInfo.componentStack,
            },
        });
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return <CriticalErrorPage />;
        }

        return children;
    }
}
