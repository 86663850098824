import { omit } from 'rambda';

export const defaultTextColor = '#1C1C1C';
export const defaultPrimaryColor = '#2B44FF';
export const defaultSecondaryColor = '#2B44FF';
export const defaultLinkColor = '#2B44FF';
export const defaultGrayColor = '#828796';
export const defaultErrorColor = '#f44336';

export const defaultFontStretch = '100%';
export const defaultLineHeight = 1.35;
export const defaultFontWeight = 400;

// OpenSans Condensed Variable font is 75% wdth font-stretch
export const condensedFontStretch = '75%';
export const normalFontWeight = 400;
export const semiBoldFontWeight = 600;
export const boldFontWeight = 700;

export const bodyFontSize = 14;
export const bodySecondaryFontSize = 12;
export const heading1FontSize = 32;
export const heading2FontSize = 20;
export const heading3FontSize = 18;

export const bodyTypography = {
    fontSize: bodyFontSize,
    fontWeight: defaultFontWeight,
    fontStretch: defaultFontStretch,
    lineHeight: defaultLineHeight,
};

export const bodyStrongTypography = {
    ...bodyTypography,
    fontWeight: semiBoldFontWeight,
};

export const bodySecondaryTypography = {
    ...bodyTypography,
    fontSize: bodySecondaryFontSize,
};

export const bodySecondaryStrongTypography = {
    ...bodyStrongTypography,
    ...omit(['fontWeight'], bodySecondaryTypography),
};

export const heading1Typography = {
    fontSize: heading1FontSize,
    fontWeight: normalFontWeight,
    fontStretch: condensedFontStretch,
    lineHeight: defaultLineHeight,
    wordBreak: 'break-word',
};

export const heading2Typography = {
    ...omit(['fontSize', 'fontWeight'], heading1Typography),
    fontSize: heading2FontSize,
    fontWeight: semiBoldFontWeight,
};

export const heading3Typography = {
    ...omit(['fontSize', 'fontWeight'], heading1Typography),
    fontSize: heading3FontSize,
    fontWeight: boldFontWeight,
};

export const textLinkTypography = {
    ...bodyTypography,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: defaultTextColor,
};

export const textLinkSecondaryTypography = {
    ...textLinkTypography,
    fontSize: bodySecondaryFontSize,
};

export const linkTypography = {
    ...textLinkTypography,
    color: defaultLinkColor,
};

export const linkSecondaryTypography = {
    ...linkTypography,
    fontSize: bodySecondaryFontSize,
};
