import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isValid, lastDayOfMonth, addMonths, format, lightFormat } from 'date-fns';
import { FormControl, InputLabel, FormHelperText, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import API from 'api/api';
import { offsetDate } from 'utils/misc';
import { isDesktop } from 'utils/mobileDetect';
import { LEASE_TERM_ENDS_ON_LAST_DAY_OF_MONTH } from 'constants/constants';

const KEYBOARD_CLOSE_DURATION_MS = 30;

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: theme.shadows[8],
    },
}));

function getMenuItems(isReady, leaseTerms, unitId) {
    if (!unitId) {
        return <MenuItem key="not-ready">Please select a unit</MenuItem>;
    }
    if (!isReady) {
        return <MenuItem key="not-ready">Loading...</MenuItem>;
    }
    if (leaseTerms.length === 0) {
        return <MenuItem key="no-results">No results found</MenuItem>;
    }
    return leaseTerms.map((choice) => (
        <MenuItem key={choice} value={choice}>
            {choice} Months
        </MenuItem>
    ));
}

function getLeaseEndDateText(leaseStartDate, leaseTerm, leaseTermEnds, leaseTermStartThreshold) {
    if (!leaseStartDate || !leaseTerm) {
        return '';
    }

    if (!isValid(leaseStartDate)) {
        return '';
    }

    if (leaseTermEnds === LEASE_TERM_ENDS_ON_LAST_DAY_OF_MONTH) {
        const threshold = leaseTermStartThreshold || 1;

        // if leaseStartDate day is less or equal to the threshold, the first piece of month is counted as the first month of the terms,
        // otherwise the following month is considered the first month of the terms
        const monthsTillEndOfLease = lightFormat(leaseStartDate, 'd') <= threshold ? leaseTerm - 1 : leaseTerm;
        const leaseEnd = addMonths(leaseStartDate, monthsTillEndOfLease);
        const leaseEndLastDay = lastDayOfMonth(leaseEnd);

        return `Ends ${format(leaseEndLastDay, 'MMMM do, yyyy')}`;
    }
    const offsetDay = -1;
    return `Ends ${offsetDate(leaseStartDate, leaseTerm, offsetDay)}`;
}

export default function AvailableLeaseTermsSelector(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [leaseTerms, setLeaseTerms] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const { lease_term_ends, lease_term_start_threshold } = props.config;
    const { leaseStartDate, unitId, leaseTransaction } = props;

    useEffect(() => {
        if (unitId && leaseTerms.length === 0 && leaseStartDate != null) {
            const data = {
                unit_id: unitId,
                move_in_date: leaseStartDate.toISOString().split('T')[0],
            };

            API.fetchAvailableLeaseTerms(leaseTransaction.id, data).then((res) => {
                if (res.lease_terms?.length) setLeaseTerms(res.lease_terms);
                setIsReady(true);
            });
        }
    }, [unitId, leaseStartDate, leaseTerms, leaseTransaction.id]);

    const handleClick = useCallback(async () => {
        if (props.disabled) return;
        if (isDesktop) {
            return setOpen(!open);
        }

        if (!open) {
            // Close keyboard and wait for it to close before showing lease term options
            document.activeElement.blur();
            await new Promise((resolve) => setTimeout(resolve, KEYBOARD_CLOSE_DURATION_MS));
            return setOpen(true);
        }

        setOpen(false);
    }, [open, props.disabled]);

    return (
        <FormControl fullWidth onClick={handleClick} disabled={props.disabled}>
            <InputLabel htmlFor="lease-term">Lease Term</InputLabel>
            <Select
                open={open}
                fullWidth
                value={isReady && props.leaseTerm ? props.leaseTerm : ''}
                onChange={props.handleChange}
                MenuProps={{ classes: { paper: classes.paper } }}
                inputProps={{
                    name: 'lease_term',
                    id: 'lease-term',
                }}
            >
                {getMenuItems(isReady, leaseTerms, props.unitId)}
            </Select>
            <FormHelperText>
                {getLeaseEndDateText(
                    props.leaseStartDate,
                    props.leaseTerm,
                    lease_term_ends,
                    lease_term_start_threshold
                )}
            </FormHelperText>
        </FormControl>
    );
}

AvailableLeaseTermsSelector.propTypes = {
    unitId: PropTypes.number,
    leaseTerm: PropTypes.any,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    leaseStartDate: PropTypes.instanceOf(Date),
    leaseTransaction: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
};

AvailableLeaseTermsSelector.defaultProps = {
    leaseStartDate: null,
};
