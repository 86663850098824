import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from 'constants/constants';

import IdentityVerificationProvider from './IdentityVerificationContext';
import OTPRequestPage from './pages/OTPRequestPage';
import OTPVerifyPage from './pages/OTPVerifyPage';
import QuestionsPage from './pages/QuestionsPage';
import PlaidLaunchPage from './pages/PlaidLaunchPage';
import ManuaIdentityVerificationPage from './pages/ManualIdentityVerificationPage';

export function IdentityVerificationContainer() {
    return (
        <IdentityVerificationProvider>
            <Switch>
                <Route path={ROUTES.IDENTITY_VERIFICATION_OTP_REQUEST} component={OTPRequestPage} />
                <Route path={ROUTES.IDENTITY_VERIFICATION_OTP_VERIFY} component={OTPVerifyPage} />
                <Route path={ROUTES.IDENTITY_VERIFICATION_QUESTIONS} component={QuestionsPage} />
                <Route path={ROUTES.IDENTITY_VERIFICATION_LAUNCH_PLAID} component={PlaidLaunchPage} />
                <Route path={ROUTES.MANUAL_IDENTITY_VERIFICATION} component={ManuaIdentityVerificationPage} />
            </Switch>
        </IdentityVerificationProvider>
    );
}

export default IdentityVerificationContainer;
