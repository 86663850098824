import PropTypes from 'prop-types';

export const leaseTransactionType = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.number.isRequired,
    move_in_date: PropTypes.string,
    move_out_date: PropTypes.string,
    lease_start_date: PropTypes.string,
    lease_end_date: PropTypes.string,
    lease_term: PropTypes.number,
    fees_breakdown: PropTypes.object,
    role: PropTypes.number,
    unit: PropTypes.object,
    community: PropTypes.object,
    type: PropTypes.number,
});
