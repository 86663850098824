import { createSlice } from '@reduxjs/toolkit';

import API from 'api/api';

const banking = createSlice({
    name: 'banking',
    initialState: null,
    reducers: {
        bankingDataReceived(state, action) {
            state = action.payload;
            return state;
        },
        bankingDataCleared(state) {
            state = null;
            return state;
        },
    },
});

const { actions, reducer } = banking;
export const { bankingDataReceived, bankingDataCleared } = actions;
export default reducer;

export const refreshFinancialSources = () => {
    return async (dispatch, getState) => {
        const { transaction } = getState();
        const result = await API.getFinancialSources(transaction.id);
        dispatch(bankingDataReceived(result));
        return result;
    };
};

export const clearFinancialSources = () => {
    return async (dispatch, getState) => {
        const { transaction } = getState();
        await API.resetApplicantFinancials(transaction.id);
        dispatch(bankingDataCleared());
    };
};
