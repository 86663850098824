import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ROUTES } from 'constants/constants';
import captureRoute from 'utils/captureRoute';
import { applicantUpdated } from 'reducers/applicant';
import { fetchPayments } from 'reducers/payments';

import { ApplicationApprovedView } from 'pages/Application/components/ApplicationApprovedView';
import SignLeaseView from 'pages/Application/components/SignLeaseView/SignLeaseView';
import withTransactionPath from 'utils/withTransactionPath';
import { LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION } from 'constants/leaseTransaction';

export const ApplicationApprovedPage = ({
    history = {},
    applicant = null,
    leaseTransaction = null,
    configuration = null,
    applicantUpdated = (f) => f,
    fetchPayments = (f) => f,
    getTransactionPath = (f) => f,
}) => {
    const [showSignDocumentPage, setShowSignDocumentPage] = useState(false);
    let skipApprovedPage = null;

    if (leaseTransaction?.type === LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION) {
        skipApprovedPage = true;
    } else {
        skipApprovedPage = false;
    }

    if (!leaseTransaction || !configuration || !applicant || skipApprovedPage === null) return null;

    if (skipApprovedPage || showSignDocumentPage) {
        return (
            <SignLeaseView
                history={history}
                leaseTransaction={leaseTransaction}
                configuration={configuration}
                applicantUpdated={applicantUpdated}
                fetchPayments={fetchPayments}
                setShowSignDocumentPage={setShowSignDocumentPage}
                getTransactionPath={getTransactionPath}
                applicant={applicant}
            />
        );
    }
    if (!skipApprovedPage)
        return (
            <ApplicationApprovedView
                history={history}
                applicant={applicant}
                leaseTransaction={leaseTransaction}
                configuration={configuration}
                setShowSignDocumentPage={setShowSignDocumentPage}
            />
        );
};

ApplicationApprovedPage.propTypes = {
    history: PropTypes.object,
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,

    updateApplicant: PropTypes.object,
    applicantUpdated: PropTypes.func,
    fetchPayments: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    leaseTransaction: state.transaction,
    configuration: state.configuration,
});

const mapDispatchToProps = {
    applicantUpdated,
    fetchPayments,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(captureRoute(withTransactionPath(ApplicationApprovedPage), ROUTES.APPLICATION_STATUS_APPROVED));
