import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import API from 'api/api';
import { ROUTES, RENTER_PROFILE_TYPE_GUARANTOR } from 'constants/constants';

import { fetchTransaction } from 'reducers/transaction';
import { actions as loaderActions } from 'reducers/loader';
import withTransactionPath from 'utils/withTransactionPath';

import { BackLink } from 'common-components/BackLink/BackLink';
import { InviteForm } from 'common-components/InviteForm/InviteForm';
import ConfirmationPage from 'pages/Confirmation';
import { SpacedH1, SpacedH6 } from 'assets/styles';
import resendEnvelope from 'assets/new-icons/send-email-fly-steamline.svg';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';

const ImageContainer = styled.div`
    margin-top: 31px;
    margin-bottom: 31px;
    img {
        max-height: 90px;
        max-width: 90px;
    }
`;

export class ResendLinkForm extends Component {
    state = { confirmSent: false, errors: null };

    onSubmit = (values, { setSubmitting, setErrors }) => {
        const resendValues = Object.assign({}, values);
        resendValues.resend_invite = true;

        this.props.toggleLoader(true);

        API.updateInvitee(this.props.leaseTransaction.id, resendValues, values.id)
            .then((res) => {
                if (res.error_type === 'ValidationError') {
                    if (!values.email && !values.phone_number) {
                        return this.setState({ errors: ['Phone Number or Email are required'] });
                    } else if (!values.email) {
                        return setErrors({ phone_number: res.errors.phone_number[0], email: null });
                    } else if (!values.phone_number) {
                        return setErrors({ email: res.errors.email[0], phone_number: null });
                    } else {
                        return this.setState({
                            errors: ['There was an error with your submission. Please try again.'],
                        });
                    }
                }
                this.props.fetchTransaction();
                this.setState({ confirmSent: true });
            })
            .catch((res) => {
                if (res?.responseError?.errors?.email) {
                    this.setState({ errors: res.responseError.errors.email });
                } else {
                    this.setState({ errors: ['There was an error with your submission. Please try again.'] });
                }
            })
            .finally(() => {
                this.props.toggleLoader(false);
                setSubmitting(false);
            });
    };

    render() {
        if (this.state.confirmSent) {
            return (
                <ConfirmationPage
                    successMessage="Amazing! Invite Sent"
                    secondarySuccessMessage="You’ll be able to track everyone else’s progress from your rental profile."
                    buttonClick={() => this.props.history.push(this.props.location.state.returnRoute)}
                    buttonText="Continue"
                    secondaryButtonClick={() => this.props.history.push(ROUTES.CO_APPLICANTS)}
                    secondaryButtonText="Add Another Person"
                />
            );
        }
        return (
            <>
                <SpacedH1>Let’s Try This Again</SpacedH1>
                <SpacedH6>Double check all the information below and let’s resend the invite</SpacedH6>
                {this.state.errors && <GenericFormMessage type="error" messages={this.state.errors} />}
                <ImageContainer>
                    <img src={resendEnvelope} alt="coin" />
                </ImageContainer>
                <InviteForm
                    handleOnSubmit={this.onSubmit}
                    initialValues={this.props.location.state?.initialValues}
                    isGuarantor={this.props.location.state?.returnRoute.includes(RENTER_PROFILE_TYPE_GUARANTOR)}
                />
                <BackLink to={this.props.location.state?.returnRoute} />
            </>
        );
    }
}

ResendLinkForm.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    toggleLoader: PropTypes.func,
    fetchTransaction: PropTypes.func,
    leaseTransaction: PropTypes.object.isRequired,
    getTransactionPath: PropTypes.func,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
});

const mapDispatchToProps = {
    fetchTransaction,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(ResendLinkForm));
