import { stringToBoolean } from 'utils/misc';
import { RENTAL_OPTIONS_PETS_CATS, RENTAL_OPTIONS_PETS_DOGS, RENTAL_OPTIONS_PETS_OTHER } from 'constants/constants';

const getRentalOptionByLeasingCategory = (rentalOptions, category) => {
    return rentalOptions?.find((r) => r.leasing_category === category) || null;
};

export const getPetLimits = (rentalOptions = []) => {
    const limits = {
        [RENTAL_OPTIONS_PETS_DOGS]: 0,
        [RENTAL_OPTIONS_PETS_CATS]: 0,
        [RENTAL_OPTIONS_PETS_OTHER]: 0,
    };

    limits[RENTAL_OPTIONS_PETS_DOGS] =
        getRentalOptionByLeasingCategory(rentalOptions, RENTAL_OPTIONS_PETS_DOGS)?.limit || 0;
    limits[RENTAL_OPTIONS_PETS_CATS] =
        getRentalOptionByLeasingCategory(rentalOptions, RENTAL_OPTIONS_PETS_CATS)?.limit || 0;
    limits[RENTAL_OPTIONS_PETS_OTHER] =
        getRentalOptionByLeasingCategory(rentalOptions, RENTAL_OPTIONS_PETS_OTHER)?.limit || 0;

    return limits;
};

export const getIsPetLimitExceeded = (selectedRentalOptions = [], type = undefined, limit = Number.MAX_VALUE) => {
    const petsCount =
        selectedRentalOptions?.filter((p) => {
            return p.pet_type === type && !stringToBoolean(p.service_animal);
        })?.length || 0;
    return petsCount > limit;
};

const getNotExemptedItemsCount = (selectedRentalOption) => {
    const { leasing_context: leasingContext = {}, quantity = 0 } = selectedRentalOption || {};
    const { pets = [] } = leasingContext;

    let count = quantity;
    // Exclude service animals from the count
    if (pets.length > 0) {
        count = pets?.filter((p) => !stringToBoolean(p.service_animal))?.length || 0;
    }

    return count;
};

export const getLimitExceededRentalOptionGroups = (rentalOptions = [], selectedRentalOptions = []) => {
    const groups = {};

    selectedRentalOptions.forEach((selectedOption) => {
        const rentalOption = rentalOptions.find((r) => r.id === selectedOption.rental_option.id) || {};
        const { rental_option_group: rentalOptionGroup = {} } = rentalOption;
        if (!rentalOptionGroup?.maximum_count) {
            return;
        }

        const group = groups[rentalOptionGroup.id] || {
            id: rentalOptionGroup.id,
            name: rentalOptionGroup.name,
            maximum: rentalOptionGroup.maximum_count,
            limitExceeded: false,
            count: 0,
            rentalOptions: [],
        };

        const count = getNotExemptedItemsCount(selectedOption);
        group.count += count;
        group.limitExceeded = group.count > group.maximum;
        groups[rentalOptionGroup.id] = group;
    });

    return Object.values(groups).filter((group) => group.limitExceeded);
};

export const getIsPetsLimitsExceeded = (rentalOptions = [], selectedRentalOptions = []) => {
    const petLimits = getPetLimits(rentalOptions);

    return !!selectedRentalOptions.find((option) => {
        const limit = petLimits[option.pet_type] || Number.MAX_VALUE;
        return getIsPetLimitExceeded(selectedRentalOptions, option.pet_type, limit);
    });
};
