import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import API from 'api/api';
import { ROUTES } from 'constants/constants';
import captureRoute from 'utils/captureRoute';
import { sessionIsValidForCommunityId } from 'utils/misc';

import UnauthenticatedPage from 'pages/Unauthenticated';
import { ScrollableTermsCardSection, Card, SpacedH1 } from 'assets/styles';

export function PrivacyPolicyPage(props) {
    const [html, setHtml] = useState(null);

    useEffect(() => {
        API.fetchPrivacyPolicy().then((result) => {
            setHtml(result);
        });
    }, []);

    if (!html) return null;

    const base = (
        <>
            <SpacedH1>Privacy Policy</SpacedH1>
            <br />
            <Card>
                <ScrollableTermsCardSection>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: html,
                        }}
                    />
                </ScrollableTermsCardSection>
            </Card>
        </>
    );

    if (props.isSignedIn) {
        return base;
    }

    return <UnauthenticatedPage>{base}</UnauthenticatedPage>;
}

PrivacyPolicyPage.propTypes = {
    isSignedIn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isSignedIn: sessionIsValidForCommunityId(state.siteConfig.basename),
});

export default connect(mapStateToProps)(captureRoute(PrivacyPolicyPage, ROUTES.PRIVACY_POLICY));
