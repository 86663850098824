import { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePlaidLink } from 'react-plaid-link';
import API from 'api/api';
import {
    PLAID_PRODUCTS,
    PLAID_INCOME_BANK,
    PLAID_INCOME_PAYROLL,
    PLAID_INCOME_PAYROLL_MANUAL,
} from 'constants/constants';

function PlaidLink({ leaseTransactionId, plaidProduct, updateState, handleOnSuccessCallback, onClose }) {
    const [token, setToken] = useState(null);
    const bankIncomeCompleted = useRef(null);
    const reportType = useRef(PLAID_INCOME_PAYROLL);
    const identityVerificationComplete = useRef(null);

    const onSuccess = useCallback(
        (publicToken, metadata) => {
            // https://plaid.com/docs/api/tokens/#token-exchange-flow
            if (bankIncomeCompleted.current) {
                updateState({
                    showPlaidIframe: null,
                    errors: null,
                    loadingReport: true,
                    isLoading: false,
                    plaidProduct: plaidProduct,
                });
                handleOnSuccessCallback(publicToken, reportType.current, plaidProduct);
            }

            if (
                [
                    PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_PAYROLL,
                    PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_PAYROLL_PAYSTUB,
                    PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_PAYROLL_TAXFORM,
                ].includes(plaidProduct)
            ) {
                updateState({
                    showPlaidLink: false,
                    loadingReport: true,
                    plaidProduct: plaidProduct,
                });
                handleOnSuccessCallback(publicToken, reportType.current, plaidProduct);
            }

            if (identityVerificationComplete.current) {
                handleOnSuccessCallback(metadata);
            }
        },
        [bankIncomeCompleted, updateState, handleOnSuccessCallback, plaidProduct, identityVerificationComplete]
    );
    const onEvent = useCallback(
        (eventName) => {
            if (eventName === 'BANK_INCOME_INSIGHTS_COMPLETED') {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                bankIncomeCompleted.current = true;
                reportType.current = PLAID_INCOME_BANK;
            }
            if (eventName === 'SUBMIT_DOCUMENTS_SUCCESS') {
                //this event means that manual payroll docs were uploaded
                reportType.current = PLAID_INCOME_PAYROLL_MANUAL;
            }
            if (
                eventName === 'IDENTITY_VERIFICATION_FAIL_SESSION' ||
                eventName === 'IDENTITY_VERIFICATION_PASS_SESSION'
            ) {
                identityVerificationComplete.current = true;
            }
            // https://plaid.com/docs/link/web/#onevent
        },
        [bankIncomeCompleted]
    );
    const onExit = useCallback(() => {
        // https://plaid.com/docs/link/web/#onexit
        onClose();
    }, [onClose]);

    // Creates a Link token
    const createLinkToken = useCallback(() => {
        // For OAuth, use previously generated Link token
        if (window.location.href.includes('?oauth_state_id=')) {
            const linkToken = localStorage.getItem('link_token');
            setToken(linkToken);
        } else {
            API.createPlaidLinkTokenUrl(leaseTransactionId, plaidProduct).then((res) => {
                setToken(res.token);
                localStorage.setItem('link_token', res.token);
            });
        }
    }, [setToken, leaseTransactionId, plaidProduct]);

    let isOauth = false;

    const config = {
        token,
        onSuccess,
        onEvent,
        onExit,
    };

    // For OAuth, configure the received redirect URI
    if (window.location.href.includes('?oauth_state_id=')) {
        config.receivedRedirectUri = window.location.href;
        isOauth = true;
    }
    const { open, ready } = usePlaidLink(config);

    useEffect(() => {
        if (token == null) {
            createLinkToken();
        }
        if (ready) {
            open();
        }
    }, [token, isOauth, ready, open, createLinkToken]);

    return null;
}

PlaidLink.propTypes = {
    leaseTransactionId: PropTypes.number,
    plaidProduct: PropTypes.string,
    updateState: PropTypes.func,
    handleOnSuccessCallback: PropTypes.func,
    onClose: PropTypes.func,
};

export default PlaidLink;
