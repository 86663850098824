export const VERIFICATION_METHOD_AUTOMATIC = 10;
export const VERIFICATION_METHOD_SELECT_METHOD = 20;
export const VERIFICATION_METHOD_QUESTIONS = 30;
export const VERIFICATION_METHOD_PIN = 40;
export const VERIFICATION_METHOD_PIN_CALL = 42;
export const VERIFICATION_METHOD_PIN_TEXT = 44;

export const TU_ANSWER_KEY_PIN_TEXT = 'Deliver passcode via Text Message';
export const TU_ANSWER_KEY_PIN_CALL = 'Deliver passcode via Voice Call';
export const TU_QUESTION_KEY_SELECT_METHOD = 'IDM_Choice';
export const TU_QUESTION_KEY_ENTER_PIN = 'IDM_PINVERIFY';
