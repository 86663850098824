import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import API from 'api/api';

import { currencyToNumber, getMonthToMonthRent } from 'pages/Renewal/utils/helpers';
import { PaymentDetailsRows, PaymentItemsExpansionPanel } from 'common-components/PaymentDetails';
import { styles, StyledComponents } from 'common-components/PaymentDetails/styles';
import lightbulb from 'assets/new-icons/light-bulb-shine-streamlined.svg';

const useStyles = makeStyles(() => ({
    headerContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        textTransform: 'none',
        paddingBottom: 0,
        marginBottom: '-10px',
    },
}));

export const MonthToMonthPaymentDetailsCard = ({ lease }) => {
    const [paymentData, setPaymentData] = useState(null);

    useEffect(() => {
        API.getCurrentQuoteMonthly(lease.current_quote.id).then(setPaymentData);
    }, [lease]);

    const classes = useStyles();

    const monthlyFees = useMemo(() => {
        if (!lease) {
            return null;
        }
        const monthToMonthBaseRent = getMonthToMonthRent(lease);
        if (!paymentData?.monthly_fees) {
            return {
                items: [],
                total: monthToMonthBaseRent.toLocaleString('en-US'),
            };
        }

        const baseRentItem = paymentData.monthly_fees.items.find((item) => item.category === 'base_rent');
        const monthlyFeesItems = paymentData.monthly_fees.items.filter((item) => item.category !== 'base_rent');

        const currentBaseRent = currencyToNumber(baseRentItem.amount);
        const currentTotalPrice = currencyToNumber(paymentData.monthly_fees.total);
        const monthToMonthTotalPrice = currentTotalPrice - currentBaseRent + monthToMonthBaseRent;

        const monthlyFees = {
            items: [baseRentItem, ...monthlyFeesItems],
            total: monthToMonthTotalPrice.toLocaleString('en-US'),
        };

        monthlyFees.items[0] = {
            ...monthlyFees.items[0],
            amount: monthToMonthBaseRent.toLocaleString('en-US'),
        };

        return monthlyFees;
    }, [lease, paymentData]);

    if (!monthlyFees) {
        return null;
    }

    return (
        <StyledComponents.PaymentCard>
            <div className={classes.headerContainer}>
                <div className={styles.imageWrapper}>
                    <img alt="coin" src={lightbulb} />
                </div>
                <div className={styles.cardHeader}>
                    <div className={styles.title}>Payment breakdown</div>
                    <div>Lease Term: Month-to-month</div>
                </div>
            </div>
            <PaymentItemsExpansionPanel amount={monthlyFees.total} label={'monthly rent'} defaultExpanded={false}>
                <PaymentDetailsRows paymentObject={monthlyFees} paymentType="monthly_fees" />
            </PaymentItemsExpansionPanel>
        </StyledComponents.PaymentCard>
    );
};

MonthToMonthPaymentDetailsCard.propTypes = {
    lease: PropTypes.object.isRequired,
    paymentBreakdown: PropTypes.object,
    fetchPaymentBreakdown: PropTypes.func,
};

export default MonthToMonthPaymentDetailsCard;
