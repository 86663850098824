import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Info as InfoIcon } from '@material-ui/icons';

import { CATEGORY_ADDITIONAL_SECURITY_DEPOSIT, CATEGORY_UNIT_SECURITY_DEPOSIT } from 'constants/leaseTransaction';
import { selectors } from 'reducers/renter-profile';
import { getRentalOptionFeeDisplayName } from 'common-components/PaymentDetails/utils';

import PaidText from 'common-components/PaidText/PaidText';
import SimplePopover from 'common-components/SimplePopover/SimplePopover';
import { styles } from 'common-components/PaymentDetails/styles';
import { infoIconRoot } from 'assets/styles';

export const TYPE_CONCESSION = 'concession';

export const PaymentLineItems = ({ lineItems, everyone }) => {
    const lineItemsWithPersonName = lineItems.map((lineItem) => {
        const person =
            everyone.find((person) => {
                return parseInt(lineItem.applicant) === person.applicant_id || parseInt(lineItem.invitee) === person.id;
            }) || {};
        return {
            ...lineItem,
            name: `${person.first_name} ${person.last_name}`,
        };
    });

    return (
        <div>
            {lineItemsWithPersonName.map((lineItem) => (
                <div
                    key={lineItem.id}
                    className={styles.paymentLineItem}
                    data-testid="payment-breakdown-section-inner-item"
                >
                    <span>{lineItem.name}</span>
                    {lineItem.waived ? <PaidText waived={true} /> : <span>${lineItem.amount}</span>}
                </div>
            ))}
        </div>
    );
};

PaymentLineItems.propTypes = {
    lineItems: PropTypes.array.isRequired,
    everyone: PropTypes.array.isRequired,
};

export const PaymentDetailRow = ({
    style,
    className,
    paymentTotal,
    name,
    everyone,
    item = {},
    enableDepositAlternative = false,
}) => {
    const {
        price,
        prorated,
        days,
        exempted,
        type,
        per_day: perDay,
        processed: isPaid,
        quantity = 1,
        included = 0,
        line_items: lineItems = [],
    } = item;

    if (!quantity && !lineItems.length) return null;

    const showItemized = lineItems.length > 1;
    const isWaived = !!lineItems.length && lineItems.every((item) => item.waived);
    const displayName = getRentalOptionFeeDisplayName(
        name,
        price,
        quantity,
        included,
        prorated,
        days,
        perDay,
        exempted,
        showItemized,
        isWaived
    );

    if (showItemized) {
        return (
            <>
                <div style={style} className={className} data-testid="payment-breakdown-section-item">
                    <div>{displayName}</div>
                </div>
                <PaymentLineItems lineItems={lineItems} everyone={everyone} />
            </>
        );
    }

    const getFeeHelpText = (item) => {
        return item.fee_help_text || `Option to choose between refundable deposit or non-refundable fee.`;
    };

    return (
        <div style={style} className={className} data-testid="payment-breakdown-section-item">
            <div>
                {displayName}{' '}
                {[CATEGORY_UNIT_SECURITY_DEPOSIT, CATEGORY_ADDITIONAL_SECURITY_DEPOSIT].includes(item.category) &&
                    enableDepositAlternative && (
                        <SimplePopover text={getFeeHelpText(item)}>
                            <InfoIcon classes={{ root: infoIconRoot }} style={{ color: '#828796', width: 16 }} />
                        </SimplePopover>
                    )}
            </div>
            <div className={styles.paidTextContainer}>
                {isWaived ? (
                    <PaidText waived={true} />
                ) : (
                    <>
                        {isPaid && <PaidText />}
                        <div>
                            {type === TYPE_CONCESSION && `-`}${paymentTotal}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

PaymentDetailRow.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string.isRequired,
    paymentTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    everyone: PropTypes.array.isRequired,
    enableDepositAlternative: PropTypes.bool,
    item: PropTypes.shape({
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        quantity: PropTypes.number,
        included: PropTypes.number,
        prorated: PropTypes.bool,
        days: PropTypes.number,
        per_day: PropTypes.string,
        exempted: PropTypes.number,
        type: PropTypes.string,
        processed: PropTypes.bool,
        line_items: PropTypes.array,
    }),
};

export function PaymentDetailRows({
    rowStyle = {},
    paymentObject = {},
    everyone = [],
    className = null,
    paymentType = null,
    label = null,
    disclaimer = null,
    enableDepositAlternative = false,
    showTotal = true,
}) {
    const rows = paymentObject.items.map((item) => (
        <PaymentDetailRow
            key={`${item.name}-${paymentType}`}
            className={styles.paymentDetailRow}
            style={rowStyle}
            name={item.name}
            paymentTotal={item.amount}
            item={item}
            everyone={everyone}
            enableDepositAlternative={enableDepositAlternative}
        />
    ));

    const showTaxes = parseFloat(paymentObject.total_tax) > 0;
    showTaxes &&
        rows.push(
            <PaymentDetailRow
                key={`tax${paymentType}`}
                className={styles.paymentDetailRow}
                style={rowStyle}
                name="Tax"
                paymentTotal={paymentObject.total_tax}
                everyone={everyone}
            />
        );

    showTotal &&
        rows.push(
            <PaymentDetailRow
                key={`total${paymentType}`}
                className={styles.paymentDetailRowTotal}
                style={rowStyle}
                name="Total"
                paymentTotal={paymentObject.total}
                everyone={everyone}
            />
        );

    return (
        <div className={className}>
            {label && <div className={styles.paymentDetailRowsLabel}>{label}</div>}
            {disclaimer && <div className={styles.paymentDetailRowsDisclaimer}>{disclaimer}</div>}
            {rows}
        </div>
    );
}

PaymentDetailRows.propTypes = {
    rowStyle: PropTypes.object,
    paymentObject: PropTypes.object,
    everyone: PropTypes.array,
    paymentType: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    disclaimer: PropTypes.string,
    enableDepositAlternative: PropTypes.bool,
    showTotal: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    everyone: selectors.selectEveryone(state),
});

export default connect(mapStateToProps)(PaymentDetailRows);
