import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Typography } from '@material-ui/core';

import ActionButton from 'common-components/ActionButton/ActionButton';
import Page from 'common-components/Page/Page';
import { Card, ColoradoLegalLanguageSection } from 'assets/styles';

const useStyles = makeStyles(() => ({
    root: {
        margin: '10px 0px 0px 0px',
    },
    contactText: {
        margin: '0px 0px 50px 10px',
        textAlign: 'left',
    },
    provisionThree: {
        paddingLeft: '10px',
    },
    provisionFour: {
        paddingLeft: '10px',
    },
}));

const COLORADO_LEGAL_DISCLAIMER =
    'Per the requirements outlined in Colorado Revised Statutes, 38-12-904, you are advised as follows:';
const COLORADO_LEGAL_TEXT_LINE_ONE =
    '1. YOU HAVE THE RIGHT TO PROVIDE TO THE LANDLORD A PORTABLE TENANT SCREENING REPORT, AS DEFINED ' +
    'IN SECTION 38-12-902 (2.5), COLORADO REVISED STATUTES; AND';
const COLORADO_LEGAL_TEXT_LINE_TWO =
    '2. IF YOU PROVIDE THE LANDLORD WITH A PORTABLE TENANT SCREENING REPORT, THE LANDLORD IS PROHIBITED FROM:';
const COLORADO_LEGAL_TEXT_LINE_THREE = 'CHARGING YOU A RENTAL APPLICATION FEE; OR';
const COLORADO_LEGAL_TEXT_LINE_FOUR =
    'CHARGING YOU A FEE FOR THE LANDLORD TO ACCESS OR USE THE PORTABLE TENANT SCREENING REPORT.';

export const ScreeningOptionsView = ({
    onSelfScreening = () => {},
    onFunnelScreening = () => {},
    contactPhone = '',
}) => {
    const classes = useStyles();
    return (
        <Page
            title="Applicant Screening"
            subTitle="You have the right to choose between using our screening process or providing your own report."
            className={classes.root}
        >
            <Card>
                <ColoradoLegalLanguageSection>
                    {COLORADO_LEGAL_DISCLAIMER}
                    <br />
                    <br />
                    {COLORADO_LEGAL_TEXT_LINE_ONE}
                    <br />
                    {COLORADO_LEGAL_TEXT_LINE_TWO}
                    <br />
                    <div className={classes.provisionThree}>{COLORADO_LEGAL_TEXT_LINE_THREE}</div>
                    <div className={classes.provisionFour}>{COLORADO_LEGAL_TEXT_LINE_FOUR}</div>
                </ColoradoLegalLanguageSection>
            </Card>
            <Typography variant="body2" data-testid="screening-options-contact" className={classes.contactText}>
                To learn more about providing your own report, please call us at{' '}
                <a href={`tel:${contactPhone}`}>{contactPhone}</a>.
            </Typography>
            <ActionButton
                data-testid="screening-options-continue-button"
                onClick={onFunnelScreening}
                marginTop={25}
                marginBottom={20}
            >
                Continue with Screening Process
            </ActionButton>
            <ActionButton
                data-testid="screening-options-provide-button"
                onClick={onSelfScreening}
                variant="outlined"
                marginBottom={20}
            >
                Provide My Own Screening Report
            </ActionButton>
        </Page>
    );
};

ScreeningOptionsView.propTypes = {
    onSelfScreening: PropTypes.func,
    onFunnelScreening: PropTypes.func,
    contactPhone: PropTypes.string,
};

export default ScreeningOptionsView;
