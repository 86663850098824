import { css } from 'emotion';

export const existingItemRow = css`
    border-top: 1px solid #eeeeee;
    padding: 10px 0;
    display: flex;
    text-align: left;
    justify-content: space-between;
`;

export const existingItemsContainer = css`
    margin-bottom: 15px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    .MuiExpansionPanelSummary-root {
        padding: 0;
    }
`;
