import PropTypes from 'prop-types';

import { ESIGN_PROVIDER_HELLOSIGN, ESIGN_PROVIDER_BLUE_MOON } from 'constants/signLeaseView';

import HelloSignLeaseView from 'pages/Application/components/SignLeaseView/HelloSignLeaseView';
import BlueMoonLeaseView from 'pages/Application/components/SignLeaseView/BlueMoonLeaseView';

export const DynamicESignView = ({
    applicantUpdated,
    getTransactionPath,
    history,
    leaseTransaction,
    props,
    type,
    setLoading,
}) => {
    const componentMap = {
        [ESIGN_PROVIDER_HELLOSIGN]: HelloSignLeaseView,
        [ESIGN_PROVIDER_BLUE_MOON]: BlueMoonLeaseView,
    };

    const Component = componentMap[type];

    if (!Component) {
        return null;
    }

    return (
        <Component
            applicantUpdated={applicantUpdated}
            getTransactionPath={getTransactionPath}
            history={history}
            leaseTransaction={leaseTransaction}
            setLoading={setLoading}
            {...props}
        />
    );
};
DynamicESignView.propTypes = {
    applicantUpdated: PropTypes.func,
    history: PropTypes.object,
    leaseTransaction: PropTypes.object,
    getTransactionPath: PropTypes.func,
    props: PropTypes.object.isRequired,
    setLoading: PropTypes.func,
    type: PropTypes.number,
};
