import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core';

import { ROUTES } from 'constants/constants';

import Page from 'common-components/Page/Page';
import ActionButton from 'common-components/ActionButton/ActionButton';

import houseLove from 'assets/new-icons/real-estate-house-love.svg';
import { arrowIcon, blackLinkRoot, LinkButton } from 'assets/styles';

const useStyles = makeStyles(() => ({
    renewalIcon: {
        width: '180px',
        height: '180px',
        marginBottom: '0',
        marginTop: '30px',
    },
    form: {
        marginTop: '60px',
        textAlign: 'left',
    },
}));

export function MonthToMonthInitialPage({ lease, history }) {
    const classes = useStyles();

    if (!lease) return null;

    return (
        <Page
            title="Month-to-Month"
            subTitle={"All that's left to do is review your rental profile."}
            image={{ src: houseLove, className: classes.renewalIcon }}
        >
            <p>
                {lease.unit.building.street_address} Unit {lease.unit.unit_number}
            </p>
            <ActionButton
                onClick={() =>
                    history.push(
                        generatePath(ROUTES.LEASE_RENEWAL_MONTH_TO_MONTH_PAYMENT_DETAILS, { lease_id: lease.id })
                    )
                }
                marginTop={100}
                marginBottom={20}
            >
                Continue
            </ActionButton>
            <LinkButton
                className={blackLinkRoot}
                onClick={() => history.push(generatePath(ROUTES.LEASE_RENEWAL_OFFERS, { lease_id: lease.id }))}
            >
                <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
            </LinkButton>
        </Page>
    );
}

MonthToMonthInitialPage.propTypes = {
    lease: PropTypes.object,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    lease: state.lease,
});

export default connect(mapStateToProps)(MonthToMonthInitialPage);
