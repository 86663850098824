import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import styled from '@emotion/styled';

import { ROUTES, RENTER_PROFILE_TYPE_GUARANTOR } from 'constants/constants';
import API from 'api/api';

import { fetchTransaction } from 'reducers/transaction';
import { fetchApplicant } from 'reducers/applicant';
import { pageComplete, selectors } from 'reducers/renter-profile';
import { actions as loaderActions } from 'reducers/loader';

import InviteForm from 'common-components/InviteForm/InviteForm';
import BackLink from 'common-components/BackLink/BackLink';
import ConfirmationPage from 'pages/Confirmation';
import { SpacedH1, SpacedH6 } from 'assets/styles';
import coin from 'assets/new-icons/accounting-coins-streamlined.svg';
import withTransactionPath from 'utils/withTransactionPath';
import { totalGuarantorsCount } from 'utils/misc';
import { LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE } from 'constants/leaseTransaction';
import { REQUESTED_GUARANTORS_ADDED } from 'constants/pageComplete';

const ERROR_INVITE =
    'There was an error sending your guarantor an invite. Make sure you are not using the same email or phone number. Please Try again.';

const ImageContainer = styled.div`
    margin-top: 31px;
    margin-bottom: 31px;
    img {
        max-height: 90px;
        max-width: 90px;
    }
`;

export class GuarantorPage extends Component {
    state = {
        confirmSent: false,
        errors: null,
        maximumAllowedGuarantorsReached: this.maximumAllowedGuarantorsReached,
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.leaseTransaction?.guarantors !== prevProps.leaseTransaction?.guarantors ||
            this.props.leaseTransaction?.invitees !== prevProps.leaseTransaction?.invitees
        ) {
            this.setState({
                maximumAllowedGuarantorsReached: this.maximumAllowedGuarantorsReached,
            });
        }
    }

    get maximumAllowedGuarantorsReached() {
        if (!(this.props.leaseTransaction && this.props.configuration)) return true;
        return (
            totalGuarantorsCount(this.props.leaseTransaction) >= this.props.configuration?.maximum_guarantors_allowed
        );
    }

    onSubmit = (values, { setSubmitting, setErrors }) => {
        this.props.toggleLoader(true);
        this.setState({ errors: null });

        return API.inviteGuarantor(this.props.leaseTransaction.id, { guarantors: [values] })
            .then(async (res) => {
                setSubmitting(false);
                if (res.errors) {
                    const validationErrors = get(res, 'errors.guarantors[0]');
                    validationErrors && setErrors(validationErrors);
                    this.setState({ errors: [res.errors.Error || ERROR_INVITE] });
                } else {
                    await this.props.fetchTransaction();
                    this.setState({ confirmSent: true });
                }
            })
            .catch((res) => {
                this.setState({ errors: [res.errors || ERROR_INVITE] });
            })
            .finally(() => {
                this.props.toggleLoader(false);
                setSubmitting(false);
            });
    };

    handleContinueAfterInviteSent = () => {
        if (this.props.stillFinishingApp) {
            this.props.history.push(
                this.props.getTransactionPath(`${ROUTES.PROFILE_OPTIONS}#${RENTER_PROFILE_TYPE_GUARANTOR}`)
            );
        } else {
            this.props
                .pageComplete(REQUESTED_GUARANTORS_ADDED)
                .then(() => this.props.fetchApplicant())
                .then(() => this.props.history.push(this.props.nextRoute));
        }
    };

    render() {
        if (!(this.props.leaseTransaction && this.props.configuration)) return null;

        if (this.state.confirmSent) {
            return (
                <ConfirmationPage
                    successMessage="Invite Sent!"
                    secondarySuccessMessage="You’ll be able to check in on your guarantor's progress once you complete your application."
                    buttonClick={this.handleContinueAfterInviteSent}
                    buttonText="Continue"
                    secondaryButtonClick={
                        this.state.maximumAllowedGuarantorsReached ? null : () => this.setState({ confirmSent: false })
                    }
                    secondaryButtonText="Add Another Guarantor"
                />
            );
        }

        const backLinkRoute =
            this.props.leaseTransaction.type === LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE
                ? ROUTES.GUARANTOR_REQUESTED
                : `${ROUTES.PROFILE_OPTIONS}#${RENTER_PROFILE_TYPE_GUARANTOR}`; // we restrict access to the rental profile page during MLC's

        return (
            <>
                <SpacedH1 variant="h1">Let&apos;s Invite a Guarantor</SpacedH1>
                <SpacedH6 variant="subtitle1">
                    They agree to be financially responsible for the apartment if you are unable to pay.
                </SpacedH6>
                <ImageContainer>
                    <img src={coin} alt="coin" width={100} height={100} />
                </ImageContainer>
                <InviteForm handleOnSubmit={this.onSubmit} displayedErrors={this.state.errors} isGuarantor={true} />
                <BackLink to={this.props.getTransactionPath(backLinkRoute)} />
            </>
        );
    }
}

GuarantorPage.propTypes = {
    leaseTransaction: PropTypes.object,
    stillFinishingApp: PropTypes.bool,
    nextRoute: PropTypes.string,
    history: PropTypes.object,
    toggleLoader: PropTypes.func,
    fetchTransaction: PropTypes.func,
    fetchApplicant: PropTypes.func,
    pageComplete: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
    configuration: PropTypes.object,
};

const mapStateToProps = (state) => ({
    stillFinishingApp: selectors.selectApplicantStillFinishingApplication(state),
    nextRoute: selectors.selectNextRoute(state),
    leaseTransaction: state.transaction,
    configuration: state.configuration,
});

const mapDispatchToProps = {
    fetchTransaction,
    fetchApplicant,
    pageComplete,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(GuarantorPage));
