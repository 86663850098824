import { css } from 'emotion';

export const viewPetPolicy = css`
    color: #2b44ff;
    cursor: pointer;
    text-decoration: underline;
`;

export const policyDiv = css`
    margin-bottom: 20px;
`;
