import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import ActionButton from 'common-components/ActionButton/ActionButton';
import { LinkButton } from 'assets/styles';

export const aanLink = css`
    outline: none;
`;

export const LINK_BUTTON = 'link';
export const ACTION_BUTTON = 'button';

const DEFAULT_ERROR = 'An error occurred. Please try again.';
const UNSUPPORTED_BROWSER_FEATURE_ERROR =
    'Oops! We’re having trouble opening this page. Please update your browser to the latest version and try again.';

export function DocumentNoticeButton({ componentType, fetchDocument = () => {}, setPageError = () => {}, ...props }) {
    const openDocument = async () => {
        try {
            // Reset errors
            setPageError(null);

            const w = window.open('', '_blank');
            if (!w) {
                throw new Error('Failed to open new window');
            }

            w.document.write('Loading');

            const response = await fetchDocument();
            const blobUrl = URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
            w.location.replace(blobUrl);
        } catch (e) {
            if (e?.message === 'Failed to open new window') {
                return setPageError(UNSUPPORTED_BROWSER_FEATURE_ERROR);
            }
            setPageError(DEFAULT_ERROR);
        }
    };

    const Component = componentType === LINK_BUTTON ? LinkButton : ActionButton;
    return (
        <Component onClick={openDocument} className={aanLink} {...props}>
            {props.children}
        </Component>
    );
}

DocumentNoticeButton.propTypes = {
    children: PropTypes.node,
    componentType: PropTypes.oneOf([LINK_BUTTON, ACTION_BUTTON]).isRequired,
    fetchDocument: PropTypes.func.isRequired,
    setPageError: PropTypes.func.isRequired,
};
