import React from 'react';
import { useSentryUtils } from 'common-components/SentryUtils/SentryUtilsProvider';

function withSentryUtils(Component) {
    function ContextWrapper(props) {
        const context = useSentryUtils();

        return <Component {...props} {...context} />;
    }

    return ContextWrapper;
}

export default withSentryUtils;
