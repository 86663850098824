import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import API from 'api/api';
import {
    MILESTONE_LEASE_SENT,
    MILESTONE_DOCUMENTS_SENT,
    ROLE_OCCUPANT,
    ROUTES,
    EVENT_OFFLINE_LEASE_DOCUMENT_CREATED,
} from 'constants/constants';
import {
    LEASE_TRANSACTION_TYPE_APPLICATION,
    LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE,
    LEASE_TRANSACTION_TYPE_RENEWAL,
    LEASE_TRANSACTION_TYPE_TRANSFER,
    LEASE_TRANSACTION_TYPE_VACATE,
    LEASE_TRANSACTION_TYPES,
} from 'constants/leaseTransaction';
import { prettyCurrency } from 'utils/misc';
import { getLeaseSettingsIdFromUrl, reloadWindowOnURL } from 'utils/routingHelpers';

import ActionButton from 'common-components/ActionButton/ActionButton';
import { DocumentNoticeButton, LINK_BUTTON } from 'common-components/DocumentNoticeButton/DocumentNoticeButton';
import Page from 'common-components/Page/Page';
import { arrowIcon, blackLinkRoot, LinkButton } from 'assets/styles';

import approvedSign from 'assets/new-icons/approved-house-checkmark.svg';
import houseLove from 'assets/new-icons/real-estate-house-love.svg';
import leaseVacate from 'assets/new-icons/truck-moving-streamlined.svg';
import handshakeImage from 'assets/new-icons/business-contract-handshake.svg';
import lightbulb from 'assets/new-icons/light-bulb-shine-streamlined.svg';
import mlcSign from 'assets/new-icons/task-checklist-writing.svg';
import penSignatureImage from 'assets/new-icons/content-pen-write.svg';

const useStyles = makeStyles(() => ({
    approvedImage: {
        margin: '10px 0 5px 0',
        height: '150px',
        width: '150px',
    },
    applicationUnit: {
        color: '#454b57',
        fontSize: '14px',
        lineHeight: '17px',
        textAlign: 'center',
        paddingTop: '10px',
    },
    bulbImage: {
        width: '46px',
        height: '42px',
    },
    securityDepositHelpText: {
        color: '#454b57',
        fontSize: '14px',
        lineHeight: '17px',
        textAlign: 'left',
    },
    gridContainer: {
        minHeight: '100px',
    },
    securityDepositTip: {
        marginTop: '35px',
    },
    leftText: {
        textAlign: 'left',
    },
    emph: {
        fontSize: '14px',
        fontWeight: 600,
    },
    colorBlack: {
        color: '#000',
    },
}));

export const ApplicationApprovedView = ({
    leaseTransaction = {},
    configuration = {},
    applicant = {},
    setShowSignDocumentPage = () => {},
}) => {
    const [notifications, setNotifications] = useState([]);
    const leaseSettingsId = getLeaseSettingsIdFromUrl();
    const classes = useStyles();
    const { unit, security_deposit: securityDeposit } = leaseTransaction;

    const buildingName = configuration.community.building_name || configuration.community.normalized_street_address;
    const unitNumber = !!unit && !!unit.unit_number ? ` Unit ${unit.unit_number}` : '';
    const isOccupant = applicant.role === ROLE_OCCUPANT;
    const leaseReadyToSign = leaseTransaction.events.some(({ event }) =>
        [MILESTONE_LEASE_SENT, MILESTONE_DOCUMENTS_SENT].includes(event)
    );
    const leaseIsWetSignable = leaseTransaction.events.some(({ event }) =>
        [EVENT_OFFLINE_LEASE_DOCUMENT_CREATED].includes(event)
    );
    const requireNTVDocumentSignature = configuration.require_resident_signature_for_ntv;

    const { title, subtitle, disclaimer, img, showBackButton } = useMemo(() => {
        return getCopy(
            leaseTransaction,
            applicant,
            isOccupant,
            leaseReadyToSign,
            leaseIsWetSignable,
            requireNTVDocumentSignature
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaseTransaction, applicant]);

    const setPageError = (error) => {
        if (!error) {
            return setNotifications([]);
        }
        setNotifications([
            {
                type: 'error',
                messages: [error],
            },
        ]);
    };

    return (
        <Page
            title={title}
            subTitle={subtitle}
            notifications={notifications}
            image={{
                src: img,
                className: classes.approvedImage,
            }}
        >
            <div id="application-unit" className={classes.applicationUnit}>
                {buildingName}
                {unitNumber}
            </div>
            <div className={classes.gridContainer}>
                {securityDeposit && (
                    <Grid
                        classes={{ root: classes.securityDepositTip }}
                        container
                        justify={'center'}
                        className="security-deposit-container"
                    >
                        <Grid item xs={2}>
                            <img alt="light bulb" src={lightbulb} className={classes.bulbImage} />
                        </Grid>
                        <Grid item xs={9} classes={{ root: classes.leftText }}>
                            <span className={classes.securityDepositHelpText}>
                                {configuration?.enable_deposit_alternative ? (
                                    <>
                                        An{' '}
                                        <Typography component="strong" className={classes.emph}>
                                            additional security deposit or security fee{' '}
                                        </Typography>
                                        is required for this application.&nbsp;
                                    </>
                                ) : (
                                    <>
                                        A{' '}
                                        <Typography component="strong" className={classes.emph}>
                                            {prettyCurrency(securityDeposit)} security deposit{' '}
                                        </Typography>
                                        is required for this application.&nbsp;
                                    </>
                                )}
                            </span>
                            <DocumentNoticeButton
                                componentType={LINK_BUTTON}
                                fetchDocument={() => {
                                    return API.fetchAANDocument(leaseTransaction.id);
                                }}
                                setPageError={setPageError}
                            >
                                Learn Why
                            </DocumentNoticeButton>
                        </Grid>
                    </Grid>
                )}
                {!isOccupant && leaseReadyToSign && !leaseIsWetSignable && applicant.is_pending_signature && (
                    <Box margin="28px 0 0 0">
                        <Typography component="p" variant="caption" className={classes.colorBlack}>
                            {disclaimer}
                        </Typography>
                        <ActionButton
                            className="show-payments"
                            onClick={() => setShowSignDocumentPage(true)}
                            marginTop={30}
                        >
                            Continue
                        </ActionButton>
                    </Box>
                )}
            </div>
            {showBackButton && (
                <LinkButton
                    className={blackLinkRoot}
                    onClick={() => {
                        reloadWindowOnURL(
                            `${leaseSettingsId}${generatePath(ROUTES.LEASE_RENEWAL_OFFERS, {
                                lease_id: leaseTransaction.lease.id,
                            })}`
                        );
                    }}
                >
                    <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
                </LinkButton>
            )}
        </Page>
    );
};

ApplicationApprovedView.propTypes = {
    applicant: PropTypes.object.isRequired,
    configuration: PropTypes.object.isRequired,
    leaseTransaction: PropTypes.object.isRequired,
    setShowSignDocumentPage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

const getCopy = (
    leaseTransaction,
    applicant,
    isOccupant,
    leaseReadyToSign,
    leaseIsWetSignable,
    requireNTVDocumentSignature
) => {
    let img = approvedSign;
    let subtitle = '';
    let title = '';
    let showBackButton = false;
    const documentType = leaseTransaction.type === LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE ? 'document' : 'lease';
    const disclaimer = `The ${documentType} linked below constitutes a legal agreement between you and Landlord. Funnel
    Leasing does not provide legal advice, and we recommend that you consult your legal
    counsel before accepting these terms.`;

    const defaultCopy = {
        title: '',
        subtitle: '',
        disclaimer: disclaimer,
        img: img,
    };
    if (!leaseTransaction || !applicant) {
        return defaultCopy;
    }

    const enableSelfServeRenewals = leaseTransaction.lease?.enable_self_service_renewals;
    switch (leaseTransaction.type) {
        case LEASE_TRANSACTION_TYPE_RENEWAL:
            title = `We're Thrilled You're Staying!`;
            img = houseLove;
            if (!applicant.is_pending_signature) {
                subtitle = `We'll send an email with instructions on how to sign your new lease shortly.`;
                showBackButton = enableSelfServeRenewals;
            } else if (leaseTransaction.documents_sent) {
                subtitle = `All that's left to do is review your payment details and sign the lease.`;
            } else {
                subtitle = `We'll reach out to you about how to sign the lease shortly.`;
                showBackButton = enableSelfServeRenewals;
            }
            break;
        case LEASE_TRANSACTION_TYPE_VACATE:
            title = `We're Sorry to See You Go!`;
            if (leaseTransaction.documents_sent && applicant.is_pending_signature) {
                subtitle = `All that's left to do is sign the notice to vacate.`;
                img = penSignatureImage;
            } else if (!requireNTVDocumentSignature || !applicant.is_pending_signature) {
                subtitle = `We'll send you an email with information regarding your notice to vacate shortly.`;
                img = leaseVacate;
                showBackButton = enableSelfServeRenewals;
            } else {
                subtitle = `We'll send an email with instructions on how to sign your notice to vacate shortly.`;
                img = handshakeImage;
                showBackButton = enableSelfServeRenewals;
            }
            break;
        case LEASE_TRANSACTION_TYPE_TRANSFER:
        case LEASE_TRANSACTION_TYPE_APPLICATION:
            title = `Your ${LEASE_TRANSACTION_TYPES[leaseTransaction.type]} Has Been Approved!`;
            if (isOccupant || !applicant.is_pending_signature) {
                subtitle = `We'll let you know when everything has been finalized.`;
            } else if (leaseReadyToSign && !leaseIsWetSignable) {
                subtitle = `All that's left to do is review your payment details and sign the lease.`;
            } else {
                subtitle = `We'll reach out to you about how to sign the lease shortly.`;
            }
            break;
        case LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE:
            title = `Your changes are almost complete!`;
            img = mlcSign;
            if (isOccupant || !applicant.is_pending_signature) {
                subtitle = `We'll let you know when everything has been finalized.`;
            } else if (leaseReadyToSign && !leaseIsWetSignable) {
                subtitle = `All that's left to do is review your payment details and sign the document.`;
            } else {
                subtitle = `We'll reach out to you about how to sign the document shortly.`;
            }
            break;
        default:
            break;
    }

    return {
        title,
        subtitle,
        disclaimer,
        showBackButton,
        img,
    };
};
