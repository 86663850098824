import { useCallback, useEffect, useState } from 'react';

import API from 'api/api';
import usePrevious from 'hooks/usePrevious';

export default function useScreeningReports() {
    const [loading, setIsLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const prevLoading = usePrevious(loading);

    const fetchScreeningReports = useCallback(async () => {
        setIsLoading(true);
        setError(null);

        try {
            const reports = await API.getApplicantScreeningReports();
            setData(reports.filter((r) => r.onlineleasing_report_url));
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchScreeningReports();
    }, [fetchScreeningReports]);

    useEffect(() => {
        if (loading === false && prevLoading === true) {
            setFinished(true);
        }
    }, [loading, prevLoading]);

    return {
        loading,
        error,
        finished,
        data,
    };
}
