import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Elements as StripeElementsWrapper } from 'react-stripe-elements';

import {
    ROUTES,
    LINE_ITEM_TYPE_HOLDING_DEPOSIT,
    ROLE_PRIMARY_APPLICANT,
    CARD_PAYMENT,
    BANK_ACCOUNT_STATUS_VERIFIED,
} from 'constants/constants';
import { LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE, LEASE_TRANSACTION_TYPE_TRANSFER } from 'constants/leaseTransaction';
import { VIEWED_RECEIPTS_PAGE } from 'constants/pageComplete';
import API from 'api/api';
import withRelativeRoutes from 'utils/withRelativeRoutes';
import withTransactionPath from 'utils/withTransactionPath';

import { fetchApplicant } from 'reducers/applicant';
import { fetchTransaction } from 'reducers/transaction';
import { pageComplete } from 'reducers/renter-profile';
import { actions as loaderActions } from 'reducers/loader';

import PaymentTerms from 'common-components/PaymentTerms/PaymentTerms';
import FeesDepositsOptions from 'pages/FeesAndDeposits/components/FeesDepositsOptions';
import UnitNotHeldWaitingPage from 'pages/FeesAndDeposits/components/UnitNotHeldWaitingPage';
import FeesDepositsReceipt from 'pages/FeesAndDeposits/components/FeesDepositsReceipt';
import PaymentMethods from 'pages/FeesAndDeposits/components/PaymentMethods';
import AddBankAccount from 'pages/FeesAndDeposits/components/AddBankAccount';
import AddBankDetails from 'pages/FeesAndDeposits/components/AddBankDetails';
import { mapPayersToGroupedLineItems } from 'utils/misc';
import BankAccountPendingVerification from './components/BankAccountPendingVerification';

export const FeesDepositsPage = ({
    prevRoute,
    getNextRoute,
    leaseTransaction,
    applicant,
    configuration,
    isOutstanding,
    toggleLoader,
    fetchApplicant,
    fetchTransaction,
    getTransactionPath,
    pageComplete,
}) => {
    const [currentPage, setCurrentPage] = useState('options');
    const [lineItemsToPayFor, setLineItemsToPayFor] = useState([]);
    const [totalPayment, setTotalPayment] = useState(null);
    const [receipt, setReceipt] = useState(applicant && applicant.receipt);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const receiptPaid = !!receipt ? receipt.paid : false;
    const isPrimaryApplicant = applicant?.role === ROLE_PRIMARY_APPLICANT;

    useEffect(() => {
        if (receiptPaid && !isOutstanding) {
            setCurrentPage('receipt');
        } else {
            fetchApplicant();
        }
    }, [receiptPaid, isOutstanding, fetchApplicant]);

    useEffect(() => {
        applicant && setReceipt(applicant.receipt);
    }, [applicant]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    const handleContinueReceiptClick = useCallback(async () => {
        setIsLoading(true);
        toggleLoader(true);
        await pageComplete(VIEWED_RECEIPTS_PAGE);
        await getNextRoute();
        toggleLoader(false);
        setIsLoading(false);
    }, [pageComplete, getNextRoute, toggleLoader]);
    const everyone = useMemo(() => {
        return leaseTransaction
            ? [
                  leaseTransaction.primary_applicant,
                  ...leaseTransaction.guarantors,
                  ...leaseTransaction.co_applicants,
                  ...leaseTransaction.invitees,
                  ...leaseTransaction.occupants,
              ]
            : [];
    }, [leaseTransaction]);
    const getGroupedPayables = useCallback(
        (applicantReceipt) =>
            mapPayersToGroupedLineItems(everyone, leaseTransaction.payables, applicantReceipt, isPrimaryApplicant),
        [everyone, leaseTransaction.payables, isPrimaryApplicant]
    );
    const groupedPayables = getGroupedPayables();

    const blockACHPaymentsBeforeMovein = configuration?.block_ach_payment_within_specified_days_before_movein || false;
    const achPaymentRestrictionPeriod = configuration?.ach_payment_restriction_period_before_movein || 0;
    const acceptACHPayments = configuration?.community?.company?.accept_ach_payments_from_applicants || false;

    if (!leaseTransaction || !applicant || (!lineItemsToPayFor && !receipt && !isOutstanding)) return <div />;

    const unitNumber = leaseTransaction.unit?.unit_number;
    const communityName = leaseTransaction.community?.display_name;
    const leaseStartDate = leaseTransaction.lease_start_date;

    const getVerifiedBankAccountTokens = () => {
        const verifiedTokens = [];
        applicant.bank_accounts.forEach((bank_account) => {
            if (bank_account.status === BANK_ACCOUNT_STATUS_VERIFIED) {
                verifiedTokens.push(bank_account.payment_method_token);
            }
        });
        return verifiedTokens;
    };

    const handlePaymentContinue = async (selectedPaymentMethod) => {
        if (selectedPaymentMethod === CARD_PAYMENT) {
            setCurrentPage('payment_methods');
        }
        if (getVerifiedBankAccountTokens().includes(selectedPaymentMethod)) {
            try {
                setError();
                setIsLoading(true);
                toggleLoader(true);
                await API.Payments.createIntent(leaseTransaction.id, {
                    payables: lineItemsToPayFor,
                    total: totalPayment,
                    token: selectedPaymentMethod,
                });
                await fetchApplicant();
                await fetchTransaction();
                setCurrentPage('receipt');
            } catch (error) {
                console.error(error);
                setError('Failed to process this payment. Please try again or contact support.');
            } finally {
                toggleLoader(false);
                setIsLoading(false);
            }
        }
    };

    const handlePaymentOptionsContinue = () => {
        setLineItemsToPayFor((items) => items.map((lineItem) => ({ ...lineItem, paid: true })));
        setTotalPayment(lineItemsToPayFor.reduce((sum, l) => sum + l.amount_with_tax, 0));
        setCurrentPage('terms');
    };

    const handleTermsAccepted = async (data) => {
        setIsLoading(true);
        toggleLoader(true);

        try {
            await API.acceptTerms(leaseTransaction.id, data);
            if (totalPayment) {
                setCurrentPage('payment_methods');
            } else {
                setReceipt({
                    line_items: lineItemsToPayFor.filter((l) => parseInt(l.applicant) === applicant.id),
                    total: 0,
                    paid: true,
                    paid_by: null,
                });
                setCurrentPage('receipt');
            }
        } finally {
            toggleLoader(false);
            setIsLoading(false);
        }
    };

    const applicationFeeTooltipCopy = configuration?.community?.company?.application_fee_tooltip_message;
    const holdingDepositAmount = groupedPayables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].reduce((sum, p) => sum + p.amount, 0);

    const goToPreviousPage = () => {
        const reportedNoIncome = !applicant.income_total && !applicant.asset_total;

        if (configuration.collect_employer_information && !reportedNoIncome) {
            return getTransactionPath(ROUTES.EMPLOYER_DETAILS);
        } else {
            return prevRoute;
        }
    };

    if (
        !isPrimaryApplicant &&
        !leaseTransaction.unit_is_held &&
        leaseTransaction.type !== LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE
    ) {
        return (
            <UnitNotHeldWaitingPage
                primaryApplicantFirstName={leaseTransaction.primary_applicant.first_name}
                primaryApplicantLastName={leaseTransaction.primary_applicant.last_name}
                communityName={communityName}
                unitNumber={unitNumber}
            />
        );
    }

    switch (currentPage) {
        case 'options':
            return (
                <FeesDepositsOptions
                    applicant={applicant}
                    applicationFeeTooltipCopy={applicationFeeTooltipCopy}
                    payables={groupedPayables}
                    lineItemsToPayFor={lineItemsToPayFor}
                    setLineItemsToPayFor={setLineItemsToPayFor}
                    unitNumber={unitNumber}
                    communityName={communityName}
                    isOutstanding={isOutstanding}
                    isTransfer={leaseTransaction?.type === LEASE_TRANSACTION_TYPE_TRANSFER}
                    handleClickBack={goToPreviousPage}
                    handleContinue={handlePaymentOptionsContinue}
                    allowApplicantsToPayForEveryone={configuration?.allow_applicants_to_pay_for_everyone}
                />
            );
        case 'terms':
            return (
                <PaymentTerms
                    disabled={isLoading}
                    holdingDepositAmount={holdingDepositAmount}
                    unitNumber={unitNumber}
                    communityName={communityName}
                    leaseStartDate={leaseStartDate}
                    canProceedToPayment={true}
                    handleClickBack={() => setCurrentPage('options')}
                    handleTermsAccepted={handleTermsAccepted}
                    leaseTransaction={leaseTransaction}
                />
            );
        case 'receipt':
            if (!receipt) return <div />;
            return (
                <FeesDepositsReceipt
                    disabled={isLoading}
                    payables={getGroupedPayables(applicant.receipt)}
                    receipt={receipt}
                    handleContinue={handleContinueReceiptClick}
                    applicant={applicant}
                    applicationFeeTooltipCopy={applicationFeeTooltipCopy}
                />
            );
        case 'payment_methods':
            return (
                <PaymentMethods
                    applicant={applicant}
                    totalPayment={totalPayment}
                    payments={lineItemsToPayFor}
                    handleClickBack={() => setCurrentPage('terms')}
                    setCurrentPage={(page) => setCurrentPage(page)}
                    handlePayButtonClicked={handlePaymentContinue}
                    acceptACHPayments={acceptACHPayments}
                    paymentButtonDisabled={isLoading}
                    error={error}
                    onPaymentMethodChanged={() => setError()}
                    achPaymentRestrictionPeriod={achPaymentRestrictionPeriod}
                    blockACHPaymentsBeforeMovein={blockACHPaymentsBeforeMovein}
                    leaseTransaction={leaseTransaction}
                />
            );
        case 'add_bank_details':
            return (
                <AddBankDetails
                    handleClickBack={() => setCurrentPage('payment_methods')}
                    handleContinueClick={() => setCurrentPage('add_bank_account')}
                />
            );
        case 'add_bank_account':
            return (
                <StripeElementsWrapper>
                    <AddBankAccount
                        handleClickBack={() => setCurrentPage('payment_methods')}
                        onBankAccountAdded={() => setCurrentPage('bank_account_pending_verification')}
                        communityName={communityName}
                    />
                </StripeElementsWrapper>
            );
        case 'bank_account_pending_verification':
            return (
                <BankAccountPendingVerification
                    clickToAction={() => setCurrentPage('payment_methods')}
                    titleContent="Your bank account is pending verification"
                    bodyContent={
                        <>
                            Our payment processor Stripe will send an email to <strong>{applicant.email}</strong> with
                            further instructions.
                        </>
                    }
                    clickToActionContent="Continue"
                />
            );
        default:
            break;
    }
};

FeesDepositsPage.propTypes = {
    isOutstanding: PropTypes.bool,
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,
    toggleLoader: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
    pageComplete: PropTypes.func.isRequired,
    fetchApplicant: PropTypes.func.isRequired,
    fetchTransaction: PropTypes.func.isRequired,
    getNextRoute: PropTypes.func,
    prevRoute: PropTypes.string,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    configuration: state.configuration,
    leaseTransaction: state.transaction,
});

const mapStateToPropsOutstandingBalance = (state) => ({
    applicant: state.applicant,
    configuration: state.configuration,
    leaseTransaction: state.transaction,
    isOutstanding: true,
});

const mapDispatchToProps = {
    fetchApplicant,
    fetchTransaction,
    pageComplete,
    toggleLoader: loaderActions.toggleLoader,
};

export const FeesAndDepositsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRelativeRoutes(withTransactionPath(FeesDepositsPage), ROUTES.FEES_AND_DEPOSITS));

export const OutstandingBalancePage = connect(
    mapStateToPropsOutstandingBalance,
    mapDispatchToProps
)(withRelativeRoutes(withTransactionPath(FeesDepositsPage), ROUTES.OUTSTANDING_BALANCE));
