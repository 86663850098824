import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { getIn, Field } from 'formik';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormHelperText, Typography } from '@material-ui/core';

import { RENTAL_OPTIONS_PETS_DOGS, RENTAL_OPTIONS_PETS_CATS, RENTAL_OPTIONS_PETS_OTHER } from 'constants/constants';
import { stringToBoolean } from 'utils/misc';
import FormTextInput from 'common-components/FormTextInput/FormTextInput';
import PetTypeSelect from './PetTypeSelect';
import { ErrorDetail } from 'assets/styles';
import { viewPetPolicy } from './PetItemStyles';

const root = css`
    flex-direction: row !important;
`;

const ErrorMessage = ({ name }) => (
    <Field
        name={name}
        render={({ form }) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            const submitCount = form.submitCount;
            if ((touch && error) || submitCount) {
                return <ErrorDetail>{error}</ErrorDetail>;
            }
            return null;
        }}
    />
);

ErrorMessage.propTypes = {
    name: PropTypes.string,
};

export class PetItem extends Component {
    state = { showServiceAnimalDisclaimer: false };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showServiceAnimalDisclaimer === true && !stringToBoolean(this.props.petOption?.service_animal)) {
            this.setState({ showServiceAnimalDisclaimer: false });
        }
        if (prevState.showServiceAnimalDisclaimer === false && stringToBoolean(this.props.petOption?.service_animal)) {
            this.setState({ showServiceAnimalDisclaimer: true });
        }
    }

    cache = {};

    showDisclaimer(show) {
        this.setState({ showServiceAnimalDisclaimer: show });
    }

    renderServiceAnimalField() {
        const {
            index = -1,
            petOption = {},
            serviceAnimalDisclaimer = null,
            handleChange,
            handleBlur,
            toggleViewServiceAnimalDisclaimer,
        } = this.props;
        const { showServiceAnimalDisclaimer } = this.state;

        return (
            <FormControl component="fieldset">
                <FormHelperText id="service-animal">Is this an assistance animal?</FormHelperText>
                <RadioGroup
                    classes={{ root }}
                    aria-label="service-animal"
                    name={`petOptions[${index}].service_animal`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={stringToBoolean(petOption.service_animal)}
                >
                    <FormControlLabel
                        value={false}
                        control={<Radio onChange={() => this.showDisclaimer(false)} />}
                        label="No"
                    />
                    <FormControlLabel
                        value={true}
                        control={<Radio onChange={() => this.showDisclaimer(true)} />}
                        label="Yes"
                    />
                </RadioGroup>
                {showServiceAnimalDisclaimer && serviceAnimalDisclaimer && (
                    <FormHelperText data-testid="service-animal-disclaimer" id="service-animal">
                        Make sure you read our{' '}
                        <span role="button" onClick={toggleViewServiceAnimalDisclaimer} className={viewPetPolicy}>
                            assistance animal policy.
                        </span>
                    </FormHelperText>
                )}
            </FormControl>
        );
    }

    renderDogFields() {
        const { petOption, handleChange, handleBlur, index, toggleViewPetRestrictions } = this.props;

        return (
            <>
                {this.renderServiceAnimalField()}
                <FormTextInput
                    label="Dog Name"
                    name={`petOptions[${index}].name`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={petOption.name}
                />
                <ErrorMessage name={`petOptions[${index}].name`} />
                <FormTextInput
                    label="Breed"
                    name={`petOptions[${index}].breed`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={petOption.breed}
                />
                <span role="button" onClick={toggleViewPetRestrictions} className={viewPetPolicy}>
                    <Typography variant="body2" variantMapping={{ body2: 'span' }}>
                        View animal restrictions
                    </Typography>
                </span>
                <ErrorMessage name={`petOptions[${index}].breed`} />
                <FormTextInput
                    label="Weight"
                    name={`petOptions[${index}].weight`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={petOption.weight}
                    endAdornment={<span style={{ color: '#828796' }}>Lb</span>}
                />
                <ErrorMessage name={`petOptions[${index}].weight`} />
            </>
        );
    }

    renderCatFields() {
        const { petOption, handleChange, handleBlur, index } = this.props;
        return (
            <>
                {this.renderServiceAnimalField()}
                <FormTextInput
                    label="Cat Name"
                    name={`petOptions[${index}].name`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={petOption.name}
                />
                <ErrorMessage name={`petOptions[${index}].name`} />
                <FormTextInput
                    label="Weight"
                    name={`petOptions[${index}].weight`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={petOption.weight}
                    endAdornment={<span style={{ color: '#828796' }}>Lb</span>}
                />
                <ErrorMessage name={`petOptions[${index}].weight`} />
            </>
        );
    }

    renderOtherFields() {
        const { petOption, handleChange, handleBlur, index, toggleViewPetRestrictions } = this.props;
        return (
            <>
                {this.renderServiceAnimalField()}
                <FormTextInput
                    label="Description"
                    name={`petOptions[${index}].description`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={petOption.description}
                    helperText="Please share a bit about your pet"
                />
                <span role="button" onClick={toggleViewPetRestrictions} className={viewPetPolicy}>
                    <Typography variant="body2">View animal restrictions</Typography>
                </span>
                <ErrorMessage name={`petOptions[${index}].description`} />
            </>
        );
    }

    onChangePetType = (value) => {
        const { petOption, arrayHelpers, index } = this.props;
        this.cache[petOption.pet_type] = { ...petOption };
        arrayHelpers.replace(index, {
            pet_type: value,
            key: petOption.key,
            service_animal: 'false',
            ...this.cache[value],
        });
    };

    render() {
        const {
            index = -1,
            arrayHelpers = {},
            petOption = {},
            rentalOptions = [],
            handleDelete = (f) => f,
        } = this.props;

        return (
            <div>
                <PetTypeSelect
                    petOption={petOption}
                    rentalOptions={rentalOptions}
                    onDelete={() => handleDelete(arrayHelpers, index)}
                    onChange={this.onChangePetType}
                />
                <ErrorMessage name={`petOptions[${index}].pet_type`} />
                {petOption.pet_type === RENTAL_OPTIONS_PETS_DOGS && this.renderDogFields()}
                {petOption.pet_type === RENTAL_OPTIONS_PETS_CATS && this.renderCatFields()}
                {petOption.pet_type === RENTAL_OPTIONS_PETS_OTHER && this.renderOtherFields()}
            </div>
        );
    }
}

PetItem.propTypes = {
    index: PropTypes.number,
    arrayHelpers: PropTypes.object,
    petOption: PropTypes.object,
    rentalOptions: PropTypes.array,
    serviceAnimalDisclaimer: PropTypes.string,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    toggleViewPetRestrictions: PropTypes.func,
    toggleViewServiceAnimalDisclaimer: PropTypes.func,
    handleDelete: PropTypes.func,
};

export default PetItem;
