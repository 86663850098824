import { MOCKY } from 'config';
import mock from 'reducers/fixtures/mock-profile.json';
import API from 'api/api';
import { filterRentalOptionsByUnit } from 'reducers/configuration';
import { renterProfileReceived } from 'reducers/renter-profile';
import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';

const transaction = createSlice({
    name: 'transaction',
    initialState: null,
    reducers: {
        transactionReceived(state, action) {
            state = action.payload;
            return state;
        },
        transactionUpdated(state, action) {
            return produce(state, (draft) => {
                Object.assign(draft, action.payload);
            });
        },
    },
    extraReducers: {
        USER_LOGOUT: () => {
            return null;
        },
    },
});

const { actions, reducer } = transaction;
export const { transactionReceived, transactionUpdated } = actions;
export default reducer;

export const fetchTransaction = (id) => {
    return async (dispatch, getState) => {
        const { transaction } = getState();
        id = id || transaction?.id;

        let fetchedTransaction;
        if (MOCKY) {
            fetchedTransaction = mock;
        } else {
            fetchedTransaction = await API.fetchTransaction(id);
        }

        dispatch(transactionReceived(fetchedTransaction));
        dispatch(renterProfileReceived(fetchedTransaction));
        dispatch(filterRentalOptionsByUnit(fetchedTransaction));

        return fetchedTransaction;
    };
};

export const updateTransaction = (newData, stateUpdate = null) => {
    return async (dispatch, getState) => {
        const { transaction } = getState();

        if (MOCKY) {
            dispatch({
                type: transactionUpdated.toString(),
                payload: stateUpdate || newData,
            });
            return Promise.resolve({});
        }

        const updatedLeaseTransaction = await API.patchTransaction(transaction.id, newData);
        return dispatch({
            type: transactionUpdated.toString(),
            payload: stateUpdate || updatedLeaseTransaction,
        });
    };
};
