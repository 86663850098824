import { useContext } from 'react';

import BankingContext from './BankingContext';

export const useBankingContext = () => {
    return useContext(BankingContext);
};

const hooks = {
    useBankingContext,
};

export default hooks;
