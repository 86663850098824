import { createSlice } from '@reduxjs/toolkit';

const vacateForm = createSlice({
    name: 'vacateForm',
    initialState: {},
    reducers: {
        setVacateFormData(state, action) {
            return { ...state, ...action.payload };
        },
    },
});

const { actions, reducer } = vacateForm;
export const { setVacateFormData } = actions;
export default reducer;
