import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import API from 'api/api';
import { ROUTES, PLAID_PRODUCTS } from 'constants/constants';
import withTransactionPath from 'utils/withTransactionPath';
import { useIdentityVerification } from '../IdentityVerificationContext';

import ActionButton from 'common-components/ActionButton/ActionButton';
import BackLink from 'common-components/BackLink/BackLink';
import Page from 'common-components/Page/Page';
import { LinkButton } from 'assets/styles';

import PlaidLink from 'pages/Banking/components/PlaidLink';

import pageImageSrc from 'assets/new-icons/single-neutral-id-card.svg';

const useStyles = makeStyles(() => ({
    pageImage: {
        width: '147px',
        height: '110px',
    },
    footer: {
        marginBottom: '20px',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '14px',
        letterSpacing: '0px',
        textAlign: 'center',
    },
    manualIdentityVerificationLink: {
        marginTop: '10px',
        marginBottom: '30px',
    },
}));

export function PlaidLaunchPage({ getTransactionPath, leaseTransaction, history, enableManualIdentityVerification }) {
    const classes = useStyles();
    const [showPlaidLink, setShowPlaidLink] = useState(false);

    const {
        showLaunchPlaidPage,
        toggleLoader,
        identityVerification,
        error,
        setError,
        GENERIC_ERROR_MESSAGE,
        handleCompletedVerification,
    } = useIdentityVerification();

    const pageNotification = error && {
        type: 'error',
        messages: error,
    };

    const backLinkTo = getTransactionPath(ROUTES.FEES_AND_DEPOSITS, {
        lease_transaction_id: leaseTransaction.id,
    });

    const onLaunchPlaidIdentityVerification = () => {
        setShowPlaidLink(true);
        setError(false);
        toggleLoader(true);
    };

    const onLaunchManualIdentityVerification = () => {
        const manualIdentityVerificationPage = getTransactionPath(ROUTES.MANUAL_IDENTITY_VERIFICATION, {
            lease_transaction_id: leaseTransaction.id,
        });
        history.push(manualIdentityVerificationPage);
    };

    const onSuccessfulVerification = async (metadata) => {
        try {
            const verificationResults = await API.postPlaidVerifyResidentId(leaseTransaction?.id, metadata);

            if (verificationResults?.is_completed) {
                await handleCompletedVerification();
            }
        } catch (e) {
            setError(GENERIC_ERROR_MESSAGE);
            console.error(e);
        } finally {
            setShowPlaidLink(false);
            toggleLoader(false);
        }
    };

    const onClose = () => {
        setShowPlaidLink(false);
        showLaunchPlaidPage();
    };

    if (showPlaidLink) {
        return (
            <PlaidLink
                leaseTransactionId={leaseTransaction?.id}
                plaidProduct={PLAID_PRODUCTS.IDENTITY_VERIFICATION}
                identityVerification={identityVerification}
                handleOnSuccessCallback={onSuccessfulVerification}
                onClose={onClose}
            />
        );
    }

    return (
        <Page
            title="Let’s Verify Your Identity!"
            subTitle={`For added security, we'll need to verify your identity.`}
            image={{
                src: pageImageSrc,
                className: classes.pageImage,
            }}
            notification={pageNotification}
        >
            <ActionButton
                data-testid="launch-plaid-action-button"
                onClick={onLaunchPlaidIdentityVerification}
                marginTop={30}
                marginBottom={10}
            >
                Verify Identity Now
            </ActionButton>
            <div data-testid="launch-plaid-redirect-text" className={classes.footer}>
                You’ll be redirected to Plaid, our identity verification provider.
            </div>
            {enableManualIdentityVerification && (
                <div>
                    <LinkButton
                        className={classes.manualIdentityVerificationLink}
                        onClick={onLaunchManualIdentityVerification}
                        data-testid="manual-identity-verification-link"
                    >
                        Manually Verify Identity Instead
                    </LinkButton>
                </div>
            )}
            <BackLink to={backLinkTo} />
        </Page>
    );
}

PlaidLaunchPage.propTypes = {
    getTransactionPath: PropTypes.func.isRequired,
    history: PropTypes.func.isRequired,
    leaseTransaction: PropTypes.object.isRequired,
    enableManualIdentityVerification: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    enableManualIdentityVerification: state.configuration.enable_applicants_to_request_manual_identity_verification,
});

export default connect(mapStateToProps, null)(withRouter(withTransactionPath(PlaidLaunchPage)));
