import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ROUTES } from 'constants/constants';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import withRelativeRoutes from 'utils/withRelativeRoutes';

import { getShowAutomatedAddressForm } from 'selectors/launchDarkly';
import { updateApplicant } from 'reducers/applicant';
import { actions as loaderActions } from 'reducers/loader';

import Page from 'common-components/Page/Page';
import ApplicantAddressForm from 'pages/Address/ApplicantAddressForm';
import sticky from 'assets/new-icons/style-one-pin-information.svg';

export const AddressPage = ({
    applicant,
    leaseTransaction,
    showAutomatedAddress,
    toggleLoader,
    updateApplicant,
    getNextRoute,
}) => {
    const [errors, setErrors] = useState(null);

    const handleSubmit = useCallback(
        async (values, { setErrors: setFormErrors, setSubmitting }) => {
            toggleLoader(true);
            setErrors(null);

            try {
                const response = await updateApplicant(values);
                if (response.errors) {
                    setFormErrors(response.errors);
                } else {
                    await getNextRoute();
                }
            } catch {
                setErrors([GENERIC_ERROR_MESSAGE]);
            } finally {
                toggleLoader(false);
                setSubmitting(false);
            }
        },
        [toggleLoader, updateApplicant, getNextRoute, setErrors]
    );

    if (!applicant) {
        return null;
    }

    return (
        <Page
            title="Current Address"
            subTitle="We’ll need your current address."
            image={{
                src: sticky,
            }}
        >
            <ApplicantAddressForm
                applicant={applicant}
                leaseTransaction={leaseTransaction}
                errors={errors}
                onSubmit={handleSubmit}
                showAutomatedAddress={showAutomatedAddress}
            />
        </Page>
    );
};

AddressPage.propTypes = {
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    showAutomatedAddress: PropTypes.bool,
    toggleLoader: PropTypes.func,
    updateApplicant: PropTypes.func.isRequired,
    getNextRoute: PropTypes.func,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    leaseTransaction: state.transaction,
    showAutomatedAddress: getShowAutomatedAddressForm(state),
});

const mapActionsToProps = {
    updateApplicant,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapActionsToProps)(withRelativeRoutes(AddressPage, ROUTES.ADDRESS));
