import { createSlice } from '@reduxjs/toolkit';
import { generatePath } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { createSelector } from 'reselect';
import fp from 'lodash/fp';

import { MOCKY } from 'config';
import API from 'api/api';
import {
    ROUTES,
    ROLE_PRIMARY_APPLICANT,
    APPLICANT_EVENTS,
    MILESTONE_APPLICANT_SUBMITTED,
    MILESTONE_REQUEST_GUARANTOR,
    MILESTONE_FINANCIAL_STREAM_MISSING_DOCUMENTS_REQUESTED,
    MILESTONE_FINANCIAL_STREAM_ADDITIONAL_DOCUMENTS_REQUESTED,
    MILESTONE_PROOF_OF_STATED_INCOME_REQUESTED,
    ROUTE_LABELS,
    LEASE_TRANSACTION_STEPS,
    ROLE_CO_APPLICANT,
} from 'constants/constants';
import { fetchTransaction } from 'reducers/transaction';
import { getOriginalQuoteExpired } from 'utils/leaseTransaction';

const renterProfile = createSlice({
    name: 'renterProfile',
    initialState: null,
    reducers: {
        renterProfileReceived(state, action) {
            state = { ...action.payload };
            if (state.pets) {
                state.pets.forEach((pet) => (pet.key = uuidv4()));
            } else {
                state.pets = [{ key: uuidv4() }];
            }
            return state;
        },
    },
    extraReducers: {
        USER_LOGOUT: () => {
            return null;
        },
    },
});

const { actions, reducer } = renterProfile;
export const { renterProfileReceived } = actions;
export default reducer;

export const pageComplete = (page) => (dispatch, getState) => {
    const { transaction } = getState();
    return API.postPageComplete(transaction.id, page).then(() => {
        dispatch(fetchTransaction(transaction.id));
    });
};

// selectors
export const selectors = {};

selectors.selectDefaultBankingPage = createSelector(
    (state) => state.applicant,
    (state) => state.configuration,
    (state) => state.banking,
    (applicant, configuration, data) => {
        if (!applicant) {
            return ROUTES.INCOME_VERIFICATION_CONNECT;
        }

        const applicantEventSet = applicant.events
            ? new Set(
                  applicant.events.reduce((acc, { event, lease_transaction }) => {
                      if (lease_transaction === applicant.lease_transaction_id) {
                          acc.push(event);
                      }
                      return acc;
                  }, [])
              )
            : new Set();

        const agentRequestedIncomeAssets =
            applicantEventSet.has(MILESTONE_FINANCIAL_STREAM_MISSING_DOCUMENTS_REQUESTED) ||
            applicantEventSet.has(MILESTONE_FINANCIAL_STREAM_ADDITIONAL_DOCUMENTS_REQUESTED) ||
            applicantEventSet.has(MILESTONE_PROOF_OF_STATED_INCOME_REQUESTED);
        const applicantEnteredIncomeOrAssets =
            data?.income_sources?.length || data?.asset_sources?.length || data?.reported_no_income_assets;
        if (agentRequestedIncomeAssets) {
            return applicantEnteredIncomeOrAssets
                ? ROUTES.INCOME_VERIFICATION_SUMMARY
                : ROUTES.INCOME_VERIFICATION_CONNECT;
        }

        const applicantSubmittedStatedIncome = !!applicant.stated_income && !!applicant.stated_assets;
        if (!applicantEnteredIncomeOrAssets && !applicantSubmittedStatedIncome) {
            return applicant.enabled_for_stated_income_assets
                ? ROUTES.STATED_INCOME_AND_ASSETS
                : ROUTES.INCOME_VERIFICATION_CONNECT;
        }

        const addedEmployerInfo = applicantEventSet.has(APPLICANT_EVENTS.EVENT_APPLICANT_UPDATED_EMPLOYER_INFO);

        const reportedNoIncome = applicantEventSet.has(APPLICANT_EVENTS.EVENT_INCOME_REPORTED_NONE);

        const statedZeroIncomeAndAssets =
            applicantSubmittedStatedIncome &&
            !applicant.asset_total &&
            !applicant.income_total &&
            Number(applicant.stated_income) === 0 &&
            Number(applicant.stated_assets) === 0;

        const shouldEditEmployerInfo =
            configuration.collect_employer_information &&
            !addedEmployerInfo &&
            !reportedNoIncome &&
            !statedZeroIncomeAndAssets;

        if (shouldEditEmployerInfo) {
            return ROUTES.EMPLOYER_DETAILS;
        } else {
            return applicant.enabled_for_stated_income_assets
                ? ROUTES.STATED_INCOME_AND_ASSETS
                : ROUTES.INCOME_VERIFICATION_SUMMARY;
        }
    }
);

selectors.selectOrderedRoutes = createSelector(
    (state) => state.applicant,
    (state) => state.configuration,
    selectors.selectDefaultBankingPage,
    (applicant, configuration = {}, bankingIndexRoute) => {
        const { enable_identity_verification, require_servicemember_information } = configuration;

        const showMilitaryStatusPage =
            require_servicemember_information && [ROLE_PRIMARY_APPLICANT, ROLE_CO_APPLICANT].includes(applicant?.role);

        const routes = [
            ROUTES.ADDRESS,
            showMilitaryStatusPage && ROUTES.MILITARY_STATUS,
            ROUTES.LEASE_TERMS,
            applicant?.role === ROLE_PRIMARY_APPLICANT && ROUTES.PROFILE_OPTIONS,
            applicant?.has_income_verification_enabled && bankingIndexRoute,
            ROUTES.SCREENING,
            ROUTES.FEES_AND_DEPOSITS,
            enable_identity_verification && ROUTES.IDENTITY_VERIFICATION,
            ROUTES.APP_COMPLETE,
        ].filter((r) => !!r);

        return routes;
    }
);

// Determines which routes the applicant still needs to submit/complete
// A route returning FALSE here indicates that the user has not completed it
const pageCompleted = (state) => {
    const { applicant } = state;
    const containerIndexRoutes = selectors.selectDefaultContainerPage(state);

    return {
        [ROUTES.ADDRESS]: applicant.is_address_completed,
        [ROUTES.MILITARY_STATUS]: applicant.is_servicemember_information_completed,
        [ROUTES.LEASE_TERMS]: applicant.is_lease_terms_completed,
        [ROUTES.PROFILE_OPTIONS]: applicant.is_rental_options_completed && applicant.role === ROLE_PRIMARY_APPLICANT,
        [containerIndexRoutes[ROUTES.BANKING]]: applicant.is_income_and_employment_completed,
        [ROUTES.FEES_AND_DEPOSITS]: applicant.is_fees_paid,
        [ROUTES.SCREENING]: applicant.is_screening_completed,
        [ROUTES.IDENTITY_VERIFICATION]: applicant.is_id_verification_completed,
        [ROUTES.APP_COMPLETE]: applicant.is_transaction_submitted,
    };
};

selectors.selectDefaultContainerPage = createSelector(selectors.selectDefaultBankingPage, (bankingIndexRoute) => {
    return {
        [ROUTES.BANKING]: bankingIndexRoute,
    };
});

const getContainerRoute = (route, application) => {
    if (!route || !application) return;

    if (route.includes(':lease_transaction_id')) {
        route = leaseTransactionPath(route, application);
    }

    if (route.startsWith(leaseTransactionPath(ROUTES.BANKING, application))) {
        return leaseTransactionPath(ROUTES.BANKING, application);
    }

    if (route.startsWith(leaseTransactionPath(ROUTES.RENTAL_PROFILE, application))) {
        return leaseTransactionPath(ROUTES.PROFILE_OPTIONS, application);
    }

    return route;
};

export const leaseTransactionPath = (route, transaction, params = {}) => {
    if (!transaction) return null;
    const { lease_transaction_id } = transaction;
    return generatePath(route, { lease_transaction_id, ...params });
};

selectors.canAccessRoute = (state, route) => {
    if (MOCKY && route != null) return true;
    if (route === null) return false;
    if (!state.applicant || !state.transaction) return false;

    /*
     Ordered screens and generally can't be completed out of order.
     Some pages can always be accessed no matter what.
     Here contains logic around access permissions for certain pages.
     This is not totally comprehensive.
    */
    // These pages should always be accessible
    if ([ROUTES.ACCOUNT, ROUTES.TERMS, ROUTES.PRIVACY_POLICY, ROUTES.FAQ, ROUTES.FUNNEL_TERMS].includes(route)) {
        return true;
    }

    const { applicant, transaction, renterProfile } = state;
    const { id: transaction_id } = transaction;

    const eventsSet = new Set(
        applicant.events.filter((e) => e.lease_transaction === transaction_id).map((event) => parseInt(event.event))
    );

    if (route === ROUTES.PAYMENT_DETAILS) {
        return eventsSet.has(APPLICANT_EVENTS.EVENT_LEASE_TERMS_COMPLETED);
    }

    const pagesCompleted = pageCompleted(state);
    if (renterProfile) {
        for (const page in pagesCompleted) {
            const completedPage = getContainerRoute(page, renterProfile);
            const currentRoute = getContainerRoute(route, renterProfile);
            if (completedPage === currentRoute && pagesCompleted[page] === true) {
                return true;
            }
        }
    }

    if (selectors.selectDirectRoute(state)) {
        return true;
    }

    // route is next page
    return selectors.selectNextRoute(state) === leaseTransactionPath(route, state.renterProfile);
};

export const DIRECT_ROUTES = [
    ROUTES.PRIVACY_POLICY,
    ROUTES.FAQ,
    ROUTES.TERMS,
    ROUTES.TERMS_DID_REGISTER,
    ROUTES.ACCOUNT,
    ROUTES.LEASE_TRANSACTIONS,
    ROUTES.LEASES,
    ROUTES.PAYMENT_METHODS,
    ROUTES.PAYMENT_DETAILS,
    ROUTES.SCREENING_REPORTS,
];

const getDirectRoute = (route, application) => {
    if (!route) return null;

    if (application) {
        const paymentRoute = leaseTransactionPath(ROUTES.PAYMENT_DETAILS, application);
        if (route.endsWith(paymentRoute)) {
            return paymentRoute;
        }
    }

    route = DIRECT_ROUTES.find((r) => route.includes(r));

    return route;
};

const getCurrentStepRouteMapping = (leaseTransaction, containerIndexRoutes) => {
    return {
        // Priority routes (because a certain event got triggered)
        [LEASE_TRANSACTION_STEPS.STEP_GUARANTOR_REQUESTED]: ROUTES.GUARANTOR_REQUESTED,
        [LEASE_TRANSACTION_STEPS.STEP_OUTSTANDING_BALANCE]: ROUTES.OUTSTANDING_BALANCE,
        [LEASE_TRANSACTION_STEPS.STEP_INCOME_VERIFICATION]: [containerIndexRoutes[ROUTES.BANKING]],
        [LEASE_TRANSACTION_STEPS.STEP_INCOME_REQUESTED]: [containerIndexRoutes[ROUTES.BANKING]],

        // Lease transaction step routes
        [LEASE_TRANSACTION_STEPS.STEP_TERMS_OF_SERVICE]: `${ROUTES.TERMS_DID_REGISTER}&transaction_id=${leaseTransaction.id}`,
        [LEASE_TRANSACTION_STEPS.STEP_ADDRESS]: ROUTES.ADDRESS,
        [LEASE_TRANSACTION_STEPS.STEP_MILITARY_STATUS]: ROUTES.MILITARY_STATUS,
        [LEASE_TRANSACTION_STEPS.STEP_LEASE_TERMS]: ROUTES.LEASE_TERMS,
        [LEASE_TRANSACTION_STEPS.STEP_RENTAL_OPTIONS]: ROUTES.PROFILE_OPTIONS,
        [LEASE_TRANSACTION_STEPS.STEP_INCOME_EMPLOYMENT]: [containerIndexRoutes[ROUTES.BANKING]],
        [LEASE_TRANSACTION_STEPS.STEP_SCREENING]: ROUTES.SCREENING,
        [LEASE_TRANSACTION_STEPS.STEP_FEES_AND_DEPOSITS]: ROUTES.FEES_AND_DEPOSITS,
        [LEASE_TRANSACTION_STEPS.STEP_IDENTITY_VERIFICATION]: ROUTES.IDENTITY_VERIFICATION,

        // Lease transaction status routes
        [LEASE_TRANSACTION_STEPS.STEP_TRANSACTION_CANCELED]: ROUTES.APP_CANCELLED,
        [LEASE_TRANSACTION_STEPS.STEP_TRANSACTION_DENIED]: ROUTES.APP_DENIED,
        [LEASE_TRANSACTION_STEPS.STEP_TRANSACTION_PENDING_CRIMINAL_REVIEW]: ROUTES.APP_PENDING_CRIMINAL_REVIEW,
        [LEASE_TRANSACTION_STEPS.STEP_LEASE_VOIDED]: ROUTES.LEASE_VOIDED,
        [LEASE_TRANSACTION_STEPS.STEP_LEASE_SIGNED]: ROUTES.LEASE_SIGNED,
        [LEASE_TRANSACTION_STEPS.STEP_TRANSACTION_APPROVED]: ROUTES.APP_APPROVED,
        [LEASE_TRANSACTION_STEPS.STEP_LEASE_TRANSACTION_SUBMITTED]: ROUTES.APP_COMPLETE,
        [LEASE_TRANSACTION_STEPS.STEP_TRANSACTION_COMPLETED]: ROUTES.LEASE_EXECUTED,
        [LEASE_TRANSACTION_STEPS.STEP_TRANSACTION_WAITING_AGENT_CONFIRMATION]: ROUTES.APP_PENDING_CONFIRMATION,

        // Other
        [LEASE_TRANSACTION_STEPS.STEP_UNIT_UNAVAILABLE]: ROUTES.UNIT_UNAVAILABLE,
        [LEASE_TRANSACTION_STEPS.STEP_NOTICE_TO_VACATE_SIGNED]: ROUTES.LEASE_SIGNED,
    };
};

selectors.selectDirectRoute = createSelector(
    (state) => state.transaction,
    (leaseTransaction) => {
        if (!leaseTransaction) {
            return null;
        }
        const directRoute = getDirectRoute(window.location.pathname, { lease_transaction_id: leaseTransaction.id });
        if (directRoute) {
            return directRoute;
        }

        return null;
    }
);

selectors.selectNextRoute = createSelector(
    (state) => state.applicant,
    (state) => state.transaction,
    selectors.selectDefaultContainerPage,
    (applicant, leaseTransaction, containerIndexRoutes) => {
        if (applicant && leaseTransaction && applicant?.current_step) {
            const routeParams = { lease_transaction_id: leaseTransaction.id };
            // Note we have to specifically check for false as unit_available can return true, false or null
            if (leaseTransaction.unit_available === false) {
                return leaseTransactionPath(ROUTES.UNIT_UNAVAILABLE, routeParams);
            }

            if (getOriginalQuoteExpired({ leaseTransaction, applicant })) {
                return leaseTransactionPath(ROUTES.PAYMENT_DETAILS, routeParams);
            }

            const currentRouteMapping = getCurrentStepRouteMapping(leaseTransaction, containerIndexRoutes);
            const currentRoute = currentRouteMapping[applicant.current_step.toString()]; // container routes will return an array

            const route = Array.isArray(currentRoute) ? currentRoute[0] : currentRoute;
            if (route) return leaseTransactionPath(route, routeParams);
            console.error('Could not determine current page.');
        }

        if (applicant && !applicant.current_step) {
            // When applicant records are created for applicant roles during reconciliation,
            // there are no current steps for them. So when they try to log in they were stuck in a loop of being redirected to the login page.
            // In these cases we want to redirect them to the /my-leases so that they can renew their lease.
            return ROUTES.LEASES;
        }
    }
);

selectors.selectPrevRoute = createSelector(
    selectors.selectOrderedRoutes,
    (state) => state.siteConfig.currentRoute,
    (state) => state.transaction,
    (orderedRoutes, currentRoute, leaseTransaction) => {
        if (!leaseTransaction) {
            return null;
        }

        const orderedRoutesWithId = orderedRoutes.map((route) =>
            getContainerRoute(route, { lease_transaction_id: leaseTransaction.id })
        );

        if (orderedRoutesWithId && currentRoute) {
            return orderedRoutesWithId[orderedRoutesWithId.indexOf(currentRoute) - 1];
        }
    }
);

selectors.selectInitialPage = createSelector(
    selectors.selectDirectRoute,
    selectors.selectNextRoute,
    (directRoute, selectNextRoute) => {
        if (directRoute) {
            return directRoute;
        }

        return selectNextRoute;
    }
);

selectors.selectApplicantStillFinishingApplication = createSelector(
    (state) => state.applicant && state.applicant.events,
    (state) => state.transaction,
    (applicantEvents, leaseTransaction) => {
        if (!applicantEvents || !leaseTransaction) return;
        // if applicant has submitted milestone, they're not completing fields anymore
        return !applicantEvents
            .filter((e) => e.lease_transaction === leaseTransaction.id)
            .find((e) => parseInt(e.event) === parseInt(MILESTONE_APPLICANT_SUBMITTED));
    }
);

selectors.selectGuarantorRequested = createSelector(
    (state) => state.applicant && state.applicant.events,
    (state) => state.transaction,
    (events, leaseTransaction) => {
        if (!(events && leaseTransaction)) return false;
        const leaseTransactionEvents = leaseTransaction.events
            ? new Set(leaseTransaction.events.map((event) => parseInt(event.event)))
            : null;
        return leaseTransactionEvents && leaseTransactionEvents.has(MILESTONE_REQUEST_GUARANTOR);
    }
);

selectors.selectNav = createSelector(selectors.selectOrderedRoutes, (orderedRoutes) => {
    if (orderedRoutes) {
        return orderedRoutes.map((r) => ({ name: ROUTE_LABELS[r], value: r }));
    }
});

selectors.selectUnit = (state) => state?.transaction?.unit;

selectors.leaseTransactionFees = createSelector(
    (state) => state.payments,
    (payments = []) => {
        if (!payments || payments.length === 0) {
            return { allPaid: false, items: [], total: '0', total_tax: '0' };
        }
        const paymentDetailsFormat = (payable) => {
            const [name, items] = payable;
            const unwaivedItems = items.filter((item) => !item.waived);

            return {
                name,
                amount: fp.sumBy('amount')(unwaivedItems),
                amount_with_tax: fp.sumBy('amount_with_tax')(unwaivedItems),
                tax_amount: fp.sumBy('tax_amount')(unwaivedItems),
                quantity: unwaivedItems.length,
                price: items[0].amount,
                line_items: items,
                fee_help_text: items[0].fee_help_text,
                type: 'fee',
            };
        };

        const items = fp.flow(fp.groupBy('name'), fp.toPairs, fp.map(paymentDetailsFormat))(payments);
        const total = fp.sumBy('amount_with_tax')(items).toFixed(2).toString();
        const total_tax = fp.sumBy('tax_amount')(items).toFixed(2).toString();
        const allPaid = fp.every({ paid: true, waived: false })(payments) && !!payments.length;

        return { total, items, allPaid, total_tax };
    }
);

selectors.selectEveryone = createSelector(
    (state) => state.transaction,
    (leaseTransaction) => {
        if (!leaseTransaction) return [];
        const everyone = [
            ...leaseTransaction.guarantors,
            ...leaseTransaction.co_applicants,
            ...leaseTransaction.invitees,
        ];
        everyone.unshift(leaseTransaction.primary_applicant);
        return everyone;
    }
);
