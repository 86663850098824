import auth from 'utils/auth';
import {
    LEASE_TRANSACTION_TYPE_APPLICATION,
    LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE,
    LEASE_TRANSACTION_TYPE_RENEWAL,
    LEASE_TRANSACTION_TYPE_TRANSFER,
    LEASE_TRANSACTION_TYPE_VACATE,
} from 'constants/leaseTransaction';

export const getLeaseSettingsIdFromUrl = () => {
    return window.location.pathname.split('/')[1];
};

export const getLeaseTransactionIdFromUrl = () => {
    const matchUrl = window.location.pathname.match(/transaction\/([0-9]+)/);
    return matchUrl && matchUrl[1];
};

export const getLeaseIdFromUrl = () => {
    const matchUrl = window.location.pathname.match(/lease\/([0-9]+)/);
    return matchUrl && matchUrl[1];
};

export const getApplicantIsInWrongCommunityEnv = (transaction) => {
    const leaseSettingsIdFromUrl = getLeaseSettingsIdFromUrl();

    if (String(leaseSettingsIdFromUrl) !== String(transaction.lease_settings)) {
        return true;
    }

    return false;
};

export const getIsOnMyLeasesPage = () => {
    const matchUrl = window.location.pathname.match(/my-leases/);
    return Boolean(matchUrl);
};

export const getRedirectToLeaseTransactionsPage = (profile, configuration, accessedAppByInvitationOrWebsite) => {
    const transactionIdFromUrl = getLeaseTransactionIdFromUrl();
    const leaseIdFromUrl = getLeaseIdFromUrl();
    const isOnMyLeasesPage = getIsOnMyLeasesPage();
    const applicationIdFromConfiguration = configuration?.application_id;
    const numberOfTransactions = profile?.transactions?.length;
    const has_managed_applications = profile?.has_managed_applications;

    if (leaseIdFromUrl || isOnMyLeasesPage) {
        return false;
    }

    if (
        configuration?.lease_transaction_id &&
        profile?.transactions?.find((t) => t.id === configuration?.lease_transaction_id)
    ) {
        return false;
    }

    if (!accessedAppByInvitationOrWebsite && (transactionIdFromUrl || applicationIdFromConfiguration)) {
        return false;
    }

    if (accessedAppByInvitationOrWebsite && numberOfTransactions > 0) {
        return true;
    }

    if (!transactionIdFromUrl && !applicationIdFromConfiguration && numberOfTransactions > 1) {
        return true;
    }

    if (numberOfTransactions === 0) {
        return true;
    }

    if (numberOfTransactions === 1 && has_managed_applications) {
        return true;
    }

    return false;
};

export const getRedirectToAccountSetupPage = (profile = {}) => {
    // ensure value is false and not falsey
    return profile.completed_account_setup === false;
};

export const switchCommunityEnv = (leaseTransaction, initialPage) => {
    auth.setScope(leaseTransaction.lease_settings);
    return reloadWindowOnURL(`${leaseTransaction.lease_settings}${initialPage}`);
};

export const reloadWindowForLeaseTransaction = (leaseTransaction) => {
    return reloadWindowOnURL(`${leaseTransaction.lease_settings}/transaction/${leaseTransaction.id}`);
};

export const goToLeaseTransaction = (leaseTransaction) => {
    auth.setScope(leaseTransaction.lease_settings);
    return reloadWindowOnURL(`${leaseTransaction.lease_settings}/transaction/${leaseTransaction.id}`);
};

export const goToMonthToMonthConfirmation = (leaseTransaction) => {
    auth.setScope(leaseTransaction.lease_settings);
    reloadWindowOnURL(
        `${leaseTransaction.lease_settings}/lease/${leaseTransaction.lease.id}/renewal/month-to-month/confirmed`
    );
};

export const reloadWindowOnURL = (url) => {
    let location = `${window.location.origin}/${url}`;
    if (url.charAt(0) === '/') {
        location = `${window.location.origin}${url}`;
    }

    window.location = location;
};

/**
 * Get next route
 *
 * Tmp solution to handle different routing for different kind of lease transactions.
 * The function simpley checks what type of lease transaction it is and calls that lease transaction getNextRoute callback.
 *
 * usage:
 *
 * myFunction() {
 *     const nextRoute = getNextRoute({
 *         leaseTransaction: leaseTransaciton,
 *         getApplicationNextRoute: () => 'SOME_APPLICATION_NEXT_ROUTE',
 *         getMidLeaseChangeNextRoute: () => 'SOME_MLC_NEXT_ROUTE',
 *     });
 *
 *     history.push(nextRoute);
 * }
 *
 * @param leaseTransaction              the lease treansaction
 * @param getApplicationNextRoute       getNextRoute callback function for application type lease transactions
 * @param getMidLeaseChangeNextRoute    getNextRoute callback function for mid lease change type lease transactions
 * @param getVacateNextRoute            getNextRoute callback function for vacate type lease transactions
 * @param getRenewalNextRoute           getNextRoute callback function for renewal type lease transactions
 * @param getTransferNextRoute          getNextRoute callback function for transfer type lease transactions
 * @return {*}
 */
export const getNextRoute = ({
    leaseTransaction,
    getApplicationNextRoute,
    getMidLeaseChangeNextRoute,
    getVacateNextRoute,
    getRenewalNextRoute,
    getTransferNextRoute,
}) => {
    // eslint-disable-next-line default-case
    switch (leaseTransaction.type) {
        case LEASE_TRANSACTION_TYPE_APPLICATION:
            return getApplicationNextRoute(leaseTransaction);
        case LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE:
            return getMidLeaseChangeNextRoute(leaseTransaction);
        case LEASE_TRANSACTION_TYPE_TRANSFER:
            return getTransferNextRoute(leaseTransaction);
        case LEASE_TRANSACTION_TYPE_VACATE:
            return getVacateNextRoute(leaseTransaction);
        case LEASE_TRANSACTION_TYPE_RENEWAL:
            return getRenewalNextRoute(leaseTransaction);
    }
};

export default {
    getApplicantIsInWrongCommunityEnv,
    getRedirectToLeaseTransactionsPage,
    switchCommunityEnv,
    getNextRoute,
};
