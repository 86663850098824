import React, { useState, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import AddCircle from '@material-ui/icons/AddCircle';
import { Lock } from '@material-ui/icons';
import { MenuItem, Select, FormControl, InputLabel, Divider, ListItemText, Typography } from '@material-ui/core';
import { Elements } from 'react-stripe-elements';
import { prettyCurrency } from 'utils/misc';
import {
    BANK_ACCOUNT_STATUS_LABELS,
    BANK_ACCOUNT_STATUS_VERIFIED,
    CARD_PAYMENT,
    NEW_BANK_ACCOUNT,
    ROUTES,
} from 'constants/constants';
import { LinkButton, blackLinkRoot, arrowIcon, SpacedH1, SpacedH6, CustomTypographyBody2 } from 'assets/styles';
import padlockImage from 'assets/new-icons/padlock-image.svg';
import paymentWallet from 'assets/new-icons/cash-payment-wallet.svg';
import creditCardImage from 'assets/new-icons/credit-card.svg';

import ActionButton from 'common-components/ActionButton/ActionButton';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import GenericMessage from 'common-components/GenericMessage/GenericMessage';
import PaymentForm from './PaymentForm';
import { defaultLinkColor } from 'assets/constants';

const bodyRow = css`
    display: flex;
    color: #828796;
    font-size: 12px;
    margin: 30px 30px 0 30px;
    font-weight: 300;
    align-items: center;
`;

const bodyText = css`
    margin-left: 15px !important;
`;

const linkStyle = css`
    color: ${defaultLinkColor};
    font-weight: 300;
`;

const SpacedImg = styled.img`
    margin: 15px 0;
`;

const warningStyle = css`
    text-align: left;
`;

export const PaymentMethods = ({
    error,
    applicant,
    totalPayment,
    payments,
    handleClickBack,
    setCurrentPage,
    paymentButtonDisabled,
    handlePayButtonClicked,
    onPaymentMethodChanged,
    acceptACHPayments,
    achPaymentRestrictionPeriod,
    blockACHPaymentsBeforeMovein,
    leaseTransaction,
}) => {
    const allowACHPayments = useMemo(() => {
        //if achPaymentRestrictionPeriod == 0 then no time has been set and we allow
        if (blockACHPaymentsBeforeMovein && achPaymentRestrictionPeriod > 0) {
            const startDate = new Date(leaseTransaction.lease_start_date);
            const restrictionStartDate = startDate.setDate(startDate.getDate() - achPaymentRestrictionPeriod);
            if (new Date() >= restrictionStartDate) {
                //we are inside the restriction window
                return false;
            }
        }
        return true;
    }, [blockACHPaymentsBeforeMovein, achPaymentRestrictionPeriod, leaseTransaction.lease_start_date]);

    //if there is no lease_start_date then turn off ach payments
    const [paymentMethod, setPaymentMethod] = useState(
        leaseTransaction?.lease_start_date && acceptACHPayments ? '' : 'card'
    );
    const [disableBack, setDisableBack] = useState(false);
    const handleChange = (e) => {
        setPaymentMethod(e.target.value);
        onPaymentMethodChanged();
    };
    const totalToPay = totalPayment ? prettyCurrency(totalPayment) : '$0';

    return (
        <>
            <SpacedH1>Almost There, {applicant.first_name}!</SpacedH1>
            <SpacedH6>The application payments for this apartment are {totalToPay}.</SpacedH6>
            {!allowACHPayments && (
                <GenericFormMessage
                    type="warning"
                    messages={
                        <div className={warningStyle}>
                            To ensure your payment is successfully processed by your move-in date, we&apos;re only
                            accepting credit/debit card payments at this time.
                        </div>
                    }
                />
            )}
            {paymentMethod === 'card' ? (
                <img src={creditCardImage} alt="credit card" />
            ) : (
                <SpacedImg src={paymentWallet} alt={'wallet'} />
            )}
            <div className={bodyRow}>
                <img src={padlockImage} alt="padlock" width="18" height="28" />
                <Typography variant="body2" align="left" className={bodyText}>
                    This app will never make any transaction on your behalf. We guard your data and you can read more
                    about our{' '}
                    <Link className={linkStyle} target="_blank" to={ROUTES.PRIVACY_POLICY}>
                        privacy policy here.
                    </Link>
                </Typography>
            </div>
            <br />
            {error && <GenericFormMessage type="error" messages={error} />}
            {acceptACHPayments && (
                <FormControl fullWidth>
                    <InputLabel htmlFor="payment-method-selector">Select Payment Method</InputLabel>
                    <Select
                        id="payment-method-selector"
                        defaultValue={''}
                        displayEmpty={true}
                        value={paymentMethod}
                        onChange={handleChange}
                    >
                        {applicant?.bank_accounts.map((bank_account) => (
                            <MenuItem
                                key={bank_account.payment_method_token}
                                value={bank_account.payment_method_token}
                                disabled={bank_account.status !== BANK_ACCOUNT_STATUS_VERIFIED || !allowACHPayments}
                            >
                                {bank_account.status === BANK_ACCOUNT_STATUS_VERIFIED
                                    ? bank_account.label
                                    : bank_account.label + ' - ' + BANK_ACCOUNT_STATUS_LABELS[bank_account.status]}
                            </MenuItem>
                        ))}
                        {!!applicant.bank_accounts.length && <Divider />}
                        <MenuItem id="card_payment_menuitem" value={CARD_PAYMENT} style={{ color: '#26305B' }}>
                            <ListItemText style={{ textAlign: 'left' }}>Credit/Debit Card</ListItemText>
                        </MenuItem>
                        <Divider />
                        {acceptACHPayments && (
                            <MenuItem
                                id="bank_details_menuitem"
                                value={NEW_BANK_ACCOUNT}
                                disabled={!allowACHPayments}
                                onClick={() => setCurrentPage('add_bank_details')}
                                style={{ color: '#26305B' }}
                            >
                                <AddCircle fontSize="small" />
                                &nbsp;&nbsp;Add Bank Account
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            )}
            {paymentMethod === 'card' ? (
                <Elements
                    /* elements requires direct import of fonts */
                    fonts={[
                        {
                            cssSrc: 'https://use.typekit.net/asb6wyn.css',
                        },
                    ]}
                >
                    <PaymentForm
                        applicant={applicant}
                        setDisableBack={setDisableBack}
                        onSuccess={() => setCurrentPage('receipt')}
                        totalPayment={totalPayment}
                        payments={payments}
                    />
                </Elements>
            ) : (
                <>
                    <CustomTypographyBody2
                        id="payment-method-helper-text"
                        align="left"
                        margin="5px 0 0 0"
                        color="#969696"
                    >
                        Saved bank accounts can be managed by going to{' '}
                        <Link to={ROUTES.PAYMENT_METHODS}>Payment Methods</Link>.
                    </CustomTypographyBody2>
                    {applicant.failed_payment && applicant.failed_payment.payment_method_id === paymentMethod && (
                        <GenericMessage
                            type="warning"
                            messages={
                                <div className={warningStyle}>
                                    Selected payment method failed on{' '}
                                    {moment(applicant.failed_payment.failed_date).format('MM/DD/YYYY')}. Please resolve
                                    any issues before you continue with this payment method. Learn more about your
                                    failed payment in <Link to={ROUTES.PAYMENT_METHODS}>Payment Methods</Link>.
                                </div>
                            }
                        />
                    )}
                    <CustomTypographyBody2 align="left" margin="37px 0 0 0" color="#000000">
                        Stripe and its affiliates will be processing this transaction for Funnel Leasing. Please see
                        their{' '}
                        <Link to={ROUTES.TERMS} target="_blank">
                            terms of service
                        </Link>{' '}
                        for more information.
                    </CustomTypographyBody2>
                    <br />
                    <br />
                    <br />
                    <ActionButton
                        marginTop={35}
                        marginBottom={20}
                        disabled={!paymentMethod || paymentButtonDisabled}
                        onClick={() => handlePayButtonClicked(paymentMethod)}
                    >
                        <Lock style={{ width: 16, marginRight: 8 }} />
                        {`Pay ${totalToPay}`}
                    </ActionButton>
                </>
            )}
            <LinkButton disabled={disableBack} className={blackLinkRoot} onClick={handleClickBack}>
                <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
            </LinkButton>
        </>
    );
};

PaymentMethods.propTypes = {
    error: PropTypes.string,
    applicant: PropTypes.object,
    totalPayment: PropTypes.number,
    payments: PropTypes.array,
    handleClickBack: PropTypes.func,
    setCurrentPage: PropTypes.func,
    acceptACHPayments: PropTypes.bool,
    achPaymentRestrictionPeriod: PropTypes.number,
    blockACHPaymentsBeforeMovein: PropTypes.bool,
    leaseTransaction: PropTypes.object,
    paymentButtonDisabled: PropTypes.bool.isRequired,
    onPaymentMethodChanged: PropTypes.func.isRequired,
    handlePayButtonClicked: PropTypes.func.isRequired,
};

export default PaymentMethods;
