import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { generatePath } from 'react-router';

import Grid from '@material-ui/core/Grid';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

import { actions as loaderActions } from 'reducers/loader';
import { setVacateFormData } from 'reducers/forms/vacate-form';
import { ROUTES } from 'constants/constants';
import { serializeDate } from 'utils/misc';

import GenericMessage from 'common-components/GenericMessage/GenericMessage';
import Page from 'common-components/Page/Page';
import ReasonsToVacateSelector from 'pages/Vacate/components/ReasonsToVacateSelector';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { getNtvWarningMessage } from 'pages/Vacate/utils/helpers';

import businessContractHandshake from 'assets/new-icons/business-contract-handshake.svg';
import { arrowIcon, blackLinkRoot, LinkButton } from 'assets/styles';

const useStyles = makeStyles(() => ({
    vacateIcon: {
        width: '180px',
        height: '180px',
        marginBottom: '5px',
    },
    form: {
        marginTop: '60px',
        textAlign: 'left',
    },
}));

export function VacateDetailsPage({ configuration, lease, toggleLoader, history, setVacateFormData, vacateFormData }) {
    const { lease_end_date: leaseEndDate = '', is_month_to_month: isMonthToMonth = false } = lease || {};
    const initialMoveOutDate = vacateFormData?.move_out_date || lease?.move_out_date || leaseEndDate;
    const [warningMessage, setWarningMessage] = useState(null);

    const classes = useStyles();

    const handleSubmit = useCallback(
        async (values, { setSubmitting, setErrors }) => {
            toggleLoader(true);
            setSubmitting(true);

            try {
                setVacateFormData({
                    move_out_date:
                        typeof values.move_out_date === 'object'
                            ? serializeDate(values.move_out_date)
                            : values.move_out_date,
                    vacate_reason_id: values.vacate_reason,
                });

                toggleLoader(false);
                history.push(generatePath(ROUTES.VACATE_NEW_ADDRESS, { lease_id: lease.id }));
            } catch (e) {
                setErrors('Oops! We ran into some issues. Please try again later.');
                toggleLoader(false);
                setSubmitting(false);
            }
        },
        [toggleLoader, history, lease, setVacateFormData]
    );

    if (!lease) return null;

    const initialValues = {
        move_out_date: initialMoveOutDate,
        vacate_reason: vacateFormData?.vacate_reason_id,
    };

    return (
        <Page
            title="We're sorry to see you go!"
            subTitle={'We just need some info so we can get you all ready to move out.'}
            image={{ src: businessContractHandshake, className: classes.vacateIcon }}
        >
            <p>
                {lease.unit.building.street_address} Unit {lease.unit.unit_number}
            </p>
            <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                {({ values, handleChange, isSubmitting, setFieldValue }) => {
                    return (
                        <Form autoComplete="off" className={classes.form}>
                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <KeyboardDatePicker
                                            id="move-out-date"
                                            format="MM/dd/yyyy"
                                            placeholder="mm/dd/yyyy"
                                            label="Move out date"
                                            value={values?.move_out_date ? moment(values.move_out_date) : null}
                                            onChange={(value) => {
                                                setFieldValue('move_out_date', value);
                                                setWarningMessage(
                                                    getNtvWarningMessage(
                                                        configuration,
                                                        leaseEndDate,
                                                        value,
                                                        isMonthToMonth
                                                    )
                                                );
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            clearable
                                            fullWidth
                                        />
                                        {warningMessage && (
                                            <GenericMessage
                                                type="warning"
                                                messages={[
                                                    `Your lease ends on ${moment(leaseEndDate).format('MM/DD/YYYY')}.`,
                                                    warningMessage,
                                                ]}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReasonsToVacateSelector
                                            vacateReason={values.vacate_reason}
                                            handleChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <ActionButton
                                disabled={!values.move_out_date || !values.vacate_reason || isSubmitting}
                                marginTop={31}
                                marginBottom={20}
                            >
                                Continue
                            </ActionButton>
                        </Form>
                    );
                }}
            </Formik>
            <LinkButton
                className={blackLinkRoot}
                onClick={() => history.push(generatePath(ROUTES.LEASE_RENEWAL_OFFERS, { lease_id: lease.id }))}
            >
                <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
            </LinkButton>
        </Page>
    );
}

VacateDetailsPage.propTypes = {
    configuration: PropTypes.object,
    lease: PropTypes.object,
    history: PropTypes.object.isRequired,
    vacateFormData: PropTypes.object,
    toggleLoader: PropTypes.func.isRequired,
    setVacateFormData: PropTypes.func,
};

const mapStateToProps = (state) => ({
    configuration: state.configuration,
    lease: state.lease,
    vacateFormData: state.vacateForm,
});

const mapActionsToProps = {
    toggleLoader: loaderActions.toggleLoader,
    setVacateFormData,
};

export default connect(mapStateToProps, mapActionsToProps)(VacateDetailsPage);
