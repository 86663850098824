import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { Typography } from '@material-ui/core';
import intersectionBy from 'lodash/intersectionBy';
import { Link } from 'react-router-dom';

import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import { labelForLineItemGroup, prettyCurrency } from 'utils/misc';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { BackLink } from 'common-components/BackLink/BackLink';
import ApplicationFees from './ApplicationFees';
import { HoldingDeposit } from './HoldingDeposit';
import { Card, CardSection, CardRow, CardRowBorderTop, CardRowTotal, SpacedH1, SpacedH6 } from 'assets/styles';
import paymentWallet from 'assets/new-icons/cash-payment-wallet.svg';
import { LINE_ITEM_FEE_TYPES, LINE_ITEM_TYPE_HOLDING_DEPOSIT, ROUTES } from 'constants/constants';

const SpacedImg = styled.img`
    margin: 15px 0;
`;

const warningStyle = css`
    text-align: left;
`;

export const applicationUnit = css`
    color: #454b57;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding-bottom: 68px;
`;

export const FeesDepositsOptions = ({
    payables,
    applicant,
    unitNumber,
    communityName,
    isTransfer,
    isOutstanding,
    handleContinue,
    handleClickBack,
    lineItemsToPayFor,
    setLineItemsToPayFor,
    applicationFeeTooltipCopy,
    allowApplicantsToPayForEveryone,
}) => {
    useEffect(() => {
        // Initially, current applicant defaults to pay their own line items only and
        // since line items are grouped by type we need to ungroup and filter by current applicant.
        // We do this only on mount
        setLineItemsToPayFor(
            Object.values(payables)
                .reduce((values, value) => [...values, ...value], [])
                .filter(
                    (p) =>
                        (p.payer.applicant_id === applicant.id || p.payer.id === applicant.id) && !p.waived && !p.paid
                )
        );
        // eslint-disable-next-line
    }, []);

    if (!payables) return <div />;
    const totalPaymentAmount = lineItemsToPayFor.reduce((sum, l) => sum + l.amount_with_tax, 0);
    const totalTaxAmount = lineItemsToPayFor.reduce((sum, l) => sum + l.tax_amount, 0);

    return (
        <>
            <SpacedH1>
                {isTransfer ? 'Transfer Payments' : isOutstanding ? 'Outstanding Balance' : 'Application Payments'}
            </SpacedH1>
            {isOutstanding && !isTransfer ? (
                <SpacedH6>
                    It looks like you have some outstanding fees/deposits. Let’s settle up so you can move forward with
                    your application.
                </SpacedH6>
            ) : null}
            {applicant.failed_payment && (
                <GenericFormMessage
                    type="warning"
                    messages={
                        <div className={warningStyle}>
                            Payment failed. Please resubmit payment to prevent the cancelation of your application.
                            Learn more about your failed payment in{' '}
                            <Link to={ROUTES.PAYMENT_METHODS}>Payment Methods</Link>.
                        </div>
                    }
                />
            )}
            <SpacedImg src={paymentWallet} alt={'wallet'} />
            <div className={applicationUnit}>{`${communityName} Unit ${unitNumber}`}</div>
            <Card>
                <CardSection>
                    <CardRow>
                        <Typography variant="body1">
                            <strong>Application Payments</strong>
                        </Typography>
                    </CardRow>
                    {LINE_ITEM_FEE_TYPES.map((lineItemType) => {
                        const currentPayerFees = [];
                        const otherPayersFees = [];
                        payables[lineItemType].forEach((f) => {
                            if (f.payer.applicant_id === applicant.id || f.payer.id === applicant.id)
                                currentPayerFees.push(f);
                            else otherPayersFees.push(f);
                        });

                        const otherPayersHaveUnpaidFees = otherPayersFees.some((f) => !f.waived && !f.paid);
                        // Show the fees if:
                        // - There are ones that belong to the current applicant
                        // - There are ones that belong to other applicants and are unpaid and unwaived
                        if (!currentPayerFees.length && !otherPayersHaveUnpaidFees) return null;

                        return (
                            <ApplicationFees
                                {...{
                                    key: lineItemType,
                                    applicationFeeTooltipCopy,
                                    payingForEveryone: !!intersectionBy(lineItemsToPayFor, otherPayersFees, 'id')
                                        .length,
                                    setPayingForEveryone: (payingForEveryone) =>
                                        setLineItemsToPayFor((_lineItemsToPayFor) => {
                                            // If paying for everyone, then we need to add all unpaid line items of this type
                                            if (payingForEveryone)
                                                return [
                                                    ..._lineItemsToPayFor.filter((l) => l.type !== lineItemType),
                                                    ...payables[lineItemType].filter((l) => !l.waived && !l.paid),
                                                ];
                                            // Otherwise we need to add only current applicant's unpaid line items
                                            else
                                                return [
                                                    ..._lineItemsToPayFor.filter((l) => l.type !== lineItemType),
                                                    ...currentPayerFees.filter((l) => !l.waived && !l.paid),
                                                ];
                                        }),
                                    allPayersFees: payables[lineItemType],
                                    currentPayerFeesRepresentation:
                                        currentPayerFees.length > 0
                                            ? {
                                                  waived:
                                                      currentPayerFees.filter((f) => f.waived).length ===
                                                          currentPayerFees.length && currentPayerFees.length > 0,
                                                  amount: currentPayerFees.reduce(
                                                      (sum, p) => (!p.waived && !p.paid ? sum + p.amount : sum),
                                                      0
                                                  ),
                                                  paid:
                                                      currentPayerFees.filter((f) => f.paid).length ===
                                                          currentPayerFees.length && currentPayerFees.length > 0,
                                              }
                                            : null,
                                    canPayForEveryone: allowApplicantsToPayForEveryone && otherPayersHaveUnpaidFees,
                                    name: labelForLineItemGroup(payables[lineItemType]),
                                    disclaimer: payables[lineItemType].find((p) => !!p.fee_help_text)?.fee_help_text,
                                }}
                            />
                        );
                    })}
                    {!!payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].length && (
                        <HoldingDeposit
                            paid={
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].filter((p) => p.waived || p.paid).length ===
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].length
                            }
                            waived={
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].filter((p) => p.waived).length ===
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].length
                            }
                            amount={prettyCurrency(
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].reduce((sum, p) => sum + p.amount, 0)
                            )}
                            name={labelForLineItemGroup(payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT])}
                            disclaimer={
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].find((p) => !!p.fee_help_text)?.fee_help_text
                            }
                        />
                    )}

                    {Boolean(totalTaxAmount) && (
                        <CardRowBorderTop>
                            <Typography variant="body1">Tax</Typography>
                            <div>
                                <Typography variant="body1">{prettyCurrency(totalTaxAmount)}</Typography>
                            </div>
                        </CardRowBorderTop>
                    )}

                    {!!totalPaymentAmount && (
                        <CardRowTotal>
                            <Typography variant="body1">
                                <strong>Total</strong>
                            </Typography>
                            <div>
                                <Typography variant="body1">
                                    <strong>{prettyCurrency(parseFloat(totalPaymentAmount))}</strong>
                                </Typography>
                            </div>
                        </CardRowTotal>
                    )}
                </CardSection>
            </Card>
            <ActionButton onClick={() => handleContinue()} marginTop={30} marginBottom={20}>
                Continue
            </ActionButton>
            {!isOutstanding ? <BackLink to={handleClickBack} /> : undefined}
        </>
    );
};

FeesDepositsOptions.propTypes = {
    applicationFeeTooltipCopy: PropTypes.string,
    handleContinue: PropTypes.func,
    handleClickBack: PropTypes.func,
    applicant: PropTypes.object,
    payables: PropTypes.object,
    lineItemsToPayFor: PropTypes.array,
    setLineItemsToPayFor: PropTypes.func,
    unitNumber: PropTypes.string.isRequired,
    communityName: PropTypes.string.isRequired,
    isOutstanding: PropTypes.bool,
    isTransfer: PropTypes.bool,
    allowApplicantsToPayForEveryone: PropTypes.bool,
};

export default FeesDepositsOptions;
