import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from 'emotion';
import exampleCheck from 'assets/images/exampleCheck.svg';
import { SpacedH1 } from 'assets/styles';
import ActionButton from 'common-components/ActionButton/ActionButton';

const Header = styled.div`
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
`;

const body = css`
    padding: 0 20px 0 20px;
    text-align: left;
`;

const image = css`
    padding: 40px 20px 40px 20px;
`;

const line = css`
    border-top: 1px solid #eeeeee;
`;

export const BankAccountRoutingDetails = ({ handleContinueClick }) => {
    return (
        <Fragment>
            <Header>
                <SpacedH1>Where are my routing and account numbers?</SpacedH1>
            </Header>
            <div className={line} />
            <div className={image}>
                <img src={exampleCheck} alt="car parking" />
            </div>
            <div className={body}>
                Don’t have a check handy? Here are a few other ways you can find your routing and account numbers:
                <ul>
                    <li>Check your bank statement</li>
                    <li>Log in to your bank’s website or mobile app</li>
                    <li>Call your bank’s customer service line</li>
                </ul>
            </div>

            <ActionButton id="account_routing_details" onClick={handleContinueClick} marginTop={25} marginBottom={20}>
                Got it
            </ActionButton>
        </Fragment>
    );
};

BankAccountRoutingDetails.propTypes = {
    handleContinueClick: PropTypes.func.isRequired,
};

export default BankAccountRoutingDetails;
