import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { employerAddressFieldsValidation } from 'utils/validation';
import EmployerAutomatedAddressForm from './EmployerAutomatedAddressForm';
import EmployerManualAddressForm from './EmployerManualAddressForm';

export const EmployerAddressForm = ({ applicant, showAutomatedAddress, errors, onSubmit }) => {
    const initialValues = useMemo(() => {
        const searchBuilder = [];
        const employer = applicant.employer;
        if (employer?.address_street) searchBuilder.push(employer?.address_street);
        if (employer?.address_city) searchBuilder.push(employer?.address_city);
        if (employer?.address_state) searchBuilder.push(employer?.address_state);
        if (employer?.address_postal_code) searchBuilder.push(employer?.address_postal_code);
        const search = searchBuilder.join(', ');

        return {
            search,
            name: employer?.name || '',
            address_street: employer?.address_street || '',
            address_city: employer?.address_city || '',
            address_state: employer?.address_state || '',
            address_postal_code: employer?.address_postal_code || '',
            address_line_2: employer?.address_line_2 || '',
        };
    }, [applicant]);

    if (!applicant) {
        return null;
    }

    const FormComponent = showAutomatedAddress ? EmployerAutomatedAddressForm : EmployerManualAddressForm;

    return (
        <FormComponent
            validationSchema={employerAddressFieldsValidation}
            initialValues={initialValues}
            errors={errors}
            onSubmit={onSubmit}
        />
    );
};

EmployerAddressForm.propTypes = {
    applicant: PropTypes.object,
    errors: PropTypes.array,
    showAutomatedAddress: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
};

export default EmployerAddressForm;
