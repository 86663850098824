import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import API from 'api/api';
import { ROUTES } from 'constants/constants';
import { actions as loaderActions } from 'reducers/loader';
import { useSentryUtils } from 'common-components/SentryUtils/SentryUtilsProvider';
import { getMonthToMonthRent, getMonthToMonthLeaseOffer } from 'pages/Renewal/utils/helpers';

import Page from 'common-components/Page/Page';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { MonthToMonthPaymentDetailsCard } from 'pages/MonthToMonth/components/MonthToMonthPaymentDetailsCard';

import { arrowIcon, blackLinkRoot, LinkButton } from 'assets/styles';
import { Grid, makeStyles } from '@material-ui/core';
import { LEASE_TYPE_IMPORTED } from 'constants/constants';
import { WarningRounded } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    warningText: {
        color: '#000000',
        fontSize: '12px',
        textAlign: 'left',
        lineHeight: '16px',
    },
    warningContainer: {
        marginTop: '20px',
    },
    leftText: {
        textAlign: 'left',
    },
    icon: {
        fill: '#F8D509',
    },
}));

export function MonthToMonthPaymentDetailsPage({ lease, history, toggleLoader }) {
    const classes = useStyles();
    const { logToSentry } = useSentryUtils();
    const [error, setError] = useState(null);
    const errorNotification = error && {
        type: 'error',
        messages: 'Oops! We ran into some issues. Please try again later.',
    };

    const handleSubmit = useCallback(async () => {
        toggleLoader(true);
        const monthToMonthLeaseOffer = getMonthToMonthLeaseOffer(lease);
        try {
            const monthToMonthBaseRent = getMonthToMonthRent(lease); // This should be replaced by monthToMonthLeaseOffer

            const response = await API.patchLease(lease.id, {
                is_month_to_month: true,
                month_to_month_rent: monthToMonthBaseRent,
            });

            if (response.errors) {
                setError(true);
                return response;
            }

            history.push(generatePath(ROUTES.LEASE_RENEWAL_MONTH_TO_MONTH_CONFIRMED, { lease_id: lease.id }));
        } catch (e) {
            setError(true);
            logToSentry(e, monthToMonthLeaseOffer);
        } finally {
            toggleLoader(false);
        }
    }, [toggleLoader, logToSentry, history, lease]);

    if (!lease) return null;

    return (
        <Page
            title="Payment Details"
            subTitle={'Review your payment details before confirming.'}
            notification={errorNotification}
        >
            <MonthToMonthPaymentDetailsCard lease={{ ...lease }} />

            {lease.type === LEASE_TYPE_IMPORTED && (
                <Grid classes={{ root: classes.warningContainer }} container>
                    <Grid item xs={2}>
                        <WarningRounded classes={{ root: classes.icon }} />
                    </Grid>
                    <Grid item xs={9} classes={{ root: classes.leftText }}>
                        <span className={classes.warningText}>
                            Pets and rental options such as parking and storage are not included in this breakdown.
                            Please call us at 123-456-7890 if you have questions about your full payments.
                        </span>
                    </Grid>
                </Grid>
            )}
            <ActionButton marginTop={30} marginBottom={20} onClick={handleSubmit}>
                Confirm
            </ActionButton>
            <LinkButton
                className={blackLinkRoot}
                onClick={() => history.push(generatePath(ROUTES.LEASE_RENEWAL_MONTH_TO_MONTH, { lease_id: lease.id }))}
            >
                <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
            </LinkButton>
        </Page>
    );
}

MonthToMonthPaymentDetailsPage.propTypes = {
    lease: PropTypes.object,
    history: PropTypes.object.isRequired,
    toggleLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    lease: state.lease,
});

const mapActionsToProps = {
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapActionsToProps)(MonthToMonthPaymentDetailsPage);
