import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import {
    APPLICANT_ROLE_VALUES,
    APPLICANT_ROLE_LABELS,
    ROUTES,
    APPLICATION_STATUS_IN_PROGRESS,
} from 'constants/constants';
import { LEASE_TRANSACTION_TYPE_APPLICATION } from 'constants/leaseTransaction';
import captureRoute from 'utils/captureRoute';
import ResendLinkForm from 'common-components/ResendLinkForm/ResendLinkForm';
import PersonRow from 'pages/Application/components/PersonRow';

import { FolderImage, BulbImage, statusBlurb, gridContainer } from './ApplicationCompletePageStyles';
import {
    Card,
    CardSection,
    leftText,
    CardRow,
    SpacedH1,
    SpacedH6,
    CustomTypographyBody2,
    CustomTypographyBody1,
} from 'assets/styles';
import lightbulb from 'assets/new-icons/light-bulb-shine-streamlined.svg';
import statusFolder from 'assets/new-icons/task-checklist.svg';
import { Typography } from '@material-ui/core';

export const ApplicationCompletePage = ({ leaseTransaction, configuration, applicant }) => {
    const [resendFormValues, setResendFormValues] = useState();
    const isApplicationStatusInProgess = leaseTransaction?.status === APPLICATION_STATUS_IN_PROGRESS || false;

    if (!leaseTransaction || !configuration) return null;
    if (resendFormValues) {
        return (
            <ResendLinkForm
                initialValues={resendFormValues}
                handleConfirmationClick={setResendFormValues}
                confirmationButtonText="Back to Application Status"
            />
        );
    }

    const isGuarantor = (person) => person.role === APPLICANT_ROLE_VALUES['ROLE_GUARANTOR'];

    const { unit, primary_applicant, co_applicants, occupants, invitees } = leaseTransaction;
    const guarantors = [...leaseTransaction.guarantors, ...invitees.filter(isGuarantor)];
    const buildingName = configuration.community.building_name || configuration.community.normalized_street_address;
    const role = applicant.role;

    return (
        <Fragment>
            <SpacedH1>Hooray! You&apos;re done.</SpacedH1>
            <SpacedH6>
                We&apos;ll notify you about your application status, but you can always come back here to check the
                progress!
            </SpacedH6>
            <FolderImage src={statusFolder} />
            <div className={gridContainer}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <BulbImage alt="light bulb" src={lightbulb} />
                    </Grid>
                    <Grid item xs={9} classes={{ root: leftText }}>
                        <span className={statusBlurb}>
                            Once the application is reviewed, you’ll get an email detailing next steps.
                        </span>
                    </Grid>
                </Grid>
            </div>
            <Card>
                <CardSection>
                    <CardRow>
                        <Typography variant="body1">
                            <strong>Application Status</strong>
                        </Typography>
                    </CardRow>
                    <CardRow>
                        <div>
                            <Typography variant="body1">{buildingName}</Typography>
                            <CustomTypographyBody2 color="#828796">
                                {unit && unit.unit_number && `Unit ${unit.unit_number}`}
                            </CustomTypographyBody2>
                        </div>
                        <div>
                            {isApplicationStatusInProgess ? (
                                <CustomTypographyBody1 color="#FCC022">
                                    <strong>In Progress</strong>
                                </CustomTypographyBody1>
                            ) : (
                                <CustomTypographyBody1 color="#67C18B">
                                    <strong>Submitted</strong>
                                </CustomTypographyBody1>
                            )}
                        </div>
                    </CardRow>
                </CardSection>
            </Card>
            {leaseTransaction.type === LEASE_TRANSACTION_TYPE_APPLICATION && (
                <>
                    <Card>
                        <CardSection>
                            <CardRow>
                                <Typography variant="body1">
                                    <strong>Applicant Status</strong>
                                </Typography>
                            </CardRow>
                            {primary_applicant && (
                                <PersonRow
                                    person={primary_applicant}
                                    label="Main Applicant"
                                    role={role}
                                    handleClickLink={setResendFormValues}
                                />
                            )}
                            {co_applicants?.map((coApp) => (
                                <PersonRow
                                    key={coApp.id}
                                    person={coApp}
                                    label="Roommate"
                                    role={role}
                                    handleClickLink={setResendFormValues}
                                />
                            ))}
                            {occupants?.map((occupant) => (
                                <PersonRow
                                    key={occupant.id}
                                    person={occupant}
                                    label="Occupant"
                                    role={role}
                                    handleClickLink={setResendFormValues}
                                />
                            ))}
                            {invitees
                                .filter((i) => !isGuarantor(i))
                                .map((invitee) => (
                                    <PersonRow
                                        key={invitee.id}
                                        person={invitee}
                                        label={APPLICANT_ROLE_LABELS[invitee.role]}
                                        role={role}
                                        handleClickLink={setResendFormValues}
                                    />
                                ))}
                        </CardSection>
                    </Card>
                    {guarantors?.length > 0 && (
                        <Card>
                            <CardSection>
                                <CardRow>
                                    <Typography variant="body1">
                                        <strong>Guarantor Status</strong>
                                    </Typography>
                                </CardRow>
                                {guarantors.map((guarantor) => (
                                    <PersonRow
                                        key={guarantor.id}
                                        person={guarantor}
                                        label="Guarantor"
                                        role={role}
                                        handleClickLink={setResendFormValues}
                                    />
                                ))}
                            </CardSection>
                        </Card>
                    )}
                </>
            )}
        </Fragment>
    );
};

ApplicationCompletePage.propTypes = {
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,
    applicant: PropTypes.object,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    configuration: state.configuration,
    applicant: state.applicant,
});

export default connect(mapStateToProps, null)(captureRoute(ApplicationCompletePage, ROUTES.APP_COMPLETE));
