import auth from 'utils/auth';
import fetch from 'utils/fetch';

import { HOTDOCS_DOCUMENT_TYPE, UPLOADED_FINANCIAL_PROOF_DOCUMENT_MODEL } from 'constants/constants';
import { CHUCK_BASE_URL } from 'config';

export class VeryGoodSecurityApi {
    constructor({ vault_url } = {}) {
        // Singleton
        if (VeryGoodSecurityApi._instance) {
            return VeryGoodSecurityApi._instance;
        }
        if (!vault_url) throw new Error('Cannot initialize with missing vgs config');
        VeryGoodSecurityApi._instance = this;
        // Runtime configuration
        this.vaultUrl = vault_url;
    }

    async uploadFiles(files_with_context) {
        const url = `${this.vaultUrl}/api/vgs/${UPLOADED_FINANCIAL_PROOF_DOCUMENT_MODEL}/${auth.getToken()}/`;
        const uploadPromises = files_with_context.map(async (f) => {
            return await fetch(url, {
                method: 'POST',
                data: [f],
                fullUrl: true,
            });
        });

        const responses = await Promise.all(uploadPromises);
        return responses.flat();
    }
}

const API = {
    Payments: {},
};

API.Payments.setupIntent = async (leaseTransactionId) => {
    return fetch(`/transaction/${leaseTransactionId}/payments/intents/setup/`, {
        method: 'POST',
    });
};

API.Payments.confirmIntent = async (leaseTransactionId, payload) => {
    return fetch(`/transaction/${leaseTransactionId}/payments/intents/confirm/`, {
        method: 'POST',
        data: { ...payload },
    });
};

API.Payments.createIntent = async (leaseTransactionId, payload) => {
    return fetch(`/transaction/${leaseTransactionId}/payments/intents/create/`, {
        method: 'POST',
        data: { ...payload },
    });
};

API.Payments.removePaymentMethod = async (leaseTransactionId, paymentMethodId) => {
    return fetch(`/transaction/${leaseTransactionId}/payments/methods/${paymentMethodId}/`, {
        method: 'DELETE',
    });
};

API.fetchConfiguration = async (communityId) => {
    const configuration = await fetch(`/communities/${communityId}/`);
    new VeryGoodSecurityApi(configuration.vgs_config);
    return configuration;
};

API.fetchPrivacyPolicy = () => {
    return fetch('/privacy-policy', { responseFormat: 'TEXT' });
};

API.fetchFunnelTerms = (leaseSettingsId) => {
    return fetch(`/funnel_terms/${leaseSettingsId}`, { responseFormat: 'TEXT' });
};

API.fetchHoldingDepositTerms = (leaseTransactionId, canProceedToPayment) => {
    const url = `/transaction/${leaseTransactionId}/holding_deposit_terms/?can_proceed_to_payment=${
        canProceedToPayment ? 1 : 0
    }`;
    return fetch(url);
};

API.fetchPersonalizedInfo = (communityId, hash) => {
    return fetch(`/communities/${communityId}/personalized/${hash}`);
};

API.postPageComplete = (transactionId, page) => {
    return fetch(`/page-complete/${transactionId}/`, {
        method: 'POST',
        data: { page },
    });
};

API.fetchAvailableUnits = (lease_transaction_id, move_in_date) => {
    return fetch(`/transaction/${lease_transaction_id}/available-units/?move_in_date=${move_in_date}`);
};

API.patchTransaction = (transaction_id, data) => {
    return fetch(`/transactions/${transaction_id}/`, {
        method: 'PATCH',
        data,
    });
};

API.putApplicant = (transactionId, data) => {
    return fetch(`/applicant/${transactionId}/`, {
        method: 'PUT',
        data,
    });
};

API.fetchApplicant = (transactionId) => {
    return fetch(`/applicant/${transactionId}/`);
};

API.login = (username, password, communityId) => {
    return fetch(`/communities/${communityId}/login/`, {
        method: 'POST',
        data: { username, password },
    });
};

API.wormholeLogin = (username, token, communityId) => {
    return fetch(`/communities/${communityId}/login/`, {
        method: 'POST',
        data: { username, w: token },
    });
};

API.register = (data, leaseSettingsId, hash) => {
    let url = `/communities/${leaseSettingsId}/register/`;
    if (hash) {
        url = url.concat(`?v=${hash}`);
    }
    return fetch(url, {
        method: 'POST',
        collectUserAgentData: true,
        data,
    });
};

API.acceptTerms = (leaseTransactionId, data) => {
    return fetch(`/transaction/${leaseTransactionId}/terms-accepted/`, {
        method: 'POST',
        collectUserAgentData: true,
        data,
    });
};

API.passwordResetRequest = (data) => {
    return fetch('/password-reset/', {
        method: 'POST',
        json: true,
        data,
    });
};

API.passwordResetVerification = (data) => {
    return fetch('/password-reset-verification/', {
        method: 'POST',
        data,
    });
};

API.passwordReset = (data, token) => {
    return fetch('/password-change/', {
        method: 'PUT',
        data,
        headers: {
            Authorization: `token ${token}`,
        },
    });
};

API.passwordChange = (password) => {
    return fetch('/password-change/', {
        method: 'PUT',
        data: {
            password,
        },
        headers: {
            Authorization: `Token ${auth.getToken()}`,
        },
    });
};

API.inviteGuarantor = (leaseTransactionId, data) => {
    return fetch(`/transaction/${leaseTransactionId}/guarantors/`, {
        method: 'POST',
        data,
    });
};

API.createFinicityUrl = (leaseTransactionId) => {
    return fetch(`/transaction/${leaseTransactionId}/generate-finicity-link/`);
};

API.createPlaidLinkTokenUrl = (leaseTransactionId, plaidProduct) => {
    return fetch(`/transaction/${leaseTransactionId}/create-plaid-link-token/${plaidProduct}`);
};

API.getPlaidIncomeAndAssets = (leaseTransactionId, publicToken, reportType, plaidProduct) => {
    return fetch(
        `/transaction/${leaseTransactionId}/get-income-and-assets/${publicToken}/${reportType}/${plaidProduct}/`
    );
};

API.generateFinicityReports = (leaseTransactionId) => {
    return fetch(`/transaction/${leaseTransactionId}/generate-finicity-reports/`);
};

API.fetchPlaidReports = (leaseTransactionId, reportType) => {
    return fetch(`/transaction/${leaseTransactionId}/fetch-plaid-reports/${reportType}`);
};

API.fetchFinicityReports = (leaseTransactionId) => {
    return fetch(`/transaction/${leaseTransactionId}/fetch-finicity-reports/`);
};

API.embeddedSigningUrl = (leaseTransactionId, type) => {
    return fetch(`/transaction/${leaseTransactionId}/embedded-signing-url/?document_type=${type}`);
};

API.getEsignProviderConfig = (leaseTransactionId, type) => {
    return fetch(`/transaction/${leaseTransactionId}/esign-config?document_type=${type}`);
};

API.leaseDocumentUrl = (leaseTransactionId, type) => {
    return fetch(`/transaction/${leaseTransactionId}/lease-document-url/?document_type=${type}`);
};

API.stripePayment = (leaseTransactionId, data) => {
    return fetch(`/transaction/${leaseTransactionId}/payment`, {
        method: 'POST',
        data,
    });
};

API.updateInvitee = (leaseTransactionId, data, inviteeId) => {
    return fetch(`/transaction/${leaseTransactionId}/invitees/${inviteeId}/`, {
        method: 'PUT',
        data,
    });
};

API.postPassthrough = (leaseTransactionId, data, vgsEnabled) => {
    return fetch(`/transaction/${leaseTransactionId}/passthrough/`, {
        method: 'POST',
        collectUserAgentData: true,
        vgsEnabled,
        data,
    });
};

API.fetchPaymentOptions = (leaseTransactionId) => {
    return fetch(`/transaction/${leaseTransactionId}/payment-options/`);
};

API.getCurrentFlatQuote = (leaseTransactionId, data) => {
    return fetch(`/transaction/${leaseTransactionId}/payment-breakdown/`, {
        method: 'POST',
        data,
    });
};

API.getCurrentQuoteMonthly = (quote, month = null, year = null) => {
    const params = {};
    if (month) params['month'] = month;
    if (year) params['year'] = year;

    return fetch(`/quote/${quote}/payment-breakdown-monthly`, {
        method: 'GET',
        params,
    });
};

API.submitFinancialSource = (leaseTransactionId, formData) => {
    return fetch(`/transaction/${leaseTransactionId}/financial-sources/`, {
        method: 'POST',
        formData,
    });
};

API.createFinancialSource = (leaseTransactionId, data) => {
    return fetch(`/transaction/${leaseTransactionId}/financial-sources/`, {
        method: 'PUT',
        data,
    });
};

API.patchFinancialSource = (leaseTransactionId, data) => {
    return fetch(`/transaction/${leaseTransactionId}/financial-sources/`, {
        method: 'PATCH',
        data,
    });
};

API.uploadFinancialDocument = (leaseTransactionId, formData) => {
    return fetch(`/transaction/${leaseTransactionId}/financial-document/`, {
        method: 'POST',
        formData,
    });
};

API.getFinancialSources = (leaseTransactionId) => {
    return fetch(`/transaction/${leaseTransactionId}/financial-sources/`);
};

API.getFinancialSource = (leaseTransactionId, id) => {
    return fetch(`/transaction/${leaseTransactionId}/financial-sources/${id}/`);
};

API.deleteFinancialSource = (leaseTransactionId, id) => {
    return fetch(`/transaction/${leaseTransactionId}/financial-sources/${id}/`, {
        method: 'DELETE',
    });
};

API.resetApplicantFinancials = (leaseTransactionId) => {
    return fetch(`/transaction/${leaseTransactionId}/financial-sources/`, {
        method: 'DELETE',
    });
};

API.deletePerson = (leaseTransactionId, id) => {
    return fetch(`/transaction/${leaseTransactionId}/person/${id}/`, {
        method: 'DELETE',
    });
};

API.deleteInvitee = (leaseTransactionId, id) => {
    return fetch(`/transaction/${leaseTransactionId}/invitees/${id}/`, {
        method: 'DELETE',
    });
};

API.fetchAvailableLeaseTerms = (lease_transaction_id, data) => {
    return fetch(`/transaction/${lease_transaction_id}/available-lease-terms/`, {
        method: 'POST',
        data,
    });
};

API.fetchAANDocument = (transaction_id) => {
    return fetch(`/transactions/${transaction_id}/aan-document/`, { responseFormat: 'BLOB' });
};

API.postEmployer = (leaseTransactionId, data) => {
    return fetch(`/applicant-employer/${leaseTransactionId}/`, {
        method: 'POST',
        data,
    });
};

API.getInvitations = () => {
    return fetch('/invitations/');
};

API.createApplicantRole = (invitee_id) => {
    return fetch('/applicant-role/', {
        method: 'POST',
        data: { invitee_id },
    });
};

API.fetchUserProfile = () => {
    return fetch('/profile/');
};

API.putUserProfile = (data) => {
    return fetch('/profile/', {
        method: 'PUT',
        collectUserAgentData: true,
        data,
    });
};

API.fetchTransaction = (transaction_id) => {
    return fetch(`/transactions/${transaction_id}/`);
};

API.getLeaseTransactions = () => {
    return fetch('/transactions/');
};

API.postLeaseTransaction = (data) => {
    return fetch('/transactions/', {
        method: 'POST',
        data,
    });
};

API.postVerifyResidentId = (transaction_id, data) => {
    return fetch(`/transactions/${transaction_id}/identity-verification/`, {
        method: 'POST',
        data,
    });
};

API.postPlaidVerifyResidentId = (transaction_id, data) => {
    return fetch(`/transactions/${transaction_id}/plaid-identity-verification/`, {
        method: 'POST',
        data,
    });
};

API.fetchCriminalReviewLetterDocument = (transaction_id) => {
    return fetch(`/transactions/${transaction_id}/documents/?document_type=${HOTDOCS_DOCUMENT_TYPE}`, {
        responseFormat: 'BLOB',
    });
};

API.patchEvaluateResidentId = (transaction_id, data) => {
    return fetch(`/transactions/${transaction_id}/identity-verification/`, {
        method: 'PATCH',
        data,
    });
};

API.fetchLease = (lease_id) => {
    return fetch(`/lease/${lease_id}/`);
};

API.patchLease = (lease_id, data) => {
    return fetch(`/lease/${lease_id}/`, {
        method: 'PATCH',
        data,
    });
};

API.fetchVacateReasons = () => {
    return fetch(`/vacate-reasons/`);
};

API.getLeases = () => {
    return fetch(`/leases/`);
};

/**
 * A transfer token is used to append into the queryString of an URL to ResApp, which automatically logs in the user.
 *
 * @returns {Promise<*>}
 */
API.createTransferToken = () => {
    return fetch(`/transfer-tokens/`, {
        method: 'POST',
    });
};

/**
 * Given a transfer token, this function will return the auth token that is associated with it. Used in App.js
 *
 * @param transferToken
 * @returns {Promise<*>}
 */
API.getAuthTokenFromTransferToken = async (transferToken) => {
    return fetch(`/transfer-tokens/validate/`, {
        method: 'POST',
        data: {
            token: transferToken,
        },
    });
};

API.getApplicantScreeningReports = () => {
    return fetch('/screening-reports/');
};

API.getScreeningReport = (url) => {
    return fetch(`${CHUCK_BASE_URL}${url}`, {
        fullUrl: true,
    });
};

API.provideScreeningReport = (transaction_id, data) => {
    return fetch(`/transaction/${transaction_id}/provide-screening-reports/`, {
        method: 'PATCH',
        data,
    });
};

export default API;
