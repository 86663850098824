import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import * as Yup from 'yup';

import ActionButton from 'common-components/ActionButton/ActionButton';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import { Spacer } from 'assets/styles';

const useStyles = makeStyles(() => ({
    fieldContainer: {
        minHeight: 70,
    },
}));

export const validationSchema = Yup.object().shape({
    stated_income: Yup.string().required('Required'),
    stated_assets: Yup.string().required('Required'),
});

export const StatedIncomeAndAssetsForm = ({ applicant, onSubmit, errors }) => {
    const classes = useStyles();
    const initialValues = {
        stated_income: applicant?.stated_income || '',
        stated_assets: applicant?.stated_assets || '',
    };

    const stateNoIncomeAssets = async (formikProps) => {
        await onSubmit(
            {
                stated_income: '0',
                stated_assets: '0',
            },
            formikProps
        );
    };

    if (!applicant) {
        return null;
    }

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, submitCount, isSubmitting, setFieldValue, errors: formErrors, setErrors, setSubmitting }) => {
                return (
                    <Form autoComplete="off">
                        <GenericFormMessage type="error" messages={errors} />
                        <div className={classes.fieldContainer}>
                            <CurrencyTextField
                                error={submitCount > 0 && !!formErrors.stated_income}
                                helperText={submitCount > 0 && formErrors.stated_income}
                                fullWidth
                                textAlign="left"
                                label="Estimated Annual Income"
                                minimumValue="0"
                                name="stated_income"
                                currencySymbol="$"
                                onChange={(_event, value) => setFieldValue('stated_income', value)}
                                outputFormat="string"
                                value={values.stated_income}
                                inputProps={{ autoComplete: 'off' }}
                                modifyValueOnWheel={false}
                            />
                        </div>
                        <Spacer height={15} />
                        <div className={classes.fieldContainer}>
                            <CurrencyTextField
                                error={submitCount > 0 && !!formErrors.stated_assets}
                                helperText={submitCount > 0 && formErrors.stated_assets}
                                fullWidth
                                textAlign="left"
                                label="Estimated Asset Balance"
                                minimumValue="0"
                                name="stated_assets"
                                currencySymbol="$"
                                onChange={(_event, value) => setFieldValue('stated_assets', value)}
                                outputFormat="string"
                                value={values.stated_assets}
                                inputProps={{ autoComplete: 'off' }}
                                modifyValueOnWheel={false}
                            />
                        </div>
                        <ActionButton
                            variant="outlined"
                            color="primary"
                            type="button"
                            marginTop={32}
                            marginBottom={20}
                            disabled={isSubmitting}
                            onClick={() =>
                                stateNoIncomeAssets({
                                    setErrors,
                                    setSubmitting,
                                })
                            }
                        >
                            I Don’t Have Income or Assets
                        </ActionButton>
                        <ActionButton marginBottom={20} disabled={isSubmitting}>
                            Continue
                        </ActionButton>
                    </Form>
                );
            }}
        </Formik>
    );
};

StatedIncomeAndAssetsForm.propTypes = {
    applicant: PropTypes.object,
    errors: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
};

export default StatedIncomeAndAssetsForm;
