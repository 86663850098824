import { datadogRum } from '@datadog/browser-rum';
import { ENV } from 'config';
import { gitVersion } from 'gitVersion';

let initialized = false;

export const setupDatadog = () => {
    if (!initialized) {
        datadogRum.init({
            applicationId: '92cee038-5be8-4568-8ed1-9879e66fd98b',
            clientToken: 'pubd5a956b4a519965c9f1ee60694a3ac03',
            site: 'datadoghq.com',
            service: 'woodhouse',
            env: ENV,
            version: gitVersion,
            sessionSampleRate: 60,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            allowedTracingUrls: [
                /.*\.nestiolistings\.com/,
                /.*\.nestiostaging\.com/,
                /.*\.nestio\.com/,
                /.*\.funnelleasing\.com/,
            ],
        });

        initialized = true;
    }

    return datadogRum;
};

export const logToDataDog = (error, context) => {
    const datadogRumInstance = setupDatadog();
    datadogRumInstance.addError(error, { context });
};
