import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ActionButton from 'common-components/ActionButton/ActionButton';
import { Spacer, SpacedH1 } from 'assets/styles';

import API from 'api/api';
import { ROUTES } from 'constants/constants';
import { useIdentityVerification } from '../IdentityVerificationContext';
import Page from 'common-components/Page/Page';
import withTransactionPath from 'utils/withTransactionPath';
import { Typography } from '@material-ui/core';

const Divider = styled.hr`
    border-style: none;
    border-bottom: 2px solid #eeeeee;
    margin: 20px -20px 22px;
`;

export function ManualIdentityVerificationPage({ leaseTransaction, history, getTransactionPath }) {
    const { toggleLoader, error, setError, GENERIC_ERROR_MESSAGE, handleCompletedVerification } =
        useIdentityVerification();

    const pageNotification = error && {
        type: 'error',
        messages: error,
    };

    const onManualVerification = useCallback(async () => {
        try {
            toggleLoader(true);
            const verificationResults = await API.postPlaidVerifyResidentId(leaseTransaction?.id, {
                manually_verify_identity: true,
            });

            if (verificationResults?.is_completed) {
                await handleCompletedVerification();
            }
        } catch (e) {
            setError(GENERIC_ERROR_MESSAGE);
        } finally {
            toggleLoader(false);
        }
    }, [leaseTransaction, handleCompletedVerification, setError, GENERIC_ERROR_MESSAGE, toggleLoader]);

    const onCancel = () => {
        const launchPlaidIdentityVerificationPage = getTransactionPath(ROUTES.IDENTITY_VERIFICATION_LAUNCH_PLAID, {
            lease_transaction_id: leaseTransaction.id,
        });
        history.push(launchPlaidIdentityVerificationPage);
    };

    return (
        <Page notification={pageNotification}>
            <SpacedH1 data-testid="manual-identity-verification-header">Manually Verify Identity</SpacedH1>
            <Divider />
            <div className="text-left">
                <Spacer height={20} />
                <Typography variant="body1" data-testid="manual-identity-verification-subtitle">
                    <strong>Are you sure you want to manually verify your identity?</strong>
                </Typography>
                <Spacer height={20} />
                <Typography variant="body1" data-testid="manual-identity-verification-body">
                    Manually verifying your identity means that you’ll need to provide identification documents for
                    review. Once you submit this application, we’ll send an email to{' '}
                    <b>{leaseTransaction?.primary_applicant?.applicant?.email}</b> with further instructions.
                </Typography>
            </div>
            <ActionButton
                data-testid="manual-identity-verification-continue-button"
                onClick={onManualVerification}
                marginBottom={20}
                marginTop={100}
            >
                Continue and Submit
            </ActionButton>
            <ActionButton
                data-testid="manual-identity-verification-cancel-button"
                onClick={onCancel}
                variant="outlined"
                marginBottom={20}
            >
                Cancel
            </ActionButton>
        </Page>
    );
}

ManualIdentityVerificationPage.propTypes = {
    leaseTransaction: PropTypes.object,
    history: PropTypes.func,
    getTransactionPath: PropTypes.func,
};

export default withTransactionPath(ManualIdentityVerificationPage);
