import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import clsx from 'clsx';
import { makeStyles, Stepper, Step } from '@material-ui/core';
import {
    ROUTES,
    MILESTONE_FINANCIAL_STREAM_MISSING_DOCUMENTS_REQUESTED,
    MILESTONE_FINANCIAL_STREAM_ADDITIONAL_DOCUMENTS_REQUESTED,
    MILESTONE_FINANCIAL_STREAM_INCOMPLETE,
    MILESTONE_PROOF_OF_STATED_INCOME_REQUESTED,
    APPLICATION_STATUSES,
} from 'constants/constants';
import { MOCKY } from 'config';

import { getOriginalQuoteExpired } from 'utils/leaseTransaction';
import { leaseTransactionPath } from 'reducers/renter-profile';

import {
    CustomStepLabel,
    NavBlockedCompletedStep,
    NavBlockedInProgressStep,
    NavBlockedLeaseDocumentCompletedStep,
} from 'common-components/NavDrawer/NavBlockedStep';
import { LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION } from 'constants/leaseTransaction';

const useStyles = makeStyles(() => ({
    root: {
        padding: 0,
    },
}));

const active = css`
    .MuiStepLabel-active {
        font-weight: bold !important;
    }
`;

const accessible = css`
    cursor: pointer !important;
    .Mui-disabled {
        cursor: pointer !important;
    }
`;

export function getStepperIndex(routes, currentRoute, leaseTransaction) {
    if (!leaseTransaction) return -1;
    if (!routes || routes.length === 0) return -1;

    for (let i = 0; i < routes.length; i++) {
        const route = routes[i];
        if (leaseTransactionPath(route.value, { lease_transaction_id: leaseTransaction.id }) === currentRoute) {
            return i;
        }
    }
    return -1;
}

export function NavStepper({
    history = {},
    applicant = {},
    leaseTransaction = {},
    navRoutes = [],
    currentRoute = null,
    initialPage = null,
    applicantStillFinishingApplication = false,
    guarantorRequested = false,
    getTransactionPath = (f) => f,
    handleDrawerClose = (f) => f,
}) {
    const classes = useStyles();

    const activeStep = getStepperIndex(navRoutes, currentRoute, leaseTransaction);
    const firstUncompletedStep = getStepperIndex(navRoutes, initialPage, leaseTransaction);
    const unitUnavailable = leaseTransaction?.unit_available === false;
    const currentStatus = leaseTransaction?.status;
    const outstandingBalance = initialPage === ROUTES.OUTSTANDING_BALANCE;
    const financialDocumentsRequested = leaseTransaction?.events?.find(
        ({ event, applicant: applicantId }) =>
            applicantId === applicant?.id &&
            [
                MILESTONE_FINANCIAL_STREAM_MISSING_DOCUMENTS_REQUESTED,
                MILESTONE_FINANCIAL_STREAM_ADDITIONAL_DOCUMENTS_REQUESTED,
                MILESTONE_FINANCIAL_STREAM_INCOMPLETE,
                MILESTONE_PROOF_OF_STATED_INCOME_REQUESTED,
            ].includes(event)
    );

    const originalQuoteExpired = getOriginalQuoteExpired({ leaseTransaction, applicant });

    function onClickRoute(e, route, i) {
        e.stopPropagation();
        if (i <= firstUncompletedStep || MOCKY) {
            history.push(getTransactionPath(route.value));
        }
    }

    const isNavigationBlocked =
        applicantStillFinishingApplication === false ||
        currentStatus === APPLICATION_STATUSES.APPLICATION_STATUS_CANCELLED ||
        guarantorRequested ||
        unitUnavailable ||
        outstandingBalance ||
        financialDocumentsRequested ||
        originalQuoteExpired;

    function renderBlockedStep() {
        if (currentStatus === APPLICATION_STATUSES.APPLICATION_STATUS_CANCELLED) {
            return (
                <NavBlockedInProgressStep
                    text={`Your application has been cancelled.`}
                    callUsText={'if you have any questions or if you’d like to start a new application.'}
                />
            );
        }

        if (unitUnavailable && !guarantorRequested) {
            return (
                <NavBlockedInProgressStep
                    text={`We've placed your application on hold for now, since the apartment you were interested in is no longer available.`}
                    callUsText={'so we can discuss some other options.'}
                />
            );
        }

        if (originalQuoteExpired) {
            return (
                <NavBlockedInProgressStep
                    text={`Your quote has expired and pricing may have updated.`}
                    handleDrawerClose={handleDrawerClose}
                />
            );
        }

        if (guarantorRequested) {
            return (
                <NavBlockedInProgressStep
                    text={`We’re waiting for you to add a guarantor.`}
                    callUsText={'if you have any questions or if you are unable or unwilling to add a guarantor.'}
                />
            );
        }

        if (financialDocumentsRequested) {
            return <NavBlockedInProgressStep text={`We’re requesting additional info to verify your income/assets.`} />;
        }

        if (outstandingBalance) {
            return (
                <NavBlockedInProgressStep
                    text={`You'll be able to move forward with your application once all outstanding balances have been paid.`}
                    handleDrawerClose={handleDrawerClose}
                />
            );
        }

        if (applicantStillFinishingApplication === false) {
            if (leaseTransaction?.type === LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION) {
                return <NavBlockedLeaseDocumentCompletedStep text={''} handleDrawerClose={handleDrawerClose} />;
            }
            return (
                <NavBlockedCompletedStep
                    text={'Your application has been completed and submitted.'}
                    handleDrawerClose={handleDrawerClose}
                />
            );
        }
    }

    return (
        <Stepper className={classes.root} activeStep={activeStep} orientation="vertical">
            {isNavigationBlocked
                ? renderBlockedStep()
                : navRoutes.map((route, i) => (
                      <Step
                          classes={{
                              root: clsx({
                                  [active]: activeStep === i,
                                  [accessible]: i <= firstUncompletedStep,
                              }),
                          }}
                          key={route.name}
                          onClick={(e) => onClickRoute(e, route, i)}
                          active={i === activeStep || i === firstUncompletedStep}
                      >
                          <CustomStepLabel icon={' '} completed={i < firstUncompletedStep}>
                              {route.name}
                          </CustomStepLabel>
                      </Step>
                  ))}
        </Stepper>
    );
}

NavStepper.propTypes = {
    history: PropTypes.object,
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    config: PropTypes.object,
    navRoutes: PropTypes.array,
    currentRoute: PropTypes.string,
    initialPage: PropTypes.string,
    applicantStillFinishingApplication: PropTypes.bool,
    guarantorRequested: PropTypes.bool,
    handleDrawerClose: PropTypes.func,
    getTransactionPath: PropTypes.func,
};

export default NavStepper;
