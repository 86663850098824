import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MOCKY } from 'config';
import { ROUTES, ROUTES_TOP_LEVEL } from 'constants/constants';
import { setSentryUser } from 'utils/sentry';

import { fetchApplicant } from 'reducers/applicant';
import { leaseTransactionPath } from 'reducers/renter-profile';
import { fetchTransaction } from 'reducers/transaction';
import { selectors } from 'reducers/renter-profile';
import { currentRouteReceived } from 'reducers/site-config';

// Second param is deprecated in favor of static param 'route'
export default function withRelativeRoutes(WrappedComponent, route) {
    // Make sure route is a top level page route! or else you will break relative routing.
    if (!ROUTES_TOP_LEVEL.includes(route)) {
        throw Error(`${route} is invalid. Route must be a top level route! Did you mean to use captureRoute?`);
    }

    class Component extends React.Component {
        blockRender = true;

        constructor(props) {
            super(props);
            this.captureRoute();
        }

        captureRoute = () => {
            const props = this.props;
            const currentRoute = props.leaseTransaction
                ? leaseTransactionPath(route, { lease_transaction_id: props.leaseTransaction.id })
                : route;

            this.blockRender = !props.initialPage;

            props.currentRouteReceived(currentRoute);
        };

        getNextRoute = async () => {
            if (!MOCKY) {
                this.props.fetchApplicant();
                await this.props.fetchTransaction();
            }
            if (this.props.unitAvailable === false) {
                return this.props.history.push(
                    leaseTransactionPath(ROUTES.UNIT_UNAVAILABLE, {
                        lease_transaction_id: this.props.leaseTransaction.id,
                    })
                );
            } else {
                return this.props.history.push(this.props.nextRoute);
            }
        };

        getPrevRoute = () => {
            this.props.history.push(this.props.prevRoute);
        };

        componentDidUpdate(prevProps) {
            const props = this.props;
            if (!prevProps.initialPage && props.initialPage) {
                this.captureRoute();
            }

            if (prevProps.applicant !== props.applicant && props.applicant.email && props.applicant.id) {
                setSentryUser(props.applicant);
            }
        }

        render() {
            if (this.blockRender) return null;
            return (
                <WrappedComponent {...this.props} getNextRoute={this.getNextRoute} getPrevRoute={this.getPrevRoute} />
            );
        }
    }

    Component.propTypes = {
        history: PropTypes.object,
        nextRoute: PropTypes.string,
        prevRoute: PropTypes.string,
        initialPage: PropTypes.string,
        unitAvailable: PropTypes.bool,
        applicant: PropTypes.object,
        fetchApplicant: PropTypes.func,
        fetchTransaction: PropTypes.func,
        currentRouteReceived: PropTypes.func,
        leaseTransaction: PropTypes.object,
    };

    const mapStateToProps = (state) => ({
        nextRoute: selectors.selectNextRoute(state),
        prevRoute: selectors.selectPrevRoute(state),
        initialPage: selectors.selectInitialPage(state),
        unitAvailable: state.leaseTransaction?.unit_available,
        applicant: state.applicant,
        leaseTransaction: state.transaction,
    });

    return connect(mapStateToProps, { currentRouteReceived, fetchApplicant, fetchTransaction })(Component);
}
