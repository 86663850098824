import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/constants';
import { initLaunchDarkly } from 'utils/launchdarkly';
import { sessionIsValidForCommunityId } from 'utils/misc';
import { getLeaseIdFromUrl } from 'utils/routingHelpers';
import * as routingHelpers from 'utils/routingHelpers';
import { getIsViewingLeaseDocument } from 'utils/lease-document';
import auth from 'utils/auth';
import GTM from 'utils/gtm';

import { selectors } from 'reducers/renter-profile';
import { fetchTransaction } from 'reducers/transaction';
import { fetchConfiguration } from 'reducers/configuration';
import { fetchApplicant } from 'reducers/applicant';
import { fetchUserProfile } from 'reducers/user-profile';
import { actions as mainActions } from 'reducers/store';
import { selectors as configSelectors } from 'reducers/configuration';
import { fetchLease } from 'reducers/lease';

import AppContextProvider from 'app/AppContextProvider';
import NavDrawer from 'common-components/NavDrawer/NavDrawer';
import ResendLinkForm from 'common-components/ResendLinkForm/ResendLinkForm';
import CriticalErrorPage from 'pages/CriticalError';
import WelcomePage from 'pages/Welcome';
import LoginPage from 'pages/Login';
import SignupPage from 'pages/Signup';
import PasswordPages from 'pages/Password';
import UnitUnavailablePage from 'pages/UnitUnavailable';
import PrivacyPolicyPage from 'pages/PrivacyPolicy';
import FAQPage from 'pages/FAQ';
import TermsPage from 'pages/Terms';
import Address from 'pages/Address';
import LeaseTermsPage from 'pages/LeaseTerms';
import AccountPage from 'pages/Account';
import RenterProfilePages from 'pages/RenterProfile';
import BankingPages from 'pages/Banking';
import FeesAndDepositsPages from 'pages/FeesAndDeposits';
import ScreeningPage from 'pages/Screening';
import ScreeningReports from 'pages/ScreeningReports';
import {
    ApplicationApprovedPage,
    ApplicationCancelledPage,
    ApplicationCompletePage,
    ApplicationDeniedPage,
    ApplicationPendingCriminalReviewPage,
} from 'pages/Application';
import LeaseExecutedPage from 'pages/LeaseExecuted';
import LeaseSignedPage from 'pages/LeaseSigned';
import LeaseVoidedPage from 'pages/LeaseVoided';
import FunnelTermsPage from 'pages/FunnelTerms';
import GuarantorRequestedPage from 'pages/GuarantorRequested';
import PaymentDetailsPage from 'pages/PaymentDetails';
import ChargesPage from 'pages/Charges';
import PaymentTermsPage from 'pages/PaymentTerms';
import LeaseTransactionsPage from 'pages/LeaseTransactions';
import IdentityVerificationPages from 'pages/IdentityVerification';
import RenewalOffersPage from 'pages/Renewal/RenewalOffersPage';
import VacateDetailsPage from 'pages/Vacate/VacateDetailsPage';
import VacateNewAddressPage from 'pages/Vacate/VacateNewAddressPage';
import MonthToMonthInitialPage from 'pages/MonthToMonth/MonthToMonthInitialPage';
import MonthToMonthPaymentDetailsPage from 'pages/MonthToMonth/MonthToMonthPaymentDetailsPage';
import MonthToMonthConfirmedPage from 'pages/MonthToMonth/MonthToMonthConfirmedPage';
import LeasesPage from 'pages/Leases/LeasesPage';
import { QuoteExpiredPage } from 'pages/Quotes';
import PaymentMethodsPage from 'pages/Banking/pages/PaymentMethodsPage';
import ApplicationUnavailablePage from 'pages/Application/ApplicationUnavailablePage';
import MilitaryStatusPage from 'pages/MilitaryStatus/MilitaryStatusPage';

// Add 'visibilitychange' to see if user focused away from tab IE: reading a lease
const documentEventsToSubscribe = [
    'visibilitychange',
    'mousemove',
    'touchstart',
    'scroll',
    'touchend',
    'click',
    'touchmove',
    'keypress',
];
const windowEventsToSubscribe = ['load', 'scroll'];

export class Main extends Component {
    state = { error: null };

    async initializeApp(isAuthenticated, configuration) {
        const { history, location } = this.props;
        const { pathname, search } = location;
        const fullPath = pathname + search;

        initLaunchDarkly(configuration?.community?.company);

        // Note, email CTA's will redirect to login?v=hash.
        // Since we support multiple apps we need the applicant to login everytime
        // so that the initial route can be determined correctly.
        if (pathname.includes('login')) {
            return;
        }

        if (!isAuthenticated) {
            if (
                pathname.includes('login') ||
                pathname.includes('signup') ||
                pathname.includes('password') ||
                pathname.includes('terms') ||
                pathname.includes('privacy-policy') ||
                pathname.includes('faq')
            ) {
                return;
            }

            if (configuration?.person?.has_access_to_application_link === false) {
                return window.location.replace(ROUTES.UNAUTHENTICATED_APPLICATION_UNAVAILABLE.slice(1));
            }

            if (fullPath.includes(ROUTES.UNAUTHENTICATED_APPLICATION_UNAVAILABLE)) {
                return history.replace(fullPath);
            }

            if (configuration.unit?.is_unavailable) {
                return history.replace(ROUTES.UNAUTHENTICATED_UNIT_UNAVAILABLE);
            }

            // Save the route so we can redirect after login
            if (
                pathname.startsWith('/transaction') ||
                pathname.startsWith('/lease') ||
                pathname.includes(ROUTES.SCREENING_REPORTS)
            ) {
                history.replace(ROUTES.LOGIN + '?next=' + pathname);
            } else if (configuration?.quote_is_expired) {
                history.replace(ROUTES.QUOTE_EXPIRED);
            } else if (configuration?.quote_is_active) {
                history.replace(ROUTES.LOGIN);
            } else {
                history.replace(ROUTES.WELCOME);
            }
        } else {
            const {
                accessedAppByInvitationOrWebsite,
                fetchUserProfile,
                fetchApplicant,
                fetchTransaction,
                fetchLease,
                canAccessCurrentRoute,
            } = this.props;

            const profile = await fetchUserProfile();

            if (configuration?.person?.has_access_to_application_link === false) {
                return window.location.replace(ROUTES.APPLICATION_UNAVAILABLE.slice(1));
            }

            if (fullPath.includes(ROUTES.TERMS_DID_REGISTER) || fullPath.includes(ROUTES.APPLICATION_UNAVAILABLE)) {
                return history.replace(fullPath);
            }

            const redirectToAccountSetupPage = routingHelpers.getRedirectToAccountSetupPage(profile);
            if (redirectToAccountSetupPage) {
                return history.replace(ROUTES.ACCOUNT);
            }

            const redirectToLeaseTransactionsPage = routingHelpers.getRedirectToLeaseTransactionsPage(
                profile,
                configuration,
                accessedAppByInvitationOrWebsite
            );
            if (redirectToLeaseTransactionsPage) {
                return history.replace(ROUTES.LEASE_TRANSACTIONS);
            }

            const leaseId = getLeaseIdFromUrl();
            if (leaseId) {
                try {
                    await fetchLease(leaseId);
                } catch (error) {
                    return history.replace(ROUTES.LEASES);
                }
                return;
            }

            // Applicants with multi or no transactions would already be captured by redirecting
            // them to the lease transactions page (unless they are trying to access the leases page)
            const [firstAndOnlyTransaction] = profile?.transactions || [];
            const transactionId =
                routingHelpers.getLeaseTransactionIdFromUrl() ||
                configuration?.lease_transaction_id ||
                firstAndOnlyTransaction?.id;

            // Applicant without any lease trx on the leases page
            // (e.g. imported resident invited to do self-serve renewal)
            if (!transactionId) {
                return;
            }

            await fetchApplicant(transactionId);
            const transaction = await fetchTransaction(transactionId);

            if (routingHelpers.getApplicantIsInWrongCommunityEnv(transaction)) {
                return history.replace(ROUTES.LEASE_TRANSACTIONS);
            }
            if (!canAccessCurrentRoute()) {
                return history.replace(this.props.initialPage);
            }
        }
    }

    async handleWormholeAuthentication(communityId, isLoggedIn) {
        const hasValidWormholeCredentials = this.props.whToken && this.props.whUsername;
        if (!hasValidWormholeCredentials) {
            return isLoggedIn;
        }

        if (isLoggedIn) {
            this.props.logout();
            localStorage.clear();
        }

        try {
            const result = await auth.wormholeLogin(this.props.whUsername, this.props.whToken, communityId);
            auth.setSession(result.token, communityId, result.wormholed_user);
        } catch (e) {
            this.props.history.replace({
                pathname: ROUTES.LOGIN,
                state: { errors: 'Oops, the token you used is invalid or expired. Please log back in to continue.' },
            });
            return false;
        }
        // TODO: Redirect to a better page
        this.props.history.replace(ROUTES.LEASE_TRANSACTIONS);
        return true;
    }

    async componentDidMount() {
        const communityId = this.props.communityId;
        const hash = this.props.hash;
        let isLoggedIn = auth.isAuthenticated() && sessionIsValidForCommunityId(communityId);

        let configuration;
        try {
            configuration = await this.props.fetchConfiguration(communityId, hash);
        } catch (error) {
            return this.setState({ hasError: true });
        }

        isLoggedIn = await this.handleWormholeAuthentication(communityId, isLoggedIn);

        await this.initializeApp(isLoggedIn, configuration);
        this.resetTimer();
        this.addIdleEventListeners();

        if (this.props.userProfile) {
            GTM.sendUserAndCompanyData(configuration.community.company, this.props.userProfile);
        }
    }

    addIdleEventListeners = () => {
        // assign document events
        documentEventsToSubscribe.forEach((eventType) => {
            document.addEventListener(eventType, this.resetTimer);
        });

        // assign window events
        windowEventsToSubscribe.forEach((eventType) => {
            window.addEventListener(eventType, this.resetTimer);
        });
    };

    removeIdleEventListeners = () => {
        documentEventsToSubscribe.forEach((eventType) => {
            document.removeEventListener(eventType, this.resetTimer);
        });

        // assign window events
        windowEventsToSubscribe.forEach((eventType) => {
            window.removeEventListener(eventType, this.resetTimer);
        });
    };

    componentWillUnmount() {
        this.removeIdleEventListeners();
    }

    resetTimer = () => {
        const isViewingLeaseDocument = getIsViewingLeaseDocument();
        clearTimeout(this.time);
        const MINUTE = 1000 * 60;
        const MINUTES = (isViewingLeaseDocument ? 120 : 20) * MINUTE;
        this.time = setTimeout(this.logout, MINUTES);
    };

    logout = () => {
        if (this.props.isLoggedIn) {
            this.props.logout();
            localStorage.clear();
            this.props.history.push({
                pathname: ROUTES.LOGIN,
                state: { errors: 'Oops, your session has timed-out. Please log back in to continue.' },
            });
        }
    };

    render() {
        const { theme, isLoggedIn } = this.props;
        const { hasError } = this.state;

        if (hasError) return <CriticalErrorPage />;
        if (!theme) return null;

        return (
            <AppContextProvider theme={theme}>
                <Switch>
                    <Route path={ROUTES.WELCOME} component={WelcomePage} />
                    <Route path={ROUTES.LOGIN} component={LoginPage} />
                    <Route path={ROUTES.SIGNUP} component={SignupPage} />
                    <Route path={ROUTES.PASSWORD} component={PasswordPages} />
                    {!isLoggedIn ? (
                        <>
                            <Route
                                path={ROUTES.UNAUTHENTICATED_APPLICATION_UNAVAILABLE}
                                component={ApplicationUnavailablePage}
                            />
                            <Route path={ROUTES.UNAUTHENTICATED_UNIT_UNAVAILABLE} component={UnitUnavailablePage} />
                            <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />
                            <Route path={ROUTES.FAQ} component={FAQPage} />
                            <Route path={ROUTES.TERMS} component={TermsPage} />
                            <Route path={ROUTES.FUNNEL_TERMS} component={FunnelTermsPage} />
                            <Route path={ROUTES.QUOTE_EXPIRED} component={QuoteExpiredPage} />
                        </>
                    ) : (
                        <NavDrawer>
                            <Route path={ROUTES.SCREENING_REPORTS} component={ScreeningReports} />
                            <Route path={ROUTES.APPLICATION_UNAVAILABLE} component={ApplicationUnavailablePage} />
                            <Route path={ROUTES.ADDRESS} component={Address} />
                            <Route path={ROUTES.MILITARY_STATUS} component={MilitaryStatusPage} />
                            <Route path={ROUTES.LEASE_TERMS} component={LeaseTermsPage} />
                            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                            <Route path={ROUTES.PAYMENT_METHODS} component={PaymentMethodsPage} />
                            <Route path={ROUTES.RENTAL_PROFILE} component={RenterProfilePages} />
                            <Route path={ROUTES.BANKING} component={BankingPages} />
                            <Route
                                path={ROUTES.FEES_AND_DEPOSITS}
                                component={FeesAndDepositsPages.FeesAndDepositsPage}
                            />
                            <Route path={ROUTES.SCREENING} component={ScreeningPage} />
                            <Route path={ROUTES.APP_APPROVED} component={ApplicationApprovedPage} />
                            <Route path={ROUTES.APP_CANCELLED} component={ApplicationCancelledPage} />
                            <Route path={ROUTES.APP_COMPLETE} component={ApplicationCompletePage} />
                            <Route path={ROUTES.APP_DENIED} component={ApplicationDeniedPage} />
                            <Route path={ROUTES.APP_PENDING_CONFIRMATION} component={ApplicationApprovedPage} />
                            <Route
                                path={ROUTES.APP_PENDING_CRIMINAL_REVIEW}
                                component={ApplicationPendingCriminalReviewPage}
                            />
                            <Route path={ROUTES.LEASE_EXECUTED} component={LeaseExecutedPage} />
                            <Route path={ROUTES.LEASE_SIGNED} component={LeaseSignedPage} />
                            <Route path={ROUTES.LEASE_VOIDED} component={LeaseVoidedPage} />
                            <Route path={ROUTES.FUNNEL_TERMS} component={FunnelTermsPage} />
                            <Route path={ROUTES.GUARANTOR_REQUESTED} component={GuarantorRequestedPage} />
                            <Route path={ROUTES.RESEND_INVITE} component={ResendLinkForm} />
                            <Route path={ROUTES.PAYMENT_DETAILS} component={PaymentDetailsPage} />
                            <Route path={ROUTES.CHARGES} component={ChargesPage} />
                            <Route path={ROUTES.PAYMENT_TERMS} component={PaymentTermsPage} />
                            <Route path={ROUTES.IDENTITY_VERIFICATION} component={IdentityVerificationPages} />
                            <Route
                                path={ROUTES.OUTSTANDING_BALANCE}
                                component={FeesAndDepositsPages.OutstandingBalancePage}
                            />
                            <Route
                                path={[ROUTES.LEASE_TRANSACTIONS_WITH_ANCHOR, ROUTES.LEASE_TRANSACTIONS]}
                                component={LeaseTransactionsPage}
                            />
                            <Route path={ROUTES.LEASES} component={LeasesPage} />
                            <Route path={ROUTES.LEASE_RENEWAL_OFFERS} component={RenewalOffersPage} />
                            <Route
                                path={ROUTES.LEASE_RENEWAL_MONTH_TO_MONTH}
                                component={MonthToMonthInitialPage}
                                exact
                            />
                            <Route
                                path={ROUTES.LEASE_RENEWAL_MONTH_TO_MONTH_PAYMENT_DETAILS}
                                component={MonthToMonthPaymentDetailsPage}
                            />
                            <Route
                                path={ROUTES.LEASE_RENEWAL_MONTH_TO_MONTH_CONFIRMED}
                                component={MonthToMonthConfirmedPage}
                            />
                            <Route path={ROUTES.UNIT_UNAVAILABLE} component={UnitUnavailablePage} />
                            <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />
                            <Route path={ROUTES.FAQ} component={FAQPage} />
                            <Route path={ROUTES.TERMS} component={TermsPage} />
                            <Route path={ROUTES.VACATE_DETAILS} component={VacateDetailsPage} />
                            <Route path={ROUTES.VACATE_NEW_ADDRESS} component={VacateNewAddressPage} />
                        </NavDrawer>
                    )}
                </Switch>
            </AppContextProvider>
        );
    }
}

Main.propTypes = {
    userProfile: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    configuration: PropTypes.object,
    communityId: PropTypes.string,
    hash: PropTypes.string,
    whToken: PropTypes.string,
    whUsername: PropTypes.string,
    accessedAppByInvitationOrWebsite: PropTypes.bool,
    initialPage: PropTypes.string,
    canAccessCurrentRoute: PropTypes.func,
    theme: PropTypes.object,
    fetchTransaction: PropTypes.func,
    fetchConfiguration: PropTypes.func,
    fetchApplicant: PropTypes.func,
    fetchUserProfile: PropTypes.func,
    fetchLease: PropTypes.func,
    logout: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
};

const mapStateToProps = (state) => ({
    userProfile: state.userProfile,
    isLoggedIn: sessionIsValidForCommunityId(state.siteConfig.basename),
    configuration: state.configuration,
    communityId: state.siteConfig.basename,
    hash: state.siteConfig.hash,
    whToken: state.siteConfig.whToken,
    whUsername: state.siteConfig.whUsername,
    accessedAppByInvitationOrWebsite: Boolean(state.siteConfig.hash),
    initialPage: selectors.selectInitialPage(state),
    canAccessCurrentRoute: () => selectors.canAccessRoute(state, state.siteConfig.currentRoute),
    theme: configSelectors.selectTheme(state),
});

const mapDispatchToProps = {
    fetchTransaction,
    fetchConfiguration,
    fetchApplicant,
    fetchUserProfile,
    fetchLease,
    logout: mainActions.logout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
