import { useEffect } from 'react';
import API from 'api/api';
import { APPLICANT_EVENTS, ROUTES } from 'constants/constants';
import { DOCUMENT_SIGNED_PAGE } from 'constants/pageComplete';
import { setIsViewingLeaseDocument } from 'utils/lease-document';

const useLeaseSignEvent = ({
    applicantUpdated,
    getTransactionPath,
    history,
    leaseTransaction,
    setLoading,
    signHandler,
}) => {
    const handleSignEvent = async () => {
        setLoading(true);

        await API.postPageComplete(leaseTransaction.id, DOCUMENT_SIGNED_PAGE);
        const newApplicant = await API.fetchApplicant(leaseTransaction.id);
        const leaseSignedMilestone = newApplicant.events.find(
            (e) => parseInt(e.event) === parseInt(APPLICANT_EVENTS.MILESTONE_APPLICANT_SIGNED_LEASE)
        );
        if (!leaseSignedMilestone) {
            newApplicant.events.push({
                event: APPLICANT_EVENTS.MILESTONE_APPLICANT_SIGNED_LEASE,
                milestone: true,
            });
        }

        applicantUpdated(newApplicant);
        setTimeout(() => {
            setIsViewingLeaseDocument(false);
            history.push(getTransactionPath(ROUTES.LEASE_SIGNED), 2500);
        });
    };

    const handleCloseEvent = () => {
        setIsViewingLeaseDocument(false);
    };

    useEffect(() => {
        signHandler(); // Initialize the signing process (e.g., opening client, loading scripts)
        setIsViewingLeaseDocument(true);
        setLoading(false);
    }, [signHandler, setLoading]);

    return {
        handleSignEvent,
        handleCloseEvent,
    };
};

export default useLeaseSignEvent;
