import moment from 'moment';

export const isBetweenNumbers = (count, min, max) => {
    const absoluteCount = Math.abs(count);
    return absoluteCount >= min && absoluteCount <= max;
};

export const getSpecialCaseMessage = (count, specialCases) => {
    //Sort the special cases with the highest max days range
    const sortedCases = [...specialCases].sort(
        (a, b) => b.number_of_days_max - b.number_of_days_min - (a.number_of_days_max - a.number_of_days_min)
    );

    const message = sortedCases
        .filter((specialCase) =>
            isBetweenNumbers(count, specialCase.number_of_days_min, specialCase.number_of_days_max)
        )
        .map((specialCase) => specialCase.message)[0];

    return message || null;
};

export const getNtvWarningMessage = (configuration, leaseEndDate, moveOutDate, isMonthToMonth) => {
    const enableSelfServiceNTValerts = configuration?.enable_self_serve_notice_to_vacate_move_out_date_alerts;
    const selfServiceNtvAlerts = configuration?.self_serve_notice_to_vacate_move_out_date_alerts;
    const {
        after_end_message: afterEndGenericMessage = '',
        after_end_special_cases: afterEndSpecialCases = [],
        before_end_message: beforeEndGenericMessage = '',
        before_end_special_cases: beforeEndSpecialCases = [],
    } = selfServiceNtvAlerts || {};

    if (!enableSelfServiceNTValerts) return null;

    const formattedMoveOutDate = moment(moveOutDate);
    const formattedLeaseEndDate = moment(leaseEndDate);
    const dayCountDifference = formattedMoveOutDate.diff(formattedLeaseEndDate, 'days');

    if (formattedMoveOutDate.isSame(formattedLeaseEndDate, 'day')) return null;

    if (formattedMoveOutDate.isBefore(formattedLeaseEndDate, 'day')) {
        if (isMonthToMonth) {
            return beforeEndGenericMessage;
        }

        return getSpecialCaseMessage(dayCountDifference, beforeEndSpecialCases) || beforeEndGenericMessage;
    }

    if (formattedMoveOutDate.isAfter(formattedLeaseEndDate, 'day')) {
        if (isMonthToMonth) {
            return afterEndGenericMessage;
        }

        return getSpecialCaseMessage(dayCountDifference, afterEndSpecialCases) || afterEndGenericMessage;
    }
};
