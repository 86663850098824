import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CardHeader, Collapse, IconButton, Typography, Divider } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { leaseTransactionType } from 'types';
import { LEASE_TRANSACTION_TYPES } from 'constants/leaseTransaction';

import { getDateLabel } from '../utils/helpers';
import LeaseTransactionAccordionContent from './LeaseTransactionAccordionContent';

export function LeaseTransactionAccordion({
    leaseTransaction,
    defaultsToExpanded,
    isLastAccordion,
    classes,
    invitee,
    setError,
}) {
    const [expanded, setExpanded] = useState(defaultsToExpanded);

    const transactionTypeTitle = leaseTransaction?.is_month_to_month_update
        ? 'Month-to-Month'
        : LEASE_TRANSACTION_TYPES[leaseTransaction.type];

    return (
        <>
            <CardHeader
                className={classes.subHeaderContainer}
                data-testid="subheader"
                title={
                    <Typography className={`${classes.typography} ${classes.body}`} variant="body">
                        {transactionTypeTitle} {getDateLabel(leaseTransaction.created_at)}
                    </Typography>
                }
                action={
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={() => setExpanded(!expanded)}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            />
            <Divider />
            <Collapse in={expanded}>
                <LeaseTransactionAccordionContent
                    leaseTransaction={leaseTransaction}
                    invitee={invitee}
                    classes={classes}
                    setError={setError}
                />
                {!isLastAccordion && <Divider />}
            </Collapse>
        </>
    );
}

LeaseTransactionAccordion.propTypes = {
    classes: PropTypes.object,
    invitee: PropTypes.object,
    leaseTransaction: leaseTransactionType,
    setError: PropTypes.func.isRequired,
    leaseTransactionToInviteeMap: PropTypes.object,
    isLastAccordion: PropTypes.bool,
    defaultsToExpanded: PropTypes.bool,
};

export default LeaseTransactionAccordion;
