import React from 'react';

import { ROUTES } from 'constants/constants';
import captureRoute from 'utils/captureRoute';

import PaymentTerms from 'common-components/PaymentTerms/PaymentTerms';

export const PaymentTermsPage = ({ ...props }) => {
    return <PaymentTerms {...props} />;
};

export default captureRoute(PaymentTermsPage, ROUTES.PAYMENT_TERMS);
