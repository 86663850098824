import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Formik, Form, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { InputLabel, ListItemText, MenuItem, Select, FormControl, FormHelperText, Typography } from '@material-ui/core';
import { injectStripe } from 'react-stripe-elements';
import { actions as loaderActions } from 'reducers/loader';
import { fetchApplicant } from 'reducers/applicant';
import { ACCOUNT_HOLDER_TYPE_INDIVIDUAL, ROUTES, PAYMENT_CONSTANTS } from 'constants/constants';
import {
    LinkButton,
    blackLinkRoot,
    arrowIcon,
    SpacedH1,
    SpacedH6,
    CustomTypographyBody2,
    Heading3,
} from 'assets/styles';
import safeImage from 'assets/new-icons/savings-bank-cash.svg';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { bankAccountFieldsValidation } from 'utils/validation';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import BankAccountRoutingDetails from 'pages/FeesAndDeposits/components/BankAccountRoutingDetails';
import API from 'api/api';

const bodyRow = css`
    margin-top: 20px;
`;

const Header = styled.div`
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
`;

const body = css`
    padding: 0 20px 0 20px;
    text-align: left;
`;

const line = css`
    border-top: 1px solid #eeeeee;
`;

const viewPolicyLink = css`
    color: #2b44ff;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
`;

const errorMessage = css`
    padding: 0;
    margin-bottom: 24px;
`;

// Keeping this in place, just to make our lives easier when testing.
// @see https://stripe.com/docs/payments/ach-debit/accept-a-payment#test-account-numbers.
// const initialValues = {
//     account_holder_name: 'Testing from Stripe RN',
//     account_nickname: 'Bank from RN',
//     account_number: '000123456789',
//     confirm_account_number: '000123456789',
//     account_type: 'checking',
//     routing_number: '110000000',
// };

export const AddBankAccount = ({
    handleClickBack,
    communityName,
    onBankAccountAdded,
    leaseTransaction,
    userProfile,
    toggleLoader,
    fetchApplicant,
    stripe,
}) => {
    const [currentPage, setCurrentPage] = useState('add_bank_account');
    const [error, setError] = useState(null);
    const [initialValues, setInitialValues] = useState({
        account_holder_name: '',
        account_nickname: '',
        account_number: '',
        account_type: '',
        confirm_account_number: '',
        routing_number: '',
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setError(null);
            toggleLoader(true);
            const { client_secret, ip_address, user_agent } = await API.Payments.setupIntent(leaseTransaction.id);
            const { setupIntent, error: stripeError } = await stripe.confirmSetupIntent(client_secret, {
                payment_method_data: {
                    type: 'us_bank_account',
                    us_bank_account: {
                        account_number: values.account_number,
                        routing_number: values.routing_number,
                        account_type: values.account_type.toLowerCase(),
                        account_holder_type: ACCOUNT_HOLDER_TYPE_INDIVIDUAL,
                    },
                    billing_details: {
                        name: values.account_holder_name,
                        email: userProfile.email,
                        phone: userProfile.phone_number,
                    },
                    metadata: {
                        nickname: values.account_nickname,
                        lease_transaction_id: leaseTransaction.id,
                        source: PAYMENT_CONSTANTS.PAYMENT_SOURCE_ONLINE_LEASING,
                    },
                },
                mandate_data: {
                    customer_acceptance: {
                        type: 'online',
                        online: {
                            ip_address,
                            user_agent,
                        },
                    },
                },
            });
            if (stripeError) throw new Error(stripeError.message);
            await API.Payments.confirmIntent(leaseTransaction.id, { client_secret: setupIntent.client_secret });
            await fetchApplicant();
            onBankAccountAdded();
        } catch (error) {
            console.error(error);
            setError(
                'The bank account cannot be saved. Please update bank information or go back and select a new payment method.'
            );
        } finally {
            setSubmitting(false);
            toggleLoader(false);
        }
    };
    const onChangeSelect = (e, handleChange, values) => {
        handleChange(e);
        values[e.target.name] = e.target.value;
        setInitialValues({ ...values });
    };

    if (currentPage === 'add_bank_account') {
        return (
            <>
                <SpacedH1>Add Bank Account</SpacedH1>
                <SpacedH6>
                    Add your bank account details to start the verification process, which can take up to 1-3 business
                    days.
                </SpacedH6>
                {error && <GenericFormMessage type="error" messages={error} className={errorMessage} />}
                <img src={safeImage} alt="safe img" />
                <div className={bodyRow}>
                    <Typography variant="body2">
                        Want to learn more about{' '}
                        <Typography
                            variant="body2"
                            variantMapping={{ body2: 'span' }}
                            id="view_policy_link"
                            role="button"
                            onClick={() => setCurrentPage('view_policy')}
                            className={viewPolicyLink}
                        >
                            bank account payments
                        </Typography>
                        ?
                    </Typography>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={bankAccountFieldsValidation}
                    validateOnBlur={false}
                    validateOnChange={true}
                    isInitialValid={bankAccountFieldsValidation.isValidSync(initialValues)}
                >
                    {({ isValid, isSubmitting, errors, touched, values, handleChange }) => {
                        return (
                            <Form autoComplete="off">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            fullWidth
                                            margin="normal"
                                            label="Account Holder Name"
                                            name="account_holder_name"
                                            component={TextField}
                                            autoComplete="none"
                                            disabled={isSubmitting}
                                            onChange={(e) => onChangeSelect(e, handleChange, values)}
                                        />
                                    </Grid>
                                    <br />
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="standard">
                                            {touched.account_type && errors.account_type ? (
                                                <InputLabel
                                                    style={{ color: '#f44336' }}
                                                    id="account_type_selector_label"
                                                >
                                                    Account Type
                                                </InputLabel>
                                            ) : (
                                                <InputLabel id="account_type_selector_label">Account Type</InputLabel>
                                            )}
                                            <Select
                                                name="account_type"
                                                id="account_type"
                                                labelId="account_type_selector_label"
                                                fullWidth
                                                value={values?.account_type}
                                                disabled={isSubmitting}
                                                onChange={(e) => onChangeSelect(e, handleChange, values)}
                                                error={Boolean(touched.account_type && errors.account_type)}
                                            >
                                                <MenuItem id="checking_menuitem" value={'Checking'}>
                                                    <ListItemText style={{ textAlign: 'left' }}>Checking</ListItemText>
                                                </MenuItem>
                                                <MenuItem id="savings_menuitem" value={'Savings'}>
                                                    <ListItemText style={{ textAlign: 'left' }}>Savings</ListItemText>
                                                </MenuItem>
                                            </Select>
                                            {touched.account_type && errors.account_type ? (
                                                <FormHelperText style={{ color: '#f44336' }}>
                                                    {touched.account_type && errors.account_type}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            fullWidth
                                            margin="normal"
                                            label="Routing Number"
                                            name="routing_number"
                                            component={TextField}
                                            autoComplete="none"
                                            disabled={isSubmitting}
                                            inputProps={{ maxLength: 9 }}
                                            onChange={(e) => onChangeSelect(e, handleChange, values)}
                                        />
                                        <Typography
                                            align="left"
                                            variant="body2"
                                            id="routing_details"
                                            role="button"
                                            className={viewPolicyLink}
                                            onClick={() => setCurrentPage('info_helper')}
                                        >
                                            Where is my routing number?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            fullWidth
                                            margin="normal"
                                            label="Bank Account Number"
                                            name="account_number"
                                            component={TextField}
                                            autoComplete="none"
                                            disabled={isSubmitting}
                                            onChange={(e) => onChangeSelect(e, handleChange, values)}
                                        />
                                        <Typography
                                            variant="body2"
                                            align="left"
                                            id="account_details"
                                            role="button"
                                            className={viewPolicyLink}
                                            onClick={() => setCurrentPage('info_helper')}
                                        >
                                            Where is my account number?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            fullWidth
                                            margin="normal"
                                            label="Confirm Bank Account Number"
                                            name="confirm_account_number"
                                            component={TextField}
                                            autoComplete="none"
                                            disabled={isSubmitting}
                                            onChange={(e) => onChangeSelect(e, handleChange, values)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            fullWidth
                                            margin="normal"
                                            label="Account Nickname (Optional)"
                                            id="account_nickname"
                                            name="account_nickname"
                                            component={TextField}
                                            autoComplete="none"
                                            disabled={isSubmitting}
                                            onChange={(e) => onChangeSelect(e, handleChange, values)}
                                            helperText="0-21 max characters"
                                        />
                                    </Grid>
                                </Grid>
                                <CustomTypographyBody2 align="left" margin="37px 0 0 0" color="#000000">
                                    By saving your bank account for {communityName} you agree to authorize payments
                                    pursuant to these{' '}
                                    <Link to={ROUTES.TERMS} target="_blank">
                                        terms of service
                                    </Link>
                                    .
                                </CustomTypographyBody2>
                                <ActionButton marginTop={35} marginBottom={20} disabled={isSubmitting || !isValid}>
                                    Add Bank Account
                                </ActionButton>
                            </Form>
                        );
                    }}
                </Formik>
                <LinkButton className={blackLinkRoot} onClick={handleClickBack}>
                    <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
                </LinkButton>
            </>
        );
    } else if (currentPage === 'view_policy') {
        return (
            <div>
                <Header>
                    <SpacedH1>Bank Account Payments</SpacedH1>
                </Header>
                <div className={line} />
                <Heading3 align="left">
                    We use Stripe, a payment processor, to link your bank account. Bank accounts require verification.
                </Heading3>
                <div className={body}>
                    How verification works:
                    <ol>
                        <li>Securely add your account details</li>
                        <li>Receive a deposit in your bank account</li>
                        <li>Confirm the 6-digit code</li>
                    </ol>
                    <br />
                    The verification process usually takes 1-3 business days.
                    <br />
                    <br />
                    Don’t worry, you’ll be able to remove this account later.
                    <br />
                </div>
                <br />
                <ActionButton onClick={() => setCurrentPage('add_bank_account')}>Got it</ActionButton>
            </div>
        );
    } else if (currentPage === 'info_helper') {
        return <BankAccountRoutingDetails handleContinueClick={() => setCurrentPage('add_bank_account')} />;
    }
};

AddBankAccount.propTypes = {
    handleClickBack: PropTypes.func,
    communityName: PropTypes.string,
    leaseTransaction: PropTypes.object.isRequired,
    userProfile: PropTypes.object.isRequired,
    onBankAccountAdded: PropTypes.func.isRequired,
    fetchApplicant: PropTypes.func.isRequired,
    toggleLoader: PropTypes.func.isRequired,
    stripe: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    userProfile: state.userProfile,
});

const mapDispatchToProps = {
    fetchApplicant,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(AddBankAccount));
