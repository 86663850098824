import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { APPLICANT_EVENTS, MILESTONE_APPLICANT_SUBMITTED, ROUTES } from 'constants/constants';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import API from 'api/api';
import withTransactionPath from 'utils/withTransactionPath';
import { getShowAutomatedAddressForm } from 'selectors/launchDarkly';
import { fetchApplicant } from 'reducers/applicant';
import { fetchTransaction } from 'reducers/transaction';
import { selectors as renterProfileSelectors } from 'reducers/renter-profile';
import hooks from 'pages/Banking/bankingHooks';

import BackLink from 'common-components/BackLink/BackLink';
import EmployerAddressForm from 'pages/Banking/components/EmployerAddressForm';
import { SpacedH1, SpacedH6 } from 'assets/styles';
import portfolio from 'assets/new-icons/briefcase-streamlined.svg';

const ImageContainer = styled.div`
    margin-top: 31px;
    margin-bottom: 31px;
    img {
        max-height: 105px;
        max-width: 114px;
    }
`;

export function EmployerDetailsPage({
    applicant,
    bankingData,
    showAutomatedAddress,
    configuration,
    leaseTransaction,
    getTransactionPath,
    prevPage,
    fetchApplicant,
    fetchTransaction,
}) {
    const context = hooks.useBankingContext();
    const [errors, setErrors] = useState(null);

    const applicantEnteredIncomeOrAssets =
        bankingData?.income_sources?.length ||
        bankingData?.asset_sources?.length ||
        bankingData?.reported_no_income_assets;

    let prevStep = prevPage;
    if (applicant.enabled_for_stated_income_assets && !applicantEnteredIncomeOrAssets) {
        prevStep = getTransactionPath(ROUTES.STATED_INCOME_AND_ASSETS);
    } else if (configuration.enable_automatic_income_verification) {
        prevStep = getTransactionPath(ROUTES.INCOME_VERIFICATION_SUMMARY);
    }

    const updatesWereRequested = !!applicant.events?.find(
        ({ event, lease_transaction }) =>
            event === APPLICANT_EVENTS.EVENT_FINANCIAL_STREAM_ADDITIONAL_DOCUMENTS_REQUESTED_EMAIL_SENT &&
            lease_transaction === applicant.lease_transaction_id
    );

    const handleSubmit = async (values, { setErrors: setFormErrors, setSubmitting }) => {
        setErrors(null);

        const employerInfo = { ...values };
        delete employerInfo.search;

        context.toggleLoader(true);

        try {
            const response = await API.postEmployer(leaseTransaction.id, employerInfo);
            if (response.errors) {
                return setFormErrors(response.errors);
            }

            await fetchTransaction();
            const updatedApplicant = await fetchApplicant();

            const applicantSubmittedApplication = !!updatedApplicant.events?.find(
                ({ event, lease_transaction }) =>
                    event === MILESTONE_APPLICANT_SUBMITTED && lease_transaction === applicant.lease_transaction_id
            );

            let nextRoute = getTransactionPath(ROUTES.SCREENING);
            if (updatesWereRequested || applicantSubmittedApplication) {
                nextRoute = ROUTES.APP_COMPLETE;
            }

            context.history.push(nextRoute);
        } catch (e) {
            setErrors([GENERIC_ERROR_MESSAGE]);
        } finally {
            context.toggleLoader(false);
            setSubmitting(false);
        }
    };

    if (!applicant) {
        return null;
    }

    return (
        <>
            <SpacedH1>Employer Details</SpacedH1>
            <SpacedH6>Tell us about your employer.</SpacedH6>
            <ImageContainer>
                <img src={portfolio} alt="Employer" width={100} height={100} />
            </ImageContainer>
            <EmployerAddressForm
                applicant={applicant}
                errors={errors}
                onSubmit={handleSubmit}
                showAutomatedAddress={showAutomatedAddress}
            />
            <BackLink to={prevStep} />
        </>
    );
}

EmployerDetailsPage.propTypes = {
    applicant: PropTypes.object,
    bankingData: PropTypes.object,
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,
    prevPage: PropTypes.string,
    showAutomatedAddress: PropTypes.bool,
    fetchApplicant: PropTypes.func,
    fetchTransaction: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    bankingData: state.banking,
    showAutomatedAddress: getShowAutomatedAddressForm(state),
    configuration: state.configuration,
    leaseTransaction: state.transaction,
    prevPage: renterProfileSelectors.selectPrevRoute(state),
});

const mapDispatchToProps = {
    fetchApplicant,
    fetchTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(EmployerDetailsPage));
