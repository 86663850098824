import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';

import API from 'api/api';
import { ROUTES } from 'constants/constants';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import { LEASE_TRANSACTION_TYPE_VACATE } from 'constants/leaseTransaction';
import { reloadWindowForLeaseTransaction } from 'utils/routingHelpers';

import { actions as loaderActions } from 'reducers/loader';
import { transactionReceived } from 'reducers/transaction';
import { setVacateFormData } from 'reducers/forms/vacate-form';
import { fetchApplicant } from 'reducers/applicant';
import { getShowAutomatedAddressForm } from 'selectors/launchDarkly';

import Page from 'common-components/Page/Page';
import VacateAddressForm from './components/VacateAddressForm';

import { arrowIcon, blackLinkRoot, LinkButton } from 'assets/styles';
import vacateAddress from 'assets/new-icons/send-email-fly-steamline.svg';

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center',
    },
    addressImage: {
        margin: '10px 0 5px 0',
        height: '132px',
        width: '139px',
    },
    p: {
        color: '#000',
        fontSize: '12px',
    },
}));

export function VacateNewAddressPage({
    lease,
    history,
    showAutomatedAddress,
    toggleLoader,
    vacateFormData,
    setVacateFormData,
    fetchApplicant,
    transactionReceived,
}) {
    const classes = useStyles();
    const [errors, setErrors] = useState(null);

    const handleSubmit = async (values, { setErrors: setFormErrors, setSubmitting }) => {
        setVacateFormData(values);
        toggleLoader(true);
        setErrors(null);

        try {
            const response = await API.postLeaseTransaction({
                lease: lease.id,
                type: LEASE_TRANSACTION_TYPE_VACATE,
                ...vacateFormData,
                ...values,
            });
            if (response.errors) {
                setFormErrors(response.errors);
            } else {
                transactionReceived(response);
                fetchApplicant(response.id);
                reloadWindowForLeaseTransaction(response);
            }
        } catch (e) {
            setErrors([GENERIC_ERROR_MESSAGE]);
        } finally {
            toggleLoader(false);
            setSubmitting(false);
        }
    };

    if (!lease) {
        return null;
    }

    if (!vacateFormData.move_out_date) {
        history.replace(generatePath(ROUTES.VACATE_DETAILS, { lease_id: lease?.id }));
    }

    return (
        <Page
            className={classes.root}
            title="New Address"
            subTitle="We’ll use this address for all future correspondence such as move out statements, invoices, and security deposit returns."
            image={{
                src: vacateAddress,
                className: classes.addressImage,
            }}
        >
            <Box margin="28px 0 21px 0">
                <Typography component="p" variant="caption" className={classes.p}>
                    You can skip this step if you don’t have a new address yet.
                </Typography>
                <VacateAddressForm
                    initialValues={vacateFormData}
                    errors={errors}
                    onSubmit={handleSubmit}
                    showAutomatedAddress={showAutomatedAddress}
                />
            </Box>
            <LinkButton
                className={blackLinkRoot}
                onClick={() => history.replace(generatePath(ROUTES.VACATE_DETAILS, { lease_id: lease?.id }))}
            >
                <ArrowBackIosIcon classes={{ root: arrowIcon }} /> Go Back
            </LinkButton>
        </Page>
    );
}

VacateNewAddressPage.propTypes = {
    lease: PropTypes.object,
    history: PropTypes.object.isRequired,
    showAutomatedAddress: PropTypes.bool,
    toggleLoader: PropTypes.func,
    vacateFormData: PropTypes.object,
    setVacateFormData: PropTypes.func.isRequired,
    transactionReceived: PropTypes.func.isRequired,
    fetchApplicant: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    lease: state.lease,
    vacateFormData: state.vacateForm,
    showAutomatedAddress: getShowAutomatedAddressForm(state),
});

const mapActionsToProps = {
    toggleLoader: loaderActions.toggleLoader,
    setVacateFormData,
    transactionReceived,
    fetchApplicant,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(VacateNewAddressPage));
