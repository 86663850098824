import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { connect } from 'react-redux';
import { Error as ErrorIcon, Info as InfoIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { APPLICANT_ROLE_LABELS, APPLICANT_ROLE_VALUES, ROUTES, ACTIVE_APPLICATION_STATUSES } from 'constants/constants';
import { LEASE_TRANSACTION_TYPES, LEASE_TRANSACTION_TYPE_VACATE } from 'constants/leaseTransaction';
import { leaseReceived } from 'reducers/lease';

import ActionButton from 'common-components/ActionButton/ActionButton';
import { blackLinkRoot, LinkButton, infoIconRoot } from 'assets/styles';

export function LeaseAccordionContent({ classes, lease, history, leaseReceived, configuration }) {
    const {
        active,
        role,
        base_rent,
        statusLabel,
        unit: { id: unitId },
        lease_end_date,
        enable_self_service_renewals,
        is_compliant,
        lease_settings,
        lease_updates = {},
    } = lease;

    const { renewal = [], vacate = [], midlease_change = [], transfer = [] } = lease_updates;

    const getIsLeaseTransactionActive = (leaseTransaction) => {
        return ACTIVE_APPLICATION_STATUSES.includes(leaseTransaction?.status);
    };

    const activeLeaseRevision = midlease_change.find(getIsLeaseTransactionActive);
    const activeTransfer = transfer.find(getIsLeaseTransactionActive);
    const activeRenewal = renewal.find(getIsLeaseTransactionActive);
    const activeNTV = vacate.find(getIsLeaseTransactionActive);
    const activeLeaseTransaction = activeLeaseRevision || activeTransfer || activeRenewal || activeNTV;

    const daysBeforeRenewalDecision = configuration.days_before_renewal_decision_is_due;
    const renewalDeadline = moment(lease_end_date).subtract(daysBeforeRenewalDecision, 'days');
    const isPrimary = role === APPLICANT_ROLE_VALUES.ROLE_PRIMARY_APPLICANT;
    const showUpForRenewal = isPrimary && enable_self_service_renewals && is_compliant;

    const showActiveDecision = useMemo(() => {
        if (!active) {
            return false;
        }
        if (!activeLeaseTransaction) {
            return lease.is_month_to_month && moment().isBefore(moment(lease_end_date));
        }
        if (activeLeaseTransaction?.type === LEASE_TRANSACTION_TYPE_VACATE) {
            return true;
        }
        if (activeLeaseTransaction?.has_confirmed_changes || activeLeaseTransaction?.is_guarantor_requested) {
            return true;
        }
        return false;
    }, [active, activeLeaseTransaction, lease.is_month_to_month, lease_end_date]);

    const renewalBanner = useMemo(() => {
        let message = 'Your lease is up for renewal. ';
        if (showActiveDecision) {
            const path = generatePath(ROUTES.LEASE_TRANSACTIONS_WITH_ANCHOR, { unit_id: unitId });
            const icon = <InfoIcon classes={{ root: infoIconRoot }} style={{ color: '#3539C3', width: 20 }} />;
            const leaseTransactionType = LEASE_TRANSACTION_TYPES[activeLeaseTransaction?.type];
            const msg = !activeLeaseTransaction
                ? 'Your lease is going into Month-to-Month'
                : `You have a ${leaseTransactionType} in progress`;

            return (
                <p className={classes.renewalDecision} data-testid="renewal-decision">
                    {icon}
                    <span>
                        {msg}. Go to <Link to={path}>My Activity</Link> for more details.
                    </span>
                </p>
            );
        }
        if (lease.is_month_to_month || !lease_settings.display_renewal_respond_by_date) {
            message = message.concat('Please let us know your decision.');
        } else if (lease_settings.display_renewal_respond_by_date && daysBeforeRenewalDecision) {
            message = message.concat(`Please respond by ${renewalDeadline.format('LL')}.`);
        }
        return (
            <p className={classes.renewalNotice} data-testid="renewal-notice">
                <ErrorIcon />
                {message}
            </p>
        );
    }, [
        showActiveDecision,
        lease.is_month_to_month,
        lease_settings.display_renewal_respond_by_date,
        daysBeforeRenewalDecision,
        classes.renewalNotice,
        classes.renewalDecision,
        unitId,
        activeLeaseTransaction,
        renewalDeadline,
    ]);

    return (
        <div className={classes.accordionContent}>
            <Box paddingBottom="20px">
                {showUpForRenewal && renewalBanner}
                <Typography className={classes.typography} variant="body1">
                    Status: <span>{statusLabel}</span>
                </Typography>
                <Typography className={classes.typography} variant="body1">
                    Monthly Rent: <span>${base_rent}</span>
                </Typography>
                <Typography className={classes.typography} variant="body1">
                    Role: <span>{APPLICANT_ROLE_LABELS[role]}</span>
                </Typography>
            </Box>
            {showUpForRenewal && (
                <ActionButton
                    onClick={() => {
                        leaseReceived(lease);
                        history.push(generatePath(ROUTES.LEASE_RENEWAL_OFFERS, { lease_id: lease.id }));
                    }}
                >
                    Go To Renewal / Move Out Options
                </ActionButton>
            )}
            <LinkButton
                className={`${blackLinkRoot} ${classes.myActivityButton}`}
                onClick={() => history.push(generatePath(ROUTES.LEASE_TRANSACTIONS_WITH_ANCHOR, { unit_id: unitId }))}
            >
                View Activity
            </LinkButton>
        </div>
    );
}

const mapActionsToProps = {
    leaseReceived,
};

const mapStateToProps = (state) => ({
    configuration: state.configuration,
});

LeaseAccordionContent.propTypes = {
    classes: PropTypes.object.isRequired,
    lease: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    leaseReceived: PropTypes.func.isRequired,
    configuration: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(LeaseAccordionContent);
