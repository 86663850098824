import _ from 'lodash';

export const getIsMonthToMonthOffer = ({ minimum_lease_term, maximum_lease_term }) => {
    return minimum_lease_term === 1 && maximum_lease_term === 1;
};

export const currencyToNumber = (value) => parseFloat(value.replace(',', ''));

export const getMonthToMonthRent = (lease) => {
    if (!lease) return null;
    const leaseOffer = lease.lease_offers.find((offer) => getIsMonthToMonthOffer(offer));
    return currencyToNumber(leaseOffer ? leaseOffer.rent : lease.base_rent);
};

export const getMonthToMonthLeaseOffer = (lease) => {
    return lease.lease_offers.find((offer) => getIsMonthToMonthOffer(offer));
};

export const generateRenewalOptions = (leaseOffersData) => {
    const options = [];
    for (const item of leaseOffersData) {
        for (let term = item.minimum_lease_term; term <= item.maximum_lease_term; term++) {
            options.push({ ...item, term, uniqueId: `${item.rent}-${term}` });
        }
    }
    return _.sortBy(options, (offer) => Number(offer.rent));
};

export const getBestOffer = (leaseOffersData) => {
    if (!leaseOffersData?.length) return null;

    let bestOffer = leaseOffersData[0];
    for (const offer of leaseOffersData) {
        if (Number(offer.rent) < bestOffer.rent) {
            bestOffer = offer;
        }
        if (offer.rent === bestOffer.rent && offer.maximum_lease_term > bestOffer.maximum_lease_term) {
            bestOffer = offer;
        }
    }
    return {
        ...bestOffer,
        term: bestOffer.maximum_lease_term,
        uniqueId: `${bestOffer.rent}-${bestOffer.maximum_lease_term}`,
    };
};
