export const community = {
    name: '555 Waverly',
};

export const ROUTES = {
    // Independent routes
    APPLICATION: '/transaction/:lease_transaction_id/',
    WELCOME: '/welcome',
    LOGIN: '/login',
    SIGNUP: '/signup',
    ADDRESS: '/transaction/:lease_transaction_id/address',
    MILITARY_STATUS: '/transaction/:lease_transaction_id/military-status',
    FEES_AND_DEPOSITS: '/transaction/:lease_transaction_id/fees-deposits',
    SCREENING: '/transaction/:lease_transaction_id/screening',
    LEASE_TERMS: '/transaction/:lease_transaction_id/lease-terms',
    OUTSTANDING_BALANCE: '/transaction/:lease_transaction_id/outstanding-balance',
    PAYMENT_TERMS: '/transaction/:lease_transaction_id/payment-terms',
    ACCOUNT: '/account',
    PAYMENT_METHODS: '/payment-methods',
    SCREENING_REPORTS: '/screening-reports',
    RESEND_INVITE: '/transaction/:lease_transaction_id/resend-invite',
    TERMS: '/terms',
    TERMS_DID_REGISTER: '/terms?registered=true',
    PRIVACY_POLICY: '/privacy-policy',
    FUNNEL_TERMS: '/funnel-terms',
    FAQ: '/faq',
    UNIT_UNAVAILABLE: '/transaction/:lease_transaction_id/unit-unavailable',
    UNAUTHENTICATED_UNIT_UNAVAILABLE: '/unauth-unit-unavailable',
    APPLICATION_UNAVAILABLE: '/application-unavailable',
    UNAUTHENTICATED_APPLICATION_UNAVAILABLE: '/unauth-application-unavailable',
    GUARANTOR_REQUESTED: '/transaction/:lease_transaction_id/guarantor_request',
    PAYMENT_DETAILS: '/transaction/:lease_transaction_id/payment-details',
    CHARGES: '/transaction/:lease_transaction_id/charges',
    IDENTITY_VERIFICATION: '/transaction/:lease_transaction_id/id-verify',
    IDENTITY_VERIFICATION_OTP_REQUEST: '/transaction/:lease_transaction_id/id-verify/request',
    IDENTITY_VERIFICATION_OTP_VERIFY: '/transaction/:lease_transaction_id/id-verify/verify',
    IDENTITY_VERIFICATION_QUESTIONS: '/transaction/:lease_transaction_id/id-verify/questions',
    IDENTITY_VERIFICATION_LAUNCH_PLAID: '/transaction/:lease_transaction_id/id-verify/launch-plaid',
    MANUAL_IDENTITY_VERIFICATION: '/transaction/:lease_transaction_id/id-verify/manual-identity-verification',

    // Application routes
    APP_COMPLETE: '/transaction/:lease_transaction_id/application-complete',
    APP_APPROVED: '/transaction/:lease_transaction_id/application-approved',
    APP_CANCELLED: '/transaction/:lease_transaction_id/application-cancelled',
    APP_DENIED: '/transaction/:lease_transaction_id/application-denied',
    APP_PENDING_CRIMINAL_REVIEW: '/transaction/:lease_transaction_id/application-pending-criminal-review',
    APP_PENDING_CONFIRMATION: '/transaction/:lease_transaction_id/pending-confirmation',

    // Lease routes
    LEASE_SIGNED: '/transaction/:lease_transaction_id/lease-signed',
    LEASE_EXECUTED: '/transaction/:lease_transaction_id/lease-executed',
    LEASE_VOIDED: '/transaction/:lease_transaction_id/lease-voided',

    // Password related routes
    PASSWORD: '/password',
    FORGOT_PASSWORD: '/password/forgot',
    VERIFY_PASSWORD_CODE: '/password/verify',
    RESET_PASSWORD: '/password/reset',

    // Rental Profile related routes
    RENTAL_PROFILE: '/transaction/:lease_transaction_id/rental-profile',
    PROFILE_OPTIONS: '/transaction/:lease_transaction_id/rental-profile/options',
    GUARANTOR: '/transaction/:lease_transaction_id/rental-profile/invite-guarantor',
    PARKING: '/transaction/:lease_transaction_id/rental-profile/parking',
    PETS: '/transaction/:lease_transaction_id/rental-profile/pets',
    STORAGE: '/transaction/:lease_transaction_id/rental-profile/storage',
    WINE_COOLER: '/transaction/:lease_transaction_id/rental-profile/wine-cooler',
    CO_APPLICANTS: '/transaction/:lease_transaction_id/rental-profile/invite-roommates',
    EDIT_DEPENDANT: '/transaction/:lease_transaction_id/rental-profile/dependent/:id/edit',
    REMOVE_PERSON: '/transaction/:lease_transaction_id/rental-profile/:type/:id/remove',

    // Income and Employment related routes
    BANKING: '/transaction/:lease_transaction_id/income-employment',
    INCOME_VERIFICATION_CONNECT: '/transaction/:lease_transaction_id/income-employment/connect',
    INCOME_VERIFICATION_SUMMARY: '/transaction/:lease_transaction_id/income-employment/summary',
    MANUAL_INCOME_ENTRY_ADD_INCOME: '/transaction/:lease_transaction_id/income-employment/manual/add-income',
    PLAID_INCOME_ENTRY_ADD_INCOME: '/transaction/:lease_transaction_id/income-employment/plaid/add-income',
    MANUAL_ASSET_ENTRY_ADD_ASSET: '/transaction/:lease_transaction_id/income-employment/manual/add-asset',
    EDIT_MANUAL_FINANCIAL_SOURCE:
        '/transaction/:lease_transaction_id/income-employment/manual/financial-sources/:id/edit',
    REMOVE_FINANCIAL_SOURCE: '/transaction/:lease_transaction_id/income-employment/manual/financial-sources/:id/remove',
    STATED_INCOME_AND_ASSETS: '/transaction/:lease_transaction_id/income-employment/stated-income-assets',
    EMPLOYER_DETAILS: '/transaction/:lease_transaction_id/income-employment/employer-details',

    // Renewals
    LEASE_RENEWAL_OFFERS: '/lease/:lease_id/renewal/lease-offers',
    LEASE_RENEWAL_MONTH_TO_MONTH: '/lease/:lease_id/renewal/month-to-month',
    LEASE_RENEWAL_MONTH_TO_MONTH_PAYMENT_DETAILS: '/lease/:lease_id/renewal/month-to-month/payment-details',
    LEASE_RENEWAL_MONTH_TO_MONTH_CONFIRMED: '/lease/:lease_id/renewal/month-to-month/confirmed',

    // Vacate
    VACATE_DETAILS: '/lease/:lease_id/vacate/details',
    VACATE_NEW_ADDRESS: '/lease/:lease_id/vacate/new-address',

    // Activity
    LEASE_TRANSACTIONS: '/my-activity',
    LEASE_TRANSACTIONS_WITH_ANCHOR: '/my-activity/unit/:unit_id?',
    LEASES: '/my-leases',

    // Quote routes
    QUOTE_EXPIRED: '/quote-expired',
};

export const ROUTE_LABELS = {
    [ROUTES.ADDRESS]: 'Current Address',
    [ROUTES.MILITARY_STATUS]: 'Military Status',
    [ROUTES.LEASE_TERMS]: 'Lease Terms',
    [ROUTES.PROFILE_OPTIONS]: 'Rental Profile',
    [ROUTES.BANKING]: 'Income & Employment',
    [ROUTES.INCOME_VERIFICATION_CONNECT]: 'Income & Employment',
    [ROUTES.INCOME_VERIFICATION_SUMMARY]: 'Income & Employment',
    [ROUTES.STATED_INCOME_AND_ASSETS]: 'Income & Employment',
    [ROUTES.EMPLOYER_DETAILS]: 'Income & Employment',
    [ROUTES.FEES_AND_DEPOSITS]: 'Fees & Deposits',
    [ROUTES.SCREENING]: 'Screening',
    [ROUTES.IDENTITY_VERIFICATION]: 'Identity Verification',
    [ROUTES.APP_COMPLETE]: 'Application Complete',
    [ROUTES.PAYMENT_DETAILS]: 'Payment Details',
};

export const ROUTES_TOP_LEVEL = [
    ROUTES.ADDRESS,
    ROUTES.MILITARY_STATUS,
    ROUTES.LEASE_TERMS,
    ROUTES.PROFILE_OPTIONS,
    ROUTES.BANKING,
    ROUTES.FEES_AND_DEPOSITS,
    ROUTES.SCREENING,
    ROUTES.IDENTITY_VERIFICATION,
    ROUTES.APP_COMPLETE,
    ROUTES.OUTSTANDING_BALANCE,
    ROUTES.PAYMENT_DETAILS,
    ROUTES.CHARGES,
];

export const DOES_NOT_EXIST = 'DoesNotExist';

export const REPORT_POLL_INTERVAL = 10000;

export const ROLE_PRIMARY_APPLICANT = 'primary_applicant';
export const ROLE_CO_APPLICANT = 'co_applicant';
export const ROLE_GUARANTOR = 'guarantor';
export const ROLE_OCCUPANT = 'occupant';
export const ROLE_DEPENDENT = 'dependent';

export const PLAID_INCOME_BANK = 'bank';
export const PLAID_INCOME_PAYROLL = 'payroll';
export const PLAID_INCOME_PAYROLL_PAYSTUB = 'payroll_paystub';
export const PLAID_INCOME_PAYROLL_TAXFORM = 'payroll_taxform';
export const PLAID_INCOME_PAYROLL_MANUAL = 'payroll_manual_upload';
export const PLAID_INCOME_ASSET = 'asset';
export const PLAID_IDENTITY_VERIFICATION = 'identity_verification';

export const APPLICANT_ROLE_VALUES = {
    ROLE_PRIMARY_APPLICANT: 10,
    ROLE_CO_APPLICANT: 20,
    ROLE_GUARANTOR: 30,
    ROLE_OCCUPANT: 40,
    ROLE_DEPENDENT: 50,
};

export const PLAID_PRODUCTS = {
    INCOME_VERIFICATION_WITH_BANK: PLAID_INCOME_BANK,
    INCOME_VERIFICATION_WITH_PAYROLL: PLAID_INCOME_PAYROLL,
    INCOME_VERIFICATION_WITH_PAYROLL_PAYSTUB: PLAID_INCOME_PAYROLL_PAYSTUB,
    INCOME_VERIFICATION_WITH_PAYROLL_TAXFORM: PLAID_INCOME_PAYROLL_TAXFORM,
    IDENTITY_VERIFICATION: PLAID_IDENTITY_VERIFICATION,
};

export const APPLICANT_ROLE_LABELS = {
    [APPLICANT_ROLE_VALUES.ROLE_PRIMARY_APPLICANT]: 'Main Applicant',
    [APPLICANT_ROLE_VALUES.ROLE_CO_APPLICANT]: 'Roommate',
    [APPLICANT_ROLE_VALUES.ROLE_GUARANTOR]: 'Guarantor',
    [APPLICANT_ROLE_VALUES.ROLE_OCCUPANT]: 'Occupant',
    [ROLE_DEPENDENT]: 'Dependent',
};

export const SMS_OPT_IN_MARKETING_ENABLED = 5;

// event constants used for navigation
export const EVENT_APPLICANT_REGISTERED = 5;
export const EVENT_LEASE_TERMS_COMPLETED = 6;
export const EVENT_RECEIPT_VIEWED_COMPLETED = 640;
export const EVENT_SSN_COMPLETED = 650;

export const EVENT_RENTAL_OPTIONS_SELECTED = 10;
export const EVENT_RENTAL_OPTIONS_NOT_SELECTED = 15;
export const EVENT_RENTAL_OPTIONS_COAPPLICANT_INVITED = 20;
export const EVENT_RENTAL_OPTIONS_GUARANTOR_INVITED = 30;
export const EVENT_RENTAL_OPTIONS_PET_ADDED = 40;

export const EVENT_INCOME_REPORTS_GENERATED = 45;
export const EVENT_APPLICATION_FEE_PAID = 50;

export const EVENT_SCREENING_COMPLETED = 60;
export const EVENT_INCOME_REPORTED_NONE = 185;
export const EVENT_FINANCIAL_STREAM_ADDITIONAL_DOCUMENTS_REQUESTED_EMAIL_SENT = 390;
export const EVENT_APPLICANT_UPDATED_EMPLOYER_INFO = 400;
export const EVENT_OFFLINE_LEASE_DOCUMENT_CREATED = 910;

// Applicant Milestones
export const MILESTONE_RENTAL_PROFILE_COMPLETED = 10020;
export const MILESTONE_INCOME_COMPLETED = 10030;
export const MILESTONE_APPLICATION_FEE_COMPLETED = 10040;
export const MILESTONE_APPLICANT_SUBMITTED = 10070;
export const MILESTONE_FINANCIAL_STREAM_INCOMPLETE = 10080;
export const MILESTONE_FINANCIAL_STREAM_ADDITIONAL_DOCUMENTS_REQUESTED = 10090;
export const MILESTONE_FINANCIAL_STREAM_MISSING_DOCUMENTS_REQUESTED = 10110;
export const MILESTONE_APPLICANT_SIGNED_LEASE = 10100;
export const MILESTONE_APPLICANT_IDENTITY_VERIFIED = 10120;
export const MILESTONE_PROOF_OF_STATED_INCOME_REQUESTED = 10180;
export const MILESTONE_DOCUMENTS_SENT = 40020;
export const MILESTONE_APPLICANT_INVITED_TO_COMPLETE_ACCOUNT = 40080;
export const MILESTONE_NOTICE_TO_VACATE_COMPLETED_WITHOUT_DOCUMENT = 40045;

// Application Milestones
export const MILESTONE_REQUEST_GUARANTOR = 20020;
export const MILESTONE_LEASE_SENT = 20030;
export const MILESTONE_LEASE_VOIDED = 20040;

export const APPLICATION_STATUS_NEW = 0; // Note: this guy doesn't actually exist in chuck contants
export const APPLICATION_STATUS_IN_PROGRESS = 5;
export const APPLICATION_STATUS_SUBMITTED = 7;
export const APPLICATION_STATUS_APPROVED = 10;
export const APPLICATION_STATUS_DENIED = 15;
export const APPLICATION_STATUS_CANCELLED = 20;
export const APPLICATION_STATUS_COMPLETED = 25;
export const APPLICATION_STATUS_CONDITIONALLY_APPROVED = 30;
export const APPLICATION_STATUS_PENDING_CRIMINAL_REVIEW = 40;
export const APPLICATION_STATUS_STATUS_PREQUALIFY_FOR_APPROVAL = 50;

export const STEP_ADDRESS = 10;
export const STEP_LEASE_TERMS = 20;
export const STEP_RENTAL_OPTIONS = 30;
export const STEP_INCOME_EMPLOYMENT = 40;
export const STEP_FEES_AND_DEPOSITS = 50;
export const STEP_SCREENING = 70;
export const STEP_IDENTITY_VERIFICATION = 80;
export const STEP_LEASE_TRANSACTION_SUBMITTED = 90;
export const STEP_TRANSACTION_CANCELED = 100;
export const STEP_TRANSACTION_COMPLETED = 110;
export const STEP_TRANSACTION_DENIED = 120;
export const STEP_LEASE_VOIDED = 140;
export const STEP_LEASE_SIGNED = 150;
export const STEP_TRANSACTION_APPROVED = 160;
export const STEP_TRANSACTION_WAITING_AGENT_CONFIRMATION = 165;
export const STEP_UNIT_UNAVAILABLE = 170;
export const STEP_INCOME_VERIFICATION = 180;
export const STEP_GUARANTOR_REQUESTED = 190;
export const STEP_OUTSTANDING_BALANCE = 200;
export const STEP_TERMS_OF_SERVICE = 210;
export const STEP_NOTICE_TO_VACATE_SIGNED = 220;
export const STEP_INCOME_REQUESTED = 230;
export const STEP_TRANSACTION_PENDING_CRIMINAL_REVIEW = 240;
export const STEP_MILITARY_STATUS = 250;

export const LEASE_TRANSACTION_STEPS = {
    STEP_ADDRESS,
    STEP_MILITARY_STATUS,
    STEP_LEASE_TERMS,
    STEP_RENTAL_OPTIONS,
    STEP_INCOME_EMPLOYMENT,
    STEP_FEES_AND_DEPOSITS,
    STEP_SCREENING,
    STEP_IDENTITY_VERIFICATION,
    STEP_LEASE_TRANSACTION_SUBMITTED,
    STEP_TRANSACTION_CANCELED,
    STEP_TRANSACTION_COMPLETED,
    STEP_TRANSACTION_DENIED,
    STEP_TRANSACTION_PENDING_CRIMINAL_REVIEW,
    STEP_TRANSACTION_WAITING_AGENT_CONFIRMATION,
    STEP_LEASE_VOIDED,
    STEP_LEASE_SIGNED,
    STEP_TRANSACTION_APPROVED,
    STEP_UNIT_UNAVAILABLE,
    STEP_INCOME_VERIFICATION,
    STEP_GUARANTOR_REQUESTED,
    STEP_OUTSTANDING_BALANCE,
    STEP_TERMS_OF_SERVICE,
    STEP_NOTICE_TO_VACATE_SIGNED,
    STEP_INCOME_REQUESTED,
};

export const APPLICANT_EVENTS = {
    // Regular events
    EVENT_APPLICANT_REGISTERED,
    EVENT_LEASE_TERMS_COMPLETED,
    EVENT_SCREENING_COMPLETED,
    EVENT_SSN_COMPLETED,
    EVENT_RECEIPT_VIEWED_COMPLETED,
    EVENT_RENTAL_OPTIONS_SELECTED,
    EVENT_RENTAL_OPTIONS_NOT_SELECTED,
    EVENT_RENTAL_OPTIONS_COAPPLICANT_INVITED,
    EVENT_RENTAL_OPTIONS_GUARANTOR_INVITED,
    EVENT_RENTAL_OPTIONS_PET_ADDED,
    EVENT_INCOME_REPORTS_GENERATED,
    EVENT_APPLICATION_FEE_PAID,
    EVENT_APPLICANT_UPDATED_EMPLOYER_INFO,
    EVENT_INCOME_REPORTED_NONE,
    EVENT_FINANCIAL_STREAM_ADDITIONAL_DOCUMENTS_REQUESTED_EMAIL_SENT,

    // Milestones
    MILESTONE_APPLICANT_SIGNED_LEASE,
    MILESTONE_LEASE_VOIDED,
    MILESTONE_INCOME_COMPLETED,
    MILESTONE_REQUEST_GUARANTOR,
    MILESTONE_APPLICANT_IDENTITY_VERIFIED,
    MILESTONE_APPLICANT_INVITED_TO_COMPLETE_ACCOUNT,
    MILESTONE_NOTICE_TO_VACATE_COMPLETED_WITHOUT_DOCUMENT,
};

export const APPLICATION_STATUSES = {
    APPLICATION_STATUS_NEW,
    APPLICATION_STATUS_IN_PROGRESS,
    APPLICATION_STATUS_SUBMITTED,
    APPLICATION_STATUS_APPROVED,
    APPLICATION_STATUS_CONDITIONALLY_APPROVED,
    APPLICATION_STATUS_COMPLETED,
    APPLICATION_STATUS_DENIED,
    APPLICATION_STATUS_CANCELLED,
    APPLICATION_STATUS_PENDING_CRIMINAL_REVIEW,
    APPLICATION_STATUS_STATUS_PREQUALIFY_FOR_APPROVAL,
};

export const ACTIVE_APPLICATION_STATUSES = [
    APPLICATION_STATUS_NEW,
    APPLICATION_STATUS_IN_PROGRESS,
    APPLICATION_STATUS_SUBMITTED,
    APPLICATION_STATUS_APPROVED,
    APPLICATION_STATUS_CONDITIONALLY_APPROVED,
    APPLICATION_STATUS_PENDING_CRIMINAL_REVIEW,
    APPLICATION_STATUS_STATUS_PREQUALIFY_FOR_APPROVAL,
];

export const ACTIVATED_APPLICATION_STATUSES = [...ACTIVE_APPLICATION_STATUSES, APPLICATION_STATUS_COMPLETED];

export const PAST_APPLICATION_STATUSES = [
    APPLICATION_STATUS_COMPLETED,
    APPLICATION_STATUS_DENIED,
    APPLICATION_STATUS_CANCELLED,
];

export const APPLICATION_STATUSES_LABELS = {
    [APPLICATION_STATUS_NEW]: 'New',
    [APPLICATION_STATUS_IN_PROGRESS]: 'In Progress',
    [APPLICATION_STATUS_PENDING_CRIMINAL_REVIEW]: 'In Progress',
    [APPLICATION_STATUS_SUBMITTED]: 'Submitted',
    [APPLICATION_STATUS_STATUS_PREQUALIFY_FOR_APPROVAL]: 'Submitted',
    [APPLICATION_STATUS_APPROVED]: 'Approved',
    [APPLICATION_STATUS_CONDITIONALLY_APPROVED]: 'Conditionally Approved',
    [APPLICATION_STATUS_COMPLETED]: 'Completed',
    [APPLICATION_STATUS_DENIED]: 'Denied',
    [APPLICATION_STATUS_CANCELLED]: 'Cancelled',
};

export const APPLICATION_STATUSES_COLORS = {
    [APPLICATION_STATUS_IN_PROGRESS]: '#000',
    [APPLICATION_STATUS_SUBMITTED]: '#000',
    [APPLICATION_STATUS_STATUS_PREQUALIFY_FOR_APPROVAL]: '#000',
    [APPLICATION_STATUS_APPROVED]: '#67C18B',
    [APPLICATION_STATUS_CONDITIONALLY_APPROVED]: '#FAC700',
    [APPLICATION_STATUS_COMPLETED]: '#000',
    [APPLICATION_STATUS_DENIED]: '#FB6D68',
    [APPLICATION_STATUS_CANCELLED]: '#FB6D68',
    [APPLICATION_STATUS_PENDING_CRIMINAL_REVIEW]: '#000',
};

// payments constants
// NOTE: To support new fee line items, simply add them here accordingly.
export const LINE_ITEM_TYPE_APPLICATION_FEE = 10;
export const LINE_ITEM_TYPE_HOLDING_DEPOSIT = 20;
export const LINE_ITEM_TYPE_TRANSFER_FEE = 30;
export const LINE_ITEM_FEE_TYPES = [LINE_ITEM_TYPE_APPLICATION_FEE, LINE_ITEM_TYPE_TRANSFER_FEE];
export const LINE_ITEM_TYPES = {
    [LINE_ITEM_TYPE_APPLICATION_FEE]: 'Application Fees',
    [LINE_ITEM_TYPE_TRANSFER_FEE]: 'Transfer Fees',
    [LINE_ITEM_TYPE_HOLDING_DEPOSIT]: 'Holding Deposits',
};

// applicant status constants
export const CO_APPLICANT_STATUS_NOT_STARTED = 'Not Started';
export const CO_APPLICANT_STATUS_IN_PROGRESS = 'In Progress';
export const CO_APPLICANT_STATUS_COMPLETED = 'Completed';

export const APPLICANT_STATUS_COLOR_MAP = {
    [CO_APPLICANT_STATUS_NOT_STARTED]: '#DB5963',
    [CO_APPLICANT_STATUS_IN_PROGRESS]: '#FAC700',
    [CO_APPLICANT_STATUS_COMPLETED]: '#67C18B',
};

// pets constants

export const RENTAL_OPTIONS_PETS_DOGS = 'pets-dogs';
export const RENTAL_OPTIONS_PETS_CATS = 'pets-cats';
export const RENTAL_OPTIONS_PETS_OTHER = 'pets-other';
export const PET_DOG_LABEL = 'Dog';
export const PET_CAT_LABEL = 'Cat';
export const PET_OTHER_LABEL = 'Other';

export const PET_RENTAL_OPTION_TYPE_TO_LABEL_MAP = {
    [RENTAL_OPTIONS_PETS_DOGS]: PET_DOG_LABEL,
    [RENTAL_OPTIONS_PETS_CATS]: PET_CAT_LABEL,
    [RENTAL_OPTIONS_PETS_OTHER]: PET_OTHER_LABEL,
};

export const RENTER_PROFILE_TYPE_CO_APPLICANTS = 'co_applicants';
export const RENTER_PROFILE_TYPE_GUARANTOR = 'guarantor';
export const RENTER_PROFILE_TYPE_OCCUPANT = 'occupant';
export const RENTER_PROFILE_TYPE_DEPENDENT = 'dependent';

export const APPLICANT_ROLE_RENTER_PROFILE_TYPES = {
    [APPLICANT_ROLE_VALUES.ROLE_PRIMARY_APPLICANT]: 'primary_applicant',
    [APPLICANT_ROLE_VALUES.ROLE_CO_APPLICANT]: RENTER_PROFILE_TYPE_CO_APPLICANTS,
    [APPLICANT_ROLE_VALUES.ROLE_GUARANTOR]: RENTER_PROFILE_TYPE_GUARANTOR,
    [APPLICANT_ROLE_VALUES.ROLE_OCCUPANT]: RENTER_PROFILE_TYPE_OCCUPANT,
    [APPLICANT_ROLE_VALUES.ROLE_DEPENDENT]: RENTER_PROFILE_TYPE_DEPENDENT,
};

export const RENTER_PROFILE_TYPE_PETS = 'pets';
export const RENTER_PROFILE_TYPE_PARKING = 'parking';
export const RENTER_PROFILE_TYPE_WINE_COOLER = 'wine-cooler';
export const RENTER_PROFILE_TYPE_STORAGE = 'storage';

// Accepted Terms Of Service Constants
export const TOS_TYPE_PAYMENTS = 'TOS_TYPE_PAYMENTS';
export const TOS_TYPE_NESTIO = 'TOS_TYPE_NESTIO';
export const TOS_TYPE_SCREENING_CRITERIA = 'TOS_TYPE_SCREENING_CRITERIA';
export const TOS_TYPE_FINICITY = 'TOS_TYPE_FINICITY';

export const INCOME_TYPE_FINICITY_AUTOMATED = 135;
export const INCOME_TYPE_PLAID_PAYROLL = 145;
export const INCOME_TYPE_PLAID_BANK = 150;
export const ASSET_TYPE_PLAID = 520;

export const PLAID_TYPES = [INCOME_TYPE_PLAID_PAYROLL, INCOME_TYPE_PLAID_BANK, ASSET_TYPE_PLAID];

export const ALL_INCOME_OR_ASSET_TYPES = {
    // INCOME TYPES
    105: {
        label: 'Employment',
        value: 105,
    },
    110: {
        label: 'Self Employment',
        value: 110,
    },
    115: {
        label: 'Student',
        value: 115,
    },
    120: {
        label: 'Government',
        value: 120,
    },
    125: {
        label: 'Retirement',
        value: 125,
    },
    130: {
        label: 'Child/Spousal Support',
        value: 130,
    },
    140: {
        label: 'Other',
        value: 140,
    },
    // ASSET TYPES
    500: {
        label: 'Savings',
        value: 500,
    },
    505: {
        label: 'Retirement',
        value: 505,
    },
    510: {
        label: 'Investment',
        value: 510,
    },
    515: {
        label: 'Other',
        value: 515,
    },
};
// used in lists
export const INCOME_TYPES = [
    ALL_INCOME_OR_ASSET_TYPES[105],
    ALL_INCOME_OR_ASSET_TYPES[110],
    ALL_INCOME_OR_ASSET_TYPES[115],
    ALL_INCOME_OR_ASSET_TYPES[120],
    ALL_INCOME_OR_ASSET_TYPES[125],
    ALL_INCOME_OR_ASSET_TYPES[130],
    ALL_INCOME_OR_ASSET_TYPES[140],
];

export const ASSET_TYPES = [
    ALL_INCOME_OR_ASSET_TYPES[500],
    ALL_INCOME_OR_ASSET_TYPES[505],
    ALL_INCOME_OR_ASSET_TYPES[510],
    ALL_INCOME_OR_ASSET_TYPES[515],
];

export const FINANCIAL_STREAM_INCOME = 5;
export const FINANCIAL_STREAM_ASSET = 10;

export const FINANCIAL_STREAM_STATUS_PENDING = 10;
export const FINANCIAL_STREAM_STATUS_APPROVED = 20;
export const FINANCIAL_STREAM_STATUS_DENIED = 30;
export const FINANCIAL_STREAM_STATUS_INCOMPLETE = 40;
export const FINANCIAL_STREAM_STATUS_UNABLE_TO_VERIFY = 50;

export const FINANCIAL_STREAM_RESOLVE_UNVERIFIED_PLAID_DOCUMENTS_BY_REQUESTING_MORE_DOCUMENTS = 1;
export const FINANCIAL_STREAM_RESOLVE_UNVERIFIED_PLAID_DOCUMENTS_WITHOUT_COUNTING_TOWARD_TOTAL = 2;
export const FINANCIAL_STREAM_RESOLVE_UNVERIFIED_PLAID_DOCUMENTS_BY_ACCEPTING = 3;

export const FINANCIAL_STREAM_PLAID_RISK_SCORE_LOW = 'LOW';
export const FINANCIAL_STREAM_PLAID_RISK_SCORE_MEDIUM = 'MEDIUM';
export const FINANCIAL_STREAM_PLAID_RISK_SCORE_HIGH = 'HIGH';

export const INCOME_TYPE_OTHER = 140;
export const ASSET_TYPE_OTHER = 515;

export const DOCUMENT_TYPE_LEASE = 5;
export const DOCUMENT_TYPE_OPTIONS = { DOCUMENT_TYPE_LEASE };

export const PAYMENT_TIME_MONTHLY = 10;

export const LEASE_TRANSACTION_TYPE_APPLICATION = 'Application';

export const HOTDOCS_DOCUMENT_TYPE = 'criminal_review_letter';

export const LEASE_RENEWAL_OPTION = 5;
export const LEASE_VACATE_OPTION = 10;
export const LEASE_MONTH_TO_MONTH_OPTION = 15;

export const LEASE_TYPE_IMPORTED = 20;
export const LEASE_STATUS_ACTIVE = 5;
export const LEASE_STATUS_UPCOMING = 15;
export const LEASE_STATUS_ENDED = 10;
export const LEASE_STATUS_CANCELED = 20;

export const LEASE_INACTIVE_STATUSES = [LEASE_STATUS_ENDED, LEASE_STATUS_CANCELED];
export const UPLOADED_FINANCIAL_PROOF_DOCUMENT_MODEL = 30;
export const ERROR_UPLOAD =
    'Oops, we had some trouble uploading your files. ' +
    'Be sure to use documents with unique filenames and refrain from renaming them during the upload process. ' +
    'If you continue to have issues, please contact an agent or try again later.';

export const LEASE_TERM_ENDS_ON_LAST_DAY_OF_MONTH = 20;
export const ACCOUNT_HOLDER_TYPE_INDIVIDUAL = 'individual';
export const ACCOUNT_HOLDER_TYPE_COMPANY = 'company';
export const CARD_PAYMENT = 'card';
export const NEW_BANK_ACCOUNT = 'new_bank';

export const BANK_ACCOUNT_STATUS_PENDING_VERIFICATION = 10;
export const BANK_ACCOUNT_STATUS_VERIFIED = 20;
export const BANK_ACCOUNT_STATUS_VERIFICATION_FAILED = 30;
export const BANK_ACCOUNT_STATUS_LABELS = {
    [BANK_ACCOUNT_STATUS_PENDING_VERIFICATION]: 'Pending Verification',
    [BANK_ACCOUNT_STATUS_VERIFIED]: 'Verified',
    [BANK_ACCOUNT_STATUS_VERIFICATION_FAILED]: 'Failed Verification',
};

// payment constants
export const PAYMENT_SOURCE_RESIDENT_APP = 'ResApp Payments';
export const PAYMENT_SOURCE_ONLINE_LEASING = 'Online Leasing';

export const PAYMENT_CONSTANTS = {
    PAYMENT_SOURCE_RESIDENT_APP,
    PAYMENT_SOURCE_ONLINE_LEASING,
};

export const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'application/pdf'];

export const FINANCIAL_SITUATION_VERIFICATION_PROVIDER_FINICITY = 10;
export const FINANCIAL_SITUATION_VERIFICATION_PROVIDER_PLAID = 20;

export const IDENTITY_VERIFICATION_PROVIDER_TRANSUNION = 10;
export const IDENTITY_VERIFICATION_PROVIDER_PLAID = 20;
