import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { StripeProvider } from 'react-stripe-elements';

import MayorLoaderProvider from 'common-components/MayorLoader/MayorLoaderProvider';
import SnackbarProvider from 'common-components/Snackbar/SnackbarProvider';
import SentryUtilsProvider from 'common-components/SentryUtils/SentryUtilsProvider';

export const AppTheme = React.createContext();

function getThemeValues(config, materialTheme) {
    if (config.dark_mode) {
        return {
            dark_mode: true,
            logo: config.logo,
            bannerBackground: materialTheme.palette.primary.main,
            bannerColor: materialTheme.palette.primary.contrastText,
            progressBarTrackBackground: '#ffffff',
            progressBarTrackOpacity: 1,
            progressBarBackground: materialTheme.palette.primary.main,
            progressBarOpacity: 0.7,
        };
    } else {
        return {
            dark_mode: false,
            logo: config.logo,
            bannerBackground: '#ffffff',
            color: '#000000',
            progressBarTrackBackground: materialTheme.palette.primary.main,
            progressBarTrackOpacity: 0.3,
            progressBarBackground: materialTheme.palette.primary.main,
            progressBarOpacity: 1,
        };
    }
}

export function AppContextProvider({ theme, children, config, skipStripeAndMayorLoader = false }) {
    const [stripeInstance, setStripeInstance] = useState(null);
    const stripeApiKey = config.stripe_publishable_key;

    useEffect(() => {
        if (window.Stripe) {
            setStripeInstance(window.Stripe(stripeApiKey));
        } else {
            const element = document.querySelector('#stripe-js');
            if (element) {
                element.addEventListener('load', () => {
                    setStripeInstance(window.Stripe(stripeApiKey));
                });
            }
        }
    }, [stripeApiKey]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppTheme.Provider value={getThemeValues(config, theme)}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {skipStripeAndMayorLoader ? (
                        children
                    ) : (
                        <SentryUtilsProvider>
                            <SnackbarProvider>
                                <MayorLoaderProvider>
                                    <StripeProvider stripe={stripeInstance}>{children}</StripeProvider>
                                </MayorLoaderProvider>
                            </SnackbarProvider>
                        </SentryUtilsProvider>
                    )}
                </MuiPickersUtilsProvider>
            </AppTheme.Provider>
        </ThemeProvider>
    );
}

AppContextProvider.propTypes = {
    config: PropTypes.object,
    theme: PropTypes.object,
    skipStripeAndMayorLoader: PropTypes.bool, // used for testing purposes
    children: PropTypes.node,
};

const mapStateToProps = (state) => ({
    config: state.configuration,
});

export default connect(mapStateToProps)(AppContextProvider);
