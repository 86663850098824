import { useState, useEffect, useCallback } from 'react';

import API from 'api/api';

export const ERROR_MESSAGE_DOCUMENT_STILL_PROCESSING = 'Lease document is still processing. Please try again later.';

const useGetLeaseDocumentUrl = ({ leaseTransaction }) => {
    const [retried, setRetried] = useState(false);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    const requestUrl = useCallback(async () => {
        if (!leaseTransaction) return;

        setLoading(true);

        try {
            const response = await API.leaseDocumentUrl(leaseTransaction.id, leaseTransaction.signable_doc_type);
            if (response && response.status === 204) {
                setUrl(null);
                setError(ERROR_MESSAGE_DOCUMENT_STILL_PROCESSING);
            } else {
                setUrl(response ? response.url : undefined);
                setError(response.url ? null : ERROR_MESSAGE_DOCUMENT_STILL_PROCESSING);
            }
        } catch (e) {
            setUrl(null);
            setError(ERROR_MESSAGE_DOCUMENT_STILL_PROCESSING);
        }

        setLoading(false);
    }, [leaseTransaction]);

    useEffect(() => {
        requestUrl();
    }, [requestUrl]);

    const fetchLeaseDocumentUrl = () => {
        if (!url) {
            setRetried(true);
            requestUrl();
        }
    };

    return {
        retried,
        loading,
        error,
        url,
        fetchLeaseDocumentUrl,
    };
};

export default useGetLeaseDocumentUrl;
