import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Divider } from '@material-ui/core';
import { css } from 'emotion';

import ActionButton from 'common-components/ActionButton/ActionButton';

const Header = styled.div`
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 8px;
`;
const SubHeader = styled.div`
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #828796;
    margin-bottom: 20px;
`;
const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 29px;
    margin-bottom: 23px;
`;
const body = css`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 76px;
`;

export const RemoveBankAccount = ({ onConfirmRemove, onCancel, bankAccountLabel }) => {
    return (
        <div>
            <Header>Remove Bank Account</Header>
            <SubHeader>{bankAccountLabel}</SubHeader>
            <Divider />
            <div className={body}>
                <Title>Are you sure you want to remove this bank account?</Title>
                You’re about to remove <strong>{bankAccountLabel}</strong>. Removing a bank account means it will no
                longer be saved to your account. You’ll need to complete the verification process again if you add this
                bank in the future.
            </div>
            <ActionButton onClick={onConfirmRemove} color="primary">
                Remove Bank Account
            </ActionButton>
            <ActionButton onClick={onCancel} variant="outlined" color="primary" marginTop={18}>
                Cancel
            </ActionButton>
        </div>
    );
};

RemoveBankAccount.propTypes = {
    onConfirmRemove: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    bankAccountLabel: PropTypes.string.isRequired,
};

export default RemoveBankAccount;
