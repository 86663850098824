import { APPLICATION_STATUS_COMPLETED, APPLICATION_STATUS_CANCELLED, APPLICATION_STATUS_DENIED } from './constants';

export const LEASE_TRANSACTION_TYPE_APPLICATION = 10;
export const LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE = 20;
export const LEASE_TRANSACTION_TYPE_RENEWAL = 30;
export const LEASE_TRANSACTION_TYPE_VACATE = 40;
export const LEASE_TRANSACTION_TYPE_TRANSFER = 60;
export const LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION = 80;
export const CATEGORY_UNIT_SECURITY_DEPOSIT = 'unit_security_deposit';
export const CATEGORY_ADDITIONAL_SECURITY_DEPOSIT = 'additional_security_deposit';

export const LEASE_TRANSACTION_TYPES = {
    [LEASE_TRANSACTION_TYPE_APPLICATION]: 'Application',
    [LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE]: 'Lease Revision',
    [LEASE_TRANSACTION_TYPE_RENEWAL]: 'Renewal',
    [LEASE_TRANSACTION_TYPE_VACATE]: 'Notice to Vacate',
    [LEASE_TRANSACTION_TYPE_TRANSFER]: 'Transfer',
    [LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION]: 'Lease Document',
};

export const PAST_LEASE_TRANSACTION_STATUSES = [
    APPLICATION_STATUS_COMPLETED,
    APPLICATION_STATUS_CANCELLED,
    APPLICATION_STATUS_DENIED,
];

export const VACATE_TERMINATION_OPTION_RENT_RESPONSIBLE = 10;
export const VACATE_TERMINATION_OPTION_FEE = 20;

export const VACATE_TERMINATION_OPTION_OPTIONS = {
    [VACATE_TERMINATION_OPTION_RENT_RESPONSIBLE]: 'Rent Responsible',
    [VACATE_TERMINATION_OPTION_FEE]: 'Termination Fee',
};

export const getDocumentTypeLabelForTransaction = (leaseTransaction) => {
    let label;
    switch (leaseTransaction?.type) {
        case LEASE_TRANSACTION_TYPE_VACATE:
            label = 'Notice to Vacate';
            break;
        case LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION:
        case LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE:
            label = 'Document';
            break;
        default:
            label = 'Lease';
    }
    return label;
};
