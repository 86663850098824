import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import API from 'api/api';
import { Heading3, SpacedH1, useBankingOptionStyles } from 'assets/styles';

import { ROUTES, REPORT_POLL_INTERVAL, PLAID_PRODUCTS } from 'constants/constants';

import BankVerifying from 'pages/Banking/components/BankVerifying';
import ActionButton from 'common-components/ActionButton/ActionButton';
import BackLink from 'common-components/BackLink/BackLink';
import withTransactionPath from 'utils/withTransactionPath';
import PlaidLink from '../components/PlaidLink';
import BankingContext from 'pages/Banking/BankingContext';

export function PlaidAddPayroll(props) {
    const context = useContext(BankingContext);
    const classes = useBankingOptionStyles();
    const [stateObj, setStateObj] = useState({
        showPlaidLink: false,
        loadingReport: false,
        plaidProduct: PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_PAYROLL_PAYSTUB,
    });

    const handleGetPlaidPayrollIncome = async (publicToken, reportType, plaidProduct) => {
        try {
            await API.getPlaidIncomeAndAssets(props.leaseTransaction.id, publicToken, reportType, plaidProduct);
            let response = await API.fetchPlaidReports(props.leaseTransaction.id, reportType);
            while (response.status === 202) {
                await new Promise((resolve) => setTimeout(resolve, REPORT_POLL_INTERVAL));
                response = await API.fetchPlaidReports(props.leaseTransaction.id, reportType);
            }

            await context.refreshFinancialSources();
            await context.fetchTransaction();
            props.history.push(props.getTransactionPath(ROUTES.INCOME_VERIFICATION_SUMMARY));
        } catch {
            setStateObj({
                showPlaidLink: false,
                loadingReport: false,
                plaidProduct: PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_PAYROLL_PAYSTUB,
            });
        }
    };

    if (stateObj.loadingReport) {
        return <BankVerifying />;
    }

    if (stateObj.showPlaidLink) {
        return (
            <PlaidLink
                leaseTransactionId={props.leaseTransaction?.id}
                plaidProduct={stateObj.plaidProduct}
                updateState={setStateObj}
                handleOnSuccessCallback={handleGetPlaidPayrollIncome}
                onClose={() => {
                    props.history.push(
                        props.getTransactionPath(ROUTES.INCOME_VERIFICATION_SUMMARY, {
                            lease_transaction_id: props.leaseTransaction.id,
                        })
                    );
                }}
            />
        );
    }

    return (
        <>
            <SpacedH1 variant="h1">Add Income</SpacedH1>
            <br />
            <div>
                {props.configuration.plaid_payroll_digital_flow_enabled && (
                    <div data-testid="plaid_payroll_digital_flow_section">
                        <Heading3 align="left">Link your payroll provider</Heading3>
                        <span className={classes.pillbox}>INSTANT VERIFICATION</span>
                        <span className={classes.pillbox}>SECURE</span>
                        <br />
                        <p className={classes.body}>
                            Select your payroll provider, sign in, and confirm your payroll information.
                        </p>
                        <ActionButton
                            variant="outlined"
                            onClick={() => {
                                setStateObj({
                                    showPlaidLink: true,
                                    loadingReport: false,
                                    plaidProduct: PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_PAYROLL,
                                });
                            }}
                        >
                            Link Payroll
                        </ActionButton>
                    </div>
                )}
                {props.configuration.plaid_payroll_digital_flow_enabled &&
                    props.configuration.plaid_payroll_manual_flow_enabled && (
                        <div className={classes.subTitleCenter}>or</div>
                    )}
                {props.configuration.plaid_payroll_manual_flow_enabled && (
                    <div data-testid="plaid_payroll_manual_flow_section">
                        <Heading3 align="left">Upload income documents</Heading3>
                        <p className={classes.body}>To verify your income, choose from the following options:</p>
                        <ul className={classes.body}>
                            <li>
                                <strong>1 tax document:</strong> Upload either a W-2 or 1099 form from the last year.
                            </li>
                            <li>
                                <strong>3 paystubs:</strong> Upload 3 recent paystubs within the past 3 months. Please
                                upload each paystub as a separate document.
                            </li>
                        </ul>
                    </div>
                )}
                <div>
                    <ActionButton
                        variant="outlined"
                        onClick={() => {
                            setStateObj({
                                showPlaidLink: true,
                                loadingReport: false,
                                plaidProduct: PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_PAYROLL_TAXFORM,
                            });
                        }}
                    >
                        Add Tax Form
                    </ActionButton>
                    <br />
                    <ActionButton
                        variant="outlined"
                        onClick={() => {
                            setStateObj({
                                showPlaidLink: true,
                                loadingReport: false,
                                plaidProduct: PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_PAYROLL_PAYSTUB,
                            });
                        }}
                    >
                        Add Paystubs
                    </ActionButton>
                    <div className={classes.footer}>You’ll be redirected to Plaid, our financial data provider.</div>
                    <br />
                    <BackLink
                        to={props.getTransactionPath(ROUTES.INCOME_VERIFICATION_SUMMARY, {
                            lease_transaction_id: props.leaseTransaction.id,
                        })}
                    />
                </div>
            </div>
        </>
    );
}

PlaidAddPayroll.propTypes = {
    history: PropTypes.object,
    configuration: PropTypes.object.isRequired,
    leaseTransaction: PropTypes.object.isRequired,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    configuration: state.configuration,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(PlaidAddPayroll));
