import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import clsx from 'clsx';

import {
    APPLICANT_STATUS_COLOR_MAP,
    CO_APPLICANT_STATUS_NOT_STARTED,
    ROUTES,
    RENTER_PROFILE_TYPE_DEPENDENT,
    RENTER_PROFILE_TYPE_OCCUPANT,
} from 'constants/constants';
import { getRoommateStatus } from 'utils/misc';
import { P, Span, Spacer, personName } from 'assets/styles';
import { link, nameContainer } from './ExistingRoommateStyles';
import { connect } from 'react-redux';
import withTransactionPath from 'utils/withTransactionPath';
import { Typography, styled } from '@material-ui/core';

const removeLink = css`
    margin-left: 15px;
`;

const EmailTypography = styled(Typography)({
    color: '#828796',
});

export function ExistingRoommate({ item, type, getTransactionPath, leaseTransaction }) {
    const isOccupant = type === RENTER_PROFILE_TYPE_OCCUPANT;
    const isDependent = type === RENTER_PROFILE_TYPE_DEPENDENT;
    const statusColor = APPLICANT_STATUS_COLOR_MAP[getRoommateStatus(item)];
    const didPersonStartApplication = getRoommateStatus(item) !== CO_APPLICANT_STATUS_NOT_STARTED;

    return (
        <>
            <div className={nameContainer}>
                <div>
                    <P className={personName}>{`${item.first_name} ${item.last_name}`}</P>
                    {!item.is_registered && !isDependent && !isOccupant && (
                        <EmailTypography variant="caption">{item.email || item.phone_number}</EmailTypography>
                    )}
                </div>
                {!item.is_registered && !isDependent && !isOccupant && (
                    <>
                        <br />
                        <Link
                            className={link}
                            to={{
                                pathname: getTransactionPath(ROUTES.RESEND_INVITE, {
                                    lease_transaction_id: leaseTransaction.id,
                                }),
                                state: {
                                    initialValues: item,
                                    confirmationButtonText: 'Back to Rental Profile',
                                    returnRoute: `${getTransactionPath(ROUTES.PROFILE_OPTIONS, {
                                        lease_transaction_id: leaseTransaction.id,
                                    })}#${type}`,
                                },
                            }}
                        >
                            Edit/Resend
                        </Link>
                    </>
                )}
                {!isDependent && !isOccupant && !didPersonStartApplication && (
                    <Link
                        className={clsx([link, removeLink])}
                        to={getTransactionPath(ROUTES.REMOVE_PERSON, {
                            id: item.id,
                            lease_transaction_id: leaseTransaction.id,
                            type,
                        })}
                    >
                        Remove
                    </Link>
                )}
                {isOccupant && (
                    <Span className="color-manatee" fontSize={14}>
                        Occupant
                    </Span>
                )}
                {isDependent && (
                    <>
                        <Span className="color-manatee" fontSize={14}>
                            Under 18
                        </Span>
                        <Spacer height={10} />
                        <Link
                            className={link}
                            to={getTransactionPath(ROUTES.EDIT_DEPENDANT, {
                                id: item.person_id,
                                lease_transaction_id: leaseTransaction.id,
                            })}
                        >
                            Edit
                        </Link>
                        <Link
                            className={clsx([link, removeLink])}
                            to={getTransactionPath(ROUTES.REMOVE_PERSON, {
                                id: item.person_id,
                                lease_transaction_id: leaseTransaction.id,
                                type,
                            })}
                        >
                            Remove
                        </Link>
                    </>
                )}
            </div>
            {!isDependent && (
                <div className="text-right">
                    <EmailTypography variant="caption">Application Status:</EmailTypography>
                    <br />
                    <P bold color={statusColor}>
                        <strong>{getRoommateStatus(item)}</strong>
                    </P>
                </div>
            )}
        </>
    );
}

ExistingRoommate.propTypes = {
    leaseTransaction: PropTypes.object,
    item: PropTypes.object,
    type: PropTypes.string,
    isDependent: PropTypes.bool,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
});

export default connect(mapStateToProps)(withTransactionPath(ExistingRoommate));
