import React from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';

import { getPaymentItemName } from 'utils/misc';

export const PaymentDetailsSavingsRow = ({ paymentTotal, name, months, pricePerMonth, className }) => {
    const monthsPlural = months > 1 ? 's' : '';

    return (
        <div className={className}>
            <div>
                {getPaymentItemName(name)}
                {months && `: ${months} month${monthsPlural} at $${pricePerMonth}/month`}
            </div>
            <div>${paymentTotal}</div>
        </div>
    );
};

PaymentDetailsSavingsRow.propTypes = {
    name: PropTypes.string.isRequired,
    paymentTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    months: PropTypes.number,
    pricePerMonth: PropTypes.string,
};

export const PaymentDetailSavingsRows = ({ paymentObject, paymentType }) => {
    const rows = paymentObject.items.map((item) => (
        <PaymentDetailsSavingsRow
            key={`${item.name}-${paymentType}`}
            name={item.name}
            paymentTotal={item.amount}
            pricePerMonth={item.price}
            months={item.months}
            className={styles.paymentDetailRow}
        />
    ));
    rows.push(
        <PaymentDetailsSavingsRow
            key={`total${paymentType}`}
            name={'Total'}
            paymentTotal={paymentObject.total}
            className={styles.paymentDetailRowTotal}
        />
    );
    return rows;
};

PaymentDetailSavingsRows.propTypes = {
    paymentObject: PropTypes.object.isRequired,
    paymentType: PropTypes.string.isRequired,
};

export default PaymentDetailSavingsRows;
