import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

import API from 'api/api';
import { reloadWindowForLeaseTransaction } from 'utils/routingHelpers';
import { renterProfileReceived } from 'reducers/renter-profile';
import { fetchApplicant } from 'reducers/applicant';
import { filterRentalOptionsByUnit } from 'reducers/configuration';
import { actions as loaderActions } from 'reducers/loader';
import { transactionReceived } from 'reducers/transaction';
import { leaseReceived } from 'reducers/lease';
import { getDisableRenewalWhenThereIsALeaseUpdate } from 'selectors/launchDarkly';

import {
    ACTIVE_APPLICATION_STATUSES,
    LEASE_MONTH_TO_MONTH_OPTION,
    LEASE_VACATE_OPTION,
    ROUTES,
} from 'constants/constants';
import { LEASE_TRANSACTION_TYPE_RENEWAL } from 'constants/leaseTransaction';
import { generateRenewalOptions, getBestOffer, getIsMonthToMonthOffer } from 'pages/Renewal/utils/helpers';
import { getRedirectToAccountSetupPage } from 'utils/routingHelpers';
import { useSentryUtils } from 'common-components/SentryUtils/SentryUtilsProvider';
import { prettyFormatPhoneNumber } from 'utils/misc';

import Page from 'common-components/Page/Page';
import ActionButton from 'common-components/ActionButton/ActionButton';
import LeaseOfferWrapper from './components/LeaseOfferWrapper';
import MonthToMonthOption from './components/MonthToMonthOption';
import VacateOption from './components/VacateOption';
import LeaseOfferDisclaimer from './components/LeaseOfferDisclaimer';

import RenewalOption from './components/RenewalOption';
import renewalIcon from 'assets/new-icons/streamline-lease-renewal.svg';

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center',
        margin: '20px auto',
        width: '90%',
    },
    renewalIcon: {
        width: '150px',
        height: '130px',
        marginBottom: '5px',
    },
    summary: {
        fontSize: '18px',
        lineHeight: '22px',
    },
    offers: {
        marginTop: '40px',
    },
}));

export function RenewalOffersPage({
    history = {},
    configuration = {},
    lease = {},
    userProfile = {},
    disableRenewalWhenThereIsALeaseUpdate = false,
    toggleLoader = (f) => f,
    transactionReceived = (f) => f,
    fetchApplicant = (f) => f,
    renterProfileReceived = (f) => f,
    filterRentalOptionsByUnit = (f) => f,
    leaseReceived = (f) => f,
}) {
    const { logToSentry } = useSentryUtils();
    const classes = useStyles();
    const { community } = configuration;
    const { company } = community;
    const { contact_phone: contactPhone = null } = community;
    const prettyPhoneNumber = prettyFormatPhoneNumber(contactPhone);
    const { is_month_to_month: isMonthToMonth = false, lease_settings: leaseSettings = {} } = lease || {};
    const { display_renewal_respond_by_date: displayRenewalRespondByDate = false } = leaseSettings || {};

    const getIsLeaseTransactionActive = (leaseTransaction) => {
        const { status } = leaseTransaction || {};
        return ACTIVE_APPLICATION_STATUSES.includes(status);
    };

    const hasActiveLeaseUpdate = useMemo(() => {
        if (!lease) return false;

        const { lease_updates: leaseUpdates = {} } = lease;
        const { midlease_change: leaseRevisions = [], transfer: transfers = [] } = leaseUpdates;
        const hasActiveLeaseUpdate = leaseRevisions.some(getIsLeaseTransactionActive);
        const hasActiveTransfer = transfers.some(getIsLeaseTransactionActive);

        return hasActiveLeaseUpdate || hasActiveTransfer;
    }, [lease]);

    const bestOffer = useMemo(() => {
        return lease && getBestOffer(lease.lease_offers);
    }, [lease]);

    const monthToMonthOffer = useMemo(() => {
        return lease && lease.lease_offers?.find((offer) => getIsMonthToMonthOffer(offer));
    }, [lease]);

    const isLeaseOfferDisclaimer = useMemo(() => {
        return company && !!company.lease_offer_disclaimer;
    }, [company]);

    const otherRenewalOptions = useMemo(() => {
        return (
            lease &&
            generateRenewalOptions(lease.lease_offers).filter(
                (offer) => offer.uniqueId !== bestOffer.uniqueId && !getIsMonthToMonthOffer(offer)
            )
        );
    }, [lease, bestOffer]);

    const isAdditionalOfferSelected = useCallback(
        (option) => {
            return ![LEASE_VACATE_OPTION, LEASE_MONTH_TO_MONTH_OPTION, bestOffer?.uniqueId].includes(option?.uniqueId);
        },
        [bestOffer]
    );

    const initialSelectedOption = useMemo(() => {
        if (!lease?.selected_lease_offer) return;

        if (lease.selected_lease_offer === LEASE_MONTH_TO_MONTH_OPTION && monthToMonthOffer) {
            return { uniqueId: LEASE_MONTH_TO_MONTH_OPTION };
        }

        if (lease.selected_lease_offer === LEASE_VACATE_OPTION) {
            return { uniqueId: LEASE_VACATE_OPTION };
        }

        if (lease?.selected_lease_offer === bestOffer?.uniqueId) {
            return bestOffer;
        }

        return otherRenewalOptions.find((offer) => offer.uniqueId === lease?.selected_lease_offer);
    }, [lease, bestOffer, otherRenewalOptions, monthToMonthOffer]);

    const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
    const [selectedAdditionalOffer, setSelectedAdditionalOffer] = useState(
        isAdditionalOfferSelected(initialSelectedOption) && initialSelectedOption
    );
    const [showMoreOptions, setShowMoreOptions] = useState(false);

    useEffect(() => {
        setSelectedOption(initialSelectedOption);
        if (initialSelectedOption && isAdditionalOfferSelected(initialSelectedOption)) {
            setSelectedAdditionalOffer(initialSelectedOption);
        }
    }, [lease, initialSelectedOption, isAdditionalOfferSelected]);

    const getIsSelected = useCallback(
        (option) => {
            return selectedOption?.uniqueId === option;
        },
        [selectedOption]
    );

    const toggleOption = useCallback(
        (option, data = {}) => {
            setSelectedOption(
                getIsSelected(option)
                    ? null
                    : {
                          uniqueId: option,
                          ...data,
                      }
            );
        },
        [setSelectedOption, getIsSelected]
    );

    if (!lease) {
        return null;
    }

    if (!lease.enable_self_service_renewals || !lease.is_compliant) {
        history.replace(ROUTES.LEASES);
        return null;
    }

    const redirectToAccountSetupPage = getRedirectToAccountSetupPage(userProfile);
    if (redirectToAccountSetupPage) {
        history.replace(ROUTES.ACCOUNT);
        return null;
    }

    const toggleMoreOptions = () => {
        if (showMoreOptions) {
            if (isAdditionalOfferSelected(selectedOption)) {
                setSelectedAdditionalOffer(selectedOption);
            } else {
                setSelectedAdditionalOffer(null);
            }
        }

        setShowMoreOptions(!showMoreOptions);
    };

    const submitRenewalDecision = async () => {
        leaseReceived({
            ...lease,
            selected_lease_offer: selectedOption.uniqueId,
        });

        if (selectedOption.uniqueId === LEASE_MONTH_TO_MONTH_OPTION) {
            return history.push(generatePath(ROUTES.LEASE_RENEWAL_MONTH_TO_MONTH, { lease_id: lease.id }));
        }

        if (selectedOption.uniqueId === LEASE_VACATE_OPTION) {
            return history.push(generatePath(ROUTES.VACATE_DETAILS, { lease_id: lease.id }));
        }

        toggleLoader(true);

        const payload = {
            type: LEASE_TRANSACTION_TYPE_RENEWAL,
            lease_term: selectedOption.term,
            lease: lease.id,
            lease_offer_id: selectedOption.nestio_id,
        };

        try {
            const response = await API.postLeaseTransaction(payload);
            transactionReceived(response);
            fetchApplicant(response.id);
            renterProfileReceived(response);
            filterRentalOptionsByUnit(response);
            reloadWindowForLeaseTransaction(response);
        } catch (e) {
            logToSentry(e);
        } finally {
            toggleLoader(false);
        }
    };

    const getLeaseOfferStartDate = (offer) => {
        return offer?.lease_start ? moment(offer.lease_start) : moment(lease.lease_end_date).add(1, 'day');
    };

    const daysBeforeRenewalDecision = configuration.days_before_renewal_decision_is_due;
    const leaseEndDate = moment(lease.lease_end_date);
    const renewalDecisionDeadline = moment(leaseEndDate).subtract(daysBeforeRenewalDecision, 'days');
    const dateFormat = 'MM/DD/YYYY';

    const getSubTitle = () => {
        let subTitle = '';
        if (!isMonthToMonth) {
            subTitle += `Your lease is ending on ${leaseEndDate.format(dateFormat)}. `;
        }
        if (daysBeforeRenewalDecision && !isMonthToMonth && displayRenewalRespondByDate) {
            subTitle += `Please let us know what your plan is by ${renewalDecisionDeadline.format(dateFormat)}. `;
        }
        if (isMonthToMonth || !displayRenewalRespondByDate) {
            subTitle += 'Please let us know your decision. ';
        }

        subTitle += '\n';
        subTitle += 'We hope you`ll decide to stay!';
        return subTitle;
    };

    const leaseOffersDisabled = hasActiveLeaseUpdate && disableRenewalWhenThereIsALeaseUpdate;
    const notifications = [];
    if (leaseOffersDisabled) {
        notifications.push({
            type: 'error',
            messages: (
                <span>
                    You have an active lease update and will be able to select a decision once the lease update is
                    completed. Call us at <a href={`tel:${contactPhone}`}>{prettyPhoneNumber}</a> if you have any
                    questions.
                    <br />
                    <Link to={ROUTES.LEASE_TRANSACTIONS}>Go to active lease update</Link>
                </span>
            ),
        });
    }

    return (
        <Page
            className={classes.root}
            title="Your Lease is Up For Renewal!"
            subTitle={getSubTitle()}
            image={{
                src: renewalIcon,
                className: classes.renewalIconl,
            }}
            notifications={notifications}
        >
            <p>
                {lease.unit.building.street_address} Unit {lease.unit.unit_number}
            </p>
            <div id="offers" className={classes.offers}>
                {bestOffer.term > 0 && (
                    <LeaseOfferWrapper
                        key={bestOffer.uniqueId}
                        disabled={leaseOffersDisabled}
                        selected={getIsSelected(bestOffer.uniqueId)}
                        onClickHandler={() => toggleOption(bestOffer.uniqueId, bestOffer)}
                    >
                        <RenewalOption
                            price={bestOffer.rent}
                            leaseStart={getLeaseOfferStartDate(bestOffer).format(dateFormat)}
                            leaseEnd={getLeaseOfferStartDate(bestOffer)
                                .add(bestOffer.term, 'months')
                                .add(-1, 'day')
                                .format(dateFormat)}
                            bestOffer={true}
                            isLeaseOfferDisclaimer={isLeaseOfferDisclaimer}
                            term={bestOffer.term}
                        />
                    </LeaseOfferWrapper>
                )}
                {monthToMonthOffer && (
                    <LeaseOfferWrapper
                        key={LEASE_MONTH_TO_MONTH_OPTION}
                        disabled={leaseOffersDisabled}
                        selected={getIsSelected(LEASE_MONTH_TO_MONTH_OPTION)}
                        onClickHandler={() => toggleOption(LEASE_MONTH_TO_MONTH_OPTION)}
                    >
                        <MonthToMonthOption
                            price={monthToMonthOffer.rent}
                            daysBeforeRenewalDecision={configuration.days_before_renewal_decision_is_due}
                            isLeaseOfferDisclaimer={isLeaseOfferDisclaimer}
                        />
                    </LeaseOfferWrapper>
                )}
                <LeaseOfferWrapper
                    key={LEASE_VACATE_OPTION}
                    disabled={leaseOffersDisabled}
                    selected={getIsSelected(LEASE_VACATE_OPTION)}
                    onClickHandler={() => toggleOption(LEASE_VACATE_OPTION)}
                >
                    <VacateOption buttonText={configuration.ntv_self_serve_renewal_button_verbiage} />
                </LeaseOfferWrapper>
                {selectedAdditionalOffer && (
                    <LeaseOfferWrapper
                        key={selectedAdditionalOffer.uniqueId}
                        disabled={leaseOffersDisabled}
                        selected={selectedOption?.uniqueId === selectedAdditionalOffer.uniqueId}
                        onClickHandler={() => {
                            toggleOption(selectedAdditionalOffer.uniqueId, selectedAdditionalOffer);
                        }}
                    >
                        <RenewalOption
                            price={selectedAdditionalOffer.rent}
                            leaseStart={getLeaseOfferStartDate(selectedAdditionalOffer).format(dateFormat)}
                            leaseEnd={getLeaseOfferStartDate(selectedAdditionalOffer)
                                .add(selectedAdditionalOffer.term, 'months')
                                .add(-1, 'day')
                                .format(dateFormat)}
                            term={selectedAdditionalOffer.term}
                            isLeaseOfferDisclaimer={isLeaseOfferDisclaimer}
                        />
                    </LeaseOfferWrapper>
                )}
                {showMoreOptions &&
                    otherRenewalOptions
                        .filter((offer) => offer.uniqueId !== selectedAdditionalOffer?.uniqueId)
                        .map((offer) => (
                            <LeaseOfferWrapper
                                key={offer.uniqueId}
                                disabled={leaseOffersDisabled}
                                selected={getIsSelected(offer.uniqueId)}
                                onClickHandler={() => {
                                    toggleOption(offer.uniqueId, offer);
                                }}
                            >
                                <RenewalOption
                                    price={offer.rent}
                                    leaseStart={getLeaseOfferStartDate(offer).format(dateFormat)}
                                    leaseEnd={getLeaseOfferStartDate(offer)
                                        .add(offer.term, 'months')
                                        .add(-1, 'day')
                                        .format(dateFormat)}
                                    term={offer.term}
                                    isLeaseOfferDisclaimer={isLeaseOfferDisclaimer}
                                />
                            </LeaseOfferWrapper>
                        ))}
                {otherRenewalOptions.length > 0 && (
                    <a href="#offers" onClick={toggleMoreOptions} data-testid="toggle-more-options">
                        {showMoreOptions ? 'Show fewer options' : 'Show more options'}
                    </a>
                )}

                {isLeaseOfferDisclaimer && <LeaseOfferDisclaimer disclaimerCopy={company.lease_offer_disclaimer} />}

                <ActionButton
                    marginTop={30}
                    marginBottom={20}
                    disabled={!selectedOption || leaseOffersDisabled}
                    onClick={submitRenewalDecision}
                >
                    Continue
                </ActionButton>
            </div>
        </Page>
    );
}

RenewalOffersPage.propTypes = {
    history: PropTypes.object,
    lease: PropTypes.object,
    configuration: PropTypes.object,
    userProfile: PropTypes.object,
    disableRenewalWhenThereIsALeaseUpdate: PropTypes.bool,
    toggleLoader: PropTypes.func,
    transactionReceived: PropTypes.func,
    renterProfileReceived: PropTypes.func,
    filterRentalOptionsByUnit: PropTypes.func,
    leaseReceived: PropTypes.func,
    fetchApplicant: PropTypes.func,
};

const mapStateToProps = (state) => ({
    lease: state.lease,
    configuration: state.configuration,
    applicant: state.applicant,
    userProfile: state.userProfile,
    disableRenewalWhenThereIsALeaseUpdate: getDisableRenewalWhenThereIsALeaseUpdate(state),
});

const mapActionsToProps = {
    transactionReceived,
    renterProfileReceived,
    leaseReceived,
    filterRentalOptionsByUnit,
    fetchApplicant,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapActionsToProps)(RenewalOffersPage);
