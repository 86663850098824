import React from 'react';
import DoneRoundedIcon from '@material-ui/icons/CheckRounded';

export const GreenCheckIcon = () => {
    return (
        <DoneRoundedIcon style={{ color: '#56BA82', width: 18, verticalAlign: 'top', position: 'relative', top: -2 }} />
    );
};

export default GreenCheckIcon;
