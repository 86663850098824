import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import {
    ROUTES,
    FINANCIAL_STREAM_INCOME,
    FINANCIAL_STREAM_ASSET,
    FINANCIAL_STREAM_STATUS_PENDING,
    ERROR_UPLOAD,
} from 'constants/constants';
import API from 'api/api';
import captureRoute from 'utils/captureRoute';
import { getIncompleteFinancialSourceWarning } from 'pages/Banking/bankingUtils';
import { fetchApplicant } from 'reducers/applicant';
import withSentryUtils from 'utils/withSentryUtils';
import withTransactionPath from 'utils/withTransactionPath';

import { BackLink } from 'common-components/BackLink/BackLink';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import AddFinancialSourceForm from 'pages/Banking/components/AddFinancialSourceForm';
import BankingContext from 'pages/Banking/BankingContext';
import { H1, H3, Spacer } from 'assets/styles';
import finance from 'assets/new-icons/currency-dollar-circle.svg';

const SkinnyH1 = styled(H1)`
    width: 70%;
`;

const SpacedH3 = styled(H3)`
    margin-top: 15px;
    margin-bottom: 30px;
`;

// TODO: refactor to functional comp using hooks similar to AddAssetSource and AddIncomeSource | created by: @JimVercoelen | Ticket: NESTIO-19936
export class EditFinancialSourcePage extends Component {
    state = { errors: [], financialSource: null };

    async componentDidMount() {
        this.fetchFinancialSource();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchFinancialSource();
        }
    }

    get initialValues() {
        const financialSource = this.state.financialSource;
        const uploadedDocuments = {};

        // eslint-disable-next-line
        financialSource.uploaded_documents?.forEach((source) => {
            // convert list of docs to format used in UploadDocuments.jsx
            if (!uploadedDocuments[source.proof_type.id]) uploadedDocuments[source.proof_type.id] = { files: [] };
            uploadedDocuments[source.proof_type.id].label = source.proof_type.label;
            uploadedDocuments[source.proof_type.id].files.push({ name: source.name, id: source.id });
        });
        return Object.assign({}, financialSource, { uploadedDocuments: uploadedDocuments });
    }

    onSubmit = async (values, { setSubmitting }) => {
        /* eslint-disable no-unused-expressions */
        this.context.toggleLoader?.(true);
        setSubmitting(true);
        this.setState({ errors: [] });
        const leaseTransactionId = this.props.leaseTransaction.id;
        const streamId = this.props.match.params.id;

        const data = {
            estimated_amount: values.estimated_amount,
            has_requested_more_documents: false,
            status: FINANCIAL_STREAM_STATUS_PENDING,
            uploaded_documents: values.uploaded_documents,
            stream_id: streamId,
        };
        if (values.other != null) {
            data.other = values.other;
        }

        try {
            await API.patchFinancialSource(leaseTransactionId, data);
            await this.context.refreshFinancialSources?.();
            await this.props.fetchApplicant(leaseTransactionId);
            this.props.history.push(this.returnLink);
        } catch (e) {
            this.props.logToSentry(e);
            this.setState({ errors: [ERROR_UPLOAD] });
        } finally {
            this.context.toggleLoader?.(false);
            setSubmitting(false);
        }
    };

    setErrors = (errors) => {
        this.setState({ errors: errors });
    };

    async fetchFinancialSource() {
        try {
            const leaseTransactionId = this.props.leaseTransaction.id;
            const data = await API.getFinancialSource(leaseTransactionId, this.props.match.params.id);
            this.setState({ financialSource: data });
        } catch (e) {
            this.props.logToSentry(e);
        }
    }

    get isAsset() {
        return this.state.financialSource?.stream_type === FINANCIAL_STREAM_ASSET;
    }

    get returnLink() {
        return this.props.getTransactionPath(
            `${ROUTES.INCOME_VERIFICATION_SUMMARY}#${this.isAsset ? 'asset' : 'income'}`
        );
    }

    render() {
        const financialSource = this.state.financialSource;
        if (!financialSource) return null;
        const isAsset = financialSource.stream_type === FINANCIAL_STREAM_ASSET;
        const warning = getIncompleteFinancialSourceWarning(financialSource, isAsset);

        return (
            <>
                <SkinnyH1>Add an {isAsset ? 'Asset' : 'Income Source'}</SkinnyH1>
                <SpacedH3>Fill in the details below to add your {isAsset ? 'asset' : 'income source'}.</SpacedH3>
                {warning && <GenericFormMessage type="error" messages={[warning]} />}
                {this.state.errors.length > 0 && <GenericFormMessage type="error" messages={this.state.errors} />}
                <img alt="coin" src={finance} />
                <Spacer height={30} />
                <AddFinancialSourceForm
                    isEditing
                    initialValues={this.initialValues}
                    financialType={isAsset ? FINANCIAL_STREAM_ASSET : FINANCIAL_STREAM_INCOME}
                    onSubmit={this.onSubmit}
                    setError={(err) => this.setErrors(err)}
                />
                <BackLink to={this.returnLink} />
            </>
        );
    }
}

EditFinancialSourcePage.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    leaseTransaction: PropTypes.object.isRequired,
    vgsEnabled: PropTypes.bool,
    getTransactionPath: PropTypes.func.isRequired,
    fetchApplicant: PropTypes.func.isRequired,
    logToSentry: PropTypes.func.isRequired,
};

EditFinancialSourcePage.route = ROUTES.EDIT_MANUAL_FINANCIAL_SOURCE;
EditFinancialSourcePage.contextType = BankingContext;

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    vgsEnabled: state.configuration.enable_encryption,
});

const mapDispatchToProps = { fetchApplicant };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(captureRoute(withTransactionPath(withSentryUtils(EditFinancialSourcePage))));
