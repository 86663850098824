import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ButtonContainer, CustomButton } from './styles';

export class ActionButton extends Component {
    render() {
        const {
            href,
            color,
            disabled,
            onClick,
            children,
            marginTop,
            marginBottom,
            variant,
            buttonClassName,
            type,
            ...rest
        } = this.props;

        return (
            <ButtonContainer
                target="_blank"
                href={href}
                as={href == null ? 'div' : 'a'}
                marginTop={marginTop}
                marginBottom={marginBottom}
                {...rest}
            >
                <CustomButton
                    onClick={onClick}
                    className={buttonClassName}
                    variant={variant}
                    color={color}
                    type={type}
                    disabled={disabled}
                    fullWidth
                >
                    {children}
                </CustomButton>
            </ButtonContainer>
        );
    }
}

ActionButton.propTypes = {
    color: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    variant: PropTypes.string,
    href: PropTypes.string,
    children: PropTypes.any,
    buttonClassName: PropTypes.string,
    type: PropTypes.string,
};

ActionButton.defaultProps = {
    variant: 'contained',
    color: 'primary',
    successGreen: false,
    type: 'submit',
};

export default ActionButton;
