import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { css } from 'emotion';
import { connect } from 'react-redux';

import { ROUTES } from 'constants/constants';
import {
    allValuesSet,
    atLeastOneNumberRegex,
    atLeastOneUpperCaseRegex,
    nameValidationRegex,
    phoneNumberValidationRegex,
} from 'utils/formik';
import { formContent, LinkButton } from 'assets/styles';
import { getShowMiddleNameSelector, getShowSuffixSelector } from 'selectors/launchDarkly';

import Checkbox from 'common-components/Checkbox/Checkbox';
import FormTextInput from 'common-components/FormTextInput/FormTextInput';
import PhoneNumberInput from 'common-components/PhoneNumberInput/PhoneNumberInput';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const linkContainer = css`
    text-align: left !important;
    margin-bottom: 50px;
    margin-top: 10px;
`;

const MAX_DATE = (() => {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 18);
    return d;
})();

const MIN_BIRTHDAY_YEAR = 1901;

export const validationSchema = (withPassword, showMiddleName) =>
    Yup.object().shape({
        first_name: Yup.string()
            .max(100, 'Exceeds 100 characters')
            .required('Legal first name is required')
            .matches(nameValidationRegex, 'Invalid name'),
        ...(showMiddleName && {
            middle_name: Yup.string()
                .max(100, 'Exceeds 100 characters')
                .nullable()
                .matches(nameValidationRegex, 'Invalid name'),
        }),
        last_name: Yup.string()
            .max(100, 'Exceeds 100 characters')
            .required('Legal last name is required')
            .matches(nameValidationRegex, 'Invalid name'),
        phone_number: Yup.string()
            .required('Phone number is required')
            .matches(phoneNumberValidationRegex, 'Must be a valid US phone number'),
        email: Yup.string().email('Enter a valid email').required('Email address is required'),
        date_of_birth: Yup.date()
            .typeError('Enter a valid date')
            .max(MAX_DATE, 'Must be 18 or older')
            .test('test-birthday-min-date', 'Invalid birthdate', (value) => {
                const date = moment(value, 'MM/dd/yyyy');
                return date.year() >= MIN_BIRTHDAY_YEAR;
            })
            .required('required'),
        ...(withPassword && {
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches(atLeastOneNumberRegex, 'Password must contain at least 1 number')
                .matches(atLeastOneUpperCaseRegex, 'Password must contain at least 1 uppercase letter')
                .required('Password must be at least 8 characters'),
        }),
        suffix: Yup.string().nullable(),
    });

const MemoizedFormTextInput = React.memo(({ ...rest }) => <FormTextInput {...rest} />);
const MemoizedPhoneNumberInput = React.memo(({ ...rest }) => <PhoneNumberInput {...rest} />);
const MemoizedKeyboardDatePicker = React.memo(({ ...rest }) => <KeyboardDatePicker {...rest} />);

export const AccountForm = ({
    initialValues,
    status,
    withPassword,
    showConsentInput,
    submitText,
    onSubmit,
    resetPassword,
    configuration,
    showMiddleName,
    showSuffix,
}) => {
    const validationSchemaMemo = useMemo(
        () => validationSchema(withPassword, showMiddleName),
        [withPassword, showMiddleName]
    );
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchemaMemo}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                handleChange,
                submitCount,
                handleBlur,
                handleSubmit,
                isSubmitting,
                touched,
                dirty,
                setFieldValue,
                setFieldTouched,
            }) => {
                return (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className={formContent}>
                            {status && <GenericFormMessage type={status.type} messages={status.detail} />}
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <MemoizedFormTextInput
                                        label="Legal first name"
                                        name="first_name"
                                        submitted={!!touched.first_name || !!values.first_name}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        error={errors.first_name}
                                        value={values.first_name}
                                        helperText={!!errors?.first_name ? errors?.first_name : null}
                                    />
                                </Grid>
                                {showMiddleName && (
                                    <Grid item xs={12}>
                                        <MemoizedFormTextInput
                                            label="Legal middle name (Required if applicable)"
                                            name="middle_name"
                                            submitted={submitCount > 0}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            error={submitCount > 0 && !!errors.middle_name}
                                            value={values.middle_name}
                                            helperText={submitCount > 0 ? errors?.middle_name : null}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={showSuffix ? 10 : 12}>
                                    <MemoizedFormTextInput
                                        label="Legal last name"
                                        name="last_name"
                                        submitted={!!touched.last_name || !!values.last_name}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        error={errors.last_name}
                                        value={values.last_name}
                                        helperText={!!errors?.last_name ? errors?.last_name : null}
                                    />
                                </Grid>
                                {showSuffix && (
                                    <Grid item xs={2}>
                                        <FormControl fullWidth data-testid="suffix-field">
                                            <InputLabel htmlFor="suffix-select">Suffix</InputLabel>
                                            <Select
                                                labelId="suffix-select-label"
                                                id="suffix-select"
                                                value={values.suffix || ''}
                                                onChange={(event) => {
                                                    const { value } = event.target;
                                                    setFieldValue('suffix', value === '' ? null : value);
                                                    setFieldTouched('suffix', true, true);
                                                }}
                                                inputProps={{
                                                    style: { textAlign: 'left' },
                                                }}
                                                label="Suffix"
                                                renderValue={(selected) => {
                                                    const item = configuration.applicant_suffix_choices.find(
                                                        (suffix) => suffix.key === selected
                                                    );
                                                    return (
                                                        <div style={{ textAlign: 'left', width: '100%' }}>
                                                            {item ? item.value : ''}
                                                        </div>
                                                    );
                                                }}
                                                displayEmpty
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {configuration?.applicant_suffix_choices?.map((suffix) => (
                                                    <MenuItem
                                                        key={suffix.key}
                                                        value={suffix.key}
                                                        style={{ textAlign: 'left' }}
                                                    >
                                                        {suffix.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <MemoizedFormTextInput
                                        label="Email address"
                                        name="email"
                                        submitted={submitCount > 0}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        error={errors.email}
                                        value={values.email}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MemoizedPhoneNumberInput
                                        label="Phone number"
                                        name="phone_number"
                                        submitted={submitCount > 0}
                                        value={values.phone_number}
                                        handleChange={(value) => setFieldValue('phone_number', value)}
                                        error={submitCount > 0 && !!errors.phone_number}
                                        helperText={submitCount > 0 ? errors.phone_number : null}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MemoizedKeyboardDatePicker
                                        id="birthday-picker"
                                        clearable
                                        disableFuture
                                        format="MM/dd/yyyy"
                                        placeholder="mm/dd/yyyy"
                                        label="Birthday"
                                        minDate={new Date(1901, 1, 1)}
                                        maxDate={MAX_DATE}
                                        error={submitCount > 0 && !!errors.date_of_birth}
                                        helperText={submitCount === 0 ? 'Must be 18 or older' : errors.date_of_birth} // preemptive helper text
                                        value={values.date_of_birth || null}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={(e) => setFieldValue('date_of_birth', e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {withPassword && (
                                        <MemoizedFormTextInput
                                            label="Password"
                                            name="password"
                                            type="password"
                                            submitted={submitCount > 0}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            error={errors.password}
                                            value={values.password}
                                            showValidationText
                                            touched={touched && touched.password}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            {showConsentInput && (
                                <Checkbox
                                    name="sms_opt_in"
                                    onChange={handleChange}
                                    checked={values.sms_opt_in}
                                    value={values.sms_opt_in}
                                    error={errors.sms_opt_in}
                                    label={
                                        <>
                                            By clicking this checkbox, you consent to receiving calls and texts on
                                            behalf of {configuration.community.company.name} via automatic dialing or
                                            other technology about apartment listings that may fit your needs. Your
                                            consent is not required to enter into a rental transaction or make any
                                            purchase. Reply STOP to cancel anytime.{' '}
                                            <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>
                                                Privacy Policy
                                            </Link>
                                        </>
                                    }
                                />
                            )}
                            {resetPassword && (
                                <div className={linkContainer}>
                                    <LinkButton onClick={resetPassword}>Change Password</LinkButton>
                                </div>
                            )}
                            <ActionButton
                                disabled={
                                    !dirty ||
                                    !allValuesSet(values, { exclude: ['sms_opt_in', 'middle_name', 'suffix'] }) ||
                                    isSubmitting
                                }
                                marginTop={20}
                                marginBottom={20}
                            >
                                {submitText}
                            </ActionButton>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

AccountForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    submitText: PropTypes.string.isRequired,
    status: PropTypes.object,
    withPassword: PropTypes.bool,
    showConsentInput: PropTypes.bool,
    resetPassword: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    configuration: PropTypes.object.isRequired,
    maxDate: PropTypes.object,
    showMiddleName: PropTypes.bool.isRequired,
    showSuffix: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    showMiddleName: getShowMiddleNameSelector(state),
    showSuffix: getShowSuffixSelector(state),
});

export default connect(mapStateToProps)(AccountForm);
