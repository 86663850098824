import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from 'emotion';

import { Heading3, SpacedH1 } from 'assets/styles';
import ActionButton from 'common-components/ActionButton/ActionButton';

const Header = styled.div`
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
`;

const body = css`
    color: #000000;
    padding: 0 20px 0 20px;
    text-align: left;
`;

const line = css`
    border-top: 1px solid #eeeeee;
`;

export const AddBankDetails = ({ handleClickBack, handleContinueClick }) => {
    return (
        <Fragment>
            <Header>
                <SpacedH1>Add Bank Account</SpacedH1>
            </Header>
            <div className={line} />
            <Heading3 align="left">
                We use Stripe, a payment processor, to link your bank account. Bank accounts require verification.
            </Heading3>
            <div className={body}>
                How verification works:
                <ol>
                    <li>Securely add your account details</li>
                    <li>Receive a deposit in your bank account</li>
                    <li>Confirm the 6-digit code</li>
                </ol>
                <br />
                The verification process usually takes 1-3 business days.
                <br />
                <br />
                Don’t worry, you’ll be able to remove this account later.
            </div>

            <ActionButton id="bank_details_continue" onClick={handleContinueClick} marginTop={25} marginBottom={20}>
                Got it, Continue
            </ActionButton>
            <ActionButton
                id="bank_details_cancel"
                onClick={handleClickBack}
                marginTop={25}
                marginBottom={20}
                variant="outlined"
                color="primary"
            >
                Cancel
            </ActionButton>
        </Fragment>
    );
};

AddBankDetails.propTypes = {
    handleClickBack: PropTypes.func.isRequired,
    handleContinueClick: PropTypes.func.isRequired,
};

export default AddBankDetails;
