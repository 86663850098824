import React, { useCallback, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

import { VERIFICATION_METHOD_PIN_CALL } from 'constants/residentIdVerification';
import withTransactionPath from 'utils/withTransactionPath';
import { useSnackbar } from 'common-components/Snackbar/SnackbarProvider';
import { useIdentityVerification } from '../IdentityVerificationContext';
import { ERROR_MESSAGE as REQUEST_CODE_ERROR_MESSAGE } from './OTPRequestPage';

import Page from 'common-components/Page/Page';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { LinkButton } from 'assets/styles';
import pageImageSrc from 'assets/new-icons/streamline-setup.svg';

export const ERROR_MESSAGE = 'Oops, we had trouble sending the code. Try again.';

const useStyles = makeStyles(() => ({
    pageImage: {
        width: '129px',
        height: '107px',
    },
}));

export const validationSchema = Yup.object().shape({
    code: Yup.string().max(5, 'Invalid code').matches(/^\d+$/, 'Only numbers are allowed'),
});

const initialValues = {
    code: '',
};

const getPinIncorrectError = (identityVerification, requestedNewPinAttempts) => {
    if (!identityVerification) return;
    if (identityVerification.answer_attempts - requestedNewPinAttempts > 0 && !identityVerification.is_completed) {
        return 'Incorrect code. Try again.';
    }
};

export function OTPVerifyPage() {
    const classes = useStyles();
    const [requestedNewPinAttempts, setRequestedNewPinAttempts] = useState(0);
    const { showSnackbar } = useSnackbar();
    const { identityVerification, applicant, error, didInitiate, evaluateQuestion } = useIdentityVerification();
    const pinIncorrectError = getPinIncorrectError(identityVerification, requestedNewPinAttempts);
    const pageNotification = error && {
        type: 'error',
        messages: error,
    };

    const requestPINByCall = identityVerification?.verification_method === VERIFICATION_METHOD_PIN_CALL;
    const phoneNumber = applicant?.phone_number;
    const phoneNumberLastFourDigits = phoneNumber?.substring(phoneNumber.length - 4, phoneNumber.length);
    const maskedPhoneNumber = `***-***-${phoneNumberLastFourDigits}`;
    const requestCodeAgainSnackbarMessage = requestPINByCall
        ? `Calling ${maskedPhoneNumber}.`
        : `Sending code to ${maskedPhoneNumber}.`;

    const handleSubmit = useCallback(
        async (values, { setSubmitting }) => {
            const question = identityVerification.questions[0];
            const answers = [
                {
                    question_key: question.key,
                    answer_key: values.code,
                },
            ];

            await evaluateQuestion({ answers, error: ERROR_MESSAGE });
            setSubmitting(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [identityVerification]
    );

    const handleRequestNewCodeClick = useCallback(async () => {
        const question = identityVerification.questions[0];
        const answers = [
            {
                question_key: question.key,
                answer_key: 'NEWPIN',
            },
        ];

        showSnackbar(requestCodeAgainSnackbarMessage);
        await evaluateQuestion({ answers, error: REQUEST_CODE_ERROR_MESSAGE });
        setRequestedNewPinAttempts(requestedNewPinAttempts + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identityVerification, requestCodeAgainSnackbarMessage, requestedNewPinAttempts]);

    if (!didInitiate) {
        return null;
    }

    return (
        <Page
            title="One-Time PIN"
            subTitle="Enter the 5 digit code."
            image={{
                src: pageImageSrc,
                className: classes.pageImage,
            }}
            notification={pageNotification}
        >
            <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
                {({ dirty, isSubmitting, errors, touched }) => {
                    const disableSubmit = !dirty || isSubmitting;

                    return (
                        <Form noValidate autoComplete="off">
                            <Field
                                fullWidth
                                margin="normal"
                                label="Verification Code:"
                                placeholder="Enter code"
                                name="code"
                                error={!!pinIncorrectError || (errors.code && touched.code)}
                                helperText={pinIncorrectError}
                                component={TextField}
                            />
                            <Typography variant="body1" className={classes.resendCode}>
                                Didn&apos;t get the code?{' '}
                                <LinkButton
                                    type="button"
                                    data-testid="resent-code-link"
                                    onClick={handleRequestNewCodeClick}
                                >
                                    Request code again
                                </LinkButton>
                            </Typography>
                            <ActionButton marginTop={30} disabled={disableSubmit} marginBottom={20}>
                                Submit
                            </ActionButton>
                        </Form>
                    );
                }}
            </Formik>
        </Page>
    );
}

export default withTransactionPath(OTPVerifyPage);
