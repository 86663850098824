import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';

import { blackLinkRoot, arrowIcon } from 'assets/styles';

export const BackLink = (props) => {
    return (
        <Link onClick={props.onClick} to={props.to} className={blackLinkRoot} style={props.style}>
            <ArrowBackIos classes={{ root: arrowIcon }} />
            Go Back
        </Link>
    );
};

BackLink.propTypes = {
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
    style: PropTypes.object,
    onClick: PropTypes.func,
};

BackLink.defaultProps = {
    to: '/',
};

// for the back buttons that are not links
export const BackButton = ({ text = 'Go Back', onClick = () => {} }) => {
    return (
        <span className={blackLinkRoot} onClick={onClick}>
            <ArrowBackIos classes={{ root: arrowIcon }} /> {text}
        </span>
    );
};

BackButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
};

export default BackLink;
