import { makeStyles } from '@material-ui/core';

export const useRenewalOptionStyles = makeStyles(() => ({
    row: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'left',

        // Override default CardRow styles defined in assets/styles/CardRow
        padding: '0 !important',
        borderBottom: 'none !important',
        '&:first-of-type': {
            padding: '0 0 15px 0 !important',
        },

        '&:last-of-type': {},
    },
    vacateRow: {
        height: '100%',
        alignItems: 'center',
    },
    bestOffer: {
        display: 'inline-block',
        marginLeft: '10px',
        borderRadius: '10px',
        background: '#eee',
        padding: '2px 15px',
        fontSize: '11px',
    },
    label: {
        fontSize: '12px',
    },
    value: {
        fontSize: '14px',
    },
    rentWrapper: {
        width: '120px',
    },
    perMonthLabel: {
        color: '#828796',
        fontSize: '12px',
        lineHeight: '12px',
    },
    rentLabel: {
        fontSize: '18px',
        lineHeight: '22px',
    },
    contentWrapper: {
        display: 'flex',
        width: '200px',
        justifyContent: 'space-between',
        fontSize: '12px',
    },
}));
