import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { ROUTES, FINANCIAL_STREAM_ASSET, ALL_INCOME_OR_ASSET_TYPES } from 'constants/constants';
import API from 'api/api';

import { prettyCurrency } from 'utils/misc';
import captureRoute from 'utils/captureRoute';
import withTransactionPath from 'utils/withTransactionPath';
import withSentryUtils from 'utils/withSentryUtils';

import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import ActionButton from 'common-components/ActionButton/ActionButton';
import BankingContext from 'pages/Banking/BankingContext';
import { P, SpacedH1, SpacedH6 } from 'assets/styles';

const Divider = styled.hr`
    border-style: none;
    border-bottom: 2px solid #eeeeee;
    margin-bottom: 22px;
`;

export class RemoveFinancialSourcePage extends React.Component {
    state = { errorSubmitting: false, financialSource: null, submitting: false };

    onSubmit = async () => {
        /* eslint-disable no-unused-expressions */
        this.context.toggleLoader?.(true);
        this.setState({ submitting: true });
        try {
            const leaseTransactionId = this.props.leaseTransaction.id;
            await API.deleteFinancialSource(leaseTransactionId, this.props.match.params.id);
            this.context.refreshFinancialSources?.();
            this.props.history.push(this.returnLink);
        } catch (e) {
            this.setState({ errorSubmitting: true });
            this.props.logToSentry(e, this.props.leaseTransaction);
        } finally {
            this.context.toggleLoader?.(false);
            this.setState({ submitting: false });
        }
    };
    async componentDidMount() {
        this.fetchFinancialSource();
    }
    async fetchFinancialSource() {
        try {
            const leaseTransactionId = this.props.leaseTransaction.id;
            const data = await API.getFinancialSource(leaseTransactionId, this.props.match.params.id);
            this.setState({ financialSource: data });
        } catch (e) {
            if (e.response?.status !== 404) {
                this.props.logToSentry(e);
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchFinancialSource();
        }
    }

    get isAsset() {
        return this.state.financialSource?.stream_type === FINANCIAL_STREAM_ASSET;
    }

    get returnLink() {
        return this.props.getTransactionPath(
            `${ROUTES.INCOME_VERIFICATION_SUMMARY}#${this.isAsset ? 'asset' : 'income'}`
        );
    }

    render() {
        const financialSource = this.state.financialSource;
        if (!financialSource) return null;
        const isAsset = financialSource.stream_type === FINANCIAL_STREAM_ASSET;
        return (
            <>
                <SpacedH1>Remove {isAsset ? 'Asset' : 'Income Source'}?</SpacedH1>
                <SpacedH6>
                    {ALL_INCOME_OR_ASSET_TYPES[financialSource.income_or_asset_type]?.label} -{' '}
                    {prettyCurrency(financialSource.estimated_amount)}
                    {isAsset ? '' : '/year'}
                </SpacedH6>
                <Divider />
                {this.state.errorSubmitting && (
                    <GenericFormMessage
                        type="error"
                        messages={[
                            'Oops! We had some trouble removing your financial source. Try again in a little bit.',
                        ]}
                    />
                )}
                <div className="text-left">
                    <Typography variant="body">
                        <strong>Are you sure you want to remove this {isAsset ? 'asset' : 'income source'}?</strong>
                    </Typography>
                    <br />
                    <br />
                    {!isAsset && (
                        <P>
                            Removing this income source means that all uploaded files associated with it will be deleted
                            and it will no longer count towards your total annual income.
                        </P>
                    )}
                    {isAsset && (
                        <P>
                            Removing this asset means that all uploaded files associated with it will be deleted and it
                            will no longer count towards your total asset balance.
                        </P>
                    )}
                </div>
                <ActionButton
                    disabled={this.state.submitting}
                    onClick={this.onSubmit}
                    marginBottom={20}
                    marginTop={100}
                >
                    Remove {isAsset ? 'Asset' : 'Income Source'}
                </ActionButton>
                <ActionButton
                    onClick={() => this.props.history.push(this.returnLink)}
                    variant="outlined"
                    marginBottom={20}
                >
                    Cancel
                </ActionButton>
            </>
        );
    }
}

RemoveFinancialSourcePage.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    leaseTransaction: PropTypes.object,
    getTransactionPath: PropTypes.func,
    logToSentry: PropTypes.func,
};

RemoveFinancialSourcePage.contextType = BankingContext;

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
});

export default connect(mapStateToProps)(captureRoute(withTransactionPath(withSentryUtils(RemoveFinancialSourcePage))));
