import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { css } from 'emotion';
import { isEmpty } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

import API from 'api/api';
import { ROLE_PRIMARY_APPLICANT, ROUTES } from 'constants/constants';
import { LEASE_TERMS_IDENTIFIER } from 'constants/pageComplete';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import withRelativeRoutes from 'utils/withRelativeRoutes';
import { serializeDate, parseDateISOString, prettyFormatPhoneNumber } from 'utils/misc';
import { getLeaseTermDatesLabels } from 'utils/leaseTransaction';
import { updateTransaction } from 'reducers/transaction';
import { pageComplete } from 'reducers/renter-profile';
import { actions as loaderActions } from 'reducers/loader';
import { getDisplayMonthlyPriceBreakdown, getMoveInDateInitBlank } from 'selectors/launchDarkly';

import Page from 'common-components/Page/Page';
import { PaymentDetailsCard } from 'common-components/PaymentDetails';
import PriceBreakdown from 'common-components/PriceBreakdown/PriceBreakdown';
import ActionButton from 'common-components/ActionButton/ActionButton';
import AvailableUnitsSelector from 'pages/LeaseTerms/components/AvailableUnitsSelector';
import AvailableLeaseTermsSelector from 'pages/LeaseTerms/components/AvailableLeaseTermsSelector';
import { leasingPricingDisclaimer } from 'assets/styles';
import rentImage from 'assets/new-icons/real-estate-sign-board.svg';

const gridContainer = css`
    padding: 20px 0 20px 0;
`;

function availabilityDateRange(unit, unitAvailabilityRange, futureUnitAvailability, maxAcceptedLeaseStartDate) {
    // the unit is either available today (meaning it became available at some point in the past, or today) or in the future
    const availabilityStartDate = moment.max(moment(), moment(unit.date_available));

    // Definition of `future` for the purposes of future availability:  future > today + unit_availability_range
    const availabilityStartDateInFuture = availabilityStartDate.isAfter(
        moment().add(unitAvailabilityRange - 1, 'days')
    );
    // Future availability is only considered when the future_unit_availability setting is available.
    // Both unit_availability_range and future_unit_availability include today, hence the -1
    const daysAvailable =
        availabilityStartDateInFuture && futureUnitAvailability
            ? futureUnitAvailability - 1
            : unitAvailabilityRange - 1;

    const availabilityEndDate = moment.min(
        moment(availabilityStartDate).add(daysAvailable, 'days'),
        maxAcceptedLeaseStartDate
    );
    return { availabilityStartDate, availabilityEndDate };
}

function isAvailableAtLeaseStartDate(
    unit,
    leaseStartDate,
    unitAvailabilityRange,
    futureUnitAvailability,
    maxAcceptedLeaseStartDate
) {
    const { availabilityStartDate, availabilityEndDate } = availabilityDateRange(
        unit,
        unitAvailabilityRange,
        futureUnitAvailability,
        maxAcceptedLeaseStartDate
    );
    return moment(leaseStartDate).isBetween(availabilityStartDate, availabilityEndDate, 'day', '[]');
}

/**
 * Some general notes for the lease terms page:
 *  - Only the primary applicant can submit the lease terms.
 *    For this reason, the form fields are disabled for non-primary applicants.
 *
 *  - It may happen that the lease terms have changed overtime and became invalid.
 *    As the form is disabled for non-primary applicants, they could get stuck on the page, not being able to continue.
 *    For this reason, we avoid assigning any validation to the form for non-primary applicants.
 */
export const LeaseTermsPage = ({
    config = null,
    leaseTransaction = null,
    applicant = null,
    isPrimaryApplicant = false,
    hasOutstandingBalance = false,
    moveInDateInitBlank = false,
    displayMonthlyPriceBreakdown = false,
    toggleLoader = () => {},
    updateTransaction = () => {},
    pageComplete = () => {},
    getNextRoute = () => {},
}) => {
    const { community } = config;
    const maxAcceptedLeaseStartDate = moment().add(community.accepted_lease_start_date_range, 'days');
    const [error, setError] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [minLeaseStartDate, setMinLeaseStartDate] = useState(null);
    const [maxLeaseStartDate, setMaxLeaseStartDate] = useState(null);
    const [units, setUnits] = useState([]);

    const contactPhone = prettyFormatPhoneNumber(community.contact_phone);
    const unitErrorMsg = `We're sorry, it looks like this unit is not available. Please select another unit, or call us at ${contactPhone} if you are having further issues.`;

    const termsAreSet = Boolean(
        leaseTransaction?.lease_start_date && leaseTransaction?.lease_term && leaseTransaction?.unit
    );

    const isValidDate = (date) => date && moment(date, 'MM/DD/YYYY', true).isValid();

    useEffect(() => {
        API.fetchAvailableUnits(leaseTransaction.id, serializeDate(maxAcceptedLeaseStartDate.toDate())).then((res) => {
            if (!res.length) return;
            // The available units returned by our request might not contain the leaseTransaction's unit if this unit has been marked as below-market-rate.
            // This may happen when an applicant was invited with a personalized link for a unit that is actually below-market-rate.
            // In that case, we manually add it to the list of available units.
            const alreadyIncluded =
                leaseTransaction.unit && res.findIndex((u) => u.id === leaseTransaction.unit.id) !== -1;
            setUnits(leaseTransaction.unit_available && !alreadyIncluded ? [...res, leaseTransaction.unit] : [...res]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaseTransaction]);

    useEffect(() => {
        if (leaseTransaction.unit) {
            const { availabilityStartDate, availabilityEndDate } = availabilityDateRange(
                leaseTransaction.unit,
                community.unit_availability_range,
                community.future_unit_availability,
                maxAcceptedLeaseStartDate
            );
            setMinLeaseStartDate(availabilityStartDate);
            setMaxLeaseStartDate(availabilityEndDate);
        } else {
            setMinLeaseStartDate(moment());
            setMaxLeaseStartDate(maxAcceptedLeaseStartDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaseTransaction]);

    useEffect(() => {
        const values = {
            lease_start_date: undefined,
            lease_term: leaseTransaction.lease_term || '',
            unit: leaseTransaction.unit,
        };
        if (leaseTransaction.unit) {
            // check if the selected date is before the availability range of the unit, and if it is then set the date to the min date for the unit.
            const leaseTransactionLeaseStartDate = parseDateISOString(leaseTransaction.lease_start_date);
            const { availabilityStartDate } = availabilityDateRange(
                leaseTransaction.unit,
                community.unit_availability_range,
                community.future_unit_availability,
                maxAcceptedLeaseStartDate
            );

            if (isValidDate(leaseTransactionLeaseStartDate)) {
                if (moment(leaseTransactionLeaseStartDate).isBefore(availabilityStartDate, 'day', '[]')) {
                    values.lease_start_date = availabilityStartDate;
                } else {
                    values.lease_start_date = leaseTransactionLeaseStartDate;
                }
            }
        } else {
            values.lease_start_date = moveInDateInitBlank ? null : moment().toDate();
        }

        setInitialValues(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaseTransaction]);

    const completePage = async () => {
        toggleLoader(true);
        await pageComplete(LEASE_TERMS_IDENTIFIER);
        await getNextRoute();
        toggleLoader(false);
    };

    const handleSubmit = async (values, formProps) => {
        const { setSubmitting, setErrors } = formProps;

        setError(null);
        toggleLoader(true);
        setSubmitting(true);

        if (!isPrimaryApplicant) {
            return;
        }

        try {
            const data = {
                lease_start_date: serializeDate(values.lease_start_date),
                unit_id: values.unit.id,
                lease_term: values.lease_term,
            };
            const moveInChanged = !moment(leaseTransaction?.lease_start_date || null).isSame(
                moment(data.lease_start_date),
                'date'
            );
            const termsChanged = leaseTransaction?.lease_term !== data.lease_term;
            const unitChanged = leaseTransaction?.unit?.id !== data?.unit_id;
            const valuesChanged = moveInChanged || termsChanged || unitChanged;

            if (!valuesChanged) {
                return;
            }

            const response = await updateTransaction(data);
            if (response.errors) {
                if (response.errors?.unit_id) {
                    setError(unitErrorMsg);
                }
                return setErrors(response.errors);
            }
        } catch {
            setError(GENERIC_ERROR_MESSAGE);
        } finally {
            setSubmitting(false);
            toggleLoader(false);
        }
    };

    // render nothing if there is no lease transaction
    if (!leaseTransaction || !initialValues) {
        return null;
    }

    const displayOnlyForReview = applicant.move_in_date && applicant?.role !== ROLE_PRIMARY_APPLICANT;

    const notifications = [];
    if (error) {
        notifications.push({
            type: 'error',
            messages: error,
        });
    }

    if (!hasOutstandingBalance) {
        notifications.push({
            type: 'error',
            messages: `Please call us at ${contactPhone} if you'd like to make any changes to your lease details.`,
        });
    }

    if (leaseTransaction?.original_client_quote && !applicant?.is_price_update_completed) {
        notifications.push({
            type: 'warning',
            messages:
                'Changes made to lease details may update pricing. Pricing is not guaranteed until you have submitted the required payments.',
        });
    }

    const handleMoveInValueChange = (value = null, formProps = {}) => {
        const { setFieldValue, setFieldError } = formProps;
        setFieldValue('unit', null);

        if (!value) {
            setFieldValue('lease_start_date', null);
        } else if (isValidDate(value)) {
            setFieldValue('lease_start_date', value);
        } else {
            setFieldError('lease_start_date', 'Invalid Date');
        }
    };

    const handleUnitValidChange = (value = null, formProps = {}) => {
        const { setFieldValue, setFieldTouched } = formProps;
        setFieldValue('unit', value);
        setFieldTouched('unit', true);

        setFieldValue('lease_term', null);

        // set min lease start date and max lease start date based on unit availability.
        if (!value) {
            setMinLeaseStartDate(moment());
            return setMaxLeaseStartDate(moment().add(community.accepted_lease_start_date_range, 'days'));
        } else {
            const { availabilityStartDate, availabilityEndDate } = availabilityDateRange(
                value,
                community.unit_availability_range,
                community.future_unit_availability,
                maxAcceptedLeaseStartDate
            );
            setMinLeaseStartDate(availabilityStartDate);
            setMaxLeaseStartDate(availabilityEndDate);
        }
    };

    const handleTermsValueChange =
        (formProps = {}) =>
        async (e) => {
            const { setFieldValue, setFieldTouched, submitForm } = formProps;
            const value = e?.target?.value || null;

            await setFieldValue('lease_term', value);
            await setFieldTouched('lease_term', true);
            displayMonthlyPriceBreakdown && (await submitForm());
        };

    return (
        <Page
            title="Lease Terms"
            subTitle={
                isPrimaryApplicant
                    ? 'Please select from the options below to move forward.'
                    : 'Please confirm the lease details for your application.'
            }
            notifications={notifications}
            image={{ src: rentImage }}
        >
            <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                {(formProps) => {
                    const { values, errors, isSubmitting, submitCount, submitForm } = formProps;

                    const availableUnits = values?.lease_start_date
                        ? units.filter((u) =>
                              isAvailableAtLeaseStartDate(
                                  u,
                                  moment(values.lease_start_date),
                                  community.unit_availability_range,
                                  community.future_unit_availability,
                                  maxAcceptedLeaseStartDate
                              )
                          )
                        : units;

                    const showPaymentBreakdown =
                        values.unit && values.lease_start_date && values.lease_term && !errors.lease_start_date;

                    const disableSubmit =
                        !values.lease_start_date ||
                        !values.unit ||
                        !values.lease_term ||
                        isSubmitting ||
                        !isEmpty(error) ||
                        !isEmpty(errors);

                    return (
                        <Form className="text-left" autoComplete="off">
                            <div className={gridContainer}>
                                {!displayOnlyForReview ? (
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <KeyboardDatePicker
                                                id="move-in-date"
                                                clearable
                                                disablePast
                                                format="MM/dd/yyyy"
                                                placeholder="mm/dd/yyyy"
                                                label="Move In Date"
                                                value={values.lease_start_date || null}
                                                fullWidth
                                                disabled={!isPrimaryApplicant || !hasOutstandingBalance}
                                                onBlur={(e) => {
                                                    handleMoveInValueChange(e?.target?.value || null, formProps);
                                                }}
                                                onChange={(moveInDate) => {
                                                    handleMoveInValueChange(moveInDate, formProps);
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                error={errors.lease_start_date}
                                                helperText={errors.lease_start_date}
                                                minDate={minLeaseStartDate}
                                                maxDate={maxLeaseStartDate}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AvailableUnitsSelector
                                                value={values.unit}
                                                disabled={!isPrimaryApplicant || !hasOutstandingBalance}
                                                error={submitCount >= 1 && !!errors.unit}
                                                helperText={submitCount >= 1 && errors.unit}
                                                availableUnits={availableUnits}
                                                onChange={(unit) => handleUnitValidChange(unit, formProps)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AvailableLeaseTermsSelector
                                                unitId={values.unit?.id}
                                                leaseTerm={values.lease_term}
                                                handleChange={handleTermsValueChange(formProps)}
                                                disabled={!isPrimaryApplicant || !hasOutstandingBalance}
                                                leaseStartDate={
                                                    values.lease_start_date
                                                        ? moment(values.lease_start_date).toDate()
                                                        : null
                                                }
                                                leaseTransaction={leaseTransaction}
                                                config={config}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <KeyboardDatePicker
                                                id="move-in-date"
                                                clearable
                                                disablePast
                                                format="MM/dd/yyyy"
                                                placeholder="mm/dd/yyyy"
                                                label="Move in date"
                                                value={applicant.move_in_date ? moment(applicant.move_in_date) : null}
                                                fullWidth
                                                disabled={true}
                                                onChange={() => {}}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AvailableUnitsSelector
                                                value={leaseTransaction.unit}
                                                disabled={true}
                                                leaseTransaction={leaseTransaction}
                                                // TODO: we need to load the selected unit for the view only mode.
                                                availableUnits={[]}
                                                onChange={() => {}}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor="lease-term">Lease Term</InputLabel>
                                            <Select
                                                open={false}
                                                fullWidth
                                                value={1}
                                                onChange={() => {}}
                                                disabled={true}
                                            >
                                                <MenuItem value={1}>
                                                    {getLeaseTermDatesLabels({ leaseTransaction, applicant })}
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                            {showPaymentBreakdown && (
                                <div style={{ marginTop: 52 }}>
                                    {displayMonthlyPriceBreakdown && termsAreSet ? (
                                        <PaymentDetailsCard
                                            title="Payments Breakdown"
                                            subtitle="Your monthly rent may update as you add rental options in the next steps."
                                            selectedOptions={values}
                                            onError={() => setError(GENERIC_ERROR_MESSAGE)}
                                        />
                                    ) : (
                                        !displayMonthlyPriceBreakdown && (
                                            <PriceBreakdown
                                                selectedOptions={{}}
                                                category="lease_terms"
                                                leaseTransaction={leaseTransaction}
                                                unitId={values.unit.id}
                                                moveInDate={values.lease_start_date}
                                                leaseTerm={values.lease_term}
                                                onError={() => setError(GENERIC_ERROR_MESSAGE)}
                                                onSuccess={() => setError('')}
                                            />
                                        )
                                    )}
                                    <div className={leasingPricingDisclaimer}>{config.leasing_pricing_disclaimer}</div>
                                </div>
                            )}
                            <ActionButton
                                type="button"
                                disabled={disableSubmit}
                                marginTop={30}
                                marginBottom={20}
                                onClick={async () => {
                                    await submitForm();
                                    await completePage();
                                }}
                            >
                                Continue
                            </ActionButton>
                        </Form>
                    );
                }}
            </Formik>
        </Page>
    );
};

LeaseTermsPage.propTypes = {
    config: PropTypes.object,
    leaseTransaction: PropTypes.object,
    applicant: PropTypes.object,
    isPrimaryApplicant: PropTypes.bool,
    hasOutstandingBalance: PropTypes.bool,
    displayMonthlyPriceBreakdown: PropTypes.bool,
    pageComplete: PropTypes.func,
    toggleLoader: PropTypes.func,
    updateTransaction: PropTypes.func,
    getNextRoute: PropTypes.func,
    moveInDateInitBlank: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    isPrimaryApplicant: state.applicant?.role === ROLE_PRIMARY_APPLICANT,
    hasOutstandingBalance: state.transaction?.outstanding_balances?.length > 0,
    leaseTransaction: state.transaction,
    config: state.configuration,
    moveInDateInitBlank: getMoveInDateInitBlank(state),
    displayMonthlyPriceBreakdown: getDisplayMonthlyPriceBreakdown(state),
});

const mapDispatchToProps = {
    updateTransaction,
    pageComplete,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRelativeRoutes(LeaseTermsPage, ROUTES.LEASE_TERMS));
