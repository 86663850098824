import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { useDarkMode } from 'utils/darkMode';
import { selectors as configSelectors } from 'reducers/configuration';
import { SpacedH1, SpacedH3 } from 'assets/styles';
import Img404 from 'assets/new-icons/app-window-disabled.svg';
import { createTheme } from 'assets/theme';

const centerAlign = css`
    text-align: center;
    margin-top: 200px;
`;

// MUI is breaking on things like NotFoundPage because there's no theme
// provided. We fetch theme with fetchConfiguration `reducers/configuration` on `Main.jsx`.
// We still want some theme to exist (for fonts etc), but since it's a 404 page and we need it
// to load quick, we provide a super bare-bones theme using user's "dark-mode" OS prefernce
function NotFoundPage({ theme }) {
    const dark_mode = useDarkMode();
    const normalizedTheme = theme ?? createTheme({ dark_mode });

    return (
        <ThemeProvider theme={normalizedTheme}>
            <div className={centerAlign}>
                <SpacedH1>Page Not Found</SpacedH1>
                <SpacedH3>The requested URL was not found on this server.</SpacedH3>
                <img alt="404 page" src={Img404} height={150} />
            </div>
        </ThemeProvider>
    );
}

NotFoundPage.propTypes = {
    theme: PropTypes.object,
};

const mapStateToProps = (state) => ({
    theme: configSelectors.selectTheme(state),
});

export default connect(mapStateToProps)(NotFoundPage);
