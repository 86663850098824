import React from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

import ActionButton from 'common-components/ActionButton/ActionButton';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';

const validationSchema = Yup.object().shape({
    is_active_servicemember: Yup.boolean().nullable().required('This field is required'),
    servicemember_branch: Yup.string().when('is_active_servicemember', {
        is: true,
        then: Yup.string()
            .required('Service branch is required')
            .max(35, 'Service branch must be 35 characters or less'),
    }),
    servicemember_name: Yup.string().when('is_active_servicemember', {
        is: true,
        then: Yup.string()
            .required('Service member name is required')
            .max(35, 'Service member name must be 35 characters or less'),
    }),
    servicemember_unit: Yup.string().when('is_active_servicemember', {
        is: true,
        then: Yup.string()
            .required('Service member unit is required')
            .max(35, 'Service member unit must be 35 characters or less'),
    }),
    servicemember_identification_number: Yup.string().when('is_active_servicemember', {
        is: true,
        then: Yup.string()
            .required('Service member ID number is required')
            .max(35, 'Service member ID number must be 35 characters or less'),
    }),
});

export const MilitaryStatusForm = ({ applicant, errors, onSubmit }) => {
    return (
        <Formik
            initialValues={{
                is_active_servicemember: applicant.is_active_servicemember || false,
                servicemember_name: applicant.servicemember_name || '',
                servicemember_unit: applicant.servicemember_unit || '',
                servicemember_branch: applicant.servicemember_branch || '',
                servicemember_identification_number: applicant.servicemember_identification_number || '',
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ values, isSubmitting, isValid, isValidating, setFieldValue, resetForm }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <FormHelperText id="service-member" style={{ fontSize: '14px', color: '#1C1C1C' }}>
                                    Are you an active service member?
                                </FormHelperText>
                                <Field name="is_active_servicemember">
                                    {({ field }) => (
                                        <RadioGroup
                                            row
                                            aria-label="service-member"
                                            value={field.value !== null ? field.value.toString() : ''}
                                            onChange={async (event) => {
                                                const value = event.target.value === 'true';
                                                setFieldValue('is_active_servicemember', value);
                                                if (!value) {
                                                    resetForm({
                                                        values: {
                                                            ...values,
                                                            is_active_servicemember: false,
                                                            servicemember_name: '',
                                                            servicemember_unit: '',
                                                            servicemember_branch: '',
                                                            servicemember_identification_number: '',
                                                        },
                                                    });
                                                }
                                            }}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                </Field>
                            </FormControl>
                        </Grid>
                        {values.is_active_servicemember && (
                            <>
                                <Grid item xs={12}>
                                    <Field
                                        name="servicemember_branch"
                                        label="Service Branch Name"
                                        component={TextField}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="servicemember_name"
                                        label="Servicemember Name"
                                        component={TextField}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="servicemember_unit"
                                        label="Servicemember Unit"
                                        component={TextField}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="servicemember_identification_number"
                                        label="Service Identification Number"
                                        component={TextField}
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <GenericFormMessage type="error" messages={errors} />
                    <ActionButton
                        marginTop={50}
                        disabled={values.is_active_servicemember === null || isValidating || isSubmitting || !isValid}
                    >
                        Continue
                    </ActionButton>
                </Form>
            )}
        </Formik>
    );
};

MilitaryStatusForm.propTypes = {
    applicant: PropTypes.object,
    errors: PropTypes.array,
    onSubmit: PropTypes.func,
};

export default MilitaryStatusForm;
