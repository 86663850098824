import * as sentryUtils from 'utils/sentry';

export const initTrustedAPI = (key) => {
    return new Promise((resolve) => {
        if (!window.TrustevV2) {
            resolve();
        }

        window.TrustevV2.Init(key, (sessionId) => {
            resolve(sessionId);

            if (!sessionId) {
                const error = new Error('Error initiating TrustevV2 for Resident ID: Session ID not found');
                sentryUtils.logToSentry(error);
            }
        });
    });
};

export const getSessionId = () => {
    return window.TrustevV2?.SessionId;
};
