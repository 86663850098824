import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { prettyCurrency } from 'utils/misc';

import PaidText from 'common-components/PaidText/PaidText';
import { styles } from 'common-components/PaymentDetails/styles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'block',
        padding: 0,
    },
}));

export function PaymentItemsExpansionPanel({
    children = null,
    label = null,
    amount = null,
    amountSuffix = null,
    defaultExpanded = false,
    isPaid = false,
    noSectionDividers = false,
}) {
    const classes = useStyles();

    const amountLabel = useMemo(() => {
        if (amount < 0) {
            return `-${prettyCurrency(Math.abs(amount))}`;
        }

        let amountText = `$${amount}`;
        if (amountSuffix) {
            amountText += amountSuffix;
        }

        return amountText;
    }, [amount, amountSuffix]);

    return (
        <div className={styles.itemsContainer} data-testid="payment-breakdown-section">
            <ExpansionPanel elevation={0} defaultExpanded={defaultExpanded}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                        <b>{amountLabel}</b>&nbsp;{label}
                    </div>
                    {isPaid && (
                        <div className={styles.paidText}>
                            <PaidText />
                        </div>
                    )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.root }}>
                    {React.Children.map(React.Children.toArray(children), (child, i) => {
                        const noBorder = noSectionDividers && i !== 0;
                        return <div className={noBorder ? styles.itemRowNoBorder : styles.itemRow}>{child}</div>;
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}

PaymentItemsExpansionPanel.propTypes = {
    children: PropTypes.any,
    label: PropTypes.string,
    amount: PropTypes.string,
    amountSuffix: PropTypes.string,
    defaultExpanded: PropTypes.bool,
    isPaid: PropTypes.bool,
    noSectionDividers: PropTypes.bool,
};

export default PaymentItemsExpansionPanel;
