import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import pluralize from 'pluralize';
import { Typography } from '@material-ui/core';

import API from 'api/api';
import { ROUTES } from 'constants/constants';
import { LEASE_TRANSACTION_TYPE_APPLICATION } from 'constants/leaseTransaction';
import { getLeaseTermDatesLabels, getShowFees } from 'utils/leaseTransaction';
import { serializeDate } from 'utils/misc';
import withTransactionPath from 'utils/withTransactionPath';
import { getPaymentBreakdownFeesDueAtMoveInLabel } from 'utils/paymentBreakdown';

import { selectors as renterProfileSelectors } from 'reducers/renter-profile';
import { fetchPayments } from 'reducers/payments';
import { fetchPaymentBreakdown } from 'reducers/paymentBreakdown';
import { getDisplayMonthlyPriceBreakdown } from 'selectors/launchDarkly';
import {
    PaymentDetailsSavingsRow,
    PaymentItemsExpansionPanel,
    PaymentDetailsRows,
} from 'common-components/PaymentDetails';
import { styles, StyledComponents } from './styles';
import { TextLink } from 'assets/styles';
import lightBulbImg from 'assets/new-icons/light-bulb-shine-streamlined.svg';

export const PaymentDetailsCard = ({
    applicant = {},
    leaseTransaction = {},
    selectedRentalOptions = {},
    leaseTransactionFees = {},
    paymentBreakdown = {},
    title = 'Payment breakdown',
    subtitle = null,
    category = null,
    categoryHelperText = null,
    isForRentalOptions = false,
    showViewMoreMonths = false,
    fetchPayments = () => {},
    fetchPaymentBreakdown = () => {},
    onError = () => {},
    getTransactionPath = () => {},
}) => {
    const [priceBreakdown, setPriceBreakdown] = useState(null);
    const { data: paymentData } = paymentBreakdown;
    const chargesUrl = getTransactionPath(ROUTES.CHARGES);

    const fetchQuote = async () => {
        if (!leaseTransaction.lease_start_date || !moment(leaseTransaction.lease_start_date).isValid()) {
            return;
        }

        const body = {
            transaction_id: leaseTransaction.id,
            rental_options: selectedRentalOptions,
            unit_id: leaseTransaction.unit.id,
            lease_term: leaseTransaction.lease_term,
            move_in_date: serializeDate(leaseTransaction.lease_start_date),
        };

        try {
            const result = await API.getCurrentFlatQuote(leaseTransaction.id, body);
            if (result.errors) {
                return;
            }

            if (result.total === '$0' || result.base_rent === '$0') {
                return;
            }

            setPriceBreakdown(result);
        } catch (e) {
            onError(e);
        }
    };

    useEffect(() => {
        fetchPayments();
        fetchPaymentBreakdown();
    }, [
        fetchPayments,
        fetchPaymentBreakdown,

        // Note: reload when either of these change!
        leaseTransaction.selected_rental_options,
        leaseTransaction.lease_start_date,
        leaseTransaction.lease_term,
        leaseTransaction.unit_id,
    ]);

    useEffect(() => {
        if (paymentBreakdown?.error) {
            onError(paymentBreakdown.error);
        }
    }, [paymentBreakdown, onError]);

    useEffect(() => {
        if (isForRentalOptions) {
            fetchQuote();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isForRentalOptions]);

    if (!paymentData) return null;

    const leaseStartDate = moment(leaseTransaction.lease_start_date).format('LL');
    const showApplicationFees = getShowFees(leaseTransactionFees);
    const showMonthlyFees = getShowFees(paymentData.monthly_fees_no_prorate);
    const showSavings = getShowFees(paymentData.savings);
    const showCurrentMonthDeposits = getShowFees(paymentData.deposits);
    const showCurrentMonthOneTimeFees = getShowFees(paymentData.one_time_fees);
    const showCurrentMonthMonthlyFees = getShowFees(paymentData.monthly_fees);

    const getCurrentCategoryInfo = () => {
        const categoryCount = Object.values(selectedRentalOptions).reduce((a, b) => a + b.quantity, 0);
        let categoryMonthlyPrice = priceBreakdown?.items_breakdown?.[category];
        let categoryInfo = 'Your monthly rent may update as you add rental options in the next steps.';

        if (categoryMonthlyPrice != null) {
            if (!categoryMonthlyPrice) categoryMonthlyPrice = '$0';
            categoryInfo = `${categoryMonthlyPrice}/mo for ${categoryCount} ${pluralize(
                categoryHelperText,
                categoryCount
            )}`;
        }

        return <>{categoryInfo}</>;
    };

    return (
        <StyledComponents.PaymentCard>
            <div className={styles.headerContainer}>
                <div className={styles.imageWrapper}>
                    <img alt="coin" src={lightBulbImg} />
                </div>
                <div className={styles.cardHeader}>
                    <Typography variant="subtitle1" gutterBottom>
                        {title}
                    </Typography>
                    {subtitle ? (
                        <Typography variant="body1">{subtitle}</Typography>
                    ) : isForRentalOptions ? (
                        <Typography variant="body1">{getCurrentCategoryInfo()}</Typography>
                    ) : leaseTransaction.type === LEASE_TRANSACTION_TYPE_APPLICATION ? (
                        <>
                            <Typography variant="body1">Move in date: {leaseStartDate}</Typography>
                            <Typography variant="body1">Lease term: {leaseTransaction.lease_term} months</Typography>
                        </>
                    ) : (
                        <Typography variant="body1">
                            Lease term: {getLeaseTermDatesLabels({ leaseTransaction, applicant })}
                        </Typography>
                    )}
                </div>
            </div>
            {showApplicationFees && (
                <PaymentItemsExpansionPanel
                    label="due at application"
                    amount={leaseTransactionFees.total}
                    defaultExpanded={false}
                    isPaid={leaseTransactionFees.allPaid}
                >
                    <PaymentDetailsRows paymentObject={leaseTransactionFees} paymentType="application" />
                </PaymentItemsExpansionPanel>
            )}
            <PaymentItemsExpansionPanel
                label={getPaymentBreakdownFeesDueAtMoveInLabel(paymentData, leaseTransaction)}
                amount={paymentData.total_fees_per_month}
                noSectionDividers
            >
                {showCurrentMonthDeposits && (
                    <PaymentDetailsRows
                        label="Deposits"
                        paymentType="deposits"
                        paymentObject={paymentData.deposits}
                        showTotal={false}
                        rowStyle={{ fontSize: 12 }}
                    />
                )}
                {showCurrentMonthOneTimeFees && (
                    <PaymentDetailsRows
                        label="Fees"
                        paymentType="one_time_fees"
                        paymentObject={paymentData.one_time_fees}
                        showTotal={false}
                        rowStyle={{ fontSize: 12 }}
                    />
                )}
                {showCurrentMonthMonthlyFees && (
                    <PaymentDetailsRows
                        label="Monthly Rent"
                        paymentType="monthly_fees"
                        paymentObject={paymentData.monthly_fees}
                        showTotal={false}
                        rowStyle={{ fontSize: 12 }}
                    />
                )}
                <div className={styles.paymentDetailRowTotal} data-testid="payment-breakdown-month-fees-section-total">
                    <div>Total</div>
                    <div>${paymentData.total_fees_per_month}</div>
                </div>
            </PaymentItemsExpansionPanel>
            {showMonthlyFees && (
                <PaymentItemsExpansionPanel
                    amount={paymentData.monthly_fees_no_prorate.total}
                    amountSuffix="*"
                    label="monthly rent"
                    defaultExpanded={false}
                >
                    <PaymentDetailsRows
                        disclaimer="*This amount reflects the base rent and recurring charges, without prorated adjustments. Deposits and one-time fees aren’t included."
                        paymentObject={paymentData.monthly_fees_no_prorate}
                        paymentType="monthly_fees_no_prorate"
                    />
                </PaymentItemsExpansionPanel>
            )}
            {showSavings && (
                <PaymentItemsExpansionPanel amount={paymentData.savings.total} label="savings" defaultExpanded={false}>
                    <PaymentDetailsSavingsRow paymentObject={paymentData.savings} paymentType="savings" />
                </PaymentItemsExpansionPanel>
            )}
            {showViewMoreMonths && (
                <TextLink to={chargesUrl} variant="link2" styles={{ marginTop: 16, display: 'block' }}>
                    View Breakdown by Month
                </TextLink>
            )}
        </StyledComponents.PaymentCard>
    );
};

PaymentDetailsCard.propTypes = {
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    selectedRentalOptions: PropTypes.object,
    leaseTransactionFees: PropTypes.object,
    paymentBreakdown: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    category: PropTypes.string,
    categoryHelperText: PropTypes.string,
    isForRentalOptions: PropTypes.bool,
    showViewMoreMonths: PropTypes.bool,
    fetchPayments: PropTypes.func,
    fetchPaymentBreakdown: PropTypes.func,
    onError: PropTypes.func,
    getTransactionPath: PropTypes.func,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    applicant: state.applicant,
    leaseTransactionFees: renterProfileSelectors.leaseTransactionFees(state),
    paymentBreakdown: state.paymentBreakdown,
    showViewMoreMonths: getDisplayMonthlyPriceBreakdown(state),
});

const mapActionsToProps = {
    fetchPayments,
    fetchPaymentBreakdown,
};

export default connect(mapStateToProps, mapActionsToProps)(withTransactionPath(PaymentDetailsCard));
