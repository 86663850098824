import { useCallback, useEffect, useState } from 'react';

import API from 'api/api';
import usePrevious from 'hooks/usePrevious';
import { useMayorLoader } from 'common-components/MayorLoader/MayorLoaderProvider';

export default function useLeases(errorMessage) {
    const { toggleLoader } = useMayorLoader();
    const [loading, setIsLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const prevLoading = usePrevious(loading);

    const getLeaseTransactions = useCallback(async () => {
        setIsLoading(true);

        try {
            const leases = await API.getLeases();
            setData(leases);
        } catch {
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        getLeaseTransactions();
    }, [getLeaseTransactions]);

    useEffect(() => {
        toggleLoader(loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (loading === false && prevLoading === true) {
            setFinished(true);
        }
    }, [loading, prevLoading]);

    return {
        loading,
        error,
        finished,
        data,
    };
}
