import React from 'react';
import PropTypes from 'prop-types';
import deprecatedPropType from 'deprecated-prop-type';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { SpacedH1, SpacedH6 } from 'assets/styles';

import Notification from 'common-components/GenericFormMessage/GenericFormMessage';

const useStyles = makeStyles((theme) => {
    return {
        header: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
        },
        title: {
            color: '#000',
        },
        subTitle: {
            color: '#454b57',
        },
        notification: {
            width: '100%',
            padding: 0,
            marginBottom: theme.spacing(3),
            textAlign: 'left',
        },
        image: {
            marginBottom: theme.spacing(3),
            width: 100,
            height: 100,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        content: {},
    };
});

export function Page({
    className,
    title,
    subTitle,
    notification = {
        messages: undefined,
        type: undefined,
    },
    image = {
        src: undefined,
        className: undefined,
    },
    notifications = [],
    loading = false,
    children,
}) {
    const classes = useStyles();

    return (
        <div className={className}>
            <div className={classes.header}>
                {title && (
                    <SpacedH1 className={classes.title} align="center">
                        {title}
                    </SpacedH1>
                )}
                {subTitle && (
                    <SpacedH6 className={classes.subTitle} align="center">
                        {subTitle}
                    </SpacedH6>
                )}
                {notification?.type && notification?.messages && (
                    <Notification
                        className={classes.notification}
                        type={notification.type}
                        messages={notification.messages}
                    />
                )}
                {notifications.map((n, i) => (
                    <Notification key={i} className={classes.notification} {...n} />
                ))}
                {image?.src && (
                    <div
                        className={clsx(classes.image, { [image.className]: image.className })}
                        style={{
                            backgroundImage: `url(${image.src})`,
                        }}
                    />
                )}
            </div>
            {!loading && <div className={classes.content}>{children}</div>}
        </div>
    );
}

Page.propTypes = {
    className: PropTypes.string,
    title: PropTypes.any,
    subTitle: PropTypes.any,
    notification: deprecatedPropType(PropTypes.object, 'Deprecated in favour of notifications'),
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
            type: PropTypes.oneOf(['success', 'error', 'warning']),
        })
    ),
    image: PropTypes.shape({
        src: PropTypes.any,
        className: PropTypes.string,
    }),
    loading: PropTypes.bool,
    children: PropTypes.node,
};

export default Page;
