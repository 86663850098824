import ldRedux from 'ld-redux';

import store from 'reducers/store';

const flags = {
    'ol-show-automated-address-form': true,
    'ol-show-middle-name-11232022': false,
    'ol-suffix-field-04092024': false,
    'ol-sms-password-reset-12222022': false,
    'ol-billing-zip-code-on-applicant-payment-02172023': false,
    'ol-unit-availability-visibility-for-applicant-experience-02072023': false,
    'OL-prevent-renewal-decisions-when-there-are-open-transactions': false,
    'ol-display-deposits-and-fees-during-application': false,
};

const getLDConfig = (company) => {
    const { enable_launch_darkly_proxy_relay_woodhouse } = company;

    const commonConfig = {
        clientSideId: process.env.REACT_APP_LD_CLIENT_KEY,
        dispatch: store.dispatch,
        flags,
        user: {
            key: `${company.id} - ${company.name}`,
            custom: {
                source: 'OnlineLeasing',
                company_id: company.id,
                company_name: company.name,
            },
        },
    };

    if (enable_launch_darkly_proxy_relay_woodhouse) {
        return {
            ...commonConfig,
            options: {
                baseUrl: process.env.REACT_APP_LD_PROXY_RELAY_URL,
                streamUrl: process.env.REACT_APP_LD_PROXY_RELAY_URL,
                eventsUrl: process.env.REACT_APP_LD_PROXY_RELAY_URL,
            },
        };
    }

    return commonConfig;
};

export const initLaunchDarkly = (company = {}) => {
    if (!process.env.REACT_APP_LD_CLIENT_KEY) {
        return;
    }

    const ldConfig = getLDConfig(company);
    ldRedux.init(ldConfig);
};
