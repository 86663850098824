import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ActionButton from 'common-components/ActionButton/ActionButton';
import { H1 } from 'assets/styles';
import { Contact } from './PetRestrictions';

export const Subtitle = styled.small`
    color: #818797;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
`;

const Header = styled.div`
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
`;

const Policy = styled.div`
    margin-top: 30px;
    white-space: pre-line;
    margin-bottom: 36px;
    text-align: left;
`;

export default function PetPolicy(props) {
    return (
        <div>
            <Header>
                <H1>{props.title}</H1>
                <br />
                {!!props.date && <Subtitle>Last Updated {props.date}</Subtitle>}
            </Header>
            <Policy>{props.policy}</Policy>
            {props.contactPhone && (
                <Contact>
                    Please call us at {<a href={`tel:${props.contactPhone}`}>{props.contactPhone}</a>} if you have any
                    questions.
                </Contact>
            )}
            <ActionButton onClick={props.onAgree}>Got It</ActionButton>
        </div>
    );
}

PetPolicy.propTypes = {
    date: PropTypes.string,
    policy: PropTypes.string,
    onAgree: PropTypes.func.isRequired,
    title: PropTypes.string,
    contactPhone: PropTypes.string,
};
