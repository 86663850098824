import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import castArray from 'lodash/castArray';
import { CheckCircle as SuccessIcon, Warning as WarningIcon, Error as ErrorIcon } from '@material-ui/icons';
import clsx from 'clsx';

const errorContainer = css`
    padding: 20px 0;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
`;

const darkGreen = css`
    background-color: #67c18b;
`;
const darkRed = css`
    background-color: #fb6d68;
`;
const darkYellow = css`
    background-color: #fac700;
`;
const lightRed = css`
    background-color: #fef0ef;
`;
const lightGreen = css`
    background-color: rgba(103, 193, 139, 0.1);
`;
const lighYellow = css`
    background-color: rgba(250, 199, 0, 0.1);
`;

const iconDiv = css`
    padding: 5px;
    border-radius: 3px 0 0 3px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const messageDiv = css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    padding: 15px 20px;
    border-radius: 3px;
`;

const getIcon = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
        case 'success':
            return SuccessIcon;
        case 'warning':
            return WarningIcon;
        case 'error':
            return ErrorIcon;
    }
};

const getIconClass = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
        case 'success':
            return darkGreen;
        case 'warning':
            return darkYellow;
        case 'error':
            return darkRed;
    }
};

const getMessageClass = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
        case 'success':
            return lightGreen;
        case 'warning':
            return lighYellow;
        case 'error':
            return lightRed;
    }
};

export default function GenericFormMessage(props) {
    if (!props.messages) return null;
    const Icon = getIcon(props.type);
    const iconClass = getIconClass(props.type);
    const messageClass = getMessageClass(props.type);

    return (
        <div className={clsx(errorContainer, { [props.className]: props.className })}>
            <div className={clsx([iconDiv, iconClass])}>
                {Icon && <Icon style={{ color: 'white', width: '18px' }} />}
            </div>
            <div className={clsx([messageDiv, messageClass])}>
                {castArray(props.messages).map((error) => {
                    return (
                        <Fragment key={error}>
                            {error} <br />
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
}

GenericFormMessage.defaultProps = {
    type: 'success',
};

GenericFormMessage.propTypes = {
    className: PropTypes.string,
    messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    type: PropTypes.oneOf(['success', 'error', 'warning']),
};
