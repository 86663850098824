import React from 'react';
import PropTypes from 'prop-types';

import {
    LEASE_TRANSACTION_TYPE_APPLICATION,
    LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION,
    LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE,
    LEASE_TRANSACTION_TYPE_RENEWAL,
    LEASE_TRANSACTION_TYPE_TRANSFER,
    LEASE_TRANSACTION_TYPE_VACATE,
} from 'constants/leaseTransaction';
import { leaseTransactionType } from 'types';

import ApplicationAccordionContent from './ApplicationAccordionContent';
import LeaseTransactionAccordionContentWrapper from './LeaseTransactionAccordionContentWrapper';
import MidLeaseChangeAccordionContent from './MidLeaseChangeAccordionContent';
import RenewalAndTransferAccordionContent from './RenewalAndTransferAccordionContent';
import VacateAccordionContent from './VacateAccordionContent';
import MonthToMonthAccordionContent from './MonthToMonthAccordionContent';

const TYPE_TO_ACCORDION_CONTENT_COMPONENT = {
    [LEASE_TRANSACTION_TYPE_APPLICATION]: ApplicationAccordionContent,
    [LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE]: MidLeaseChangeAccordionContent,
    [LEASE_TRANSACTION_TYPE_RENEWAL]: RenewalAndTransferAccordionContent,
    [LEASE_TRANSACTION_TYPE_VACATE]: VacateAccordionContent,
    [LEASE_TRANSACTION_TYPE_TRANSFER]: RenewalAndTransferAccordionContent,
    [LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION]: MidLeaseChangeAccordionContent,
};

export function LeaseTransactionAccordionContent({ leaseTransaction, classes, setError, invitee }) {
    const LeaseTransactionAccordionContent = leaseTransaction.is_month_to_month_update
        ? MonthToMonthAccordionContent
        : TYPE_TO_ACCORDION_CONTENT_COMPONENT[leaseTransaction.type];

    return (
        <LeaseTransactionAccordionContentWrapper
            classes={classes}
            leaseTransaction={leaseTransaction}
            invitee={invitee}
            setError={setError}
        >
            <LeaseTransactionAccordionContent
                classes={classes}
                leaseTransaction={leaseTransaction}
                invitee={invitee}
                setError={setError}
            />
        </LeaseTransactionAccordionContentWrapper>
    );
}

LeaseTransactionAccordionContent.propTypes = {
    leaseTransaction: leaseTransactionType,
    classes: PropTypes.object,
    invitee: PropTypes.object,
    setError: PropTypes.func,
};

export default LeaseTransactionAccordionContent;
