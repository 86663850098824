import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import { ROUTES } from 'constants/constants';
import API from 'api/api';
import { getValidationErrors } from 'utils/fieldvalidation';
import captureRoute from 'utils/captureRoute';
import { serializeDate, parseDateISOString, formatExistingPhoneNumber } from 'utils/misc';
import { getTermsArrayFromLocalStorage } from 'utils/terms';

import { updateUserProfile } from 'reducers/user-profile';
import { actions as loaderActions } from 'reducers/loader';
import { selectors as renterProfileSelectors } from 'reducers/renter-profile';
import { fetchApplicant } from 'reducers/applicant';

import AccountForm from 'common-components/AccountForm/AccountForm';
import ChangePasswordForm from 'common-components/ChangePasswordForm/ChangePasswordForm';
import VerifyAccount from 'pages/Account/components/VerifyAccount';
import { H1, blackLinkRoot, arrowIcon } from 'assets/styles';

export class AccountPage extends Component {
    state = {
        status: null,
        verified: false,
        setupIncomplete: false,
        showChangePassword: false,
        resetPasswordErrors: null,
    };

    componentDidMount() {
        const setupIncomplete = this.props.userProfile.completed_account_setup === false;
        this.setState({ setupIncomplete });

        const viewedTerms = localStorage?.getItem(`accepted-platform-terms-${this.props.leaseSettingsId}`);
        if (setupIncomplete && !viewedTerms) {
            const newRoute = this.leaseTransactionId
                ? `${ROUTES.TERMS_DID_REGISTER}&transaction_id=${this.leaseTransactionId}`
                : ROUTES.TERMS_DID_REGISTER;
            this.props.history.push(newRoute);
        }
    }

    get initialValues() {
        const userProfile = this.props.userProfile;
        let phone_number = userProfile.phone_number;
        if (phone_number) {
            phone_number = formatExistingPhoneNumber(phone_number);
        }
        let date_of_birth = userProfile.date_of_birth;
        if (date_of_birth) {
            date_of_birth = parseDateISOString(date_of_birth);
        }

        return {
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            middle_name: userProfile?.middle_name || '',
            suffix: userProfile?.suffix || '',
            phone_number: phone_number,
            email: userProfile.email,
            date_of_birth,
        };
    }

    get leaseTransactionId() {
        const transactions = this.props.userProfile.transactions || [];
        return this.props.leaseTransaction?.id || transactions[transactions.length - 1]?.id || null;
    }

    get nextRoute() {
        if (this.state.setupIncomplete) {
            if (this.props.nextRouteForLeaseTransaction) {
                return this.props.nextRouteForLeaseTransaction;
            }

            if (this.props.lease) {
                return generatePath(ROUTES.LEASE_RENEWAL_OFFERS, { lease_id: this.props.lease.id });
            }

            return ROUTES.LEASES;
        }
        return null;
    }

    onAccountDetailsSubmit = async (values, { setSubmitting, setErrors }) => {
        const data = {
            ...values,
            date_of_birth: serializeDate(values.date_of_birth),
        };
        if (this.state.setupIncomplete) {
            data['completed_account_setup'] = true;

            const termsArray = getTermsArrayFromLocalStorage(this.props.leaseSettingsId, this.props.configuration);
            data['terms'] = termsArray;
        }

        this.props.toggleLoader(true);

        try {
            const response = await this.props.updateUserProfile(data);
            if (this.leaseTransactionId) {
                await this.props.fetchApplicant(this.leaseTransactionId);
            }

            if (response.errors) {
                return setErrors(response.errors);
            }

            if (this.nextRoute) {
                return this.props.history.push(this.nextRoute);
            }

            this.setState({
                status: {
                    type: 'success',
                    detail: 'Changes saved',
                },
            });
        } catch {
            this.setState({
                status: {
                    type: 'error',
                    detail: "We couldn't save your information. Please try again.",
                },
            });
        } finally {
            this.props.toggleLoader(false);
            setSubmitting(false);
        }
    };

    onChangePasswordSubmit = async (values, { setSubmitting }) => {
        this.props.toggleLoader(true);
        try {
            await API.passwordChange(values.password);
            this.setState({
                status: {
                    type: 'success',
                    detail: 'Password successfully changed',
                },
                showChangePassword: false,
                resetPasswordErrors: null,
            });
        } catch (e) {
            if (e.response && e.response.status === 400 && e.responseError && e.responseError.errors) {
                const validationErrors = getValidationErrors(e.responseError.errors);
                this.setState({
                    resetPasswordErrors: validationErrors.fieldErrors.reduce(
                        (acc, error) => [...acc, ...error.messages],
                        []
                    ),
                });
            } else {
                this.setState({
                    resetPasswordErrors: ['There was an error with resetting your password. Please try again.'],
                });
            }
        } finally {
            this.props.toggleLoader(false);
            setSubmitting(false);
        }
    };

    render() {
        if (this.state.setupIncomplete) {
            return (
                <>
                    <H1>Complete Your Account Set Up</H1>
                    <AccountForm
                        submitText="Create Account"
                        status={this.state.status}
                        configuration={this.props.configuration}
                        initialValues={this.initialValues}
                        onSubmit={this.onAccountDetailsSubmit}
                        withPassword
                        showConsentInput
                    />
                </>
            );
        }

        if (!this.state.verified) {
            return (
                <VerifyAccount
                    communityId={this.props.leaseSettingsId}
                    setVerified={() => this.setState({ verified: true })}
                    email={this.initialValues.email}
                />
            );
        }

        if (this.state.showChangePassword) {
            return (
                <>
                    <H1>Change Password</H1>
                    <ChangePasswordForm
                        onSubmit={this.onChangePasswordSubmit}
                        errors={this.state.resetPasswordErrors}
                    />
                    <span className={blackLinkRoot} onClick={() => this.setState({ showChangePassword: false })}>
                        <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
                    </span>
                </>
            );
        }

        return (
            <>
                <H1>Your Account Details</H1>
                <AccountForm
                    submitText="Save Changes"
                    status={this.state.status}
                    configuration={this.props.configuration}
                    initialValues={this.initialValues}
                    onSubmit={this.onAccountDetailsSubmit}
                    resetPassword={() => this.setState({ showChangePassword: true })}
                />
            </>
        );
    }
}

AccountPage.propTypes = {
    configuration: PropTypes.object,
    fetchApplicant: PropTypes.func,
    history: PropTypes.object,
    lease: PropTypes.object,
    leaseSettingsId: PropTypes.string,
    leaseTransaction: PropTypes.object,
    nextRouteForLeaseTransaction: PropTypes.string,
    updateUserProfile: PropTypes.func,
    userProfile: PropTypes.object,
    toggleLoader: PropTypes.func,
};

const mapStateToProps = (state) => ({
    userProfile: state.userProfile,
    configuration: state.configuration,
    lease: state.lease,
    leaseSettingsId: state.siteConfig.basename,
    leaseTransaction: state.transaction,
    nextRouteForLeaseTransaction: renterProfileSelectors.selectNextRoute(state),
});

const mapDispatchToProps = {
    fetchApplicant,
    updateUserProfile,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(captureRoute(AccountPage, ROUTES.ACCOUNT));
