import { MOCKY } from 'config';
import history from './history';
import API from 'api/api';

// token auth service loosely based on the authentication service exemplified here: https://medium.appbase.io/how-to-implement-authentication-for-your-react-app-cf09eef3bb0b
class Auth {
    register = (data, leaseSettingsId, clientId) => {
        return API.register(data, leaseSettingsId, clientId).then((res) => {
            return Promise.resolve({ token: res.token });
        });
    };

    login = (username, password, communityId) => {
        if (MOCKY) {
            return Promise.resolve({ token: 'FAKETOKEN' });
        }
        return API.login(username, password, communityId).then((res) => {
            if (res.errors) return Promise.reject({ errors: res.errors });
            return Promise.resolve({ token: res.token });
        });
    };

    wormholeLogin = (username, token, communityId) => {
        if (MOCKY) {
            return Promise.resolve({ token: 'FAKETOKEN' });
        }
        return API.wormholeLogin(username, token, communityId).then((res) => {
            if (res.errors) return Promise.reject({ errors: res.errors });
            return Promise.resolve(res);
        });
    };

    getToken = () => {
        try {
            return localStorage?.getItem('access_token');
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    accessScope = () => {
        try {
            return localStorage?.getItem('access_scope');
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    setScope = (scope) => {
        try {
            localStorage.setItem('access_scope', scope);
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    setSession = (authToken, scope, wormholedUser) => {
        try {
            const nowPlus30Days = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).getTime();
            localStorage.setItem('expires_at', nowPlus30Days);
            localStorage.setItem('access_token', authToken);
            wormholedUser && localStorage.setItem('wormholed_user', JSON.stringify(wormholedUser));
            this.setScope(scope);
        } catch (e) {
            console.error(e);
        }
    };

    logout = () => {
        try {
            localStorage.removeItem('access_token');
            localStorage.removeItem('expires_at');
            localStorage.removeItem('wormholed_user');
            history.push('/login');
        } catch (e) {
            console.error(e);
        }
    };

    isAuthenticated = () => {
        try {
            const expiresAt = JSON.parse(localStorage?.getItem('expires_at'));
            return new Date().getTime() < expiresAt;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    getWormholedUser = () => {
        try {
            return JSON.parse(localStorage.getItem('wormholed_user'));
        } catch (e) {
            console.error(e);
            return null;
        }
    };
}

export default new Auth();
