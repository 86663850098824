import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import styled from '@emotion/styled';
import { withTheme } from '@material-ui/styles';
import { Typography, styled as materialStyled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import {
    defaultLinkColor,
    defaultGrayColor,
    defaultErrorColor,
    bodyFontSize,
    bodySecondaryFontSize,
    normalFontWeight,
    semiBoldFontWeight,
    boldFontWeight,
    condensedFontStretch,
    heading1FontSize,
    defaultLineHeight,
    bodyTypography,
    bodyStrongTypography,
    bodySecondaryTypography,
    heading1Typography,
    heading2Typography,
    heading3Typography,
} from './constants';
import { omit } from 'rambda';

export const formContent = css({
    padding: '10px',
    margin: 'auto',
    maxWidth: '500px',
});

export const cursor = css({
    cursor: 'pointer !important',
});

export const linkNoStyle = css({
    textDecoration: 'none',
});

export const ErrorDetail = styled.div({
    color: defaultErrorColor,
    textAlign: 'left',
    fontSize: bodyFontSize,
    margin: '0 0 8px 0',
});

export const Logo = styled.img({
    maxWidth: '120px',
    maxHeight: '50px',
});

export const Bold = styled.strong((props) => ({
    fontSize: 'inherit',
    fontWeight: props.fontWeight ? `${props.fontWeight}` : semiBoldFontWeight,
}));

export const H1 = styled.h1({
    ...heading1Typography,
    margin: '0 auto',
    padding: 0,
});

export const H2 = styled.h2(
    {
        ...heading2Typography,
    },
    (props) => ({ padding: props.padding || 0 })
);

export const H3 = styled.h3({
    ...heading3Typography,
    color: '#454b57',
    margin: 0,
});

export const Span = styled.span(
    {
        fontSize: bodyFontSize,
    },
    (props) => ({
        fontSize: props.fontSize ?? 'inherit',
    })
);

export const PrimarySpan = withTheme((props) => {
    const { theme, ...rest } = props;
    return (
        <span {...rest} style={{ color: theme.palette.primary.main }}>
            {props.children}
        </span>
    );
});

export const link = css({
    fontSize: bodyFontSize,
    display: 'inline-block',
    marginBottom: '20px',
    color: defaultLinkColor,
});

export const LinkButton = styled.button({
    background: 'none !important',
    border: 'none',
    padding: '0 !important',
    font: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
    label: 'link-button',
    fontSize: bodyFontSize,
    color: defaultLinkColor,

    ':disabled': {
        cursor: 'not-allowed',
        color: '#bdc0cd !important',
    },
});
LinkButton.displayName = 'LinkButton';

export const blackLinkRoot = css({
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontWeight: `${semiBoldFontWeight} !important`,
    fontSize: `${bodyFontSize}px !important`,
    textTransform: 'capitalize',
    textDecoration: 'none !important',
    color: 'black !important',
    cursor: 'pointer',

    ':disabled': {
        cursor: 'not-allowed',
        color: '#bdc0cd !important',
    },
});

export const P = materialStyled((props) =>
    props.fontSize === 12 ? (
        <Typography variant="body2" {...omit(['variant'], props)}>
            {props.children}
        </Typography>
    ) : (
        <Typography variant="body1" {...omit(['variant'], props)}>
            {props.children}
        </Typography>
    )
)(({ margin, bottomAligned, color, decoration, overflow, padding, textAlign, bold }) => ({
    // Removed font adjustments, they should be based on ./constants
    margin: margin ? margin : 0,
    marginTop: bottomAligned && 'auto',
    color: color ? color : 'inherit',
    textDecoration: decoration || 'none',
    overflow: overflow || 'auto',
    padding: padding || 0,
    textAlign: textAlign || 'inherit',
    fontWeight: bold ? semiBoldFontWeight : normalFontWeight,
}));

export const CustomTypographyBody1 = materialStyled((props) => (
    <Typography variant="body1" {...omit(['variant'], props)}>
        {props.children}
    </Typography>
))(({ padding, margin, color }) => ({
    padding,
    margin,
    color,
}));

export const CustomTypographyBody2 = materialStyled((props) => (
    <Typography variant="body2" {...omit(['variant'], props)}>
        {props.children}
    </Typography>
))(({ margin, color }) => ({
    margin,
    color,
}));

export const ExternalTextLink = (props) => {
    const { disabled, children, ...rest } = props;

    return (
        <a
            style={{
                ...(disabled && {
                    cursor: 'not-allowed',
                    color: '#bdc0cd',
                    pointerEvents: 'none',
                }),
                ...(rest.styles || {}),
            }}
            target="_blank"
            rel="noopener noreferrer"
            {...rest}
        >
            {children}
        </a>
    );
};

ExternalTextLink.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    styles: PropTypes.object,
};

export const TextLink = withTheme((props) => {
    const { theme, ...rest } = props;
    let styles = {};
    if (props?.variant === 'link') {
        styles = theme.typography.link;
    } else if (props?.variant === 'link2') {
        styles = theme.typography.link2;
    } else if (props?.variant === 'textLink') {
        styles = theme.typography.textLink;
    } else if (props?.variant === 'textLink2') {
        styles = theme.typography.textLink;
    }

    return (
        <Link {...rest} style={{ ...styles, ...(rest.styles || {}) }}>
            {props.children}
        </Link>
    );
});

TextLink.defaultProps = {
    variant: 'link',
};

TextLink.propTypes = {
    variant: PropTypes.oneOf(['textLink', 'textLink2', 'link', 'link2']).isRequired,
};

export const SpacedH3 = materialStyled((props) => (
    <Typography variant="subtitle1" {...omit(['variant'], props)}>
        {props.children}
    </Typography>
))(({ theme }) => ({
    margin: theme.spacing(0, 2, 3, 2),
}));

export const Heading3 = materialStyled((props) => (
    <Typography variant="h3" {...omit(['variant'], props)}>
        {props.children}
    </Typography>
))(({ theme }) => ({
    margin: theme.spacing(2, 0, 3, 0),
}));

export const SpacedH1 = materialStyled((props) => (
    <Typography variant="h1" {...omit(['variant'], props)}>
        {props.children}
    </Typography>
))(({ theme }) => ({
    margin: `${theme.spacing(0, 0, 2, 0)}`,
}));

export const SpacedH6 = materialStyled((props) => (
    <Typography variant="subtitle1" {...omit(['variant'], props)}>
        {props.children}
    </Typography>
))(({ theme }) => ({
    margin: theme.spacing(0, 2, 3, 2),
}));

export const CardSection = styled.div({
    padding: '15px',
});

export const Spacer = styled.div((props) => ({
    height: props.height ? `${props.height}px` : '20px',
}));

export const ScrollableTermsCardSection = styled(CardSection)({
    overflow: 'auto',
    overflowX: 'clip',
    padding: '30px',
    height: '60vh',
    fontSize: bodySecondaryFontSize,
});

export const ColoradoLegalLanguageSection = styled(CardSection)({
    overflow: 'auto',
    overflowX: 'clip',
    padding: '30px',
    fontSize: bodySecondaryFontSize,
    fontWeight: `${boldFontWeight} !important`,
});

export const Card = styled.div({
    marginBottom: '20px',
    border: '1px solid #eeeeee',
    borderRadius: '5px',
    boxShadow: '0 2px 4px 0 rgba(197, 197, 197, 0.5)',
    textAlign: 'left',
});

export const leftText = css({
    textAlign: 'left',
});

export const CardRow = styled.div({
    display: 'flex',
    padding: '10px 0',
    borderBottom: '1px solid #eeeeee',
    justifyContent: 'space-between',

    ':first-of-type': {
        paddingTop: 0,
    },
    ':last-of-type': {
        borderBottom: 'none',
        paddingBottom: 0,
    },
});

export const CardRowBorderless = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

export const CardRowBorderTop = styled(CardRow)({
    borderTop: '1px solid #e6e6e6',
    borderBottom: 'none',
});

export const CardRowTotal = styled(CardRow)({
    width: '100%',
    boxSizing: 'content-box',
    backgroundColor: 'rgba(86, 186, 130, 0.1)',
    margin: '0 0 -15px -15px',
    padding: '15px !important',
});

export const infoIconRoot = css({
    height: 'inherit !important',
});

export const arrowIcon = css({
    fontWeight: '500 !important',
    fontSize: `${bodyFontSize}px !important`,
    verticalAlign: 'sub',
});

export const paperRoot = css({
    boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.1) !important',
    borderWidth: '0px !important',
    padding: '15px !important',
    fontSize: `${bodyFontSize}px !important`,
    borderRadius: '5px',
});

export const colorManatee = css({
    color: `${defaultGrayColor} !important`,
});

export const applicationStatus = css({
    color: defaultGrayColor,
    fontSize: bodySecondaryFontSize,
});

export const personName = css({
    maxWidth: '300px',
});

export const leasingPricingDisclaimer = css({
    marginTop: '37px',
    fontSize: bodySecondaryFontSize,
});

export const styles = {
    formContent,
    cursor,
    linkNoStyle,
    link,
    blackLinkRoot,
    leftText,
    infoIconRoot,
    arrowIcon,
    paperRoot,
    colorManatee,
};

export const useBankingOptionStyles = makeStyles(() => ({
    header: {
        borderBottom: '1px solid #eeeeee',
        fontWeight: normalFontWeight,
        fontSize: heading1FontSize,
        fontStretch: condensedFontStretch,
        paddingBottom: '40px',
        lineHeight: defaultLineHeight,
    },
    pillbox: {
        marginTop: '5px',
        float: 'left',
        marginRight: '10px',
        borderRadius: '10px',
        background: '#eee',
        padding: '2px 15px',
        fontSize: bodySecondaryFontSize,
    },
    subTitleCenter: {
        ...bodyStrongTypography,
        margin: 20,
        textAlign: 'center',
    },
    subTitle: {
        ...bodyStrongTypography,
        marginLeft: '0px',
        marginTop: '0px',
        marginRight: '0px',
        marginBottom: '0px',
        textAlign: 'left',
    },
    body: {
        ...bodyTypography,
        textAlign: 'left',
    },
    footer: {
        ...bodySecondaryTypography,
        marginTop: '10px',
        textAlign: 'center',
    },
    noMargin: {
        marginLeft: '0px',
        marginTop: '0px',
        marginRight: '0px',
        marginBottom: '0px',
    },
}));
