import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { css } from 'emotion';
import { bodySecondaryFontSize } from 'assets/constants';

const checkboxRoot = css({
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
    paddingRight: '8px !important',
});

const checkboxText = css({
    fontSize: bodySecondaryFontSize,
    textAlign: 'left',
});

const gridContainer = css({
    padding: '20px 0 20px 0',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'flex-start',
});

export default function LabeledCheckbox({ name, value, checked, onChange, error, label }) {
    return (
        <div className={gridContainer}>
            <Checkbox
                name={name}
                onChange={onChange}
                checked={checked}
                value={value}
                error={error}
                classes={{ root: checkboxRoot }}
            />
            <span className={checkboxText}>{label}</span>
        </div>
    );
}

LabeledCheckbox.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    value: PropTypes.any,
    error: PropTypes.any,
    checkboxRoot: PropTypes.any,
    label: PropTypes.any,
};
