import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { CardRow } from 'assets/styles';
import { useRenewalOptionStyles } from 'pages/Renewal/styles';

export const MonthToMonthOption = ({ price, daysBeforeRenewalDecision, isLeaseOfferDisclaimer }) => {
    const classes = useRenewalOptionStyles();
    const getAsterisk = isLeaseOfferDisclaimer ? '*' : '';

    return (
        <>
            <CardRow className={classes.row}>
                <Typography>
                    <b>Month-to-Month</b>
                </Typography>
            </CardRow>
            <CardRow className={classes.row}>
                <div className={classes.rentWrapper}>
                    <div className={classes.rentLabel}>
                        ${price} {getAsterisk}
                    </div>
                    <div className={classes.perMonthLabel}>per month</div>
                </div>
                <div className={classes.contentWrapper}>
                    {!daysBeforeRenewalDecision ? (
                        <div>No set lease end date.</div>
                    ) : (
                        <div>
                            No set lease end date and {daysBeforeRenewalDecision} days notice required before moving
                            out.
                        </div>
                    )}
                </div>
            </CardRow>
        </>
    );
};

MonthToMonthOption.propTypes = {
    price: PropTypes.string.isRequired,
    daysBeforeRenewalDecision: PropTypes.number,
    isLeaseOfferDisclaimer: PropTypes.bool,
};

export default MonthToMonthOption;
