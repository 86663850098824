import TagManager from 'react-gtm-module';

const GTM = {};

GTM.sendUserAndCompanyData = (company, userProfile) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'sendPendoData',
            company_name: company.name,
            company_id: company.id,
            user_id: 'C' + userProfile.id,
            user_email: userProfile.email,
            user_first_name: userProfile.first_name,
            user_last_name: userProfile.last_name,
            user_role: 'Applicant',
        },
    });
};

export default GTM;
