import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { css } from 'emotion';

import styled from '@emotion/styled';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import { bodySecondaryFontSize } from 'assets/constants';
import API from 'api/api';
import { TOS_TYPE_PAYMENTS } from 'constants/constants';
import { prettyCurrency } from 'utils/misc';
import { actions as loaderActions } from 'reducers/loader';

import ActionButton from 'common-components/ActionButton/ActionButton';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import { Card, H1, LinkButton, ScrollableTermsCardSection, blackLinkRoot, arrowIcon } from 'assets/styles';

const CONTENT_TYPE_TEXT = 'text';

// There is a memory leak breaking MUI on this component
// MUST FIX
const SpacedH1 = styled(H1)`
    margin: 15px 10% 15px 10%;
    font-family: 'Open Sans';
    text-align: center;
`;

// WE MUST SANITIZE THIS HTML IT IS DANGEROUS
const brokenFont = css`
    p,
    span {
        ${css({
            fontFamily: `"Open Sans", Tahoma, sans-serif !important`,
            fontSize: bodySecondaryFontSize,
        })}
    }
`;

export const PaymentTerms = ({
    disabled,
    handleClickBack,
    handleTermsAccepted,
    holdingDepositAmount = 0,
    unitNumber,
    communityName,
    leaseStartDate,
    canProceedToPayment,
    leaseTransaction,
    viewOnly = false,
    toggleLoader,
}) => {
    const [html, setHtml] = useState(undefined);
    const [currentRevision, setCurrentRevision] = useState(undefined);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const moveInDate = moment(leaseStartDate).format('LL');
    const holdingDepositDisplayedAmount = prettyCurrency(holdingDepositAmount);
    const disableActionButtons = disabled || errors || isLoading;

    useEffect(() => {
        (async () => {
            if (!leaseTransaction) return;

            try {
                toggleLoader(true);
                setIsLoading(true);
                const { html, current_revision } = await API.fetchHoldingDepositTerms(
                    leaseTransaction.id,
                    canProceedToPayment
                );
                setHtml(html);
                setCurrentRevision(current_revision);
            } catch (e) {
                setErrors(["Oops! We're having trouble finding your Payment Terms. Try again in a bit."]);
            } finally {
                setIsLoading(false);
                toggleLoader(false);
            }
        })();
    }, [canProceedToPayment, leaseTransaction, toggleLoader]);

    const handleContinueClick = useCallback(() => {
        const data = {
            type: TOS_TYPE_PAYMENTS,
            agreement_document_revision: currentRevision?.id,
            context: {
                time: Date.now(),
                move_in_date: moveInDate,
                holding_deposit: holdingDepositDisplayedAmount,
                community_name: communityName,
                unit_number: unitNumber,
            },
        };

        handleTermsAccepted(data);
    }, [moveInDate, holdingDepositDisplayedAmount, communityName, unitNumber, currentRevision, handleTermsAccepted]);

    return (
        <>
            <SpacedH1>Payment Terms</SpacedH1>
            {errors && <GenericFormMessage type="error" messages={errors} />}
            {html && (
                <Card>
                    <ScrollableTermsCardSection>
                        {currentRevision?.content_type === CONTENT_TYPE_TEXT ? (
                            <p style={{ 'white-space': 'pre-wrap' }} className={brokenFont}>
                                {html}
                            </p>
                        ) : (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: html,
                                }}
                                className={brokenFont}
                            />
                        )}
                    </ScrollableTermsCardSection>
                </Card>
            )}
            <>
                {canProceedToPayment && !viewOnly ? (
                    <>
                        <ActionButton
                            disabled={disableActionButtons}
                            onClick={handleContinueClick}
                            marginTop={25}
                            marginBottom={20}
                        >
                            Agree and Continue
                        </ActionButton>
                        {!!handleClickBack && (
                            <LinkButton
                                disabled={disableActionButtons}
                                className={blackLinkRoot}
                                onClick={handleClickBack}
                            >
                                <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
                            </LinkButton>
                        )}
                    </>
                ) : (
                    !!handleClickBack && (
                        <ActionButton
                            disabled={disableActionButtons}
                            onClick={handleClickBack}
                            marginTop={25}
                            marginBottom={20}
                        >
                            Go Back
                        </ActionButton>
                    )
                )}
            </>
        </>
    );
};

PaymentTerms.propTypes = {
    disabled: PropTypes.bool,
    handleClickBack: PropTypes.func,
    handleTermsAccepted: PropTypes.func,
    holdingDepositAmount: PropTypes.number.isRequired,
    unitNumber: PropTypes.string.isRequired,
    communityName: PropTypes.string.isRequired,
    leaseStartDate: PropTypes.string.isRequired,
    canProceedToPayment: PropTypes.bool.isRequired,
    leaseTransaction: PropTypes.object.isRequired,
    viewOnly: PropTypes.bool,
    toggleLoader: PropTypes.func.isRequired,
};

const mapStateToProps = null;

const mapActionsToProps = {
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentTerms);
