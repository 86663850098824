import * as Sentry from '@sentry/react';
import { RewriteFrames } from '@sentry/integrations';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';

import { ENV, SENTRY_RELEASE } from 'config';

export const setupSentry = () => {
    const sentryOptions = {
        /**
         * The Dsn used to connect to Sentry and identify the project. If omitted, the
         * SDK will not send any data to Sentry.
         */
        dsn: 'https://99462942d8ac4d5ba01227973cf9a8b1@o16407.ingest.sentry.io/1784084',

        /** The current environment of THE application (e.g. "production"). */
        environment: ENV,

        /** Attaches stacktraces to pure capture message / log integrations */
        attachStacktrace: true,

        /**
         * This sets the sample rate to be 10%. You may want this to be 100% while
         * in development and sample at a lower rate in production
         */
        replaysSessionSampleRate: 0,

        /**
         * If the entire session is not sampled, use the below sample rate to sample
         * sessions when an error occurs.
         */
        replaysOnErrorSampleRate: 1.0,

        /**
         * List of integrations that should be installed after SDK was initialized.
         * Accepts either a list of integrations or a function that receives
         * default integrations and returns a new, updated list.
         */
        integrations: [
            /*
             * This integration allows you to apply a transformation to each frame of the stack trace. In the
             * streamlined scenario, it can be used to change the name of the file frame it originates from, or it can
             * be fed with an iterated function to apply any arbitrary transformation.
             */
            new RewriteFrames(),

            // This integration extracts all non-native attributes from the Error object and attaches them to the event as the extra data.
            new ExtraErrorDataIntegration(),

            // This integration deduplicates certain events. It can be helpful if you are receiving many duplicate errors.
            new DedupeIntegration(),

            new Sentry.Replay({
                stickySession: true,
                maskAllText: false,
                maskAllInputs: false, // We added a custom mask to sensitive pii
                blockAllMedia: true,
            }),
        ],

        /**
         * A pattern for error messages which should not be sent to Sentry.
         * By default, all errors will be sent.
         */
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',

            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',

            // Facebook borked
            'fb_xd_fragment',

            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',

            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',

            // Generic error code from errors outside the security sandbox
            // You can delete this if using raven.js > 1.0, which ignores these automatically.
            'Script error.',

            // Network errors such as going offline or being blocked by a proxy
            'Failed to fetch',

            // Ignore Non errors
            'Non-Error exception captured',
            'Non-Error promise rejection captured',
            'A network error occurred',
            'Load failed',
            'NetworkError when attempting to fetch resource',
            'Illegal invocation',

            // Error related to MS Edge
            'msDiscoverChatAvailable',

            // Generic error on minified files
            "undefined is not an object (evaluating 'a.O')",
            "Cannot read properties of undefined (reading '0')",
            'The operation is insecure',

            // Some React errors
            'Maximum update depth exceeded',

            // Other common useless errors
            'window.webkit',
            'window.screen.orientation',
            'ResizeObserver loop completed with undelivered notifications',
            '.min.js',

            'page_view_event-aggregate',
        ],

        denyUrls: [
            // Add regular expressions to match URLs of Node modules
            /node_modules/,

            // This ignores a New Relic error
            /page_view_event-aggregate/,

            // Ignore errors from minified files
            /.min\.js/,
            /.chunk\.js/,

            // Ignore known bot user agents
            /bot|spider|crawler/i,

            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            // Firefox extensions
            /^resource:\/\//i,
            // Ignore Google flakiness
            /\/(gtm|ga|analytics)\.js/i,
            // Safari webkit
            /.*@webkit-masked-url.*/,
        ],

        ignoreInternal: true,
    };

    if (SENTRY_RELEASE) {
        /**
         * The release identifier used when uploading respective source maps. Specify
         * this value to allow Sentry to resolve the correct source maps when
         * processing events.
         */
        sentryOptions.release = `woodhouse@${SENTRY_RELEASE}`;
    }

    Sentry.init(sentryOptions);
};

export const logToSentry = async (error, leaseTransaction, community, company) => {
    Sentry.withScope(async (scope) => {
        // Note: keep community & company tags format in sync with chuck.utils.sentry#capture_exception
        if (community) {
            scope.setTag('community', `(${community.id}) - ${community.display_name}`);
        }

        if (company) {
            scope.setTag('company', `(${company.id}) - ${company.name}`);
        }

        return Sentry.captureException(error);
    });
};

export const setSentryUser = (user) => {
    Sentry.setUser({
        id: user?.id,
        email: user?.email,
    });
};
