export const getShowAutomatedAddressForm = (state) => state.launchDarkly.olShowAutomatedAddressForm;
export const getShowMiddleNameSelector = (state) => state.launchDarkly.olShowMiddleName11232022;

export const getShowSuffixSelector = (state) => state.launchDarkly.olSuffixField04092024;

export const getCanSendVerificationCodeThroughSMSSelector = (state) => state.launchDarkly.olSmsPasswordReset12222022;
export const getShowBillingZipCode = (state) => state.launchDarkly.olBillingZipCodeOnApplicantPayment02172023;
export const getMoveInDateInitBlank = (state) => {
    return state.launchDarkly.olUnitAvailabilityVisibilityForApplicantExperience02072023;
};
export const getDisableRenewalWhenThereIsALeaseUpdate = (state) => {
    return state.launchDarkly.olPreventRenewalDecisionsWhenThereAreOpenTransactions;
};

export const getDisplayMonthlyPriceBreakdown = (state) => {
    return state.launchDarkly.olDisplayDepositsAndFeesDuringApplication;
};
