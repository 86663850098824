import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { leaseTransactionType } from 'types';
import { getDateLabel } from 'pages/LeaseTransactions/utils/helpers';

function VacateAccordionContent({ classes, leaseTransaction }) {
    const { move_out_date } = leaseTransaction;

    return (
        <Typography className={classes.typography} variant="body1">
            Move Out Date: <span>{getDateLabel(move_out_date)}</span>
        </Typography>
    );
}

VacateAccordionContent.propTypes = {
    classes: PropTypes.object.isRequired,
    leaseTransaction: leaseTransactionType,
};

export default VacateAccordionContent;
