import { useCallback, useEffect, useState } from 'react';

import API from 'api/api';
import usePrevious from 'hooks/usePrevious';

export default function useInvitations(errorMessage) {
    const [loading, setIsLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const prevLoading = usePrevious(loading);

    const getInvitations = useCallback(async () => {
        setIsLoading(true);

        try {
            const invitations = await API.getInvitations();
            setData(invitations);
        } catch {
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        getInvitations();
    }, [getInvitations]);

    useEffect(() => {
        if (loading === false && prevLoading === true) {
            setFinished(true);
        }
    }, [loading, prevLoading]);

    return {
        loading,
        error,
        finished,
        data,
    };
}
