import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'emotion';

import { UNIT_NOT_HELD_WAITING_IDENTIFIER } from 'constants/pageComplete';
import { pageComplete } from 'reducers/renter-profile';
import { SpacedH1 } from 'assets/styles';
import catOnCouch from 'assets/new-icons/task-list-clock-streamlined.svg';
import { Typography } from '@material-ui/core';

const bodyRowImg = css`
    margin-top: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 40%;
    height: auto;
`;

const bodyRowAddress = css`
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

export const UnitNotHeldWaitingPage = ({
    primaryApplicantFirstName,
    primaryApplicantLastName,
    communityName,
    unitNumber,
    pageComplete,
    leaseTransaction,
}) => {
    useEffect(() => {
        if (!leaseTransaction) {
            return;
        }

        pageComplete(UNIT_NOT_HELD_WAITING_IDENTIFIER);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <SpacedH1>
                Waiting on {primaryApplicantFirstName} {primaryApplicantLastName}
            </SpacedH1>
            <Typography variant="h3">
                You&#39;ll be able to continue once {primaryApplicantFirstName} submits their application, putting your
                unit on hold. We&#39;ll let you know when you&#39;re all set to continue.
            </Typography>
            <img className={bodyRowImg} src={catOnCouch} alt="cat on couch" />
            <span className={bodyRowAddress}>{`${communityName} Unit ${unitNumber}`}</span>
        </Fragment>
    );
};

UnitNotHeldWaitingPage.propTypes = {
    primaryApplicantFirstName: PropTypes.string.isRequired,
    primaryApplicantLastName: PropTypes.string.isRequired,
    communityName: PropTypes.string.isRequired,
    unitNumber: PropTypes.string.isRequired,
    pageComplete: PropTypes.func.isRequired,
    leaseTransaction: PropTypes.object,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
});

export default connect(mapStateToProps, { pageComplete })(UnitNotHeldWaitingPage);
