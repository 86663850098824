import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { leaseTransactionType } from 'types';
import { getDateLabel } from 'pages/LeaseTransactions/utils/helpers';

function MidLeaseChangeAccordionContent({ classes, leaseTransaction }) {
    const { move_in_date, move_out_date, lease_start_date, lease_end_date, total_monthly_fees } = leaseTransaction;

    return (
        <>
            {move_in_date && (
                <Typography className={classes.typography} variant="body1">
                    Move In Date: <span>{getDateLabel(move_in_date)}</span>
                </Typography>
            )}
            {move_out_date && (
                <Typography className={classes.typography} variant="body1">
                    Move Out Date: <span>{getDateLabel(move_out_date)}</span>
                </Typography>
            )}
            {lease_start_date && lease_end_date && (
                <Typography className={classes.typography} variant="body1">
                    Lease Term: <span>{getDateLabel(lease_start_date)}</span>
                    <span> - </span>
                    <span>{getDateLabel(lease_end_date)}</span>
                </Typography>
            )}
            <Typography className={classes.typography} variant="body1">
                Monthly Rent: <span>{total_monthly_fees ? `$${total_monthly_fees}` : '---'}</span>
            </Typography>
        </>
    );
}

MidLeaseChangeAccordionContent.propTypes = {
    classes: PropTypes.object.isRequired,
    leaseTransaction: leaseTransactionType,
};

export default MidLeaseChangeAccordionContent;
