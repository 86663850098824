import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { Grid, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

import { MOCKY } from 'config';
import { ROUTES } from 'constants/constants';
import API from 'api/api';
import { prettyFormatPhoneNumber } from 'utils/misc';
import withRelativeRoutes from 'utils/withRelativeRoutes';
import { actions as loaderActions } from 'reducers/loader';

import { LinkButton, blackLinkRoot, arrowIcon, SpacedH1, SpacedH6, P } from 'assets/styles';
import ActionButton from 'common-components/ActionButton/ActionButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import SocialSecurityInput from 'common-components/SocialSecurityInput/SocialSecurityInput';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import Checkbox from 'common-components/Checkbox/Checkbox';
import portfolioImg from 'assets/new-icons/task-list-search.svg';
import ssl from 'assets/images/ssl-image.png';

import ScreeningOptionsView from './components/ScreeningOptionsView';
import SelfScreeningInstructionsView from './components/SelfScreeningInstructionsView';

const Image = styled.img`
    width: 91px;
    height: 32px;
`;

const securityBlurb = css`
    color: #828796;
    font-size: 13px;
`;

const centerText = css`
    text-align: center;
`;

const gridContainer = css`
    padding: 20px 0 20px 0;
`;

const socialSecurityPrompt = css`
    margin-bottom: 15px;
`;

const SUBMIT_ERROR = 'Oops! We ran into some issues trying to obtain your screening reports. Please try again later.';
const SCREENING_HEADER = 'Your social security number will be used for a background check.';
const SCREENING_DISCLAIMER =
    'I authorize Funnel Leasing to obtain, on behalf of Landlord, a background check on me in connection with my rental application.';
const NON_SCREENING_HEADER = "We don't have an SSN on file for you. Please provide one below.";
const NON_SCREENING_DISCLAIMER =
    'I authorize Funnel Leasing to obtain on behalf of Landlord, a background check on me in connection with any future changes to my household status, if applicable changes are made.';
const SELF_SCREENING_HEADER =
    'Your social security number will be used for identity verification and any necessary screening to complete your background check.';
const SELF_SCREENING_DISCLAIMER =
    'I authorize Funnel, on behalf of [landlord], to request a consumer report and background check on me as necessary to complete this application in the event the consumer report I provide does not meet applicable requirements.';

export class ScreeningPage extends React.Component {
    state = {
        errors: null,
        showScreeningOptions: false,
        showScreeningInstruction: false,
        applicantProvidingOwnScreening: false,
    };

    componentDidMount() {
        const showScreeningOptions = this.props.applicants_can_provide_screening_reports === true;
        this.setState({ showScreeningOptions });
    }

    onProvideScreening = () => {
        this.setState({
            showScreeningOptions: false,
            showScreeningInstruction: true,
        });
    };

    onConfirmProvideScreening = () => {
        this.setState({
            showScreeningInstruction: false,
            applicantProvidingOwnScreening: true,
        });
    };

    onCancelProvideScreening = () => {
        this.setState({
            showScreeningOptions: true,
            showScreeningInstruction: false,
            applicantProvidingOwnScreening: false,
        });
    };

    onContinueWithScreening = () => {
        this.setState({
            showScreeningOptions: false,
            showScreeningInstruction: false,
            applicantProvidingOwnScreening: false,
        });
    };

    onSubmit = async (values, { setSubmitting }) => {
        if (MOCKY) return this.props.getNextRoute();
        const body = { ...values };
        if (!values.have_ssn) {
            body.ssn = '000-00-0000';
        }
        body.vgs = this.props.vgsEnabled;
        body.disclaimer = this.screeningText.disclaimer;

        this.props.toggleLoader(true);

        try {
            if (this.props.applicants_can_provide_screening_reports) {
                await API.provideScreeningReport(this.props.leaseTransaction.id, {
                    applicant_providing_own_screening: this.state.applicantProvidingOwnScreening,
                });
            }

            await API.postPassthrough(this.props.leaseTransaction.id, body, this.props.vgsEnabled);
            await this.props.getNextRoute();
        } catch {
            this.setState({ errors: [SUBMIT_ERROR] });
        } finally {
            this.props.toggleLoader(false);
            setSubmitting(false);
        }
    };

    get screeningText() {
        const headerAndDisclaimerText = {
            header: SCREENING_HEADER,
            disclaimer: SCREENING_DISCLAIMER,
        };

        if (!this.props.leaseTransaction?.has_screening_requirements && !this.state.applicantProvidingOwnScreening) {
            headerAndDisclaimerText.header = NON_SCREENING_HEADER;
            headerAndDisclaimerText.disclaimer = NON_SCREENING_DISCLAIMER;
        }

        if (this.state.applicantProvidingOwnScreening) {
            headerAndDisclaimerText.header = SELF_SCREENING_HEADER;
            headerAndDisclaimerText.disclaimer = SELF_SCREENING_DISCLAIMER;
        }

        return headerAndDisclaimerText;
    }

    render() {
        if (this.state.showScreeningOptions)
            return (
                <ScreeningOptionsView
                    onSelfScreening={this.onProvideScreening}
                    onFunnelScreening={this.onContinueWithScreening}
                    contactPhone={prettyFormatPhoneNumber(this.props.contactPhone)}
                />
            );
        if (this.state.showScreeningInstruction)
            return (
                <SelfScreeningInstructionsView
                    onSubmit={this.onConfirmProvideScreening}
                    onCancel={this.onCancelProvideScreening}
                    contactPhone={prettyFormatPhoneNumber(this.props.contactPhone)}
                    disclaimer={this.props.selfScreeningDisclaimer}
                />
            );
        const initialValues = {
            have_ssn: true,
            ssn: '',
            confirm_ssn: '',
            disclaimer: false,
        };
        const screeningText = this.screeningText;

        return (
            <>
                <SpacedH1 variant="h1">Screening Details</SpacedH1>
                <SpacedH6 variant="subtitle1" data-testid="screening-page-header">
                    {screeningText.header}
                </SpacedH6>
                <img src={portfolioImg} alt="portfolio" />
                <br />
                <br />
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={this.onSubmit}
                    validationSchema={Yup.object().shape({
                        have_ssn: Yup.boolean(),
                        // some test numbers are not valid and break some ssn rules. we may want to update with this more precise validation in the future /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/
                        ssn: Yup.string()
                            .when('have_ssn', {
                                is: true,
                                then: Yup.string().required('Social Security Number is required'),
                            })
                            .matches(/^\d{3}-\d{2}-\d{4}$/, 'Must be a valid Social Security Number eg: 555-55-5555'),
                        confirm_ssn: Yup.string().when('have_ssn', {
                            is: true,
                            then: Yup.string()
                                .required('Confirm Social Security Number is required')
                                .oneOf([Yup.ref('ssn')], 'SSN does not match'),
                        }),
                        disclaimer: Yup.string().required('You must click the checkbox to agree to the terms'),
                    })}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitCount,
                        isSubmitting,
                        setFieldValue,
                        errors,
                    }) => (
                        <form className="text-left" onSubmit={handleSubmit} autoComplete="off">
                            {!!this.state.errors && <GenericFormMessage type="error" messages={this.state.errors} />}
                            <FormControl fullWidth>
                                <div className={gridContainer}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={4} classes={{ root: centerText }}>
                                            <Image alt="ssl secured" src={ssl} />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <span className={securityBlurb}>
                                                We use industry leading encryption software to secure your data
                                            </span>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={socialSecurityPrompt}>
                                    <P margin="0" className={securityBlurb}>
                                        Do you have a social security number?
                                    </P>
                                    <RadioGroup
                                        aria-label="haveSSN"
                                        name={'have_ssn'}
                                        error={errors.have_ssn}
                                        value={values.have_ssn}
                                        row={true}
                                        default={true}
                                        onChange={(val) => setFieldValue('have_ssn', val.target.value === 'true')}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={false} control={<Radio />} label="No" />
                                    </RadioGroup>
                                </div>
                                <Grid container spacing={3}>
                                    {values.have_ssn && (
                                        <Grid item xs={12}>
                                            <SocialSecurityInput
                                                name="ssn"
                                                setFieldValue={(val) => setFieldValue('ssn', val)}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                value={values.ssn}
                                                error={errors.ssn}
                                                submitted={submitCount > 0}
                                                helperText={submitCount > 0 ? errors.ssn && 'Invalid' : null}
                                            />
                                        </Grid>
                                    )}
                                    {values.have_ssn && (
                                        <Grid item xs={12}>
                                            <SocialSecurityInput
                                                name="confirm_ssn"
                                                label="Confirm Social Security Number"
                                                setFieldValue={(val) => setFieldValue('confirm_ssn', val)}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                value={values.confirm_ssn}
                                                error={errors.confirm_ssn}
                                                submitted={submitCount > 0}
                                                helperText={submitCount > 0 ? errors.confirm_ssn : null}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Checkbox
                                    data-testid="screening-page-checkbox"
                                    name="disclaimer"
                                    onChange={handleChange}
                                    checked={values.disclaimer}
                                    value={values.disclaimer}
                                    error={errors.disclaimer}
                                    label={screeningText.disclaimer}
                                />
                                <ActionButton
                                    disabled={
                                        (!values.ssn && !values.confirm_ssn && values.requestSocialSecurityNumber) ||
                                        !values.disclaimer ||
                                        isSubmitting
                                    }
                                    marginTop={31}
                                    marginBottom={20}
                                >
                                    Continue
                                </ActionButton>
                            </FormControl>
                        </form>
                    )}
                </Formik>
                {this.props.applicants_can_provide_screening_reports && (
                    <LinkButton
                        data-testid="screening-page-back-button"
                        className={blackLinkRoot}
                        onClick={this.onCancelProvideScreening}
                    >
                        <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
                    </LinkButton>
                )}
            </>
        );
    }
}

ScreeningPage.propTypes = {
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object.isRequired,
    vgsEnabled: PropTypes.bool,
    applicants_can_provide_screening_reports: PropTypes.bool,
    toggleLoader: PropTypes.func,
    getNextRoute: PropTypes.func,
    contactPhone: PropTypes.string,
    selfScreeningDisclaimer: PropTypes.string,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    leaseTransaction: state.transaction,
    vgsEnabled: state.configuration.enable_encryption,
    applicants_can_provide_screening_reports: state.configuration.applicants_can_provide_screening_reports,
    buildingName:
        state.configuration.community.building_name || state.configuration.community.normalized_street_address,
    contactPhone:
        state.configuration.phone_number_applicant_screening_report || state.configuration.community.contact_phone,
    selfScreeningDisclaimer: state.configuration.disclaimer_for_applicants_providing_screening_reports,
});

const mapDispatchToProps = {
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRelativeRoutes(ScreeningPage, ROUTES.SCREENING));
