import React from 'react';
import PropTypes from 'prop-types';
import FormTextInput from 'common-components/FormTextInput/FormTextInput';

const PhoneNumberInput = (props) => {
    const { handleChange } = props;

    const handleChangeEvent = (event) => {
        // remove non-digit characters and slice up to first 10 digits
        let phoneNumber = event.target.value.replace(/\D/g, '').slice(0, 10);
        const len = phoneNumber.length;
        // Format phone number as (123) 456-7890
        if (len > 3 && len < 7) {
            phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        } else if (len >= 7) {
            phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
        }
        handleChange(phoneNumber);
    };
    return <FormTextInput {...props} fullWidth handleChange={handleChangeEvent} />;
};

PhoneNumberInput.propTypes = { handleChange: PropTypes.func };

export default PhoneNumberInput;
