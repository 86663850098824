import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import ActionButton from 'common-components/ActionButton/ActionButton';
import { prettyCurrency, labelForLineItemGroup } from 'utils/misc';
import { LINE_ITEM_FEE_TYPES, LINE_ITEM_TYPE_HOLDING_DEPOSIT } from 'constants/constants';
import { ApplicationFees } from './ApplicationFees';
import { HoldingDeposit } from './HoldingDeposit';
import { Card, CardSection, CardRow, CardRowBorderTop, CardRowTotal, SpacedH1, SpacedH6 } from 'assets/styles';
import receiptImage from 'assets/new-icons/credit-card-check.svg';

const SpacedImg = styled.img`
    margin: 15px 0;
`;

export const FeesDepositsReceipt = ({
    receipt,
    payables,
    disabled,
    applicant,
    handleContinue,
    applicationFeeTooltipCopy,
}) => {
    if (!receipt) return <div />;

    const paidByAnother = receipt.paid_by !== applicant.id;

    let descriptionText = paidByAnother
        ? 'Your roommates have paid all the application fees!'
        : `Thank you for your payment. We emailed a receipt to ${applicant.person.email}.`;
    if (receipt.paid_by === null) {
        descriptionText = "You don't have any outstanding payment!";
    }

    return (
        <>
            <SpacedH1>Almost Done</SpacedH1>
            <SpacedH6>{descriptionText}</SpacedH6>
            <SpacedImg src={receiptImage} alt="receipt" />
            <Card>
                <CardSection>
                    <CardRow>
                        <Typography variant="body1">
                            <strong>Payment Summary</strong>
                        </Typography>
                    </CardRow>
                    {LINE_ITEM_FEE_TYPES.map((lineItemType) => {
                        const currentPayerFees = [];
                        const allPayersFees = [];
                        payables[lineItemType].forEach((f) => {
                            if (f.payer.applicant_id === applicant.id || f.payer.id === applicant.id)
                                currentPayerFees.push(f);
                            allPayersFees.push(f);
                        });

                        // Show the fees if:
                        // - There are ones that belong to the current applicant
                        // - There are ones that belong to other applicants and are paid in this receipt
                        if (!currentPayerFees.length && !allPayersFees.some((f) => f.paid && f.paidInCurrentReceipt))
                            return null;

                        return (
                            <ApplicationFees
                                {...{
                                    key: lineItemType,
                                    receipt,
                                    applicationFeeTooltipCopy,
                                    didPayForEveryone: allPayersFees.some(
                                        // If there is a line item that was paid in current receipt and does not belong
                                        // to the current applicant then it means the applicant paid for
                                        // everyone for this fee type, otherwise they paid just for themself.
                                        (f) =>
                                            f.paidInCurrentReceipt &&
                                            f.payer.applicant_id !== applicant.id &&
                                            f.payer.id !== applicant.id
                                    ),
                                    allPayersFees,
                                    currentPayerFeesRepresentation:
                                        currentPayerFees.length > 0
                                            ? {
                                                  waived:
                                                      currentPayerFees.filter((f) => f.waived).length ===
                                                          currentPayerFees.length && currentPayerFees.length > 0,
                                                  amount: currentPayerFees.reduce(
                                                      (sum, p) => (p.paidInCurrentReceipt ? sum + p.amount : sum),
                                                      0
                                                  ),
                                                  paid:
                                                      currentPayerFees.filter((f) => f.paid && !f.paidInCurrentReceipt)
                                                          .length === currentPayerFees.length &&
                                                      currentPayerFees.length > 0,
                                              }
                                            : null,
                                    name: labelForLineItemGroup(payables[lineItemType]),
                                }}
                            />
                        );
                    })}
                    {!!payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].length && (
                        <HoldingDeposit
                            paid={
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].filter(
                                    (p) => p.receipt !== receipt.id && (p.waived || p.paid)
                                ).length === payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].length
                            }
                            waived={
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].filter((p) => p.waived).length ===
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].length
                            }
                            amount={prettyCurrency(
                                payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT].reduce(
                                    (sum, p) => (p.receipt === receipt.id && p.paid ? sum + p.amount : sum),
                                    0
                                )
                            )}
                            name={labelForLineItemGroup(payables[LINE_ITEM_TYPE_HOLDING_DEPOSIT])}
                        />
                    )}
                    {receipt.total_tax > 0 && (
                        <CardRowBorderTop>
                            <Typography variant="body1">Tax</Typography>
                            <div>
                                <Typography variant="body1">{prettyCurrency(receipt.total_tax)}</Typography>
                            </div>
                        </CardRowBorderTop>
                    )}

                    {!paidByAnother && (
                        <CardRowTotal>
                            <Typography variant="body1">
                                <strong>Total</strong>
                            </Typography>
                            <div>
                                <Typography variant="body1">
                                    <strong>{prettyCurrency(receipt.total)}</strong>
                                </Typography>
                            </div>
                        </CardRowTotal>
                    )}
                </CardSection>
            </Card>
            <ActionButton onClick={handleContinue} disabled={disabled} marginTop={30} marginBottom={20}>
                Continue
            </ActionButton>
        </>
    );
};

FeesDepositsReceipt.propTypes = {
    receipt: PropTypes.object,
    payables: PropTypes.object,
    disabled: PropTypes.bool,
    applicant: PropTypes.object,
    handleContinue: PropTypes.func,
    applicationFeeTooltipCopy: PropTypes.string,
};

export default FeesDepositsReceipt;
