/* eslint-disable no-unused-vars */
import moment from 'moment';
import { LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE, LEASE_TRANSACTION_TYPE_RENEWAL } from 'constants/leaseTransaction';

export const getPaymentBreakdownFeesDueAtMoveInLabel = (paymentData, leaseTransaction) => {
    return `due ${moment(paymentData.date).format('MMMM YYYY')}`;

    // TODO: return `due at move in` for new applicants only
    //  (applications, and for all other lease transaction only when applicant is moving in)
    // if ([
    //     LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE,
    //     LEASE_TRANSACTION_TYPE_RENEWAL,
    // ].includes(leaseTransaction.type)) {
    //     return `due ${moment(paymentData.date).format('MMMM YYYY')}`;
    // }
    //
    // return 'due at move in';
};
