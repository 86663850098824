import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import {
    ROUTES,
    RENTER_PROFILE_TYPE_CO_APPLICANTS,
    RENTER_PROFILE_TYPE_DEPENDENT,
    RENTER_PROFILE_TYPE_GUARANTOR,
} from 'constants/constants';
import API from 'api/api';

import { fetchTransaction } from 'reducers/transaction';
import { actions as loaderActions } from 'reducers/loader';

import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { P, Bold, SpacedH1, SpacedH6 } from 'assets/styles';
import withTransactionPath from 'utils/withTransactionPath';

const CapitalizedSpan = styled.span`
    text-transform: capitalize;
    word-break: break-word;
`;

const Divider = styled.hr`
    border-style: none;
    border-bottom: 2px solid #eeeeee;
    margin-bottom: 22px;
`;

const Content = styled.div`
    text-align: left;
`;

export class RemovePersonPage extends Component {
    state = { errorSubmitting: false, financialSource: null, submitting: false };

    get returnLink() {
        return this.props.getTransactionPath(`${ROUTES.PROFILE_OPTIONS}#${RENTER_PROFILE_TYPE_CO_APPLICANTS}`);
    }

    get person() {
        if (!this.props.leaseTransaction) return null;

        const {
            match: {
                params: { type, id },
            },
        } = this.props;
        const { leaseTransaction } = this.props;

        const invitee =
            leaseTransaction.invitees && leaseTransaction.invitees.find((invitee) => invitee.id === parseInt(id));
        if (invitee) {
            return invitee;
        }

        switch (type) {
            case RENTER_PROFILE_TYPE_DEPENDENT:
                return leaseTransaction.dependents.find(({ person_id }) => person_id === parseInt(id));
            case RENTER_PROFILE_TYPE_CO_APPLICANTS:
                return leaseTransaction.co_applicants.find((x) => x.id === parseInt(id));
            case RENTER_PROFILE_TYPE_GUARANTOR:
                return leaseTransaction.guarantors.find((x) => x.id === parseInt(id));
            default:
                return null;
        }
    }

    onSubmit = async () => {
        const {
            match: {
                params: { type, id },
            },
        } = this.props;

        this.setState({ submitting: true });
        this.props.toggleLoader(true);

        try {
            if (type === RENTER_PROFILE_TYPE_DEPENDENT) {
                await API.deletePerson(this.props.leaseTransaction.id, id);
            } else {
                await API.deleteInvitee(this.props.leaseTransaction.id, id);
            }

            this.props.fetchTransaction();
            this.props.history.push(this.returnLink);
        } catch {
            this.setState({ errorSubmitting: true });
        } finally {
            this.setState({ submitting: false });
            this.props.toggleLoader(false);
        }
    };

    onCancel = () => {
        this.props.history.push(this.returnLink);
    };

    render() {
        if (!this.props.leaseTransaction || !this.person) return null;

        const { match } = this.props;
        const person = this.person;
        const role = match.params.type === RENTER_PROFILE_TYPE_CO_APPLICANTS ? 'co-applicant' : match.params.type;
        const personLabel = role === RENTER_PROFILE_TYPE_GUARANTOR ? 'Guarantor' : 'Person';

        return (
            <>
                <SpacedH1>Remove {personLabel}</SpacedH1>
                <SpacedH6>
                    <CapitalizedSpan>
                        {person.first_name} {person.last_name}
                    </CapitalizedSpan>
                </SpacedH6>
                <Divider />
                <Content>
                    {this.state.errorSubmitting && (
                        <GenericFormMessage
                            type="error"
                            messages={['Oops! We had some trouble removing this person. Try again in a little bit.']}
                        />
                    )}
                    <Bold fontSize={18}>{`Are you sure you want to remove this ${personLabel.toLowerCase()}?`}</Bold>
                    <br />
                    <br />
                    {role === RENTER_PROFILE_TYPE_GUARANTOR ? (
                        <P>
                            You&apos;re about to remove <CapitalizedSpan>{person.first_name}</CapitalizedSpan> as
                            guarantor. Removing a guarantor prevents them from being able to financially back your lease
                            application.
                        </P>
                    ) : (
                        <P>
                            You&apos;re about to remove <CapitalizedSpan>{person.first_name}</CapitalizedSpan>
                            {`. Removing a person prevents them from being able to apply for this unit as a ${role} or from being added to the lease.`}
                        </P>
                    )}
                </Content>
                <ActionButton
                    id="submit-btn"
                    disabled={this.state.submitting}
                    onClick={this.onSubmit}
                    marginBottom={20}
                    marginTop={100}
                >
                    Remove {personLabel}
                </ActionButton>
                <ActionButton id="cancel-btn" onClick={this.onCancel} variant="outlined" marginBottom={20}>
                    Cancel
                </ActionButton>
            </>
        );
    }
}

RemovePersonPage.propTypes = {
    leaseTransaction: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    toggleLoader: PropTypes.func,
    fetchTransaction: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
});

const mapDispatchToProps = {
    fetchTransaction,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(RemovePersonPage));
