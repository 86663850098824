import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from 'emotion';

import { ROUTES } from 'constants/constants';
import { getDocumentTypeLabelForTransaction } from 'constants/leaseTransaction';
import captureRoute from 'utils/captureRoute';
import useGetLeaseDocumentUrl from 'utils/useGetLeaseDocumentUrl';

import ActionButton from 'common-components/ActionButton/ActionButton';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import { H1, SpacedH3, P } from 'assets/styles';
import contract from 'assets/new-icons/task-checklist.svg';

export const Img = styled.img`
    padding-top: 10px;
    height: 200px;
`;

export const buttonsContainer = css`
    padding-top: 35px;
    min-height: 100px;
`;

export function LeaseSignedPage(props) {
    const {
        retried,
        error,
        url,
        loading: urlLoading,
        fetchLeaseDocumentUrl,
    } = useGetLeaseDocumentUrl({ leaseTransaction: props.leaseTransaction });

    const documentType = getDocumentTypeLabelForTransaction(props.leaseTransaction);

    const unit = props.unit;
    const community = props.community;
    if (!unit || !community) return null;

    const getButtonText = (documentType, applicant) => {
        if (applicant.is_lease_signature_being_processed) {
            return 'Signature Processing';
        }
        if (urlLoading) {
            return `Loading ${documentType}...`;
        }
        if (url) {
            return `Review ${documentType}`;
        }
        return `Retrieve ${documentType}...`;
    };

    return (
        <>
            <H1>Thanks for Signing!</H1>
            <SpacedH3>{`We’ll send you an email when everyone has signed the ${documentType.toLowerCase()}.`}</SpacedH3>
            <Img src={contract} />
            <div>
                <P fontSize={14}>
                    {community.display_name} Unit {unit.unit_number}
                </P>
            </div>
            <div className={buttonsContainer}>
                {retried && !!error && <GenericFormMessage type="error" messages={error} />}
                <ActionButton
                    disabled={urlLoading || props.applicant.is_lease_signature_being_processed}
                    onClick={url ? undefined : fetchLeaseDocumentUrl}
                    href={url}
                    marginTop={30}
                    variant="outlined"
                >
                    {getButtonText(documentType, props.applicant)}
                </ActionButton>
            </div>
        </>
    );
}

LeaseSignedPage.propTypes = {
    unit: PropTypes.object,
    community: PropTypes.object,
    leaseTransaction: PropTypes.object.isRequired,
    applicant: PropTypes.object,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    unit: state.transaction && state.transaction.unit,
    leaseTransaction: state.transaction,
    community: state.configuration && state.configuration.community,
});

export default connect(mapStateToProps)(captureRoute(LeaseSignedPage, ROUTES.LEASE_SIGNED));
