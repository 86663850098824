import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CardHeader, Collapse, IconButton, Typography, Divider } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { VACATE_TERMINATION_OPTION_FEE } from 'constants/leaseTransaction';
import { getDateLabel } from 'pages/LeaseTransactions/utils/helpers';
import LeaseAccordionContent from 'pages/Leases/components/LeaseAccordionContent';

export const getCardTitle = (lease) => {
    const { termination_option, lease_start_date, move_out_date, lease_end_date } = lease;
    if (termination_option === VACATE_TERMINATION_OPTION_FEE) {
        return `${getDateLabel(lease_start_date)} - ${getDateLabel(move_out_date)}`;
    }

    return `${getDateLabel(lease_start_date)} - ${getDateLabel(lease_end_date)}`;
};

export function LeaseAccordion({ lease, defaultsToExpanded, classes, history, isLastAccordion }) {
    const [expanded, setExpanded] = useState(defaultsToExpanded);
    const title = useMemo(() => getCardTitle(lease), [lease]);

    return (
        <>
            <CardHeader
                className={classes.subHeaderContainer}
                data-testid="subheader"
                title={
                    <Typography className={`${classes.typography} ${classes.h4}`} variant="h4">
                        {title}
                    </Typography>
                }
                action={
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={() => setExpanded(!expanded)}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            />
            <Divider />
            <Collapse in={expanded}>
                <LeaseAccordionContent lease={lease} classes={classes} history={history} />
                {!isLastAccordion && <Divider />}
            </Collapse>
        </>
    );
}

LeaseAccordion.propTypes = {
    lease: PropTypes.object,
    classes: PropTypes.object,
    defaultsToExpanded: PropTypes.bool,
    isLastAccordion: PropTypes.bool,
    history: PropTypes.object.isRequired,
};

export default LeaseAccordion;
