import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { css } from 'emotion';

import {
    ROUTES,
    FINANCIAL_SITUATION_VERIFICATION_PROVIDER_PLAID,
    FINANCIAL_SITUATION_VERIFICATION_PROVIDER_FINICITY,
} from 'constants/constants';
import GreenCheckIcon from 'common-components/GreenCheckIcon/GreenCheckIcon';
import ActionButton from 'common-components/ActionButton/ActionButton';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import { styles, LinkButton, P, Bold, SpacedH1, SpacedH6 } from 'assets/styles';
import safeImage from 'assets/new-icons/task-list-cash.svg';
import padlockImage from 'assets/images/connect-bank/padlock.png';
import thunderImage from 'assets/images/connect-bank/thunder.jpg';
import withTransactionPath from 'utils/withTransactionPath';

const bodyContainer = css`
    margin: 40px 10px 40px 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    p {
        margin-left: 15px;
    }
`;

const iconColumn = css`
    margin-top: 5px;
`;

const bodyRow = css`
    display: flex;
    margin-bottom: 20px;
`;

const VerifyIncomeActionButtons = ({
    configuration = {},
    leaseTransaction = {},
    disabled = false,
    reportNoIncomeAssets = () => {},
    openVerificationProviderIframe = () => {},
    getTransactionPath = () => {},
}) => {
    if (!configuration.enable_automatic_income_verification) {
        return (
            <div data-testid="action-buttons">
                <Link
                    to={getTransactionPath(ROUTES.INCOME_VERIFICATION_SUMMARY, {
                        lease_transaction_id: leaseTransaction.id,
                    })}
                    className={styles.linkNoStyle}
                >
                    <ActionButton variant="contained" disabled={disabled} marginBottom={20}>
                        Add Income/Assets
                    </ActionButton>
                </Link>
                <ActionButton variant="outlined" disabled={disabled} onClick={reportNoIncomeAssets}>
                    I Don&apos;t Have Income or Assets
                </ActionButton>
            </div>
        );
    }

    if (
        configuration.financial_situation_verification_provider === FINANCIAL_SITUATION_VERIFICATION_PROVIDER_FINICITY
    ) {
        return (
            <div data-testid="action-buttons">
                <ActionButton
                    variant="contained"
                    disabled={disabled}
                    onClick={() => openVerificationProviderIframe()}
                    marginBottom={20}
                >
                    Link Bank Account
                </ActionButton>
                <Link
                    to={getTransactionPath(ROUTES.INCOME_VERIFICATION_SUMMARY, {
                        lease_transaction_id: leaseTransaction.id,
                    })}
                    className={styles.linkNoStyle}
                >
                    <ActionButton variant="outlined" disabled={disabled} marginBottom={20}>
                        I’d Rather Manually Add Income/Assets
                    </ActionButton>
                </Link>
                <ActionButton variant="outlined" disabled={disabled} onClick={reportNoIncomeAssets}>
                    I Don&apos;t Have Income or Assets
                </ActionButton>
            </div>
        );
    }

    if (configuration.financial_situation_verification_provider === FINANCIAL_SITUATION_VERIFICATION_PROVIDER_PLAID) {
        const showLinkSourcesButton = configuration.plaid_link_bank_flow_enabled;
        const showAddManualSourcesButton =
            configuration.plaid_payroll_digital_flow_enabled || configuration.plaid_payroll_manual_flow_enabled;
        return (
            <div data-testid="action-buttons">
                {showLinkSourcesButton && (
                    <ActionButton
                        variant="contained"
                        disabled={disabled}
                        onClick={() => openVerificationProviderIframe()}
                        marginBottom={20}
                    >
                        Link Bank Account
                    </ActionButton>
                )}
                {showAddManualSourcesButton && (
                    <Link
                        to={getTransactionPath(ROUTES.INCOME_VERIFICATION_SUMMARY, {
                            lease_transaction_id: leaseTransaction.id,
                        })}
                        className={styles.linkNoStyle}
                    >
                        <ActionButton
                            variant={!showLinkSourcesButton ? 'contained' : 'outlined'}
                            disabled={disabled}
                            marginBottom={20}
                        >
                            Add Income/Asset Sources
                        </ActionButton>
                    </Link>
                )}
                <LinkButton disabled={disabled} onClick={reportNoIncomeAssets}>
                    I Don&apos;t Have Income or Assets
                </LinkButton>
            </div>
        );
    }
};

VerifyIncomeActionButtons.propTypes = {
    configuration: PropTypes.object,
    leaseTransaction: PropTypes.object,
    disabled: PropTypes.bool,
    openVerificationProviderIframe: PropTypes.func,
    reportNoIncomeAssets: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
};

export const VerifyIncome = ({
    errors,
    disabled,
    leaseTransaction,
    configuration,
    reportNoIncomeAssets,
    getTransactionPath,
    openVerificationProviderIframe,
}) => {
    return (
        <>
            <SpacedH1 variant="h1">Verify Your Income and Assets</SpacedH1>
            <SpacedH6 variant="subtitle1">
                Verifying your income and/or assets will help us ensure that you qualify for this apartment.
            </SpacedH6>
            <img src={safeImage} alt="vault" />
            <div className={bodyContainer}>
                {configuration.enable_automatic_income_verification &&
                configuration.financial_situation_verification_provider ===
                    FINANCIAL_SITUATION_VERIFICATION_PROVIDER_FINICITY ? (
                    <>
                        <div className={bodyRow}>
                            <>
                                <img src={thunderImage} alt="thunder" width="18" height="28" className={iconColumn} />
                                <P>
                                    <Bold>Link your bank account for instant verification.</Bold> This option works best
                                    if your income is directly deposited to your bank account.
                                </P>
                            </>
                        </div>
                        <div className={bodyRow}>
                            <img src={padlockImage} alt="padlock" width="18" height="28" className={iconColumn} />
                            <P>
                                <Bold>Your data is safe.</Bold> Bank level encryption is used to connect and your
                                credentials are never stored.
                            </P>
                        </div>
                        <div className={bodyRow}>
                            <GreenCheckIcon />
                            <P>
                                <Bold>Your money is safe.</Bold> This does not authorize any transactions from your
                                account.
                            </P>
                        </div>
                    </>
                ) : (
                    !configuration.enable_automatic_income_verification && (
                        <div className={bodyRow}>
                            <img src={padlockImage} alt="padlock" width="18" height="28" className={iconColumn} />
                            <P>
                                <Bold>Your data is safe.</Bold> Bank level encryption is used to ensure your uploaded
                                statements and documents remain confidential and protected.
                            </P>
                        </div>
                    )
                )}
            </div>
            {!!errors && <GenericFormMessage type="error" messages={errors} />}
            <VerifyIncomeActionButtons
                configuration={configuration}
                leaseTransaction={leaseTransaction}
                disabled={disabled}
                reportNoIncomeAssets={reportNoIncomeAssets}
                openVerificationProviderIframe={openVerificationProviderIframe}
                getTransactionPath={getTransactionPath}
            />
        </>
    );
};

VerifyIncome.propTypes = {
    configuration: PropTypes.object,
    leaseTransaction: PropTypes.object,
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    disabled: PropTypes.bool,
    openVerificationProviderIframe: PropTypes.func,
    reportNoIncomeAssets: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
};

export default withTransactionPath(VerifyIncome);
