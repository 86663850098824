import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, CardHeader, Typography, Divider } from '@material-ui/core';

import { leaseTransactionType } from 'types';

import LeaseAccordion from 'pages/Leases/components/LeaseAccordion';
import LeaseTransactionAccordion from 'pages/LeaseTransactions/components/LeaseTransactionAccordion';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '25px',
        paddingTop: '22px',
        marginBottom: theme.spacing(2),
        '&:last-child': {
            marginBottom: 0,
        },
    },
    expand: {
        marginTop: '7px',
        padding: '6px',
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    accordionContent: {
        marginTop: '16px',
        marginBottom: '16px',
    },
    typography: {
        color: '#828796',
        paddingBotttom: '2px',

        '& span': {
            color: 'black',
        },
    },
    headerContainer: {
        padding: '0px',
        paddingBottom: '22px',
    },
    subHeaderContainer: {
        padding: '0px',
        paddingTop: '7px',
        paddingBottom: '6px',
    },
    h2: {
        fontWeight: 700,
        fontSize: '1rem',
        color: '#000',
        paddingBottom: '4px',
    },
    body: {
        color: '#000',
    },
    errorNotice: {
        display: 'flex',
        alignItems: 'center',
        color: '#FB6D68',
        '& span': {
            paddingLeft: '5px',
        },
    },
    myActivityButton: {
        width: '100%',
        justifyContent: 'center',
        paddingTop: '25px !important',
    },
    renewalNotice: {
        color: '#FB6D68',
        display: 'flex',
        alignItems: 'flex-start',
        '& svg': {
            paddingRight: '5px',
        },
    },
    renewalDecision: {
        color: '#3539C3',
        display: 'flex',
        alignItems: 'flex-start',
        '& svg': {
            paddingRight: '5px',
            paddingTop: '2px',
        },
        '& a': {
            color: '#3539C3',
        },
    },
}));

function UnitCard({ title, leaseTransactions, leases, leaseTransactionToInviteeMap, setError, history, unitCardRef }) {
    const classes = useStyles();
    const accordionDefaultExpandedState = useMemo(
        () =>
            (leaseTransactions ?? leases).reduce((acc, accordionEntity) => {
                return { [accordionEntity.id]: accordionEntity.active, ...acc };
            }, {}),
        [leaseTransactions, leases]
    );

    if ((!leaseTransactions && !leases) || (leaseTransactions && leases)) {
        return null;
    }

    return (
        <Card className={classes.root} elevation={2} ref={unitCardRef}>
            <CardHeader
                className={classes.headerContainer}
                data-testid="header"
                title={
                    <Typography className={`${classes.typography} ${classes.h2}`} variant="h2">
                        {title}
                    </Typography>
                }
            />
            <Divider />

            {leaseTransactions &&
                leaseTransactions.map((leaseTransaction, index) => (
                    <Fragment key={index}>
                        <LeaseTransactionAccordion
                            classes={classes}
                            leaseTransaction={leaseTransaction}
                            defaultsToExpanded={accordionDefaultExpandedState[leaseTransaction.id]}
                            setError={setError}
                            invitee={leaseTransactionToInviteeMap[leaseTransaction.id]}
                            isLastAccordion={leaseTransactions.length - 1 === index}
                        />
                    </Fragment>
                ))}

            {leases &&
                leases.map((lease, index) => (
                    <Fragment key={lease.id}>
                        <LeaseAccordion
                            classes={classes}
                            lease={lease}
                            defaultsToExpanded={accordionDefaultExpandedState[lease.id]}
                            history={history}
                            isLastAccordion={leases.length - 1 === index}
                        />
                    </Fragment>
                ))}
        </Card>
    );
}

UnitCard.propTypes = {
    leaseTransactions: PropTypes.arrayOf(leaseTransactionType),
    leases: PropTypes.array,
    title: PropTypes.string.isRequired,
    setError: PropTypes.func.isRequired,
    leaseTransactionToInviteeMap: PropTypes.object,
    history: PropTypes.object,
    unitCardRef: PropTypes.func,
};

export default UnitCard;
