import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
// PostalCodeElement is only available from our own patch 3.0.1, see patches/react-stripe-elements+3.0.1.patch
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    PostalCodeElement,
    injectStripe,
} from 'react-stripe-elements';

import { MOCKY } from 'config';
import API from 'api/api';
import { ROUTES } from 'constants/constants';
import { getGenericErrorMessage } from 'constants/messages';
import { prettyCurrency, prettyFormatPhoneNumber } from 'utils/misc';

import { fetchApplicant } from 'reducers/applicant';
import { fetchTransaction } from 'reducers/transaction';
import { actions as loaderActions } from 'reducers/loader';
import { selectors as configSelectors } from 'reducers/configuration';
import { selectors as profileSelectors } from 'reducers/renter-profile';
import { getShowBillingZipCode } from 'selectors/launchDarkly';

import ActionButton from 'common-components/ActionButton/ActionButton';
import StripeElementWrapper from 'common-components/Stripe/StripeElementWrapper/StripeElementWrapper';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import { CustomTypographyBody2 } from 'assets/styles';
import mockReceipt from 'reducers/fixtures/mock-receipt';

export const GENERIC_ERROR_MESSAGE = getGenericErrorMessage("we're having trouble processing your payment");

export const CARD_DECLINE_ERROR_MESSAGE =
    "Oops, we're having trouble processing your payment because your card was declined. Please try a different card.";

export const AVAILABILITY_ERROR_MESSAGE = (unit, contactPhone) =>
    `We’re having trouble verifying if ${unit ? `unit ${unit.unit_number}` : 'this unit'} is still available. Please ` +
    `try again in a bit. If you continue to see this error, please contact our Leasing Office at ${contactPhone} and ` +
    'an agent will be able to assist you.';

export const UNAVAILABLE_ERROR_MESSAGE = (unit, contactPhone) =>
    `Sorry, ${unit ? `unit ${unit.unit_number}` : 'this unit'} is no longer available. Call us at ${contactPhone} ` +
    'and we can help you find a similar one!';

export class PaymentForm extends React.Component {
    state = {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false,
        postalCode: false,
        submitting: false,
        errors: null,
        unitUnavailable: false,
    };

    handleChangeUpdate = (changeObj) => {
        this.setState((prevState) => ({
            ...prevState,
            [changeObj.elementType]: changeObj.complete,
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (MOCKY) {
            this.setState({ submitting: false });
            return this.props.onSuccess(mockReceipt);
        }

        this.props.toggleLoader(true);
        this.props.setDisableBack(true);
        this.setState({ submitting: true, errors: undefined });

        try {
            const tokenResponse = await this.props.stripe.createToken({
                type: 'card',
                name: 'client card',
            });
            if (!tokenResponse.token) throw new Error('No token');

            const stripePaymentResponse = await API.stripePayment(this.props.leaseTransaction.id, {
                token: tokenResponse.token.id,
                payables: this.props.payments,
                total: this.props.totalPayment,
            });
            if (stripePaymentResponse.errors) throw stripePaymentResponse;

            await this.props.fetchApplicant();
            await this.props.fetchTransaction();
            this.props.onSuccess(stripePaymentResponse);
        } catch (e) {
            this.setState({ errors: [this.getErrorMessage(e)] });
        } finally {
            this.setState({ submitting: false });
            this.props.setDisableBack(false);
            this.props.toggleLoader(false);
        }
    };

    getErrorMessage(errorResponse) {
        const errorType = errorResponse?.error_type;
        if (errorType === 'UnitPmsUnavailableError' || errorType === 'UnitTemporarilyUnavailableError') {
            return AVAILABILITY_ERROR_MESSAGE(this.props.unit, prettyFormatPhoneNumber(this.props.contactPhone));
        }

        if (errorType === 'UnitUnavailableError') {
            this.setState({ unitUnavailable: true });
            return UNAVAILABLE_ERROR_MESSAGE(this.props.unit, prettyFormatPhoneNumber(this.props.contactPhone));
        }

        // Stripe error types: https://stripe.com/docs/api/errors
        if (errorResponse?.errors?.error?.type === 'card_error') {
            // List of decline codes: https://stripe.com/docs/declines/codes
            return CARD_DECLINE_ERROR_MESSAGE;
        }

        return GENERIC_ERROR_MESSAGE;
    }

    render() {
        const { showZipCodeField } = this.props;
        const { cardNumber, cardExpiry, cardCvc, postalCode, submitting, unitUnavailable, errors } = this.state;

        return (
            <form onSubmit={this.handleSubmit}>
                {!!errors && <GenericFormMessage type="error" messages={errors} />}
                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <StripeElementWrapper
                            label="Credit/Debit Card Number"
                            component={CardNumberElement}
                            handleChangeUpdate={this.handleChangeUpdate}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <StripeElementWrapper
                            label="Expiration Date"
                            component={CardExpiryElement}
                            handleChangeUpdate={this.handleChangeUpdate}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <StripeElementWrapper
                            label="CVC"
                            component={CardCVCElement}
                            handleChangeUpdate={this.handleChangeUpdate}
                        />
                    </Grid>
                    {showZipCodeField && (
                        <Grid item xs={12}>
                            <StripeElementWrapper
                                label="Zip"
                                component={PostalCodeElement}
                                handleChangeUpdate={this.handleChangeUpdate}
                            />
                        </Grid>
                    )}
                </Grid>
                <CustomTypographyBody2 align="left" margin="37px 0 0 0">
                    Stripe and its affiliates will be processing this transaction for Funnel Leasing. Please see their{' '}
                    <Link to={ROUTES.TERMS} target="_blank">
                        terms of service
                    </Link>{' '}
                    for more information.
                </CustomTypographyBody2>
                <ActionButton
                    marginTop={35}
                    marginBottom={20}
                    disabled={
                        submitting ||
                        !cardNumber ||
                        !cardExpiry ||
                        !cardCvc ||
                        (showZipCodeField && !postalCode) ||
                        unitUnavailable
                    }
                >
                    <Lock style={{ width: 16, marginRight: 8 }} />
                    {`Pay ${prettyCurrency(this.props.totalPayment)}`}
                </ActionButton>
            </form>
        );
    }
}

PaymentForm.propTypes = {
    leaseTransaction: PropTypes.object,
    stripe: PropTypes.object,
    unit: PropTypes.object,
    payments: PropTypes.array,
    totalPayment: PropTypes.number,
    contactPhone: PropTypes.string,
    showZipCodeField: PropTypes.bool,
    onSuccess: PropTypes.func,
    setDisableBack: PropTypes.func,
    toggleLoader: PropTypes.func,
    fetchApplicant: PropTypes.func,
    fetchTransaction: PropTypes.func,
};

const mapStateToProps = (state) => ({
    contactPhone: configSelectors.selectCommunityContactPhoneNumber(state),
    unit: profileSelectors.selectUnit(state),
    leaseTransaction: state.transaction,
    showZipCodeField: getShowBillingZipCode(state),
});

const mapDispatchToProps = {
    fetchApplicant,
    fetchTransaction,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(PaymentForm));
