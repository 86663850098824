import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Adjust box shawdow to match AvailableLeaseTermsSelector box shadow (elevation 8)
const useStyles = makeStyles((theme) => ({
    root: {
        // Keep the clear functionality without showing the icon!
        '& .MuiAutocomplete-inputRoot': {
            paddingRight: '30px !important',
        },
        '& .MuiAutocomplete-clearIndicator': {
            display: 'none',
        },
    },
    paper: {
        boxShadow: theme.shadows[8],
    },
}));

export default function AvailableUnitsSelector({ value, disabled, error, helperText, availableUnits, onChange }) {
    const classes = useStyles();

    const handleChange = useCallback(
        (_, u) => {
            onChange(u);
        },
        [onChange]
    );

    const defaultProps = {
        options: availableUnits,
        getOptionLabel: (u) => u.unit_number,
        getOptionSelected: (option, value) => option.id === value.id,
    };

    return (
        <Autocomplete
            {...defaultProps}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label="Select Unit"
                    error={error}
                    helperText={helperText}
                    disabled={disabled}
                />
            )}
        />
    );
}

AvailableUnitsSelector.propTypes = {
    value: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.bool,
    availableUnits: PropTypes.array,
    onChange: PropTypes.func.isRequired,
};
