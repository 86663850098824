import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { SpacedH1, SpacedH6 } from 'assets/styles';
import { ROUTES, RENTER_PROFILE_TYPE_CO_APPLICANTS } from 'constants/constants';
import { serializeDate } from 'utils/misc';

import { updateTransaction } from 'reducers/transaction';
import { actions as loaderActions } from 'reducers/loader';

import BackLink from 'common-components/BackLink/BackLink';
import InviteForm from 'common-components/InviteForm/InviteForm';
import ConfirmationPage from 'pages/Confirmation';
import roommatesImage from 'assets/new-icons/streamlined-household.svg';
import inviteConfirm from 'assets/new-icons/send-email-fly-steamline.svg';
import withTransactionPath from 'utils/withTransactionPath';

export class InviteRoommatesPage extends Component {
    state = { confirmSent: false, errors: null };

    onSubmit = (values, { setSubmitting }) => {
        this.props.toggleLoader(true);
        this.setState({ errors: null });

        this.props
            .updateTransaction({ co_applicants: [values] })
            .then(() => {
                this.setState({ confirmSent: true });
            })
            .catch(() => {
                this.setState({
                    errors: [
                        'There was an error sending your roommate an invite. ' +
                            'Make sure you are not using the same email or phone number. ' +
                            'Please Try again.',
                    ],
                });
            })
            .finally(() => {
                this.props.toggleLoader(false);
                setSubmitting(false);
            });
    };

    onSubmitDependent = (values, { setSubmitting, setErrors }) => {
        this.props.toggleLoader(true);

        const serialized = Object.assign({}, values);
        serialized.birthday = serializeDate(serialized.birthday);

        this.props
            .updateTransaction({ dependents: [serialized] })
            .then((res) => {
                if (res.errors) {
                    const errorsObj = get(res, 'errors.dependents');
                    const errors = errorsObj && Object.values(errorsObj)[0];
                    errors
                        ? setErrors(errors)
                        : this.setState({ errors: ['There was an error adding your dependent. Please Try again.'] });
                } else {
                    this.props.history.push(
                        this.props.getTransactionPath(`${ROUTES.PROFILE_OPTIONS}#${RENTER_PROFILE_TYPE_CO_APPLICANTS}`)
                    );
                }
            })
            .catch((res) => {
                this.setState({ errors: [res.errors] });
            })
            .finally(() => {
                this.props.toggleLoader(false);
                setSubmitting(false);
            });
    };

    canInviteMore() {
        // manually setting this until product figures out how we want to determine the limit
        return this.props.leaseTransaction.co_applicants.length < 10;
    }

    render() {
        if (!this.props.leaseTransaction) return null;
        const url = this.props.getTransactionPath(`${ROUTES.PROFILE_OPTIONS}#${RENTER_PROFILE_TYPE_CO_APPLICANTS}`);
        if (this.state.confirmSent) {
            return (
                <ConfirmationPage
                    successMessage="Invite Sent!"
                    secondarySuccessMessage="You’ll be able to check in on your roommate’s progress once you complete your application."
                    buttonClick={() => {
                        this.props.history.push(url);
                    }}
                    buttonText="Continue"
                    secondaryButtonClick={this.canInviteMore() ? () => this.setState({ confirmSent: false }) : null}
                    secondaryButtonText="Add Another Person"
                    confirmationImage={inviteConfirm}
                />
            );
        }

        return (
            <>
                <SpacedH1 variant="h1">Add a Person</SpacedH1>
                <SpacedH6 variant="subtitle1">Enter their info below.</SpacedH6>
                <img src={roommatesImage} alt="hand with smartphone in it" width={100} height={100} />
                <InviteForm
                    initialIsDependent={null}
                    onSubmitDependent={this.onSubmitDependent}
                    handleOnSubmit={this.onSubmit}
                    displayedErrors={this.state.errors}
                />
                <BackLink to={url} />
            </>
        );
    }
}

InviteRoommatesPage.propTypes = {
    leaseTransaction: PropTypes.object,
    history: PropTypes.object,
    toggleLoader: PropTypes.func,
    updateTransaction: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
});

const mapDispatchToProps = {
    updateTransaction,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(InviteRoommatesPage));
