import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { isMobileOrTablet, browserName, osName } from 'utils/mobileDetect';
import { MOCKY, CHUCK_BASE_URL } from 'config';
import { ROUTES } from 'constants/constants';
import auth from 'utils/auth';
import { getLeaseSettingsIdFromUrl } from 'utils/routingHelpers';
import { VeryGoodSecurityApi } from 'api/api';

const defaultHeaders = {
    accept: 'application/json',
};

export function getPath(path) {
    return `${CHUCK_BASE_URL}/api/onlineleasing${path}`;
}

export function getVGSPath(path) {
    return new VeryGoodSecurityApi().vaultUrl + `/api/onlineleasing${path}`;
}

const attachUserAgentParams = (url) => {
    const userAgentData = {
        'device-type': isMobileOrTablet ? 'mobile' : 'desktop',
        'os-type': osName,
        'browser-type': browserName,
    };

    if (url.indexOf('?') < 0) {
        return `${url}?${queryString.stringify(userAgentData)}`;
    } else {
        return `${url}&${queryString.stringify(userAgentData)}`;
    }
};

export default async (path, args) => {
    args = args || {};
    const {
        method = 'GET',
        data = undefined,
        file = undefined,
        formData = undefined,
        vgsEnabled = false,
        collectUserAgentData = false,
        responseFormat = 'JSON',
        fullUrl = false,
        params = undefined,
    } = args;
    if (MOCKY) return Promise.reject();

    let url = fullUrl ? path : !vgsEnabled ? getPath(path) : getVGSPath(path);
    if (collectUserAgentData) {
        url = attachUserAgentParams(url);
    }

    if (!isEmpty(params)) {
        url += `?${queryString.stringify(params)}`;
    }

    const headers = {
        ...defaultHeaders,
        Authorization: `Token ${auth.getToken()}`,
        ...args.headers,
    };

    const options = {
        headers: headers,
        method: method,
        mode: 'cors',
    };

    if (data) {
        options.body = JSON.stringify(data);
        options.headers['content-type'] = 'application/json';
    }

    if (formData) {
        options.body = formData;
    }

    if (file) {
        options.body = file;
        options.headers['content-type'] = file.type;
    }

    const response = await fetch(url, options);

    const errorType = response.headers.get('error_type');
    if (errorType === 'TokenExpiredError' || errorType === 'InvalidTokenError') {
        localStorage.access_token && localStorage.clear();

        if (!window.location.href.includes('/login')) {
            const leaseSettingsId = getLeaseSettingsIdFromUrl();
            window.location.href = `${window.location.origin}/${leaseSettingsId}${ROUTES.LOGIN}`;
        }

        return Promise.reject(errorType);
    }

    if (response.status === 204 || response.status === 205 || response.status === 202) {
        return Promise.resolve(response);
    }

    if (response.status >= 200 && response.status < 300) {
        if (responseFormat === 'JSON') {
            return response.json();
        }
        if (responseFormat === 'TEXT') {
            return response.text();
        }
        if (responseFormat === 'BLOB') {
            return response.blob();
        }
    }

    const responseError = await response.text();
    let responseStatusText = response.status;
    if (response.statusText) responseStatusText += ` - ${response.statusText}`;

    const error = new Error(response.status);
    error.name = String(responseStatusText);
    error.message = responseError;
    error.response = response;

    try {
        error.responseError = JSON.parse(responseError);
    } catch (_ignore) {
        error.responseError = responseError;
    }

    throw error;
};
