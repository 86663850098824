export const getValidationErrors = (errors) => {
    const allFieldErrors = [];
    const allSchemaErrors = [];
    for (var err in errors) {
        var fieldErrors = errors[err];

        if (fieldErrors?.message && fieldErrors?.sign_in_url) {
            allSchemaErrors.push(fieldErrors);
            continue;
        }

        for (var field in fieldErrors) {
            if (field === '_schema') {
                allSchemaErrors.push(...fieldErrors[field]);
                continue;
            }
            allFieldErrors.push({ field: field, messages: [...fieldErrors[field]] });
        }
    }
    return { fieldErrors: allFieldErrors, schemaErrors: allSchemaErrors };
};
