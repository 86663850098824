import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import Page from 'common-components/Page/Page';

import calendarIcon from 'assets/new-icons/calendar-checkmark.svg';
import moment from 'moment';

const useStyles = makeStyles(() => ({
    root: {
        width: '80%',
        textAlign: 'center',
        margin: '20px auto',
    },
    calendarIcon: {
        width: '92px',
        height: '92px',
        marginTop: '40px',
        marginBottom: '10px',
    },
    text: {
        marginTop: '60px',
        fontWeight: '400',
        fontSize: '16px',
    },
}));

export function MonthToMonthConfirmedPage({ lease, configuration }) {
    const classes = useStyles();

    if (!lease) return null;

    const startDate = moment(lease.lease_end_date).add(1, 'day').format('MM/DD/YYYY');
    const daysBeforeRenewalDecision = configuration.days_before_renewal_decision_is_due;

    return (
        <Page
            className={classes.root}
            title="You’re All Set"
            subTitle={`Your lease will switch to month-to-month starting on ${startDate}.`}
            image={{ src: calendarIcon, className: classes.calendarIcon }}
        >
            <span>
                {lease.unit.building.street_address} Unit {lease.unit.unit_number}
            </span>
            {daysBeforeRenewalDecision ? (
                <p className={classes.text}>
                    You&#39;ll have no set lease end date, but will be required to give {daysBeforeRenewalDecision} days
                    notice before moving out.
                </p>
            ) : (
                <p className={classes.text}>You&#39;ll have no set lease end date.</p>
            )}
        </Page>
    );
}

MonthToMonthConfirmedPage.propTypes = {
    lease: PropTypes.object,
    configuration: PropTypes.object,
};

const mapStateToProps = (state) => ({
    lease: state.lease,
    configuration: state.configuration,
});

export default connect(mapStateToProps, null)(MonthToMonthConfirmedPage);
