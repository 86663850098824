import { createReducer } from '@reduxjs/toolkit';

export const SHOW_SNACKBAR = 'snackbar/showSnackbar';
export const HIDE_SNACKBAR = 'snackbar/hideSnackbar';

export const showSnackbar =
    (message, props = {}) =>
    (dispatch) => {
        return dispatch({
            type: SHOW_SNACKBAR,
            payload: {
                message,
                props,
            },
        });
    };

export const hideSnackbar = () => (dispatch) => {
    return dispatch({
        type: HIDE_SNACKBAR,
    });
};

export const actions = {
    showSnackbar,
    hideSnackbar,
};

const initialState = {
    isOpen: false,
    message: '',
    props: {},
};

export default createReducer(initialState, (builder) => {
    return builder
        .addCase(SHOW_SNACKBAR, (state, { payload: { message, props } }) => ({
            ...state,
            message,
            props,
            isOpen: true,
        }))
        .addCase(HIDE_SNACKBAR, () => ({
            ...initialState,
        }));
});

export const selectors = {};
selectors.getMessage = (state) => state.snackbar.message;
selectors.getIsOpen = (state) => state.snackbar.isOpen;
selectors.getProps = (state) => state.snackbar.props;
