import { createMuiTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import {
    defaultTextColor,
    defaultPrimaryColor,
    defaultSecondaryColor,
    bodyFontSize,
    condensedFontStretch,
    bodyTypography,
    bodyStrongTypography,
    bodySecondaryTypography,
    heading1Typography,
    heading2Typography,
    heading3Typography,
    defaultGrayColor,
    defaultLinkColor,
    textLinkTypography,
    textLinkSecondaryTypography,
    linkTypography,
    linkSecondaryTypography,
} from './constants';

export const createTheme = (config) => {
    if (isEmpty(config)) return;

    const { primary_color, secondary_color, dark_mode } = config;
    const darkMode = dark_mode;
    const primaryColor = primary_color ? `#${primary_color}` : defaultPrimaryColor;
    const secondaryColor = secondary_color ? `#${secondary_color}` : defaultSecondaryColor;
    const fontStretch = { fontStretch: condensedFontStretch };

    const theme = createMuiTheme({
        // Important note: don't set palette.type to 'dark' based on dark mode
        // This will put all material ui's components in a dark mode state - which will cause a lot of styling issues
        // Instead, we use our this darkMode variable
        darkMode: darkMode,

        typography: {
            useNextVariants: true,
            fontFamily: ['Open Sans', 'Tahoma', 'sans-serif'],
            h1: heading1Typography,
            h2: heading2Typography,
            h3: heading3Typography,
            h4: {
                ...bodyTypography,
                color: defaultGrayColor,
            },
            h5: fontStretch,
            h6: fontStretch,
            body1: bodyTypography,
            body2: bodySecondaryTypography,
            subtitle1: {
                ...bodyStrongTypography,
            },
            htmlFontSize: bodyFontSize,
            fontWeightRegular: 400,
            fontWeightBold: 600,
            caption: {
                ...bodySecondaryTypography,
                color: '#828796',
            },
            textLink: textLinkTypography,
            textLink2: textLinkSecondaryTypography,
            link: linkTypography,
            link2: linkSecondaryTypography,
        },
        palette: {
            text: {
                primary: defaultTextColor,
            },
            primary: {
                main: primaryColor,
            },
            secondary: {
                main: secondaryColor,
            },
            background: {
                default: '#ffffff',
            },
            link: defaultLinkColor,
            divider: '#EEEEEE',
        },
        assets: {
            logo: config.logo,
            background: config.background,
        },
    });

    // Overwrite component styling globally so when using the styling will be consistent
    // https://v4.mui.com/customization/globals/#css
    theme.overrides = {
        MuiCardHeader: {
            title: {
                fontSize: bodyFontSize,
                fontWeight: 600,
            },
            subheader: {
                fontSize: bodyFontSize,
                fontWeight: 400,
            },
        },
        // Note: the default padding of the card content is 16px, 16px, 24px, 16px
        // This override addresses that by forcing it all to 16px
        MuiCardContent: {
            root: {
                '&:last-child': {
                    padding: `${theme.spacing(2)}px !important`,
                },
            },
        },
    };

    return theme;
};
