import { css } from 'emotion';

export const container = css`
    margin-bottom: 10px;
    a {
        text-decoration: none;
    }
`;

export const errorColor = css({
    color: '#FB6D68',
});

export const paperRoot = css({
    boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.1) !important',
    borderWidth: '0px !important',
    padding: '15px !important',
    borderRadius: '5px',
});

export const prefix = css({
    fontSize: '55px',
    height: '55px',
    lineHeight: '67px',
    width: '100px',
    textAlign: 'center',
    justifyContent: 'center',

    '> img': {
        maxHeight: 48,
    },
});

export const label = css({
    color: '#454b57',
    flex: 1,
    textAlign: 'left',
});

export const description = css({
    color: '#828796',
    paddingBottom: '16px',
});

export const contentContainer = css({
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    textTransform: 'none',
    paddingBottom: '16px',
});

export const anchor = css({
    position: 'relative',
    top: '-90px',
});
