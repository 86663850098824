import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    contentWrapper: {
        display: 'flex',
        padding: '20px 10px 0',
    },
    disclaimerParagraph: {
        fontSize: '12px',
        textAlign: 'left',
        margin: 0,
    },
}));

export const LeaseOfferDisclaimer = ({ disclaimerCopy }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.contentWrapper)}>
            <p className={classes.disclaimerParagraph}>* {disclaimerCopy}</p>
        </div>
    );
};

LeaseOfferDisclaimer.propTypes = {
    disclaimerCopy: PropTypes.string.isRequired,
    classes: PropTypes.object,
};

export default LeaseOfferDisclaimer;
