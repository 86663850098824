import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, styled as materialStyled } from '@material-ui/core';
import { Info as InfoIcon, Error as ErrorIcon } from '@material-ui/icons';
import { css } from 'emotion';

import {
    ROUTES,
    ROLE_GUARANTOR,
    INCOME_TYPE_FINICITY_AUTOMATED,
    INCOME_TYPE_PLAID_PAYROLL,
    INCOME_TYPE_PLAID_BANK,
    PLAID_TYPES,
    ALL_INCOME_OR_ASSET_TYPES,
    FINANCIAL_SITUATION_VERIFICATION_PROVIDER_PLAID,
    FINANCIAL_STREAM_ASSET,
    APPLICANT_EVENTS,
    MILESTONE_APPLICANT_SUBMITTED,
    MILESTONE_FINANCIAL_STREAM_MISSING_DOCUMENTS_REQUESTED,
    ASSET_TYPE_PLAID,
    FINANCIAL_STREAM_STATUS_INCOMPLETE,
    FINANCIAL_STREAM_STATUS_APPROVED,
    FINANCIAL_STREAM_STATUS_PENDING,
    FINANCIAL_STREAM_STATUS_UNABLE_TO_VERIFY,
} from 'constants/constants';
import { prettyCurrency } from 'utils/misc';
import { getNextRoute, reloadWindowForLeaseTransaction } from 'utils/routingHelpers';
import withTransactionPath from 'utils/withTransactionPath';
import { getIncompleteFinancialSourceWarning } from 'pages/Banking/bankingUtils';
import { fetchApplicant } from 'reducers/applicant';

import SimplePopover from 'common-components/SimplePopover/SimplePopover';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import Capsule from 'common-components/Capsule/Capsule';
import ActionButton from 'common-components/ActionButton/ActionButton';
import BackLink from 'common-components/BackLink/BackLink';
import ExistingItemsExpansionPanel from 'common-components/ExistingItemsExpansionPanel/ExistingItemsExpansionPanel';
import ResetApplicantFinancials from 'pages/Banking/components/ResetApplicantFinancials';
import BankingContext from 'pages/Banking/BankingContext';

import { SpacedH1, SpacedH6, styles, Spacer, infoIconRoot } from 'assets/styles';
import { bodyFontSize } from 'assets/constants';
import finance from 'assets/new-icons/currency-dollar-circle.svg';
import piggyBank from 'assets/new-icons/saving-piggy-coins.svg';
import { LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE } from 'constants/leaseTransaction';

const linkStyle = {
    textDecoration: 'underline',
    fontSize: bodyFontSize,
};

const TotalsP = materialStyled(Typography)({
    marginTop: 0,
    marginBottom: 14,
});

const marginDiv = css`
    margin-top: 0;
    margin-bottom: 14px;
`;

const totals = css`
    text-align: left;
    border-top: 1px solid #eeeeee;
    padding-top: 15px;
`;

const incomeOrAssetItemWarning = css`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 10px;

    svg {
        color: #fb6d68;
        margin-right: 10px;
    }

    span {
        color: #fb6d68;
    }

    ul {
        margin: 0;
        padding: 0;
        padding-left: 16px;
    }
`;

const disclaimerTextCSS = css`
    margin-top: 30px;
    margin-bottom: 14px;
    text-align: left;
`;

export function IncomeOrAssetItemWarning({ source, isAsset }) {
    const warning = getIncompleteFinancialSourceWarning(source, isAsset);
    if (!warning) return null;

    return (
        <div className={incomeOrAssetItemWarning}>
            <ErrorIcon />
            <span>{warning}</span>
        </div>
    );
}

IncomeOrAssetItemWarning.propTypes = {
    source: PropTypes.object.isRequired,
    isAsset: PropTypes.bool.isRequired,
};

export function IncomeOrAssetsItem({ source, leaseTransaction, getTransactionPath }) {
    const isAsset = source.stream_type === FINANCIAL_STREAM_ASSET;

    const getSourceLabel = useCallback((source) => {
        if (source.finicity_income_stream_id && source.other) {
            return source.other;
        }
        return ALL_INCOME_OR_ASSET_TYPES[source.income_or_asset_type]?.label;
    }, []);

    const getProofString = useCallback((source) => {
        if (source.income_or_asset_type === INCOME_TYPE_FINICITY_AUTOMATED) {
            return 'Linked bank account';
        } else if (source.income_or_asset_type === INCOME_TYPE_PLAID_PAYROLL) {
            return 'Linked payroll';
        } else if (source.income_or_asset_type === INCOME_TYPE_PLAID_BANK) {
            return 'Linked bank';
        } else if (source.income_or_asset_type === ASSET_TYPE_PLAID) {
            return 'Linked asset';
        }

        // find all document type labels
        const typesSet =
            source.uploaded_documents?.reduce((accum, doc) => {
                if (doc.proof_type?.label) {
                    accum.add(doc.proof_type.label);
                }
                return accum;
            }, new Set()) || new Set();

        // join labels together with comma
        const proofs = Array.from(typesSet).join(', ');
        if (!proofs) return 'None';
        return proofs;
    }, []);

    return (
        <div>
            <IncomeOrAssetItemWarning source={source} isAsset={isAsset} />
            {source.is_plaid ? (
                <>
                    <Typography variant="body">{source?.flow_type ? source.flow_type : 'Uploaded Document'}</Typography>
                    <div className={styles.colorManatee}>
                        {source.status === FINANCIAL_STREAM_STATUS_PENDING ||
                        (source.status === FINANCIAL_STREAM_STATUS_UNABLE_TO_VERIFY &&
                            !source.method_to_resolve_unverified_documents &&
                            !source.contains_password_protected_document) ? (
                            <>
                                <Typography variant="body" variantMapping={{ body: 'span' }}>
                                    $ -- /year
                                </Typography>
                                <Typography variant="body" variantMapping={{ body: 'span' }} style={{ marginLeft: 16 }}>
                                    Verifying amount
                                </Typography>
                                <SimplePopover
                                    text={`We're currently verifying the amount. Don’t worry, you can continue with your application and we’ll update the amount once it’s verified.`}
                                >
                                    <InfoIcon
                                        classes={{ root: infoIconRoot }}
                                        style={{ color: '#828796', width: 16, marginLeft: 4 }}
                                    />
                                </SimplePopover>
                            </>
                        ) : source.status === FINANCIAL_STREAM_STATUS_APPROVED ? (
                            <div className={styles.colorManatee}>
                                <Typography variant="body" variantMapping={{ body: 'span' }}>
                                    {prettyCurrency(source.estimated_amount)}
                                </Typography>
                            </div>
                        ) : (
                            <Typography variant="body" variantMapping={{ body: 'span' }}>
                                $ -- /year
                            </Typography>
                        )}
                    </div>
                    <div className={styles.colorManatee}>
                        Proof of income:{' '}
                        <Typography variant="body">
                            {source?.flow_type ? source.flow_type : 'Uploaded Document'}
                        </Typography>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <Typography variant="body">
                            <strong>{getSourceLabel(source)}</strong>
                        </Typography>
                    </div>
                    <div className={styles.colorManatee}>
                        <Typography variant="body">{prettyCurrency(source.estimated_amount)}</Typography>
                    </div>
                    <div className={styles.colorManatee}>
                        <Typography variant="body">{`Proof of ${isAsset ? 'asset' : 'income'}: ${getProofString(
                            source
                        )}`}</Typography>
                    </div>
                </>
            )}
            {source.income_or_asset_type !== INCOME_TYPE_FINICITY_AUTOMATED &&
                !source.is_pending_plaid_verification && (
                    <>
                        {!PLAID_TYPES.includes(source.income_or_asset_type) && (
                            <>
                                <Spacer height={10} />
                                <Link
                                    style={linkStyle}
                                    to={getTransactionPath(ROUTES.EDIT_MANUAL_FINANCIAL_SOURCE, {
                                        id: source.id,
                                        lease_transaction_id: leaseTransaction.id,
                                    })}
                                >
                                    Edit
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                        )}
                        <Link
                            style={linkStyle}
                            to={getTransactionPath(ROUTES.REMOVE_FINANCIAL_SOURCE, {
                                id: source.id,
                                lease_transaction_id: leaseTransaction.id,
                            })}
                        >
                            Remove
                        </Link>
                    </>
                )}
        </div>
    );
}

IncomeOrAssetsItem.propTypes = {
    source: PropTypes.object.isRequired,
    leaseTransaction: PropTypes.object.isRequired,
    getTransactionPath: PropTypes.func.isRequired,
};

const getIsSourceIncomplete = (source) => {
    if (source.status === FINANCIAL_STREAM_STATUS_INCOMPLETE) {
        return true;
    }
};

const getIsSourceUnableToBeVerified = (source) => {
    if (source.status === FINANCIAL_STREAM_STATUS_INCOMPLETE) {
        return Boolean(
            source.plaid_is_unable_to_be_verified || source.plaid_has_fraud_risk || source.funnel_risk_signals
        );
    }
};

export function IncomeVerificationSummaryPage(props) {
    const context = React.useContext(BankingContext);
    const [showResetFinancials, setShowResetFinancials] = useState(false);

    const incomeSources = context?.bankingData?.income_sources || [];
    const assetSources = context?.bankingData?.asset_sources || [];

    const hasIncompleteIncomeSources = incomeSources?.some(getIsSourceIncomplete) || false;
    const hasIncompleteAssetSources = assetSources?.some(getIsSourceIncomplete) || false;
    const hasUnableToBeVerifiedSources = [...incomeSources, ...assetSources].some(getIsSourceUnableToBeVerified);

    const showIncompleteFinancialSourcesWarning = hasIncompleteIncomeSources || hasIncompleteAssetSources;
    const showRequestAdditionalInfoWarning = props.leaseTransaction?.events?.find(
        (event) => event.event === MILESTONE_FINANCIAL_STREAM_MISSING_DOCUMENTS_REQUESTED
    );
    const showPlaidUnableToBeVerifiedWarning = hasUnableToBeVerifiedSources;

    const setScrollPosition = useCallback(() => {
        // taken from https://github.com/ReactTraining/react-router/issues/394#issuecomment-128148470
        window.location.hash = window.decodeURIComponent(window.location.hash);
        const scrollToAnchor = () => {
            const hashParts = window.location.hash.split('#');
            if (hashParts.length > 1) {
                const hash = hashParts[1];
                const hashElement = document.querySelector(`#${hash}`);
                if (!!hashElement) {
                    hashElement.scrollIntoView();
                }
            }
        };
        scrollToAnchor();
        window.onhashchange = scrollToAnchor;
    }, []);

    useEffect(() => {
        setScrollPosition();
    }, [setScrollPosition]);

    const hashValue = props.location?.hash?.substring?.(1) ?? '';

    const getIncomeTipText = () => {
        return (
            <>
                Proof of legal, verifiable income in an amount equal to 2.0 – 3.0 times the monthly rent per household
                will be required based on pre-established requirements at the property, along with any necessary
                supporting documents.
                <br />
                Proof of income includes, but is not limited to, the following:
                <ul>
                    <li>
                        Proof of current employment (e.g., three most current paystubs within the last 45 or 90 days,
                        depending on the payment cycle);
                    </li>
                    <li>Prior year tax return;</li>
                    <li>
                        Proof of receipt of government income (e.g., social security, disability, welfare, unemployment,
                        etc.);
                    </li>
                    <li>Proof of receipt of retirement and/or investment income;</li>
                    <li>Proof of US based assets;</li>
                    <li>Student loan income;</li>
                    <li>Child/spousal support</li>
                    <li>Any other legal, verifiable income.</li>
                </ul>
            </>
        );
    };

    const getAssetTip = () => {
        return (
            <>
                If no current income, proof of liquid assets (which includes bank statements with deposits) at 2.0 – 3.0
                the net rent for the entire lease term is required.
            </>
        );
    };

    const getIncomeRequirementText = useCallback((config, leaseTransaction, applicant) => {
        if (!leaseTransaction || !applicant || !config) return <div />;

        const { guarantor_income_requirement_multiplier, applicant_income_requirements } = config;
        const leaseTransactionUnitPrice = leaseTransaction?.unit?.price;

        if (!leaseTransactionUnitPrice) return <div />;

        const guarantor_income_amount = guarantor_income_requirement_multiplier * leaseTransactionUnitPrice;
        const applicant_income_amount = applicant_income_requirements * leaseTransactionUnitPrice;

        if (applicant?.role === ROLE_GUARANTOR) {
            return (
                <div className={marginDiv}>
                    <TotalsP variant="body2" variantMapping={{ body2: 'span' }}>
                        {prettyCurrency(guarantor_income_amount)} is the required income for guarantors.
                    </TotalsP>
                    {
                        <SimplePopover
                            text={`The required income for a guarantor is ${guarantor_income_requirement_multiplier}x the monthly rent`}
                        >
                            <InfoIcon
                                classes={{ root: infoIconRoot }}
                                style={{ color: '#828796', width: 16, marginLeft: 4 }}
                            />
                        </SimplePopover>
                    }
                </div>
            );
        }

        return (
            <div className={marginDiv}>
                <TotalsP variant="body2" variantMapping={{ body2: 'span' }}>
                    {prettyCurrency(applicant_income_amount)} is the recommended household income.
                </TotalsP>
                <SimplePopover
                    text={`It’s recommended that the yearly combined income of all applicants be ${applicant_income_requirements}x the monthly rent.`}
                >
                    <InfoIcon classes={{ root: infoIconRoot }} style={{ color: '#828796', width: 16 }} />
                </SimplePopover>
            </div>
        );
    }, []);

    const hasNotAddedFinancialSources =
        !context.bankingData?.asset_sources.length && !context.bankingData?.income_sources.length;
    const reportedNoIncomeAssets = context.bankingData?.reported_no_income_assets;
    const reportedNoIncomeWarningText = props.company?.no_income_during_application_disclaimer_text;

    const updatesWereRequested = !!props.applicant.events?.find(
        ({ event, lease_transaction }) =>
            event === APPLICANT_EVENTS.EVENT_FINANCIAL_STREAM_ADDITIONAL_DOCUMENTS_REQUESTED_EMAIL_SENT &&
            lease_transaction === props.applicant.lease_transaction_id
    );

    const applicantSubmittedApplication = !!props.applicant.events?.find(
        ({ event, lease_transaction }) =>
            event === MILESTONE_APPLICANT_SUBMITTED && lease_transaction === props.applicant.lease_transaction_id
    );

    const applicantUpdatedEmployerInfo = !!props.applicant.events?.find(
        ({ event, lease_transaction }) =>
            event === APPLICANT_EVENTS.EVENT_APPLICANT_UPDATED_EMPLOYER_INFO &&
            lease_transaction === props.applicant.lease_transaction_id
    );

    let canContinue = !hasNotAddedFinancialSources || reportedNoIncomeAssets;
    if (showRequestAdditionalInfoWarning || showIncompleteFinancialSourcesWarning) {
        canContinue = false;
    }

    const onContinue = useCallback(async () => {
        const nextRoute = getNextRoute({
            leaseTransaction: props.leaseTransaction,
            getMidLeaseChangeNextRoute: async () => {
                reloadWindowForLeaseTransaction(props.leaseTransaction);
                return null;
            },
            getRenewalNextRoute: async () => {
                reloadWindowForLeaseTransaction(props.leaseTransaction);
                return null;
            },
            getTransferNextRoute: async () => {
                reloadWindowForLeaseTransaction(props.leaseTransaction);
                return null;
            },
            getApplicationNextRoute: () => {
                if (applicantSubmittedApplication || updatesWereRequested) {
                    if (!reportedNoIncomeAssets && !applicantUpdatedEmployerInfo) {
                        return ROUTES.EMPLOYER_DETAILS;
                    }

                    window.location.reload();
                    return null;
                }

                if (props.config.collect_employer_information && !reportedNoIncomeAssets) {
                    return ROUTES.EMPLOYER_DETAILS;
                }

                return ROUTES.SCREENING;
            },
        });

        switch (nextRoute) {
            case ROUTES.EMPLOYER_DETAILS: {
                props.history.push(props.getTransactionPath(nextRoute));
                break;
            }
            case ROUTES.SCREENING: {
                await props.fetchApplicant();
                props.history.push(props.getTransactionPath(nextRoute));
                break;
            }
            case ROUTES.APP_COMPLETE:
                await props.fetchApplicant();
                props.history.push(props.getTransactionPath(nextRoute));
                break;
            // When we manually reload the window to automatically put the applicant to the correct next page
            default:
                return;
        }
    }, [
        props,
        applicantSubmittedApplication,
        updatesWereRequested,
        reportedNoIncomeAssets,
        applicantUpdatedEmployerInfo,
    ]);

    if (showResetFinancials) {
        return (
            <ResetApplicantFinancials
                onStartOverClick={async () => {
                    context.toggleLoader(true);

                    try {
                        await context.clearFinancialSources();
                        props.history.push(
                            props.getTransactionPath(ROUTES.INCOME_VERIFICATION_CONNECT, {
                                lease_transaction_id: props.leaseTransaction.id,
                            })
                        );
                    } finally {
                        context.toggleLoader(false);
                    }
                }}
                onCancel={() => setShowResetFinancials(false)}
                leaseTransaction={props.leaseTransaction}
            />
        );
    }

    let disclaimerText = '';
    if (reportedNoIncomeAssets && reportedNoIncomeWarningText) {
        disclaimerText += reportedNoIncomeWarningText;
    }
    if (
        reportedNoIncomeAssets &&
        reportedNoIncomeWarningText &&
        props.config.financial_situation_verification_provider === FINANCIAL_SITUATION_VERIFICATION_PROVIDER_PLAID
    ) {
        disclaimerText += '<br/> <br/>';
    }
    if (props.config.financial_situation_verification_provider === FINANCIAL_SITUATION_VERIFICATION_PROVIDER_PLAID) {
        disclaimerText +=
            'Income/asset amounts are estimates based on data provided by Plaid and may not reflect actual amounts.';
    }

    return (
        <>
            <SpacedH1 variant="h1">
                {reportedNoIncomeAssets ? `Confirm Income and Assets` : `Income and Asset Verification`}
            </SpacedH1>
            <SpacedH6 variant="subtitle1">
                {reportedNoIncomeAssets
                    ? `Easy, right? Now just review the info below.`
                    : `Add at least one income source or asset below.`}
            </SpacedH6>
            {showRequestAdditionalInfoWarning && (
                <GenericFormMessage
                    type="error"
                    messages={["We're requesting additional info to verify your income/assets."]}
                />
            )}
            {showPlaidUnableToBeVerifiedWarning ? (
                <GenericFormMessage
                    type="error"
                    messages={[
                        'We found some issues with one or more of your income/asset sources. Please review and update your income/assets as necessary.',
                    ]}
                />
            ) : (
                showIncompleteFinancialSourcesWarning && (
                    <GenericFormMessage
                        type="error"
                        messages={['Oops! We found some issues with one or more of your income/assets sources.']}
                    />
                )
            )}
            <Capsule
                name="income"
                prefix={<img alt="coin" src={finance} />}
                label="Income"
                buttonLabel={
                    !context.bankingData?.income_sources?.length ? 'Add Income Sources' : 'Add Another Income Source'
                }
                tip={getIncomeTipText()}
                route={
                    props.config.financial_situation_verification_provider ===
                        FINANCIAL_SITUATION_VERIFICATION_PROVIDER_PLAID &&
                    props.config.enable_automatic_income_verification
                        ? props.getTransactionPath(ROUTES.PLAID_INCOME_ENTRY_ADD_INCOME, {
                              lease_transaction_id: props.leaseTransaction.id,
                          })
                        : props.getTransactionPath(ROUTES.MANUAL_INCOME_ENTRY_ADD_INCOME, {
                              lease_transaction_id: props.leaseTransaction.id,
                          })
                }
                expansionPanel={
                    <>
                        {context.bankingData?.income_total && (
                            <div className={totals}>
                                <Typography variant="body1">
                                    <strong>
                                        {prettyCurrency(parseInt(context.bankingData?.income_total))} Total Annual
                                        Income
                                    </strong>
                                </Typography>
                                {getIncomeRequirementText(props?.config, props?.leaseTransaction, props?.applicant)}
                            </div>
                        )}
                        <ExistingItemsExpansionPanel
                            label="Income Source"
                            labelQuantity={context.bankingData?.income_sources.length}
                            defaultExpanded={hashValue === 'income' || hasIncompleteIncomeSources}
                        >
                            {context.bankingData?.income_sources?.map((source) => (
                                <IncomeOrAssetsItem
                                    key={source.id}
                                    source={source}
                                    getTransactionPath={props.getTransactionPath}
                                    leaseTransaction={props.leaseTransaction}
                                />
                            ))}
                        </ExistingItemsExpansionPanel>
                    </>
                }
            />
            <Capsule
                name="asset"
                prefix={<img alt="piggy bank" src={piggyBank} />}
                label="Assets"
                buttonLabel={!context.bankingData?.asset_sources?.length ? 'Add an Asset' : 'Add Another Asset'}
                tip={getAssetTip()}
                route={props.getTransactionPath(ROUTES.MANUAL_ASSET_ENTRY_ADD_ASSET, {
                    lease_transaction_id: props.leaseTransaction.id,
                })}
                expansionPanel={
                    <>
                        {context.bankingData?.asset_total && (
                            <div className={totals}>
                                <Typography variant="body1">
                                    <strong>
                                        {prettyCurrency(parseInt(context.bankingData?.asset_total))} Total Asset Balance
                                    </strong>
                                </Typography>
                                <div className={marginDiv}>
                                    <TotalsP variant="body2">
                                        We’ll take this number into consideration in addition to or in place of income.
                                    </TotalsP>
                                </div>
                            </div>
                        )}
                        <ExistingItemsExpansionPanel
                            label="Asset"
                            labelQuantity={context.bankingData?.asset_sources.length}
                            defaultExpanded={hashValue === 'asset' || hasIncompleteAssetSources}
                        >
                            {context.bankingData?.asset_sources?.map((source) => (
                                <IncomeOrAssetsItem
                                    key={source.id}
                                    source={source}
                                    getTransactionPath={props.getTransactionPath}
                                    leaseTransaction={props.leaseTransaction}
                                />
                            ))}
                        </ExistingItemsExpansionPanel>
                    </>
                }
            />
            {disclaimerText && (
                <div className={disclaimerTextCSS}>
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: disclaimerText }} />
                </div>
            )}
            <ActionButton
                disabled={!canContinue}
                marginTop={disclaimerText ? 0 : 30}
                marginBottom={20}
                onClick={onContinue}
            >
                {props.leaseTransaction.type === LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE ? 'Submit' : 'Continue'}
            </ActionButton>
            {hasNotAddedFinancialSources ? (
                <BackLink
                    to={props.getTransactionPath(ROUTES.INCOME_VERIFICATION_CONNECT, {
                        lease_transaction_id: props.leaseTransaction.id,
                    })}
                />
            ) : (
                <Typography
                    classes={{ root: styles.cursor }}
                    onClick={() => setShowResetFinancials(true)}
                    color="primary"
                >
                    <strong>Start Income Verification Over</strong>
                </Typography>
            )}
        </>
    );
}

IncomeVerificationSummaryPage.propTypes = {
    config: PropTypes.object,
    company: PropTypes.object,
    applicant: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    leaseTransaction: PropTypes.object.isRequired,
    getTransactionPath: PropTypes.func.isRequired,
    fetchApplicant: PropTypes.func,
};

const mapStateToProps = (state) => ({
    company: state.configuration.community.company,
    config: state.configuration,
    applicant: state.applicant,
    leaseTransaction: state.transaction,
});

const mapDispatchToProps = {
    fetchApplicant,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(IncomeVerificationSummaryPage));
