import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Snackbar as MUISnackbar } from '@material-ui/core';

import { selectors, actions } from 'reducers/snackbar';

const defaultAnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};

export function Snackbar({
    anchorOrigin = defaultAnchorOrigin,
    isOpen = false,
    message = null,
    autoHideDuration = 4000,
    onClose,
    props,
}) {
    return (
        <MUISnackbar
            anchorOrigin={anchorOrigin}
            open={isOpen}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            message={message}
            {...props}
        />
    );
}

Snackbar.propTypes = {
    /** https://material-ui.com/api/snackbar/#component-name */
    anchorOrigin: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string,
    autoHideDuration: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    // can be used to overwrite any of the Material UI's Snackbar's props
    props: PropTypes.object,
};

const mapStateToProps = (state) => ({
    isOpen: selectors.getIsOpen(state),
    message: selectors.getMessage(state),
    props: selectors.getProps(state),
});

const mapActionsToProps = {
    onClose: actions.hideSnackbar,
};

export default connect(mapStateToProps, mapActionsToProps)(Snackbar);
