import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { ROUTES } from 'constants/constants';
import {
    VERIFICATION_METHOD_PIN_CALL,
    TU_ANSWER_KEY_PIN_CALL,
    TU_ANSWER_KEY_PIN_TEXT,
} from 'constants/residentIdVerification';
import withTransactionPath from 'utils/withTransactionPath';
import { useIdentityVerification } from '../IdentityVerificationContext';

import Page from 'common-components/Page/Page';
import ActionButton from 'common-components/ActionButton/ActionButton';
import BackLink from 'common-components/BackLink/BackLink';
import pageImageSrc from 'assets/new-icons/streamline-setup.svg';

export const ERROR_MESSAGE = 'Oops, we had troubles getting the code. Try again.';

const useStyles = makeStyles(() => ({
    pageImage: {
        width: '147px',
        height: '110px',
    },
}));

export function OTPRequestPage({ getTransactionPath, leaseTransaction }) {
    const classes = useStyles();
    const { identityVerification, applicant, error, didInitiate, evaluateQuestion } = useIdentityVerification();
    const requestPINByCall = identityVerification?.verification_method === VERIFICATION_METHOD_PIN_CALL;

    const phoneNumber = applicant?.phone_number;
    const phoneNumberLastFourDigits = phoneNumber?.substring(phoneNumber.length - 4, phoneNumber.length);
    const maskedPhoneNumber = `***-***-${phoneNumberLastFourDigits}`;
    const ctaButtonLabel = requestPINByCall ? 'Request Call' : 'Request Text';
    const backLinkTo = getTransactionPath(ROUTES.FEES_AND_DEPOSITS, {
        lease_transaction_id: leaseTransaction.id,
    });
    const pageNotification = error && {
        type: 'error',
        messages: error,
    };

    const onCtaButtonClick = useCallback(async () => {
        const answerKey = requestPINByCall ? TU_ANSWER_KEY_PIN_CALL : TU_ANSWER_KEY_PIN_TEXT;
        const question = identityVerification.questions[0];
        const answer = question.choices.find((c) => c.key === answerKey);
        let answers = [];

        // Note; there seems to be an edge case in which TU doesn't actually provide any questions to be answered.
        // In order to prevent applicants getting stuck during their ID verification process; we allow this to be answered
        // without any question/answer. Our system will detect this evaluation attempt as failed; which allows the applicant
        // to move on while agents can pick up the failed attempt by manually verifying the applicant's ID.
        if (question && answer) {
            answers = [
                {
                    question_key: question.key,
                    answer_key: answer.key,
                },
            ];
        }

        await evaluateQuestion({ answers, error: ERROR_MESSAGE }, ROUTES.IDENTITY_VERIFICATION_OTP_VERIFY);
    }, [evaluateQuestion, identityVerification, requestPINByCall]);

    if (!didInitiate) {
        return null;
    }

    return (
        <Page
            title="Let’s Verify Your Identity!"
            subTitle={`We’ll provide a one-time PIN to ${maskedPhoneNumber}. Standard rates apply.`}
            image={{
                src: pageImageSrc,
                className: classes.pageImage,
            }}
            notification={pageNotification}
        >
            <ActionButton onClick={onCtaButtonClick} marginTop={30} marginBottom={20}>
                {ctaButtonLabel}
            </ActionButton>
            <BackLink to={backLinkTo} />
        </Page>
    );
}

OTPRequestPage.propTypes = {
    getTransactionPath: PropTypes.func.isRequired,
    leaseTransaction: PropTypes.object.isRequired,
};

export default withTransactionPath(OTPRequestPage);
