// This should match the logic in chuck
import { getPaymentItemName } from '../../utils/misc';

export function getRentalOptionFeeDisplayName(
    name,
    price,
    quantity = 1,
    included = 0,
    prorated,
    days,
    perDay,
    exempted = 0,
    showItemized,
    isWaived
) {
    if (quantity < 1 && !isWaived) return null;

    const feesBreakdown = [];
    const num_included = quantity > included ? included : quantity;
    if (num_included > 0) {
        feesBreakdown.push(`${num_included} incl.`);
    }

    if (exempted) {
        feesBreakdown.push(`${exempted} assistance animal${exempted > 1 ? 's' : ''} incl.`);
    }

    const uncharged_quantity = included + exempted;
    const num_charged = quantity > uncharged_quantity ? quantity - uncharged_quantity : 0;
    if ((num_charged > 0 || exempted > 0) && quantity > 1 && !prorated && !showItemized) {
        feesBreakdown.push(`${num_charged} x $${price}`);
    }

    let displayName = `${getPaymentItemName(name)}`;
    const daysPlural = days > 1 ? 's' : '';

    if (prorated && num_charged > 0 && quantity > 0) {
        displayName += `: ${days} day${daysPlural} at $${perDay}/day`;
    }

    if (feesBreakdown.length > 0) {
        displayName += ` (${feesBreakdown.join(', ')})`;
    }

    return displayName;
}
