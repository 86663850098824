import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';

import { ROUTES } from 'constants/constants';
import ActionButton from 'common-components/ActionButton/ActionButton';
import funnelLogo from 'assets/images/FunnelLogo.png';
import { TextLink } from 'assets/styles';

import { titleCase } from 'utils/misc';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexFlow: 'column',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url(${theme.assets.background})`,
        },
        topSection: {
            flex: '1 0 45%',
        },
        bottomSection: {
            flex: '1 0 55%',
            background: 'white',
            borderTopRightRadius: 30,
            borderTopLeftRadius: 30,
            padding: 24,
            [theme.breakpoints.up('md')]: {
                paddingTop: 64,
            },
        },
        logoWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            minHeight: '50%',
            background: 'transparent',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            backgroundColor: theme.darkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.3)',
        },
        logo: {
            height: 80,
            width: 280,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundImage: `url(${theme.assets.logo})`,
            backgroundRepeat: 'no-repeat',
            marginBottom: 80,
            [theme.breakpoints.up('md')]: {
                marginBottom: 120,
            },
        },
        content: {
            margin: '0 auto',
            maxWidth: 500,
            display: 'flex',
            flexFlow: 'column',
            textAlign: 'center',
        },
        pageHeader: {
            marginBottom: 32,
        },
        footer: {
            margin: '0 auto',
            maxWidth: 500,
            marginTop: 32,
            textAlign: 'center',
        },
        footerCTA: {
            marginBottom: '24px !important',
        },
        footerLink: {
            display: 'block',
            marginBottom: 16,
        },
        funnelLogo: {
            width: 60,
            height: 9.87,
        },
        poweredByText: {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: '19.2px',
            color: '#000000',
            marginRight: 5,
        },
    };
});

export const WelcomePage = ({ configuration = {} }) => {
    const styles = useStyles();
    const { person, invitee, community, unit } = configuration || {};
    const {
        building_name: buildingName,
        city,
        state,
        postal_code: postalCode,
        normalized_street_address: streetAddress,
    } = community || {};

    const getFirstName = () => {
        if (person) {
            return person.first_name;
        } else if (invitee && invitee.first_name) {
            return invitee.first_name;
        } else {
            return null;
        }
    };

    const getCTAProps = () => {
        let link = ROUTES.SIGNUP;
        let label = 'Start Application';
        if (invitee?.is_registered || person?.has_account) {
            link = ROUTES.LOGIN;
            label = 'Continue Application';
        }

        return {
            link,
            label,
        };
    };

    const firstName = getFirstName();
    const ctaProps = getCTAProps();
    const headerText = firstName ? `Hello ${firstName},` : 'Hi There,';
    const cityStatePostalCodeText = `${city}, ${state} ${postalCode}`;

    return (
        <div className={styles.root}>
            <div className={styles.topSection}>
                <div className={styles.logoWrapper}>
                    <div className={styles.logo} />
                </div>
            </div>
            <div className={styles.bottomSection}>
                <div className={styles.content} data-testid="content">
                    <Typography variant="h1" className={styles.pageHeader} gutterBottom>
                        {headerText}
                    </Typography>
                    <Typography variant="body1">Your new home awaits at:</Typography>
                    <br />
                    {buildingName && (
                        <Typography variant="body1">
                            <strong>{buildingName}</strong>
                        </Typography>
                    )}
                    {streetAddress && <Typography variant="body1">{titleCase(streetAddress)}</Typography>}
                    {cityStatePostalCodeText && <Typography variant="body1">{cityStatePostalCodeText}</Typography>}
                    {unit && unit.unit_number && <Typography variant="body1">{`Unit ${unit.unit_number}`}</Typography>}
                </div>
                <div className={styles.footer}>
                    <Link to={{ pathname: ctaProps.link }} data-testid="cta-link">
                        <ActionButton className={styles.footerCTA} data-testid="cta-button">
                            {ctaProps.label}
                        </ActionButton>
                    </Link>
                    {!invitee && !person?.has_account && (
                        <TextLink to={ROUTES.LOGIN} className={styles.footerLink} variant="textLink">
                            I already started an application
                        </TextLink>
                    )}
                    <div>
                        <span className={styles.poweredByText}>Powered by</span>
                        <img className={styles.funnelLogo} src={funnelLogo} alt="funnel logo" />
                    </div>
                </div>
            </div>
        </div>
    );
};

WelcomePage.propTypes = {
    configuration: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    configuration: state.configuration,
});

const mapActionsToProps = null;

export default connect(mapStateToProps, mapActionsToProps)(WelcomePage);
