function addStylesheetToDocument(styleSheetHref) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = styleSheetHref;

    document.getElementsByTagName('head')[0].appendChild(link);
}

export function loadFont() {
    addStylesheetToDocument(
        'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap'
    );

    const fontLoadInterval = setInterval(fontLoadListener('12px "Open Sans"', 'opensans-loaded'), 50);

    function fontLoadListener(fontToTest, classToAppend) {
        let fontHasLoaded = false;

        return () => {
            try {
                fontHasLoaded = document.fonts.check(fontToTest);
            } catch (e) {
                console.info(`Document Fonts API error: ${e}`);
            }

            if (fontHasLoaded) {
                document.getElementsByTagName('body')[0].classList.add(classToAppend);

                clearInterval(fontLoadInterval);
            }
        };
    }
}
