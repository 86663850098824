import { useCallback, useState } from 'react';

import API from 'api/api';
import { REPORT_TYPES } from 'constants/report';
import { LEASE_TRANSACTION_TYPES } from 'constants/leaseTransaction';

function createObjectURL(pdfData) {
    const binary = atob(pdfData.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    return URL.createObjectURL(new Blob([view], { type: 'application/pdf' }));
}

function getReportFileName(report) {
    const leaseTransactionType = LEASE_TRANSACTION_TYPES[report.lease_transaction_type];
    const reportType = REPORT_TYPES[report.type];
    return `${leaseTransactionType}_${reportType}_report_${report.id}`.toLowerCase();
}

export default function useScreeningReports() {
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const downloadReport = useCallback(async (report) => {
        setIsLoading(true);
        setError(null);

        try {
            const data = await API.getScreeningReport(report.onlineleasing_report_url);
            const dataUrl = createObjectURL(data.pdf_data);
            const link = document.createElement('a');

            link.href = dataUrl;
            link.download = getReportFileName(report);
            link.click();
        } catch (e) {
            console.info('ERROR: ', e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }, []);

    return {
        loading,
        error,
        downloadReport,
    };
}
