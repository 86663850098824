import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';

import { setupSentry } from 'utils/sentry';
import { setupDatadog } from 'utils/datadog';
import 'utils/configureYup';
import store from 'reducers/store';

import ErrorBoundary from 'common-components/ErrorBoundary/ErrorBoundary';
import App from 'app/App';
import './assets/sass/styles.scss';

setupSentry();
setupDatadog();

const tagManagerArgs = {
    gtmId: 'GTM-W8J3S8J',
    auth: process.env.REACT_APP_GTM_AUTH,
};

TagManager.initialize(tagManagerArgs);

window.Buffer = window.Buffer || require('buffer').Buffer;

ReactDOM.render(
    process.env.NODE_ENV === 'development' ? (
        <StrictMode>
            <Provider store={store}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </Provider>
        </StrictMode>
    ) : (
        <Provider store={store}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </Provider>
    ),
    document.getElementById('root')
);
