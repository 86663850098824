import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import { Banner } from './UnauthenticatedPageStyles';
import { Logo } from 'assets/styles';
import { AppTheme } from 'app/AppContextProvider';

export const container = css`
    text-align: center;
    margin: auto;
    padding-bottom: 25px;
`;

export const subContainer = css`
    padding: 30px 20px;
    max-width: 500px;
    margin: auto;
`;

export class UnauthenticatedPage extends Component {
    static contextType = AppTheme;

    render() {
        const { isRegistered } = this.props;
        const subContainerStyle = isRegistered
            ? {
                  paddingTop: 0,
              }
            : {};

        return (
            <div className={container}>
                {!isRegistered && (
                    <Banner
                        style={{
                            backgroundColor: this.context.bannerBackground,
                            color: this.context.bannerColor,
                        }}
                    >
                        <Logo src={this.context.logo} alt="company logo" />
                    </Banner>
                )}
                <div className={subContainer} style={subContainerStyle}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

UnauthenticatedPage.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
    isRegistered: PropTypes.bool,
};

UnauthenticatedPage.defaultProps = {
    isRegistered: false,
};

export default UnauthenticatedPage;
