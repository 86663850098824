import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ROUTES } from 'constants/constants';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import withRelativeRoutes from 'utils/withRelativeRoutes';

import { updateApplicant } from 'reducers/applicant';
import { actions as loaderActions } from 'reducers/loader';

import Page from 'common-components/Page/Page';
import icon from 'assets/new-icons/military-status.svg';
import { MilitaryStatusForm } from 'pages/MilitaryStatus/MilitaryStatusForm';
import { PageImg } from 'pages/Application/ApplicationDeniedPage';

export const MilitaryStatusPage = ({ applicant, leaseTransaction, toggleLoader, updateApplicant, getNextRoute }) => {
    const [errors, setErrors] = useState(null);

    const handleSubmit = useCallback(
        async (values, { setErrors: setFormErrors, setSubmitting }) => {
            toggleLoader(true);
            setErrors(null);

            try {
                const response = await updateApplicant(values);
                if (response.errors) {
                    setFormErrors(response.errors);
                } else {
                    await getNextRoute();
                }
            } catch {
                setErrors([GENERIC_ERROR_MESSAGE]);
            } finally {
                toggleLoader(false);
                setSubmitting(false);
            }
        },
        [toggleLoader, updateApplicant, getNextRoute, setErrors]
    );

    if (!applicant) {
        return null;
    }

    return (
        <Page
            title="Military Status"
            subTitle="We’ll need your military status."
            image={{
                src: icon,
                className: PageImg,
            }}
        >
            <MilitaryStatusForm
                applicant={applicant}
                leaseTransaction={leaseTransaction}
                errors={errors}
                onSubmit={handleSubmit}
            />
        </Page>
    );
};

MilitaryStatusPage.propTypes = {
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    showAutomatedAddress: PropTypes.bool,
    toggleLoader: PropTypes.func,
    updateApplicant: PropTypes.func,
    getNextRoute: PropTypes.func,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    leaseTransaction: state.transaction,
});

const mapActionsToProps = {
    updateApplicant,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withRelativeRoutes(MilitaryStatusPage, ROUTES.MILITARY_STATUS));
