import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty, groupBy, isEqual } from 'lodash';
import pluralize from 'pluralize';
import { Typography } from '@material-ui/core';

import {
    RENTAL_OPTIONS_PETS_CATS,
    RENTAL_OPTIONS_PETS_DOGS,
    RENTAL_OPTIONS_PETS_OTHER,
    RENTER_PROFILE_TYPE_PETS,
    ROUTES,
} from 'constants/constants';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import { rentalOptionsInitialValues, prettyFormatPhoneNumber, textToUpperCase } from 'utils/misc';
import {
    getIsPetsLimitsExceeded,
    getLimitExceededRentalOptionGroups,
    getPetLimits,
} from 'pages/RenterProfile/utils/utils';
import withTransactionPath from 'utils/withTransactionPath';

import { updateTransaction } from 'reducers/transaction';
import { actions as loaderActions, selectors as loaderSelector } from 'reducers/loader';
import { getDisplayMonthlyPriceBreakdown } from 'selectors/launchDarkly';

import Page from 'common-components/Page/Page';
import AddAnotherButton from 'common-components/AddAnotherButton/AddAnotherButton';
import ActionButton from 'common-components/ActionButton/ActionButton';
import BackLink from 'common-components/BackLink/BackLink';
import PriceBreakdown from 'common-components/PriceBreakdown/PriceBreakdown';
import { PaymentDetailsCard } from 'common-components/PaymentDetails';
import PetItem from 'pages/RenterProfile/components/PetItem';
import PetPolicy from 'pages/RenterProfile/components/PetPolicy';
import PetRestrictions from 'pages/RenterProfile/components/PetRestrictions';
import { leasingPricingDisclaimer as leasingPricingDisclaimerClassName } from 'assets/styles';
import { policyDiv, viewPetPolicy as viewPetPolicyClassName } from './PetsPageStyles';
import petsImage from 'assets/new-icons/animal-print-streamlined.svg';

export const petsSchema = (config = {}) => {
    return Yup.object().shape({
        petOptions: Yup.array().of(
            Yup.object({
                name: Yup.string().when('pet_type', {
                    is: (value) => [RENTAL_OPTIONS_PETS_DOGS, RENTAL_OPTIONS_PETS_CATS].includes(value),
                    then: Yup.string().required('Required'),
                    otherwise: Yup.string().notRequired(),
                }),
                weight: Yup.number().when('pet_type', {
                    is: (value) => [RENTAL_OPTIONS_PETS_DOGS, RENTAL_OPTIONS_PETS_CATS].includes(value),
                    then: Yup.number()
                        .positive('Please enter a positive number')
                        .typeError('Please enter numbers only')
                        .required('Required')
                        .max(
                            config.petsMaxWeight,
                            `Your pet exceeds the maximum allowed weight of ${config.petsMaxWeight} lb. Please call us at ${config.communityPhoneNumber} before continuing your application.`
                        ),
                    otherwise: Yup.number().notRequired(),
                }),
                breed: Yup.string().when('pet_type', {
                    is: RENTAL_OPTIONS_PETS_DOGS,
                    then: Yup.string().required('Required'),
                    otherwise: Yup.string().notRequired(),
                }),
                description: Yup.string().when('pet_type', {
                    is: RENTAL_OPTIONS_PETS_OTHER,
                    then: Yup.string().required('Required'),
                    otherwise: Yup.string().notRequired(),
                }),
            }).test('test-empty-pet', 'Pet can not be empty', (values) => {
                return !isEmpty(values);
            })
        ),
    });
};

export const FIRST_PET = { key: 'first-pet', service_animal: 'false' };
export const PET_PLACEHOLDER = { key: 'pet-placeholder', service_animal: 'false' };

export class PetsPage extends Component {
    _isSubmitting = false;
    _formRef = null;
    _originalWindowOnPopState = window.onpopstate;

    state = {
        viewPetPolicy: false,
        viewServiceAnimalDisclaimer: false,
        viewPetRestrictions: false,
        errors: null,
        isLimitExceeded: false,
        limitExceededRentalOptionGroups: [],
    };

    get goBackUrl() {
        return this.props.getTransactionPath(`${ROUTES.PROFILE_OPTIONS}#${RENTER_PROFILE_TYPE_PETS}`);
    }

    emptyPetFilter = (petOption) => {
        const keys = Object.keys(petOption);

        return !(
            keys.length === 2 &&
            petOption[keys[0]] === PET_PLACEHOLDER.key &&
            petOption[keys[1]] === PET_PLACEHOLDER.service_animal
        );
    };

    isFirstPetEmpty = (pets) => {
        const [firstPet] = pets;
        const keys = Object.keys(firstPet);

        if (
            keys.length === 2 &&
            (firstPet[keys[0]] === FIRST_PET.key || firstPet[keys[0]] === PET_PLACEHOLDER.key) &&
            (firstPet[keys[1]] === FIRST_PET.service_animal || firstPet[keys[1]] === PET_PLACEHOLDER.service_animal)
        ) {
            return true;
        }

        return false;
    };

    serializePetsForPost = (petOptions) => {
        const petRentalOptions = this.props.configuration.rental_options.pets;

        const groupedPets = groupBy(petOptions, 'pet_type');
        const selectedRentalOptionsArray = Object.entries(groupedPets).reduce((selectedRentalOptions, petType) => {
            const selectedPetType = petType[0];
            const petsObject = petType[1];
            const rentalOptionId = petRentalOptions.find((option) => option.leasing_category === selectedPetType)?.id;

            if (rentalOptionId) {
                const amountOfAnimals = petsObject.length;
                const amountOfServiceAnimals = petsObject.filter(
                    ({ service_animal }) => service_animal === true || service_animal === 'true'
                ).length;

                const formattedSelectedOption = {
                    rental_option: {
                        id: parseInt(rentalOptionId),
                    },
                    quantity: amountOfAnimals,
                    exempted: this.props.configuration.charge_for_assistance_animals ? 0 : amountOfServiceAnimals,
                    leasing_context: {
                        pets: petsObject,
                    },
                };
                return [...selectedRentalOptions, formattedSelectedOption];
            }

            return selectedRentalOptions;
        }, []);

        // need to add a selected rental option with zero if none are selected to handle removal
        petRentalOptions.forEach((rentalOption) => {
            const rentalOptionId = parseInt(rentalOption.id);
            if (!selectedRentalOptionsArray.find((option) => option.rental_option.id === rentalOptionId)) {
                selectedRentalOptionsArray.push({
                    rental_option: {
                        id: rentalOptionId,
                    },
                    quantity: 0,
                    exempted: 0,
                    leasing_context: {
                        pets: [],
                    },
                });
            }
        });

        return selectedRentalOptionsArray;
    };

    toggleViewPetPolicy = () => {
        this.setState({ viewPetPolicy: !this.state.viewPetPolicy });
    };

    toggleViewServiceAnimalDisclaimer = () => {
        this.setState({ viewServiceAnimalDisclaimer: !this.state.viewServiceAnimalDisclaimer });
    };

    toggleViewPetRestrictions = () => {
        this.setState({ viewPetRestrictions: !this.state.viewPetRestrictions });
    };

    onSubmit = async ({ petOptions }, { setSubmitting }) => {
        const { toggleLoader, updateTransaction, configuration, isLoading } = this.props;

        if (isLoading) return;

        setSubmitting(true);
        this.setState({ errors: null });

        try {
            const { rental_options: rentalOptions = {} } = configuration;
            const pets = this.serializePetsForPost(petOptions.filter(this.emptyPetFilter));
            const isLimitExceeded = getIsPetsLimitsExceeded(rentalOptions?.pets || [], petOptions);
            const limitExceededRentalOptionGroups = getLimitExceededRentalOptionGroups(rentalOptions?.pets || [], pets);

            await this.setState({ isLimitExceeded, limitExceededRentalOptionGroups });

            if (isLimitExceeded || limitExceededRentalOptionGroups.length > 0) {
                this.setState({ isLimitExceeded, limitExceededRentalOptionGroups }, () => {
                    window.scrollTo(0, 0);
                });

                return setSubmitting(false);
            }
            toggleLoader(true);
            await updateTransaction({ selected_rental_options: pets });
        } catch (res) {
            this.setState({ errors: res.errors });
        } finally {
            toggleLoader(false);
            setSubmitting(false);
        }
    };

    goBack = async () => {
        // Note: some ugly hack to make sure our goBack waits for the form to be submitted before actually redirecting
        // Our pet item onBlur handler doesn't double the events when also the CTA button is clicked...
        setTimeout(async () => {
            while (this._isSubmitting) {
                await new Promise((resolve) => setTimeout(resolve, 100)); // check every 100ms
            }

            this.props.history.push(this.goBackUrl);
        }, 0);
    };

    handleDelete = (initialValues, formProps) => async (arrayHelpers, index) => {
        arrayHelpers.remove(index);

        if (index === 0) {
            arrayHelpers.push(PET_PLACEHOLDER);
        }

        // Note: when monthly price breakdown is shown, we save the changes on the fly
        // so that our PaymentDetailsCard component can use the latest stored data
        const item = formProps?.values?.petOptions?.[index] || {};
        const hasErrors = !isEmpty(formProps.errors);
        const hasChanges = Object.keys(item).length > 1;
        if (this.props.displayMonthlyPriceBreakdown && !hasErrors && hasChanges) {
            await formProps.submitForm();
        }
    };

    getPriceBreakdownSelectedOptions = ({ petOptions }) => {
        const selectedRentalOptionsArray = this.serializePetsForPost(petOptions);
        return rentalOptionsInitialValues(
            selectedRentalOptionsArray,
            this.props.configuration.rental_options['pets'] || []
        );
    };

    handleBackClick = async (formProps) => {
        const { toggleLoader, updateTransaction } = this.props;
        const { initialValues, values } = formProps;
        const initialPets = this.serializePetsForPost(initialValues.petOptions.filter(this.emptyPetFilter));
        const pets = this.serializePetsForPost(values.petOptions.filter(this.emptyPetFilter));
        const didTemporarySaveChanges = !isEqual(initialPets, pets);

        if (didTemporarySaveChanges) {
            try {
                this.setState({ errors: null });
                toggleLoader(true);

                await updateTransaction({ selected_rental_options: initialPets });
            } catch {
                this.setState({ errors: `We where not able to undo your selection of pets. Please try again.` });
            } finally {
                toggleLoader(false);
            }
        }
    };

    componentDidMount() {
        window.onpopstate = async (event) => {
            await this.handleBackClick(this._formRef);
            this._originalWindowOnPopState && this._originalWindowOnPopState(event);
        };
    }

    componentWillUnmount() {
        window.onpopstate = this._originalWindowOnPopState;
    }

    render() {
        if (!this.props.leaseTransaction || !this.props.configuration) return null;
        const {
            viewPetPolicy,
            viewPetRestrictions,
            viewServiceAnimalDisclaimer,
            limitExceededRentalOptionGroups,
            isLimitExceeded,
        } = this.state;
        const { leaseTransaction = {}, configuration = {} } = this.props;
        const { lease_start_date: leaseStartDate } = leaseTransaction;
        const {
            community = {},
            rental_options: rentalOptions = {},
            leasing_pricing_disclaimer: leasingPricingDisclaimer,
        } = configuration;
        const {
            service_animal_disclaimer: serviceAnimalDisclaimer = '',
            pets_notes: petsNotes = '',
            pets_restrictions: petsRestrictions = '',
            contact_phone: contactPhone = '',
        } = community;
        const petsMaxWeight = community.pets_max_weight || Infinity;

        const selectedPetOptions = [];
        if (leaseTransaction.selected_rental_options.pets) {
            leaseTransaction.selected_rental_options.pets.forEach((selectedPetOption = { items: [] }) => {
                selectedPetOption.items.forEach((selectedPetOptionItem) => {
                    const petItem = selectedPetOptionItem?.leasing_context?.item
                        ? {
                              ...selectedPetOptionItem?.leasing_context?.item,
                              selected_rental_option_item_id: selectedPetOptionItem.id,
                          }
                        : {
                              pet_type: selectedPetOption.rental_option.leasing_category,
                              selected_rental_option_item_id: null,
                              service_animal: false,
                          };
                    petItem.service_animal = petItem.service_animal.toString();
                    selectedPetOptions.push(petItem);
                });
            });
        }

        const initialValues = {
            petOptions: selectedPetOptions.length > 0 ? selectedPetOptions : [FIRST_PET],
        };
        const prettyPhoneNumber = prettyFormatPhoneNumber(contactPhone);
        const petLimits = getPetLimits(rentalOptions?.pets || []);

        const notifications = [];
        if (isLimitExceeded) {
            notifications.push({
                type: 'error',
                messages:
                    'The maximum number of pets at this community is ' +
                    `${petLimits[RENTAL_OPTIONS_PETS_DOGS]} ${pluralize(
                        'dog',
                        petLimits[RENTAL_OPTIONS_PETS_DOGS]
                    )}, ` +
                    `${petLimits[RENTAL_OPTIONS_PETS_CATS]} ${pluralize(
                        'cat',
                        petLimits[RENTAL_OPTIONS_PETS_CATS]
                    )}, and ` +
                    `${petLimits[RENTAL_OPTIONS_PETS_OTHER]} other. Please edit your selections and save again.`,
            });
        }
        if (limitExceededRentalOptionGroups.length > 0) {
            notifications.push({
                type: 'error',
                messages:
                    'The maximum number of ' +
                    `${limitExceededRentalOptionGroups
                        .map(({ name, maximum }) => `${textToUpperCase(name)} is ${maximum}`)
                        .join(' ')} ` +
                    'at this community. Please edit your selections and save again.',
            });
        }
        if (this.state.errors) {
            notifications.push({
                type: 'error',
                messages: this.state.errors,
            });
        }

        return (
            <>
                <Page
                    className={clsx({
                        'hide-element': viewPetPolicy || viewServiceAnimalDisclaimer || viewPetRestrictions,
                    })}
                    title="Tell Us About Your Pet or Assistance Animal"
                    subTitle="Your bestie, sidekick, amigo, pal. We want to hear about your pet!"
                    image={{ src: petsImage }}
                    notifications={notifications}
                >
                    <>
                        <div className={policyDiv}>
                            <Typography variant="body2">
                                Have you read the pet policy?{' '}
                                <span
                                    role="button"
                                    className={viewPetPolicyClassName}
                                    onClick={this.toggleViewPetPolicy}
                                >
                                    Read it now!
                                </span>
                            </Typography>
                        </div>
                        <Formik
                            validationSchema={petsSchema({
                                petsMaxWeight,
                                communityPhoneNumber: prettyPhoneNumber,
                            })}
                            initialValues={initialValues}
                            onSubmit={this.onSubmit}
                        >
                            {(formProps) => {
                                this._formRef = formProps;

                                const { values, errors, isSubmitting, dirty, handleChange, handleBlur, submitForm } =
                                    formProps;
                                const { petOptions } = values;
                                const disableSubmit = !dirty || isSubmitting;
                                const isFirstPetEmpty = this.isFirstPetEmpty(petOptions);
                                const submitLabel =
                                    petOptions.length === 1 &&
                                    petOptions[0].key === FIRST_PET.key &&
                                    !petOptions[0].selected_rental_option_item_id
                                        ? 'Add Animal'
                                        : 'Save Changes';
                                const showPaymentBreakdown = petOptions.length > 0 && !isFirstPetEmpty;

                                const handlePetInputBlur = async (e) => {
                                    handleBlur(e);

                                    const hasErrors = !isEmpty(errors);
                                    const hasChanges = !isEqual(initialValues, values);

                                    if (this.props.displayMonthlyPriceBreakdown && !hasErrors && hasChanges) {
                                        this._isSubmitting = true;
                                        await submitForm();
                                        this._isSubmitting = false;
                                    }
                                };

                                return (
                                    <Form className="text-left" autoComplete="off">
                                        <FieldArray
                                            name="petOptions"
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {petOptions.map((petOption, index) => {
                                                        return (
                                                            <PetItem
                                                                key={index}
                                                                index={index}
                                                                arrayHelpers={arrayHelpers}
                                                                petOption={petOption}
                                                                rentalOptions={rentalOptions?.pets}
                                                                serviceAnimalDisclaimer={serviceAnimalDisclaimer}
                                                                handleChange={handleChange}
                                                                handleBlur={handlePetInputBlur}
                                                                handleDelete={(...args) =>
                                                                    this.handleDelete(initialValues, formProps)(...args)
                                                                }
                                                                toggleViewPetRestrictions={
                                                                    this.toggleViewPetRestrictions
                                                                }
                                                                toggleViewServiceAnimalDisclaimer={
                                                                    this.toggleViewServiceAnimalDisclaimer
                                                                }
                                                            />
                                                        );
                                                    })}
                                                    <AddAnotherButton
                                                        thing="Animal"
                                                        onClick={() => arrayHelpers.push({ key: uuidv4() })}
                                                    />
                                                    {showPaymentBreakdown && (
                                                        <div style={{ marginTop: 52 }}>
                                                            {this.props.displayMonthlyPriceBreakdown ? (
                                                                <PaymentDetailsCard
                                                                    selectedRentalOptions={this.getPriceBreakdownSelectedOptions(
                                                                        values
                                                                    )}
                                                                    category="Pets"
                                                                    categoryHelperText="animals"
                                                                    isForRentalOptions={true}
                                                                    onError={() =>
                                                                        this.setState({ errors: GENERIC_ERROR_MESSAGE })
                                                                    }
                                                                />
                                                            ) : (
                                                                <PriceBreakdown
                                                                    category="Pets"
                                                                    categoryHelperText="animals"
                                                                    leaseTransaction={leaseTransaction}
                                                                    moveInDate={leaseStartDate}
                                                                    selectedOptions={this.getPriceBreakdownSelectedOptions(
                                                                        values
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        />
                                        {leasingPricingDisclaimer && (
                                            <div
                                                data-testid="leasing-pricing-disclaimer"
                                                className={leasingPricingDisclaimerClassName}
                                            >
                                                {leasingPricingDisclaimer}
                                            </div>
                                        )}
                                        <ActionButton
                                            type="button"
                                            onMouseDown={async () => {
                                                await submitForm();
                                                await this.goBack();
                                            }}
                                            disabled={disableSubmit}
                                            marginTop={30}
                                            marginBottom={20}
                                        >
                                            {submitLabel}
                                        </ActionButton>
                                        <BackLink
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => this.handleBackClick(formProps)}
                                            to={this.goBackUrl}
                                        />
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                </Page>
                {viewPetPolicy && (
                    <PetPolicy
                        date="April 2019"
                        title="Pet Policy"
                        policy={petsNotes}
                        onAgree={this.toggleViewPetPolicy}
                    />
                )}
                {viewServiceAnimalDisclaimer && (
                    <PetPolicy
                        title="Assistance Animal Policy"
                        policy={serviceAnimalDisclaimer}
                        contactPhone={prettyPhoneNumber}
                        onAgree={this.toggleViewServiceAnimalDisclaimer}
                    />
                )}
                {viewPetRestrictions && (
                    <PetRestrictions
                        breedPolicy={petsRestrictions}
                        contactPhone={prettyPhoneNumber}
                        onAgree={this.toggleViewPetRestrictions}
                    />
                )}
            </>
        );
    }
}

PetsPage.propTypes = {
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,
    history: PropTypes.object,
    displayMonthlyPriceBreakdown: PropTypes.bool,
    isLoading: PropTypes.bool,
    toggleLoader: PropTypes.func,
    updateTransaction: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    configuration: state.configuration,
    leaseTransaction: state.transaction,
    displayMonthlyPriceBreakdown: getDisplayMonthlyPriceBreakdown(state),
    isLoading: loaderSelector.getIsVisible(state),
});

const mapDispatchToProps = {
    updateTransaction,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(PetsPage));
