import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from 'emotion';
import clsx from 'clsx';

import API from 'api/api';
import { ROUTES, ROLE_PRIMARY_APPLICANT } from 'constants/constants';
import { prettyFormatPhoneNumber } from 'utils/misc';
import captureRoute from 'utils/captureRoute';

import {
    ACTION_BUTTON,
    LINK_BUTTON,
    DocumentNoticeButton,
} from 'common-components/DocumentNoticeButton/DocumentNoticeButton';
import Page from 'common-components/Page/Page';
import ActionButton from 'common-components/ActionButton/ActionButton';
import GuarantorExplanation from 'pages/GuarantorRequested/components/GuarantorExplanation';
import { LinkButton } from 'assets/styles';
import addGuarantor from 'assets/new-icons/single-neutral-actions-money.svg';
import withTransactionPath from 'utils/withTransactionPath';

export const requestGuarantorHelpText = css`
    color: #454b57;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-top: 24px;
`;

export const PageImg = css`
    width: 200px !important;
    height: 200px !important;
`;

export const explanationLink = css`
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding-top: 10px;
`;

const Contact = styled.div`
    text-align: left;
    font-weight: 600;
    font-size: 16px;
`;

const DescriptionMessage = styled.div`
    margin-top: 103px;
    margin-bottom: 36px;
    text-align: left;
`;

export class GuarantorRequestedPage extends React.Component {
    state = {
        viewRequestReason: false,
        viewGuarantorExplanation: false,
        error: false,
        notifications: [],
    };

    toggleViewRequestReason = () => {
        this.setState({
            viewRequestReason: !this.state.viewRequestReason,
        });
    };

    toggleViewGuarantorExplanation = () => {
        this.setState({
            viewGuarantorExplanation: !this.state.viewGuarantorExplanation,
        });
    };

    addGuarantor = () => {
        this.props.history.push(this.props.getTransactionPath(ROUTES.GUARANTOR));
    };

    setPageError = (error) => {
        if (!error) {
            return this.setState({ notifications: [] });
        }
        this.setState({
            notifications: [
                {
                    type: 'error',
                    messages: [error],
                },
            ],
        });
    };

    render() {
        const { leaseTransaction, configuration, applicant } = this.props;
        if (!leaseTransaction || !configuration || !applicant) return null;

        const { viewRequestReason, viewGuarantorExplanation } = this.state;

        const isPrimaryApplicant = applicant.role === ROLE_PRIMARY_APPLICANT;
        const contactPhone = configuration.community.contact_phone;
        const guarantor_income_requirement_multiplier = configuration.guarantor_income_requirement_multiplier;
        const primaryApplicantFullName = `${leaseTransaction.primary_applicant.first_name} ${leaseTransaction.primary_applicant.last_name}`;

        return (
            <Page
                className={clsx({ 'hide-element': viewRequestReason || viewGuarantorExplanation })}
                title="Please Add a Guarantor"
                subTitle="We're requiring that you add a guarantor in order to move forward with your application."
                notifications={this.state.notifications}
                image={{
                    src: addGuarantor,
                    className: PageImg,
                }}
            >
                <div id="application-unit" className={explanationLink}>
                    <LinkButton onClick={this.toggleViewGuarantorExplanation}>{`What's a guarantor?`}</LinkButton>
                </div>
                <DescriptionMessage>
                    {isPrimaryApplicant ? (
                        <>
                            <span className={requestGuarantorHelpText}>
                                Unfortunately, we will not be able to approve your application without a
                                guarantor.&nbsp;
                            </span>
                            <DocumentNoticeButton
                                componentType={LINK_BUTTON}
                                fetchDocument={() => {
                                    return API.fetchAANDocument(leaseTransaction.id);
                                }}
                                setPageError={this.setPageError}
                            >
                                Learn Why
                            </DocumentNoticeButton>
                        </>
                    ) : (
                        <span className={requestGuarantorHelpText}>
                            {`Unfortunately, we will not be able to approve your application without a guarantor. If you’d like to add a guarantor, the primary applicant, ${primaryApplicantFullName}, can do so on behalf of everyone by logging into their account.`}
                        </span>
                    )}
                </DescriptionMessage>
                <Contact>
                    Call us at&nbsp;
                    <a href={`tel:${contactPhone}`}>{prettyFormatPhoneNumber(contactPhone)}</a> if you have any
                    questions or if you are unable or unwilling to add a guarantor.
                </Contact>
                {isPrimaryApplicant ? (
                    <ActionButton onClick={this.addGuarantor} marginTop={65}>
                        Add a Guarantor
                    </ActionButton>
                ) : (
                    <DocumentNoticeButton
                        componentType={ACTION_BUTTON}
                        marginTop={65}
                        fetchDocument={() => {
                            return API.fetchAANDocument(leaseTransaction.id);
                        }}
                        setPageError={this.setPageError}
                    >
                        Learn Why
                    </DocumentNoticeButton>
                )}
                {viewGuarantorExplanation && (
                    <GuarantorExplanation
                        onAgree={this.toggleViewGuarantorExplanation}
                        contactPhone={contactPhone}
                        multiplier={guarantor_income_requirement_multiplier}
                    />
                )}
            </Page>
        );
    }
}

GuarantorRequestedPage.propTypes = {
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,
    applicant: PropTypes.object,
    isPrimaryApplicant: PropTypes.bool,
    history: PropTypes.object,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    configuration: state.configuration,
    applicant: state.applicant,
});

export default connect(mapStateToProps)(
    captureRoute(withTransactionPath(GuarantorRequestedPage), ROUTES.GUARANTOR_REQUESTED)
);
