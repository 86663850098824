import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import API from 'api/api';
import { isDesktop } from 'utils/mobileDetect';

const KEYBOARD_CLOSE_DURATION_MS = 30;

// Adjust box shawdow to match AvailableUnitSelector box shadow (elevation 8)
const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: theme.shadows[8],
    },
}));

function getMenuItems(isReady, vacateReasons) {
    if (!isReady) {
        return <MenuItem key="not-ready">Loading...</MenuItem>;
    }
    if (vacateReasons.length === 0) {
        return <MenuItem key="no-results">No results found</MenuItem>;
    }
    return vacateReasons.map(({ reason, id }) => (
        <MenuItem key={id} value={id}>
            {reason}
        </MenuItem>
    ));
}

export default function ReasonsToVacateSelector(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [vacateReasons, setVacateReasons] = useState([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (!isReady) {
            API.fetchVacateReasons().then((res) => {
                setVacateReasons(res.items);
                setIsReady(true);
            });
        }
    }, [vacateReasons, isReady]);

    const handleClick = useCallback(async () => {
        if (isDesktop) {
            return setOpen(!open);
        }

        if (!open) {
            // Close keyboard and wait for it to close before showing lease term options
            document.activeElement.blur();
            await new Promise((resolve) => setTimeout(resolve, KEYBOARD_CLOSE_DURATION_MS));
            return setOpen(true);
        }

        setOpen(false);
    }, [open]);

    return (
        <FormControl fullWidth onClick={handleClick}>
            <InputLabel htmlFor="lease-term">Reason</InputLabel>
            <Select
                open={open}
                fullWidth
                value={isReady && props.vacateReason ? props.vacateReason : ''}
                onChange={props.handleChange}
                MenuProps={{ classes: { paper: classes.paper } }}
                inputProps={{
                    name: 'vacate_reason',
                    id: 'vacate-reason',
                }}
            >
                {getMenuItems(isReady, vacateReasons)}
            </Select>
        </FormControl>
    );
}

ReasonsToVacateSelector.propTypes = {
    vacateReason: PropTypes.any,
    handleChange: PropTypes.func.isRequired,
};
