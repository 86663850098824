import styled from '@emotion/styled';

export const Banner = styled.div`
    height: 80px;
    box-shadow: 0 2px 4px 0 rgba(177, 181, 189, 0.4);
    font-size: 30px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
`;
