import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/constants';
import { LEASE_TRANSACTION_TYPE_APPLICATION } from 'constants/leaseTransaction';
import auth from 'utils/auth';
import * as routingHelpers from 'utils/routingHelpers';
import { prettyFormatPhoneNumber } from 'utils/misc';
import { getLeaseIdFromUrl } from 'utils/routingHelpers';

import { selectors } from 'reducers/renter-profile';
import { fetchApplicant } from 'reducers/applicant';
import { fetchTransaction } from 'reducers/transaction';
import { fetchUserProfile } from 'reducers/user-profile';
import { actions as loaderActions } from 'reducers/loader';
import { fetchLease } from 'reducers/lease';
import { clearSiteConfigHash } from 'reducers/site-config';

import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import LoginForm from 'common-components/LoginForm/LoginForm';
import UnauthenticatedPage from 'pages/Unauthenticated';
import { SpacedH1, SpacedH3 } from 'assets/styles';
import welcome from 'assets/new-icons/real-estate-search-house.svg';
import GTM from 'utils/gtm';

const BAD_CREDENTIALS_ERROR = 'The email and password you entered do not match our records. Please try again.';
const GENERIC_ERROR = 'Oops, something has gone wrong.';

const getNoApplicationError = (phone) =>
    `Oops, there is no longer an application associated with this account. Please call us at ${phone} if you have any questions.`;

export class LoginPage extends Component {
    state = { errors: null };

    auth = auth;

    get errors() {
        if (this.state.errors) {
            return this.state.errors;
        }
        if (this.props.location.state?.errors) {
            return this.props.location.state.errors;
        }
        return null;
    }

    get_error_message(error) {
        switch (error) {
            case 'Invalid credentials':
                return BAD_CREDENTIALS_ERROR;
            case 'Application does not exist':
                return getNoApplicationError(prettyFormatPhoneNumber(this.props.community.contact_phone));
            default:
                return GENERIC_ERROR;
        }
    }

    onSubmit = (values, { setSubmitting }) => {
        this.setState({ errors: null });
        this.props.toggleLoader(true);

        const { history, location } = this.props;

        return auth
            .login(values.email, values.password, this.props.communityId)
            .then(async (res) => {
                const {
                    communityId,
                    accessedAppByInvitationOrWebsite,
                    configuration,
                    fetchUserProfile,
                    fetchApplicant,
                    fetchTransaction,
                    fetchLease,
                    clearSiteConfigHash,
                } = this.props;

                auth.setSession(res.token, communityId);

                const profile = await fetchUserProfile();
                if (profile) {
                    GTM.sendUserAndCompanyData(configuration.community.company, profile);
                }

                const redirectToAccountSetupPage = routingHelpers.getRedirectToAccountSetupPage(profile);
                const redirectToLeaseTransactionsPage = routingHelpers.getRedirectToLeaseTransactionsPage(
                    profile,
                    configuration,
                    accessedAppByInvitationOrWebsite
                );

                const urlParams = new URLSearchParams(location.search);
                const nextPage = urlParams.get('next');

                if (nextPage) {
                    history.replace(nextPage);
                } else if (redirectToAccountSetupPage) {
                    return history.replace(ROUTES.ACCOUNT);
                } else if (redirectToLeaseTransactionsPage) {
                    return history.replace(ROUTES.LEASE_TRANSACTIONS);
                }

                const leaseId = getLeaseIdFromUrl();
                if (leaseId) {
                    await fetchLease(leaseId);
                    return;
                }

                // Applicants with multiple or no transactions would already be captured by redirecting
                // them to the applications page
                const [firstAndOnlyTransaction] = profile?.transactions || [];
                const transactionId =
                    routingHelpers.getLeaseTransactionIdFromUrl() ||
                    configuration?.lease_transaction_id ||
                    firstAndOnlyTransaction?.id;

                await fetchApplicant(transactionId);
                const transaction = await fetchTransaction(transactionId);

                /*
                    The configuration hash doesn't have lease trx type hashed on initialization,
                    startingNewApplicationWithConfiguration becomes true when we don't clear the store hash upon login
                */
                if (transaction.type !== LEASE_TRANSACTION_TYPE_APPLICATION) {
                    clearSiteConfigHash();
                }

                if (routingHelpers.getApplicantIsInWrongCommunityEnv(transaction)) {
                    return history.replace(ROUTES.LEASE_TRANSACTIONS);
                }

                history.replace(this.props.initialPage);
            })
            .catch((res) => {
                const error = res?.errors?.error;
                this.setState({ errors: [this.get_error_message(error)] });
            })
            .finally(() => {
                this.props.toggleLoader(false);
                setSubmitting(false);
            });
    };

    render() {
        const { configuration = {}, invitee = {}, community = {} } = this.props;
        const { quote_is_active: quoteIsActive } = configuration;
        const { is_registered: isRegistered } = invitee;
        const { company = {}, contact_phone: contactPhone = null } = community;
        const prettyPhoneNumber = prettyFormatPhoneNumber(contactPhone);

        return (
            <UnauthenticatedPage>
                <SpacedH1>Welcome Back</SpacedH1>
                <SpacedH3>
                    {isRegistered
                        ? `Looks like you've applied to ${company.name} community before. Sign in to get started on your application.`
                        : 'Sign in to continue.'}
                </SpacedH3>
                <GenericFormMessage type="error" messages={this.errors} />
                {quoteIsActive && (
                    <GenericFormMessage
                        type="warning"
                        messages={
                            <div>
                                There is already an application associated with this quote. Please contact{' '}
                                <a href={`tel:${contactPhone}`}>{prettyPhoneNumber}</a> for a new quote or log in below.
                            </div>
                        }
                    />
                )}
                <img src={welcome} alt="welcome sign" width="118" height="94" />
                <LoginForm handleOnSubmit={this.onSubmit} includeRegister={true} buttonText="Sign In" />
            </UnauthenticatedPage>
        );
    }
}

LoginPage.propTypes = {
    fetchTransaction: PropTypes.func,
    initialPage: PropTypes.string,
    communityId: PropTypes.string,
    community: PropTypes.object,
    applicant: PropTypes.object,
    invitee: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    accessedAppByInvitationOrWebsite: PropTypes.bool,
    fetchApplicant: PropTypes.func,
    fetchUserProfile: PropTypes.func,
    configuration: PropTypes.object.isRequired,
    toggleLoader: PropTypes.func,
    fetchLease: PropTypes.func.isRequired,
    clearSiteConfigHash: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    initialPage: selectors.selectInitialPage(state),
    communityId: state.siteConfig.basename,
    configuration: state.configuration,
    community: state.configuration?.community,
    invitee: state.configuration?.invitee,
    applicant: state.applicant,
    accessedAppByInvitationOrWebsite: Boolean(state.siteConfig.hash),
});

const mapDispatchToProps = {
    fetchTransaction,
    fetchApplicant,
    fetchUserProfile,
    fetchLease,
    toggleLoader: loaderActions.toggleLoader,
    clearSiteConfigHash,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
