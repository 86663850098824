export const REPORT_TYPE_CREDIT = 10;
export const REPORT_TYPE_CRIMINAL = 20;
export const REPORT_TYPE_EVICTION = 30;
export const REPORT_TYPE_INCOME = 40;

export const REPORT_TYPES = {
    [REPORT_TYPE_CREDIT]: 'Credit',
    [REPORT_TYPE_CRIMINAL]: 'Criminal',
    [REPORT_TYPE_EVICTION]: 'Eviction',
    [REPORT_TYPE_INCOME]: 'Income',
};
