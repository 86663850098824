import queryString from 'query-string';
import API from 'api/api';

const TRANSFER_TOKEN_KEY = 'transfer_token';

export const getTransferTokenFromUrl = () => {
    const params = queryString.parse(window.location.search);
    return params?.[TRANSFER_TOKEN_KEY];
};

/**
 * Returns a URL that automatically authenticates the user in ResApp
 *
 * @param url
 * @returns {Promise<string>}
 */
export const getAuthenticatedUrl = async (url) => {
    const transferToken = await API.createTransferToken();
    const transferUrl = new URL(url);
    transferUrl.searchParams.set(TRANSFER_TOKEN_KEY, transferToken);
    return transferUrl.toString();
};
