import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { isEqual } from 'lodash';

import { ROUTES } from 'constants/constants';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import { getDisplayMonthlyPriceBreakdown } from 'selectors/launchDarkly';
import {
    rentalOptionsInitialValues,
    getRentalOptionSubtitleItemAdder,
    rentalOptionCTALabel,
    textToUpperCase,
} from 'utils/misc';
import withTransactionPath from 'utils/withTransactionPath';
import { getLimitExceededRentalOptionGroups } from 'pages/RenterProfile/utils/utils';

import { updateTransaction } from 'reducers/transaction';
import { actions as loaderActions, selectors as loaderSelector } from 'reducers/loader';

import Page from 'common-components/Page/Page';
import { PaymentDetailsCard } from 'common-components/PaymentDetails';
import PriceBreakdown from 'common-components/PriceBreakdown/PriceBreakdown';
import { BackLink } from 'common-components/BackLink/BackLink';
import ItemAdder from 'common-components/ItemAdder/ItemAdder';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { leasingPricingDisclaimer } from 'assets/styles';
import { bodySecondaryTypography } from 'assets/constants';

const listStyle = css(bodySecondaryTypography);

export const RentalOptionsPage = ({
    history = {},
    config = null,
    leaseTransaction = null,
    title = '',
    subTitle = '',
    categoryKey = null,
    subtitleSuffix = null,
    addButtonLabel = null,
    imageSrc = null,
    priceBreakdownProps = {
        category: '',
        categoryHelperText: '',
    },
    displayMonthlyPriceBreakdown = false,
    isLoading = false,
    updateTransaction = (f) => f,
    toggleLoader = (f) => f,
    getTransactionPath = (f) => f,
}) => {
    const [errors, setError] = useState(null);
    const formRef = useRef(null);

    useEffect(() => {
        const originalWindowOnPopState = window.onpopstate;

        window.onpopstate = async (event) => {
            await handleBackClick(formRef.current);
            originalWindowOnPopState && originalWindowOnPopState(event);
        };

        return () => {
            window.onpopstate = originalWindowOnPopState;
        };
    });

    if (!config || !leaseTransaction || !categoryKey) {
        return null;
    }

    const goBackUrl = getTransactionPath(`${ROUTES.PROFILE_OPTIONS}#${categoryKey}`);
    const initialSelectedRentalOptions = leaseTransaction.selected_rental_options[categoryKey];
    const rentalOptions = config?.rental_options[categoryKey];
    const initialValues = rentalOptionsInitialValues(initialSelectedRentalOptions, rentalOptions);

    const goBack = () => {
        history.push(goBackUrl);
    };

    const serializeValuesForPost = (values) => {
        const selectedRentalOptions = [];
        Object.entries(values).forEach((option) => {
            selectedRentalOptions.push({
                rental_option: { id: parseInt(option[0]) },
                quantity: option[1].quantity,
            });
        });
        return selectedRentalOptions;
    };

    const onSubmit = async (values, { setSubmitting }) => {
        if (isLoading) {
            return;
        }

        setError(null);

        const selectedRentalOptions = serializeValuesForPost(values);
        const limitExceededRentalOptionGroups = getLimitExceededRentalOptionGroups(
            rentalOptions,
            selectedRentalOptions
        );
        if (limitExceededRentalOptionGroups.length > 0) {
            setError(
                'The maximum number of ' +
                    `${limitExceededRentalOptionGroups
                        .map(({ name, maximum }) => `${textToUpperCase(name)} is ${maximum}`)
                        .join(' ')} ` +
                    'at this community. Please edit your selections and save again.'
            );
            setSubmitting(false);
            window.scrollTo(0, 0);
            return;
        }

        try {
            toggleLoader(true);
            await updateTransaction({ selected_rental_options: selectedRentalOptions });
        } catch (e) {
            setError(`We couldn't save your ${categoryKey} options. Please try again.`);
        } finally {
            toggleLoader(false);
            setSubmitting(false);
        }
    };

    const getSubtitles = (option) => {
        const subtitles = getRentalOptionSubtitleItemAdder(option, subtitleSuffix);
        return subtitles.split('\n').map((item, key) => {
            return (
                item && (
                    <li key={key} className={listStyle}>
                        {item}
                        <br />
                    </li>
                )
            );
        });
    };

    const handleBackClick = async (formProps) => {
        const { initialValues, values } = formProps;
        const didTemporarySaveChanges = !isEqual(initialValues, values);
        if (didTemporarySaveChanges) {
            try {
                setError(null);
                toggleLoader(true);

                const selectedRentalOptions = serializeValuesForPost(initialValues);
                await updateTransaction({ selected_rental_options: selectedRentalOptions });
            } catch {
                setError(`We where not able to undo your selection of ${categoryKey} options. Please try again.`);
            } finally {
                toggleLoader(false);
            }
        }
    };

    const notifications = [];
    if (errors) {
        notifications.push({
            type: 'error',
            messages: errors,
        });
    }

    return (
        <Page
            title={title}
            subTitle={subTitle}
            image={{
                src: imageSrc,
            }}
            notifications={notifications}
        >
            <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize={false}>
                {(formProps) => {
                    formRef.current = formProps;

                    const { values, submitForm, setFieldValue, dirty, isSubmitting } = formProps;
                    const submitLabel = rentalOptionCTALabel(initialSelectedRentalOptions, addButtonLabel);
                    const hasAnyOptionsSelected = Object.values(values).reduce((a, b) => a + b.quantity, 0) > 0;

                    return (
                        <Form className="text-left" autoComplete="off">
                            {rentalOptions.map((option) => (
                                <ItemAdder
                                    key={option.id}
                                    title={option.name}
                                    subtitle={getSubtitles(option)}
                                    value={values[option.id].quantity}
                                    limit={option.limit}
                                    onChange={async (e) => {
                                        await setFieldValue(`[${option.id}].quantity`, e);

                                        if (displayMonthlyPriceBreakdown) {
                                            await submitForm();
                                        }
                                    }}
                                />
                            ))}
                            {hasAnyOptionsSelected && (
                                <div style={{ marginTop: 52 }}>
                                    {displayMonthlyPriceBreakdown ? (
                                        <PaymentDetailsCard
                                            selectedRentalOptions={values}
                                            title="Payments Breakdown"
                                            category={priceBreakdownProps.category}
                                            categoryHelperText={priceBreakdownProps.categoryHelperText}
                                            isForRentalOptions={true}
                                            onError={() => setError(GENERIC_ERROR_MESSAGE)}
                                        />
                                    ) : (
                                        <PriceBreakdown
                                            selectedOptions={values}
                                            leaseTransaction={leaseTransaction}
                                            category={priceBreakdownProps.category}
                                            categoryHelperText={priceBreakdownProps.categoryHelperText}
                                            moveInDate={leaseTransaction.lease_start_date}
                                        />
                                    )}
                                </div>
                            )}
                            {config?.leasing_pricing_disclaimer && (
                                <div data-testid="leasing-pricing-disclaimer" className={leasingPricingDisclaimer}>
                                    {config.leasing_pricing_disclaimer}
                                </div>
                            )}
                            <ActionButton
                                type="button"
                                marginTop={30}
                                marginBottom={20}
                                disabled={!dirty || isSubmitting}
                                onClick={async () => {
                                    await submitForm();
                                    await goBack();
                                }}
                            >
                                {submitLabel}
                            </ActionButton>
                            <BackLink
                                style={{ display: 'block', width: '100%', textAlign: 'center', cursor: 'pointer' }}
                                onClick={() => handleBackClick(formProps)}
                                to={goBackUrl}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </Page>
    );
};

RentalOptionsPage.propTypes = {
    history: PropTypes.object,
    config: PropTypes.object,
    leaseTransaction: PropTypes.object,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    categoryKey: PropTypes.string,
    subtitleSuffix: PropTypes.string,
    imageSrc: PropTypes.string,
    addButtonLabel: PropTypes.string,
    priceBreakdownProps: PropTypes.shape({
        category: PropTypes.string,
        categoryHelperText: PropTypes.string,
    }),
    displayMonthlyPriceBreakdown: PropTypes.bool,
    isLoading: PropTypes.bool,
    updateTransaction: PropTypes.func,
    toggleLoader: PropTypes.func,
    getTransactionPath: PropTypes.func,
};

const mapStateToProps = (state) => ({
    config: state.configuration,
    leaseTransaction: state.transaction,
    displayMonthlyPriceBreakdown: getDisplayMonthlyPriceBreakdown(state),
    isLoading: loaderSelector.getIsVisible(state),
});

const mapDispatchToProps = {
    updateTransaction,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(RentalOptionsPage));
