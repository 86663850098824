import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { leaseTransactionType } from 'types';
import { getDateLabel } from 'pages/LeaseTransactions/utils/helpers';

function MonthToMonthAccordionContent({ classes, leaseTransaction }) {
    const { start_date, end_date, rent_price } = leaseTransaction;

    return (
        <>
            <Typography className={classes.typography} variant="body1">
                Lease Term: <span>{getDateLabel(start_date)}</span>
                <span> - </span>
                <span>{getDateLabel(end_date)}</span>
            </Typography>
            <Typography className={classes.typography} variant="body1">
                Monthly Rent: <span>{rent_price ? `$${rent_price}` : '---'}</span>
            </Typography>
        </>
    );
}

MonthToMonthAccordionContent.propTypes = {
    classes: PropTypes.object.isRequired,
    leaseTransaction: leaseTransactionType,
};

export default MonthToMonthAccordionContent;
