import { css } from 'emotion';
import styled from '@emotion/styled';
import { Card } from '../../assets/styles';

export const cardHeader = css`
    align-self: start;
    flex: 1;
    padding-left: 0;
    font-weight: 400;
    display: block;
    font-size: 14px;
`;

export const headerContainer = css`
    display: flex;
    flex: 1;
    align-items: center;
    text-transform: none;
    padding-bottom: 15px;
`;

export const imageWrapper = css`
    line-height: 67px;
    width: 50px;
    height: 46px;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-right: 10px;
`;

export const itemsContainer = css`
    margin-bottom: 0;
    border-bottom: 1px solid #eeeeee;

    .MuiExpansionPanelSummary-root {
        padding: 0;
    }

    .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: 20px;
        height: 45px;
        margin: 0;
    }

    :nth-child(2) {
        border-top: 1px solid #eeeeee;
    }
`;

export const itemRow = css`
    padding: 10px 0;
    text-align: left;
    border-top: 1px solid #eeeeee;
    font-size: 15px;
`;

export const itemRowNoBorder = css`
    padding: 10px 0;
    text-align: left;
    font-size: 15px;
`;

export const paidTextContainer = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

export const paidText = css`
    position: absolute;
    right: 28px;
    top: 13px;
`;

export const paymentDetailRow = css`
    display: flex;
    justify-content: space-between;
    padding-bottom: 9px;
    font-size: 14px;
`;

export const paymentDetailRowTotal = css`
    display: flex;
    justify-content: space-between;
    background-color: rgba(103, 193, 139, 0.1);
    padding: 10px 15px 10px 16px;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 15px;
    font-weight: bold;
`;

export const paymentDetailRowsLabel = css`
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    text-transform: uppercase;
    padding-bottom: 9px;
`;

export const paymentDetailRowsDisclaimer = css`
    font-weight: 400;
    font-size: 12px;
    padding-bottom: 16px;
    color: #666666;
`;

export const paymentLineItem = css`
    ${paymentDetailRow}
    min-height: 29.02px;
    padding-left: 10px;
    padding-bottom: 5px;
`;

export const title = css`
    font-weight: bold;
`;

export const rentalOptionName = css`
    padding-right: 20px;
`;

export const styles = {
    cardHeader,
    headerContainer,
    itemsContainer,
    itemRow,
    itemRowNoBorder,
    imageWrapper,
    paidText,
    paidTextContainer,
    paymentDetailRow,
    paymentDetailRowTotal,
    paymentDetailRowsLabel,
    paymentDetailRowsDisclaimer,
    paymentLineItem,
    title,
    rentalOptionName,
};

const PaymentCard = styled(Card)`
    padding: 15px;
`;

export const StyledComponents = {
    PaymentCard,
};
