import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    ROUTES,
    RENTER_PROFILE_TYPE_CO_APPLICANTS,
    RENTER_PROFILE_TYPE_GUARANTOR,
    RENTER_PROFILE_TYPE_PETS,
    RENTER_PROFILE_TYPE_PARKING,
    RENTER_PROFILE_TYPE_STORAGE,
    RENTER_PROFILE_TYPE_DEPENDENT,
    RENTER_PROFILE_TYPE_OCCUPANT,
    RENTER_PROFILE_TYPE_WINE_COOLER,
    APPLICANT_ROLE_VALUES,
    APPLICANT_ROLE_RENTER_PROFILE_TYPES,
} from 'constants/constants';
import { RENTER_PROFILE_IDENTIFIER } from 'constants/pageComplete';
import withRelativeRoutes from 'utils/withRelativeRoutes';
import withTransactionPath from 'utils/withTransactionPath';
import { prettyFormatPhoneNumber, totalGuarantorsCount } from 'utils/misc';
import { petsSchema as petsValidationSchema } from 'pages/RenterProfile/pages/PetsPage';

import { pageComplete } from 'reducers/renter-profile';
import { updateTransaction } from 'reducers/transaction';
import { actions as loaderActions } from 'reducers/loader';

import Page from 'common-components/Page/Page';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { BackLink } from 'common-components/BackLink/BackLink';
import Capsule from 'common-components/Capsule/Capsule';
import ExistingItemsExpansionPanel from 'common-components/ExistingItemsExpansionPanel/ExistingItemsExpansionPanel';
import ExistingGenericRentalOption from 'pages/RenterProfile/components/ExistingGenericRentalOption';
import ExistingPet from 'pages/RenterProfile/components/ExistingPet';
import ExistingRoommate from 'pages/RenterProfile/components/ExistingRoommate';

import coapplicants from 'assets/new-icons/streamlined-household.svg';
import guarantor from 'assets/new-icons/accounting-coins-streamlined.svg';
import cat from 'assets/new-icons/animal-print-streamlined.svg';
import addparking from 'assets/new-icons/streamlined-parking.svg';
import addstorage from 'assets/new-icons/warehouse-storage-streamlined.svg';
import addwinecooler from 'assets/new-icons/champagne-bottle-glass.svg';

export class RentalProfileOptions extends React.Component {
    state = {
        submitting: false,
        hasError: false,
        arePetsValid: true,
        notification: null,
    };

    setScrollPosition = () => {
        // taken from https://github.com/ReactTraining/react-router/issues/394#issuecomment-128148470
        window.location.hash = window.decodeURIComponent(window.location.hash);
        const scrollToAnchor = () => {
            const hashParts = window.location.hash.split('#');
            if (hashParts.length > 1) {
                const hash = hashParts[1];
                const hashElement = document.querySelector(`#${hash}`);
                if (!!hashElement) {
                    hashElement.scrollIntoView();
                }
            }
        };
        scrollToAnchor();
        window.onhashchange = scrollToAnchor;
    };

    async componentDidMount() {
        await this.checkArePetsValid();

        if (!this.props.profile) return null;
        this.setScrollPosition();
        return null;
    }

    async componentDidUpdate(prevProps) {
        if (!prevProps.profile && !!this.props.profile) {
            this.setScrollPosition();
        }
    }

    onContinue = async () => {
        this.props.toggleLoader(true);
        this.setState({ submitting: true });

        try {
            await this.props.pageComplete(RENTER_PROFILE_IDENTIFIER);
            await this.props.getNextRoute();
        } catch {
            this.setState({ hasError: true });
        } finally {
            this.props.toggleLoader(false);
            this.setState({ submitting: false });
        }
    };

    get configurableRentalOptions() {
        const activeRentalOptions = new Set([RENTER_PROFILE_TYPE_CO_APPLICANTS]);
        Object.keys(this.props.config.rental_options).forEach((key) => activeRentalOptions.add(key.toLowerCase()));
        if (this.props.config.allow_guarantors) {
            activeRentalOptions.add(RENTER_PROFILE_TYPE_GUARANTOR);
        }
        return activeRentalOptions;
    }

    get existingPets() {
        const existingPets = [];
        if (this.props.leaseTransaction.selected_rental_options.pets) {
            this.props.leaseTransaction.selected_rental_options.pets.forEach((selectedPetOption = { items: [] }) => {
                selectedPetOption.items.forEach((selectedPetOptionItem) => {
                    existingPets.push(
                        selectedPetOptionItem?.leasing_context?.item || {
                            pet_type: selectedPetOption.rental_option.leasing_category,
                        }
                    );
                });
            });
        }

        return existingPets;
    }

    checkArePetsValid = async () => {
        if (this.existingPets.length === 0) return true;

        const { config = {} } = this.props;
        const { community = {} } = config;
        const { contact_phone: contactPhone = '' } = community;
        const petsMaxWeight = community.pets_max_weight || Infinity;
        const prettyPhoneNumber = prettyFormatPhoneNumber(contactPhone);

        try {
            await petsValidationSchema({
                petsMaxWeight,
                communityPhoneNumber: prettyPhoneNumber,
            }).validate({ petOptions: this.existingPets });
            this.setState({ arePetsValid: true });
        } catch (e) {
            this.setState({ arePetsValid: false });
        }
    };

    filterOptions = (option) =>
        this.props.leaseTransaction.selected_rental_options[option]
            ? this.props.leaseTransaction.selected_rental_options[option].filter((option) => option.quantity > 0)
            : [];

    render() {
        if (this.props.leaseTransaction == null) return null;
        const hashValue = !!this.props.location.hash ? this.props.location.hash.substring(1) : '';
        const filteredParking = this.filterOptions(RENTER_PROFILE_TYPE_PARKING);
        const filteredStorage = this.filterOptions(RENTER_PROFILE_TYPE_STORAGE);
        const filteredWineCooler = this.filterOptions(RENTER_PROFILE_TYPE_WINE_COOLER);
        const options = this.configurableRentalOptions;

        const isGuarantor = (person) => person.role === APPLICANT_ROLE_VALUES['ROLE_GUARANTOR'];

        const people = this.props.leaseTransaction.co_applicants
            .concat(this.props.leaseTransaction.occupants)
            .concat(this.props.leaseTransaction.dependents)
            .concat(this.props.leaseTransaction.invitees.filter((i) => !isGuarantor(i)));

        const guarantors = [
            ...this.props.leaseTransaction.guarantors,
            ...this.props.leaseTransaction.invitees.filter(isGuarantor),
        ];

        const maximumAllowedGuarantorsReached =
            totalGuarantorsCount(this.props.leaseTransaction) >= this.props.config.maximum_guarantors_allowed;

        const notifications = [];
        if (this.state.hasError) {
            notifications.push({
                type: 'error',
                messages: 'Oops, something went wrong. Try again.',
            });
        }
        if (this.props.leaseTransaction.original_client_quote && !this.state.arePetsValid) {
            notifications.push({
                type: 'error',
                messages: 'Some sections are imported from your quote and require additional information.',
            });
        }

        return (
            <Page
                title="Let's Set Up Your Rental Profile"
                subTitle="Complete the sections that apply to you and skip the ones that don't."
                notifications={notifications}
            >
                <div>
                    {options.has(RENTER_PROFILE_TYPE_CO_APPLICANTS) && (
                        <Capsule
                            prefix={<img alt="coapplicants" src={coapplicants} />}
                            name={RENTER_PROFILE_TYPE_CO_APPLICANTS}
                            label="I'll be living with other people"
                            description="Add anyone that you'll be living with, including roommates, occupants, and dependents"
                            buttonLabel={!!people.length ? 'Add Another Person' : 'Add a Person'}
                            route={this.props.getTransactionPath(ROUTES.CO_APPLICANTS)}
                            expansionPanel={
                                !!people.length && (
                                    <ExistingItemsExpansionPanel
                                        label="Roommates"
                                        labelQuantity={people.length}
                                        defaultExpanded={hashValue === RENTER_PROFILE_TYPE_CO_APPLICANTS}
                                    >
                                        {this.props.leaseTransaction.co_applicants.map((item) => (
                                            <ExistingRoommate
                                                key={`co_applicant-${item.id}`}
                                                item={item}
                                                type={RENTER_PROFILE_TYPE_CO_APPLICANTS}
                                            />
                                        ))}
                                        {this.props.leaseTransaction.occupants.map((item) => (
                                            <ExistingRoommate
                                                key={`occupant-${item.id}`}
                                                item={item}
                                                type={RENTER_PROFILE_TYPE_OCCUPANT}
                                            />
                                        ))}
                                        {this.props.leaseTransaction.dependents.map((item) => (
                                            <ExistingRoommate
                                                key={`dependent-${item.id}`}
                                                item={item}
                                                type={RENTER_PROFILE_TYPE_DEPENDENT}
                                            />
                                        ))}
                                        {this.props.leaseTransaction.invitees
                                            .filter((i) => !isGuarantor(i))
                                            .map((item) => (
                                                <ExistingRoommate
                                                    key={`invitee-${item.id}`}
                                                    item={item}
                                                    type={APPLICANT_ROLE_RENTER_PROFILE_TYPES[item.role]}
                                                />
                                            ))}
                                    </ExistingItemsExpansionPanel>
                                )
                            }
                        />
                    )}
                    {options.has(RENTER_PROFILE_TYPE_GUARANTOR) && (
                        <Capsule
                            prefix={<img alt="coins" src={guarantor} />}
                            name={RENTER_PROFILE_TYPE_GUARANTOR}
                            label="I'll need a guarantor"
                            buttonLabel="Invite a guarantor"
                            route={this.props.getTransactionPath(ROUTES.GUARANTOR)}
                            tip="This is a person that agrees to be legally responsible for the apartment, its condition, and the money owed for rent if you are unable to pay."
                            limitReached={maximumAllowedGuarantorsReached}
                            expansionPanel={
                                !!guarantors.length && (
                                    <ExistingItemsExpansionPanel
                                        label="Guarantor"
                                        labelQuantity={guarantors.length}
                                        defaultExpanded={hashValue === RENTER_PROFILE_TYPE_GUARANTOR}
                                    >
                                        {guarantors.map((item) => (
                                            <ExistingRoommate
                                                key={`guarantor-${item.id}`}
                                                item={item}
                                                type={RENTER_PROFILE_TYPE_GUARANTOR}
                                            />
                                        ))}
                                    </ExistingItemsExpansionPanel>
                                )
                            }
                        />
                    )}
                    {options.has(RENTER_PROFILE_TYPE_PETS) && (
                        <Capsule
                            prefix={<img alt="dog" src={cat} />}
                            name={RENTER_PROFILE_TYPE_PETS}
                            label="I'll be living with pets or assistance animals"
                            error={!this.state.arePetsValid ? 'Complete all required fields.' : null}
                            buttonLabel={this.existingPets.length ? 'Manage Animals' : 'Add an Animal'}
                            route={this.props.getTransactionPath(ROUTES.PETS)}
                            expansionPanel={
                                !!this.existingPets.length && (
                                    <ExistingItemsExpansionPanel
                                        label={
                                            this.existingPets.length > 1
                                                ? 'Pets or Assistance Animals'
                                                : 'Pet or Assistance Animal'
                                        }
                                        labelQuantity={this.existingPets.length}
                                        defaultExpanded={hashValue === RENTER_PROFILE_TYPE_PETS}
                                    >
                                        {this.existingPets.map((item, index) => (
                                            <ExistingPet key={index} item={item} />
                                        ))}
                                    </ExistingItemsExpansionPanel>
                                )
                            }
                        />
                    )}
                    {options.has(RENTER_PROFILE_TYPE_PARKING) && (
                        <Capsule
                            prefix={<img src={addparking} alt="car parking" />}
                            name={RENTER_PROFILE_TYPE_PARKING}
                            label="I'll need parking"
                            buttonLabel={filteredParking.length > 0 ? 'Manage Parking' : 'Add Parking'}
                            route={this.props.getTransactionPath(ROUTES.PARKING)}
                            expansionPanel={
                                filteredParking.length > 0 && (
                                    <ExistingItemsExpansionPanel
                                        label="Parking Space"
                                        labelQuantity={this.props.leaseTransaction.selected_rental_options.parking.reduce(
                                            (totalSelected, selectedOption) => {
                                                return (totalSelected += selectedOption.quantity);
                                            },
                                            0
                                        )}
                                        defaultExpanded={hashValue === RENTER_PROFILE_TYPE_PARKING}
                                    >
                                        {filteredParking.map((item) => (
                                            <ExistingGenericRentalOption
                                                key={item.id}
                                                quantity={item.quantity}
                                                rentalOption={item.rental_option}
                                            />
                                        ))}
                                    </ExistingItemsExpansionPanel>
                                )
                            }
                        />
                    )}
                    {options.has(RENTER_PROFILE_TYPE_STORAGE) && (
                        <Capsule
                            prefix={<img src={addstorage} alt="storage" />}
                            name={RENTER_PROFILE_TYPE_STORAGE}
                            label="I'll need storage"
                            buttonLabel={filteredStorage.length > 0 ? 'Manage Storage' : 'Add Storage'}
                            route={this.props.getTransactionPath(ROUTES.STORAGE)}
                            expansionPanel={
                                filteredStorage.length > 0 && (
                                    <ExistingItemsExpansionPanel
                                        label="Storage Space"
                                        labelQuantity={this.props.leaseTransaction.selected_rental_options.storage.reduce(
                                            (totalSelected, selectedOption) => {
                                                return (totalSelected += selectedOption.quantity);
                                            },
                                            0
                                        )}
                                        defaultExpanded={hashValue === RENTER_PROFILE_TYPE_STORAGE}
                                    >
                                        {filteredStorage.map((item) => (
                                            <ExistingGenericRentalOption
                                                key={item.id}
                                                quantity={item.quantity}
                                                rentalOption={item.rental_option}
                                            />
                                        ))}
                                    </ExistingItemsExpansionPanel>
                                )
                            }
                        />
                    )}
                    {options.has(RENTER_PROFILE_TYPE_WINE_COOLER) && (
                        <Capsule
                            prefix={<img src={addwinecooler} alt="wine_cooler" />}
                            name={RENTER_PROFILE_TYPE_WINE_COOLER}
                            label="I'll need a cooler to store wine"
                            buttonLabel={filteredWineCooler.length > 0 ? 'Manage Wine Coolers' : 'Add Wine Cooler'}
                            route={this.props.getTransactionPath(ROUTES.WINE_COOLER)}
                            expansionPanel={
                                filteredWineCooler.length > 0 && (
                                    <ExistingItemsExpansionPanel
                                        label="Wine Cooler"
                                        labelQuantity={this.props.leaseTransaction.selected_rental_options[
                                            'wine-cooler'
                                        ].reduce((totalSelected, selectedOption) => {
                                            return (totalSelected += selectedOption.quantity);
                                        }, 0)}
                                        defaultExpanded={hashValue === RENTER_PROFILE_TYPE_WINE_COOLER}
                                    >
                                        {filteredWineCooler.map((item) => (
                                            <ExistingGenericRentalOption
                                                key={item.id}
                                                quantity={item.quantity}
                                                rentalOption={item.rental_option}
                                            />
                                        ))}
                                    </ExistingItemsExpansionPanel>
                                )
                            }
                        />
                    )}
                </div>
                <ActionButton
                    disabled={this.state.submitting || !this.state.arePetsValid}
                    onClick={this.onContinue}
                    marginTop={60}
                    marginBottom={27}
                >
                    Continue
                </ActionButton>
                <BackLink to={this.props.prevRoute} />
            </Page>
        );
    }
}

RentalProfileOptions.propTypes = {
    leaseTransaction: PropTypes.object,
    profile: PropTypes.object,
    config: PropTypes.object,
    location: PropTypes.object,
    prevRoute: PropTypes.string,
    pageComplete: PropTypes.func,
    toggleLoader: PropTypes.func,
    getNextRoute: PropTypes.func,
    updateTransaction: PropTypes.func.isRequired,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    config: state.configuration,
    leaseTransaction: state.transaction,
});

const mapDispatchToProps = {
    updateTransaction,
    pageComplete,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRelativeRoutes(withTransactionPath(RentalProfileOptions), ROUTES.PROFILE_OPTIONS));
