import { connect } from 'react-redux';
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { DOES_NOT_EXIST } from 'constants/constants';
import { getLeaseSettingsIdFromUrl } from 'utils/routingHelpers';
import { basenameReceived } from 'reducers/site-config';

import Main from './Main';
import ScrollToTop from 'common-components/ScrollToTop/ScrollToTop';
import NotFoundPage from 'pages/NotFound';
import { getTransferTokenFromUrl } from 'utils/transferToken';
import { loadFont } from 'utils/loadFont';
import auth from 'utils/auth';
import { captureException } from '@sentry/browser';
import API from '../api/api';

export class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInitialized: false,
        };
    }

    async componentDidMount() {
        const basename = getLeaseSettingsIdFromUrl();
        const siteConfig = {};
        siteConfig.basename = basename;

        const params = queryString.parse(window.location.search);
        siteConfig.hash = params.v;
        siteConfig.whToken = params.w;
        siteConfig.whUsername = params.username;

        this.props.basenameReceived(siteConfig);

        loadFont();
        await this.ensureTransferToken(basename);
    }

    async ensureTransferToken(leaseSettingsId) {
        try {
            const transferToken = getTransferTokenFromUrl();

            if (!transferToken) {
                return this.setState({
                    isInitialized: true,
                });
            }

            const { token } = await API.getAuthTokenFromTransferToken(transferToken);
            await auth.setSession(token, leaseSettingsId);

            this.setState({
                isInitialized: true,
            });
        } catch (e) {
            captureException(e);

            return this.setState({
                isInitialized: true,
            });
        }
    }

    badRoute() {
        return (
            !this.props.basename ||
            this.props.basename === '/' ||
            this.props.configuration.error_type === DOES_NOT_EXIST
        );
    }

    render() {
        if (!this.state.isInitialized) {
            return null;
        }

        if (this.badRoute()) return <NotFoundPage />;

        return (
            <div className="App">
                <BrowserRouter basename={this.props.basename}>
                    <ScrollToTop />
                    <Main />
                </BrowserRouter>
            </div>
        );
    }
}

App.propTypes = {
    basenameReceived: PropTypes.func.isRequired,
    basename: PropTypes.string.isRequired,
    configuration: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ basename: state.siteConfig.basename, configuration: state.configuration });
const mapDispatchToProps = { basenameReceived };
export default connect(mapStateToProps, mapDispatchToProps)(App);
