import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import Box from '@material-ui/core/Box';
import Adder from 'common-components/Adder/Adder';
import { Typography } from '@material-ui/core';

// TODO: change size to 16px | created by: @Chakib | Ticket: NESTIO-19932
const subtitle = css({
    color: '#444b58',
    listStyle: 'none',
    padding: '0',
    lineHeight: '1.35',
    margin: '8px 0 0 0',
});

export default function ItemAdder(props) {
    return (
        <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center" padding="10px">
            <div>
                <Typography variant="subtitle1">{props.title}</Typography>
                {!!props.subtitle && <ul className={subtitle}>{props.subtitle}</ul>}
            </div>
            <Adder ceil={props.limit} onChange={props.onChange} value={props.value} />
        </Box>
    );
}

ItemAdder.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.any,
    limit: PropTypes.number,
    onChange: PropTypes.func,
    value: PropTypes.number,
};
