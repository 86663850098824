import { css } from 'emotion';
import styled from '@emotion/styled';

export const drawerContent = css`
    max-width: 500px;
    text-align: center;
    margin: auto;
    padding: 45px 20px 25px;
    min-width: 300px;
`;

export const Banner = styled.div`
    height: 80px;
    box-shadow: 0 2px 4px 0 rgba(177, 181, 189, 0.4);
    font-size: 30px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
`;
