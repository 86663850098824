import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import Info from '@material-ui/icons/Info';

import PaidText from 'common-components/PaidText/PaidText';
import SimplePopover from 'common-components/SimplePopover/SimplePopover';
import { CardRowBorderless, infoIconRoot } from 'assets/styles';

const CardRowBorderlessPadded = styled(CardRowBorderless)`
    padding: 15px 0;
`;

export const HoldingDeposit = ({ amount, name, disclaimer, paid = false, waived = false }) => {
    const title = name
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    const fee_help_text = disclaimer
        ? disclaimer
        : `The ${name.toLowerCase()} takes your apartment off the market while the application process is happening. Our community requires the main applicant to pay the ${name.toLowerCase()}.`;

    return (
        <CardRowBorderlessPadded>
            <div>
                {title}{' '}
                <SimplePopover text={fee_help_text}>
                    <Info classes={{ root: infoIconRoot }} style={{ color: '#828796', width: 16 }} />
                </SimplePopover>
            </div>
            <div>{paid ? <PaidText waived={waived} /> : <Typography variant="body1">{amount}</Typography>}</div>
        </CardRowBorderlessPadded>
    );
};

HoldingDeposit.propTypes = {
    amount: PropTypes.string.isRequired,
    disclaimer: PropTypes.string,
    name: PropTypes.string.isRequired,
    paid: PropTypes.bool.isRequired,
    waived: PropTypes.bool.isRequired,
};

export default HoldingDeposit;
