import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { makeStyles, Typography } from '@material-ui/core';
import { css } from 'emotion';

import API from 'api/api';
import {
    LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION,
    LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE,
    LEASE_TRANSACTION_TYPE_VACATE,
} from 'constants/leaseTransaction';
import auth from 'utils/auth';
import { prettyFormatPhoneNumber } from 'utils/misc';
import useGetLeaseDocumentUrl from 'utils/useGetLeaseDocumentUrl';
import { getDocumentTypeLabelForTransaction } from 'constants/leaseTransaction';

import Page from 'common-components/Page/Page';
import { PaymentDetailsCard } from 'common-components/PaymentDetails';
import ActionButton from 'common-components/ActionButton/ActionButton';
import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import { arrowIcon, blackLinkRoot, LinkButton, leasingPricingDisclaimer, ExternalTextLink } from 'assets/styles';
import { reloadWindowForLeaseTransaction } from 'utils/routingHelpers';

import contract from 'assets/new-icons/content-pen-write.svg';
import receiptImage from 'assets/new-icons/receipt-dollar-streamlined.svg';
import { DynamicESignView } from './DynamicESignView';

export const buttonsContainer = css`
    padding-top: 35px;
    min-height: 100px;
`;

const useStyles = makeStyles(() => ({
    vacateText: {
        textAlign: 'center',
        color: '#828796',
        width: '278px',
        height: '57px',
        margin: '35% auto 35% auto',
    },
}));

export const SignLeaseView = ({
    history = {},
    leaseTransaction = {},
    configuration = {},
    applicantUpdated = (f) => f,
    setShowSignDocumentPage = (f) => f,
    fetchPayments = (f) => f,
    getTransactionPath = (f) => f,
    applicant = {},
}) => {
    const [loading, setLoading] = useState(false);
    const [showPaymentDetails, setShowPaymentDetails] = useState(null);
    const [contactPhone, setContactPhone] = useState(null);
    const [canGoBack, setCanGoBack] = useState(null);
    const [esignType, setEsignType] = useState(null);
    const [esignData, setEsignData] = useState({});
    const {
        retried,
        error,
        url,
        loading: urlLoading,
        fetchLeaseDocumentUrl,
    } = useGetLeaseDocumentUrl({ leaseTransaction });

    const classes = useStyles();
    const wormholedUser = auth.getWormholedUser();
    let disableCTA = false;
    if (wormholedUser && !wormholedUser.can_sign_documents_while_wormholed) {
        disableCTA = true;
    }

    useEffect(() => {
        ![LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION, LEASE_TRANSACTION_TYPE_VACATE].includes(
            leaseTransaction?.type
        )
            ? setShowPaymentDetails(true)
            : setShowPaymentDetails(false);

        leaseTransaction?.type === LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION
            ? setCanGoBack(false)
            : setCanGoBack(true);
    }, [leaseTransaction]);

    useEffect(() => {
        if (showPaymentDetails) {
            fetchPayments();
        }
    }, [fetchPayments, showPaymentDetails]);

    useEffect(() => {
        if (leaseTransaction?.type === LEASE_TRANSACTION_TYPE_VACATE) {
            setContactPhone(prettyFormatPhoneNumber(configuration?.community?.contact_phone));
        }
    }, [leaseTransaction, configuration]);

    const { title, subtitle, imageSrc, disclaimer, buttonText } = useMemo(() => {
        const imageSrc = { src: null };
        let subtitle,
            buttonText,
            disclaimer,
            title = 'Payment Details';

        switch (leaseTransaction?.type) {
            case LEASE_TRANSACTION_TYPE_VACATE:
                subtitle = '';
                buttonText = 'View & Sign Notice to Vacate';
                imageSrc.src = receiptImage;
                break;
            case LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION:
                subtitle = 'Please review and sign the document';
                buttonText = 'View & Sign Document';
                title = 'You Have a Document Ready to Sign';
                imageSrc.src = contract;
                disclaimer = `The document linked below constitutes a legal agreement between you and Landlord. Funnel
                              Leasing does not provide legal advice, and we recommend that you consult your legal
                              counsel before accepting these terms.`;
                break;
            case LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE:
                subtitle = 'Make sure everything looks good before signing the document.';
                buttonText = 'View & Sign Document';
                break;
            default:
                subtitle = 'Make sure everything looks good before signing the lease.';
                buttonText = 'View & Sign Lease';
                disclaimer = configuration?.leasing_pricing_disclaimer || '';
        }

        if (applicant?.is_lease_signature_being_processed) {
            buttonText = 'Signature Processing';
        }

        return {
            title,
            subtitle,
            imageSrc,
            disclaimer,
            buttonText,
        };
    }, [leaseTransaction, applicant, configuration]);

    const documentType = getDocumentTypeLabelForTransaction(leaseTransaction);

    const downloadLeaseButtonText = useMemo(() => {
        if (!url && !urlLoading) {
            return `Retrieve ${documentType} Copy`;
        }

        return `Download ${documentType} Copy`;
    }, [documentType, url, urlLoading]);

    const openEsignProviderConfig = async () => {
        setLoading(true);
        try {
            const { type, data } = await API.getEsignProviderConfig(
                leaseTransaction.id,
                leaseTransaction.signable_doc_type
            );

            setEsignType(type);
            setEsignData(data);
        } catch {
            reloadWindowForLeaseTransaction(leaseTransaction);
        }
    };

    return (
        <Page title={title} subTitle={subtitle} image={imageSrc}>
            {showPaymentDetails ? (
                <div>
                    <PaymentDetailsCard />
                </div>
            ) : leaseTransaction?.type === LEASE_TRANSACTION_TYPE_VACATE ? (
                <div className={classes.vacateText}>
                    {contactPhone && (
                        <Typography>
                            You can get a summary of your move out costs by calling us at{' '}
                            <a href={`tel:${contactPhone}`}>{contactPhone}</a>.
                        </Typography>
                    )}
                </div>
            ) : (
                <div>
                    {configuration?.community.display_name} Unit {leaseTransaction?.unit.unit_number}
                </div>
            )}
            {disclaimer && (
                <div data-testid="leasing-pricing-disclaimer" className={leasingPricingDisclaimer}>
                    {disclaimer}
                </div>
            )}
            <ActionButton
                className="sign-lease"
                onClick={openEsignProviderConfig}
                marginTop={30}
                disabled={disableCTA || loading || applicant.is_lease_signature_being_processed}
            >
                {buttonText}
            </ActionButton>
            {downloadLeaseButtonText && (
                <div className={buttonsContainer}>
                    {retried && !!error && <GenericFormMessage type="error" messages={error} />}
                    <ExternalTextLink
                        href={url}
                        onClick={url ? undefined : fetchLeaseDocumentUrl}
                        className="download-lease"
                        disabled={disableCTA || loading || urlLoading || applicant.is_lease_signature_being_processed}
                    >
                        {downloadLeaseButtonText}
                    </ExternalTextLink>
                </div>
            )}
            <br />
            <DynamicESignView
                type={esignType}
                leaseTransaction={leaseTransaction}
                props={esignData}
                history={history}
                applicantUpdated={applicantUpdated}
                getTransactionPath={getTransactionPath}
                setLoading={setLoading}
            />
            {canGoBack && (
                <LinkButton className={blackLinkRoot} onClick={() => setShowSignDocumentPage(false)}>
                    <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
                </LinkButton>
            )}
        </Page>
    );
};

SignLeaseView.propTypes = {
    history: PropTypes.object.isRequired,
    leaseTransaction: PropTypes.object.isRequired,
    configuration: PropTypes.object.isRequired,
    applicantUpdated: PropTypes.func.isRequired,
    fetchPayments: PropTypes.func.isRequired,
    setShowSignDocumentPage: PropTypes.func.isRequired,
    getTransactionPath: PropTypes.func.isRequired,
    applicant: PropTypes.object.isRequired,
};

export default SignLeaseView;
