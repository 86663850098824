import { createSlice } from '@reduxjs/toolkit';

const siteConfig = createSlice({
    name: 'renterProfile',
    initialState: {
        basename: '',
        theme: null,
        hash: null,
        currentRoute: null,
    },
    reducers: {
        basenameReceived(state, action) {
            state.basename = action.payload.basename;
            state.hash = action.payload.hash;
            state.whToken = action.payload.whToken;
            state.whUsername = action.payload.whUsername;
            return state;
        },
        currentRouteReceived(state, action) {
            state.currentRoute = action.payload;
            return state;
        },
        clearSiteConfigHash(state) {
            delete state.hash;
            return state;
        },
    },
});

const { actions, reducer } = siteConfig;
export const { basenameReceived, currentRouteReceived, clearSiteConfigHash } = actions;
export default reducer;
