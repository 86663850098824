import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { leaseTransactionPath } from 'reducers/renter-profile';

export default function withTransactionPath(WrappedComponent) {
    class TransactionPaths extends Component {
        static propTypes = {
            leaseTransaction: PropTypes.object,
        };

        getTransactionPath = (route, params) => {
            const { leaseTransaction } = this.props;
            return leaseTransactionPath(route, { lease_transaction_id: leaseTransaction.id }, params);
        };

        render = () => {
            return <WrappedComponent getTransactionPath={this.getTransactionPath} {...this.props} />;
        };
    }

    const mapStateToProps = (state) => ({
        leaseTransaction: state.transaction,
    });

    const mapActionsToProps = null;

    return connect(mapStateToProps, mapActionsToProps)(TransactionPaths);
}
