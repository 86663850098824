import { useCallback } from 'react';

/**
 * Creates a callback ref that can be attached to an element and
 * scrolls vertically to the element when the component mounts
 **/
export default function useScrollToElement() {
    return useCallback((elementNode) => {
        if (elementNode) {
            const elementYPos = elementNode.getBoundingClientRect().y;
            const navBarElement = document.querySelector('#top-navbar');
            let navBarHeight = 0;
            if (navBarElement) {
                navBarHeight = navBarElement.getBoundingClientRect().height;
            }

            window.scrollTo(0, elementYPos - navBarHeight);
        }
    }, []);
}
