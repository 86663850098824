import { Step, StepLabel, styled } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { prettyFormatPhoneNumber } from 'utils/misc';
import { withRouter } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { selectors } from 'reducers/renter-profile';
import { connect } from 'react-redux';
import { css } from 'emotion';

import { CustomButton } from 'common-components/ActionButton/styles';

const iconRoot = css`
    align-items: flex-start !important;
    .appCompletedMsg,
    .outstandingBalance {
        color: #828796;
    }
`;

// Not sure we need this CSS anymore
const viewProgress = css`
    margin: 30px auto !important;
    display: block !important;
`;

export const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
    '& .MuiStepLabel-label': {
        fontSize: theme.typography.body1.fontSize,
    },
}));

export function NavBlockedStep(props) {
    const {
        text,
        history,
        config,
        currentRoute,
        nextRoute,
        handleDrawerClose,
        stepProps,
        stepClass,
        buttonColor,
        callUsText,
    } = props;

    const onClick = () => {
        if (currentRoute && !currentRoute.startsWith(nextRoute)) {
            history.push(nextRoute);
        }
        handleDrawerClose && handleDrawerClose();
    };

    return (
        <Step active>
            <CustomStepLabel {...stepProps}>
                <span className={stepClass}>
                    {text} Please call us at{' '}
                    <a href={`tel:${config.community.contact_phone}`}>
                        {prettyFormatPhoneNumber(config.community.contact_phone)}
                    </a>{' '}
                    {callUsText || 'if you have any questions.'}
                </span>
            </CustomStepLabel>
            <CustomButton
                variant="outlined"
                color={buttonColor}
                id="viewProgressButton"
                fullWidth
                classes={{
                    root: viewProgress,
                }}
                disabled={false}
                onClick={onClick}
            >
                View Progress
            </CustomButton>
        </Step>
    );
}

NavBlockedStep.propTypes = {
    nextRoute: PropTypes.string.isRequired,
    currentRoute: PropTypes.string,
    history: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    handleDrawerClose: PropTypes.func,
    stepClass: PropTypes.string.isRequired,
    stepProps: PropTypes.object.isRequired,
    buttonColor: PropTypes.string.isRequired,
    callUsText: PropTypes.string,
};

const mapStateToProps = (state) => ({
    nextRoute: selectors.selectNextRoute(state),
    currentRoute: state.siteConfig.currentRoute,
    config: state.configuration,
});

const ConnectedNavBlockedStep = connect(mapStateToProps, null)(withRouter(NavBlockedStep));

export default ConnectedNavBlockedStep;

export function NavBlockedInProgressStep(props) {
    return (
        <ConnectedNavBlockedStep
            {...props}
            stepClass={'stepBlockedMsg'}
            stepProps={{
                active: true,
                classes: { root: iconRoot },
                StepIconComponent: () => <ErrorIcon color="primary" />,
            }}
            buttonColor={'default'}
        />
    );
}

export function NavBlockedCompletedStep(props) {
    return (
        <ConnectedNavBlockedStep
            {...props}
            stepClass={'appCompletedMsg'}
            stepProps={{
                completed: true,
                classes: { root: iconRoot },
                icon: 1,
            }}
            buttonColor={'primary'}
        />
    );
}

export function NavBlockedLeaseDocumentCompletedStep(props) {
    return (
        <ConnectedNavBlockedStep
            {...props}
            stepClass={'appCompletedMsg'}
            stepProps={{
                completed: true,
                classes: { root: iconRoot },
            }}
            buttonColor={'primary'}
        />
    );
}

NavBlockedCompletedStep.propTypes = {
    text: PropTypes.string.isRequired,
    callUsText: PropTypes.string,
    handleDrawerClose: PropTypes.func,
};

NavBlockedInProgressStep.propTypes = {
    text: PropTypes.string.isRequired,
    callusText: PropTypes.string,
    handleDrawerClose: PropTypes.func,
};
