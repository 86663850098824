import { useCallback, useEffect, useState } from 'react';

import API from 'api/api';
import usePrevious from 'hooks/usePrevious';

export default function useLeaseTransactions(errorMessage) {
    const [loading, setIsLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [mtmData, setMtmData] = useState([]);
    const prevLoading = usePrevious(loading);

    const getLeaseTransactions = useCallback(async () => {
        setIsLoading(true);

        try {
            const data = await API.getLeaseTransactions();
            const leaseTransactions = data?.lease_transactions;
            const leaseTransactionsData =
                leaseTransactions?.map(({ lease_transaction, ...rest }) => {
                    return {
                        ...lease_transaction,
                        ...rest,
                    };
                }) || [];

            setData(leaseTransactionsData);
            setMtmData(data?.month_to_month || []);
        } catch {
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        getLeaseTransactions();
    }, [getLeaseTransactions]);

    useEffect(() => {
        if (loading === false && prevLoading === true) {
            setFinished(true);
        }
    }, [loading, prevLoading]);

    return {
        loading,
        error,
        finished,
        data,
        mtmData,
    };
}
