import React from 'react';
import PropTypes from 'prop-types';
import GreenCheckIcon from 'common-components/GreenCheckIcon/GreenCheckIcon';
import { P } from 'assets/styles';

export const PaidText = ({ margin = '0', waived = false }) => (
    <P bold color="#56BA82" margin={margin}>
        <GreenCheckIcon />
        {waived ? 'Waived' : 'Paid'}
    </P>
);

PaidText.propTypes = {
    margin: PropTypes.string,
    waived: PropTypes.bool,
};

export default PaidText;
