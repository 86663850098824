import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { addressFieldsValidation } from 'utils/validation';
import AutomatedAddressForm from './AutomatedAddressForm';
import ManualAddressForm from './ManualAddressForm';

export const AddressForm = ({
    address,
    showAutomatedAddress,
    errors,
    onSubmit,
    validationSchema = addressFieldsValidation,
    isInitialValid = false,
    buttonLabel,
}) => {
    const initialValues = useMemo(() => {
        const searchBuilder = [];
        const { address_street, address_city, address_state, address_postal_code, address_line_2 } = address;

        if (address_street) searchBuilder.push(address_street);
        if (address_city) searchBuilder.push(address_city);
        if (address_state) searchBuilder.push(address_state);
        if (address_postal_code) searchBuilder.push(address_postal_code);

        const search = searchBuilder.join(', ');

        return {
            search,
            address_street,
            address_city,
            address_state,
            address_postal_code,
            address_line_2,
        };
    }, [address]);

    if (!address) {
        return null;
    }

    const FormComponent = showAutomatedAddress ? AutomatedAddressForm : ManualAddressForm;

    return (
        <FormComponent
            validationSchema={validationSchema}
            initialValues={initialValues}
            errors={errors}
            onSubmit={onSubmit}
            isInitialValid={isInitialValid}
            buttonLabel={buttonLabel}
        />
    );
};

AddressForm.propTypes = {
    address: PropTypes.object,
    errors: PropTypes.array,
    showAutomatedAddress: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    validationSchema: PropTypes.object,
    isInitialValid: PropTypes.bool,
    buttonLabel: PropTypes.string,
};

AddressForm.defaultProps = {
    buttonLabel: 'Continue',
};

export default AddressForm;
