import { useRef, useEffect, useState } from 'react';

export const STATES = {
    IDLE: 'idle',
    LOADING: 'loading',
    ERROR: 'error',
    READY: 'ready',
};

export default ({ url = null, autoRetry = true, maxRetryAttempts = 3 }) => {
    const attempts = useRef(0);
    const [state, setState] = useState(url ? STATES.LOADING : STATES.IDLE);

    useEffect(() => {
        if (!url) {
            return setState(STATES.IDLE);
        }

        let script = document.querySelector(`script[src="${url}"]`);
        if (script) {
            return setState(STATES.READY);
        }

        const appendScript = () => {
            const handleLoadScript = async (e) => {
                let newState = e.type === 'load' ? STATES.READY : STATES.ERROR;
                if (newState === STATES.ERROR && autoRetry && attempts.current < maxRetryAttempts) {
                    newState = STATES.LOADING;
                    attempts.current += 1;
                    appendScript();
                }

                setState(newState);
            };

            script = document.createElement('script');
            script.type = 'application/javascript';
            script.src = url;
            script.async = true;
            document.body.appendChild(script);

            script.addEventListener('load', handleLoadScript);
            script.addEventListener('error', handleLoadScript);
        };

        appendScript();
    }, [url, autoRetry, maxRetryAttempts]);

    return { state };
};
