import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { ROUTES } from 'constants/constants';
import { prettyFormatPhoneNumber } from 'utils/misc';
import captureRoute from 'utils/captureRoute';

import UnauthenticatedPage from 'pages/Unauthenticated';
import Page from 'common-components/Page/Page';
import Img404 from 'assets/new-icons/app-window-disabled.svg';

const useStyles = makeStyles(() => ({
    pageImage: {
        width: '154px !important',
        height: '175px !important',
    },
}));

const QuoteExpiredPage = ({ configuration = {} }) => {
    const classes = useStyles();
    const { community = {} } = configuration;
    const { contact_phone: contactPhone } = community;

    return (
        <UnauthenticatedPage>
            <Page
                title="Quote Expired"
                subTitle={
                    <span style={{ textAlign: 'center' }}>
                        Your quote has expired. Please call us at{' '}
                        <a href={`tel:${contactPhone}`}>{prettyFormatPhoneNumber(contactPhone)}</a> to receive a new
                        quote. Still want to apply?{' '}
                        <Link to={ROUTES.WELCOME}>Click here to create an account or sign in</Link>.
                    </span>
                }
                image={{
                    src: Img404,
                    className: classes.pageImage,
                }}
            />
        </UnauthenticatedPage>
    );
};

QuoteExpiredPage.propTypes = {
    configuration: PropTypes.object,
};

const mapStateToProps = (state) => ({
    configuration: state.configuration,
});

const mapActionsToProps = null;

export default connect(mapStateToProps, mapActionsToProps)(captureRoute(QuoteExpiredPage, ROUTES.QUOTE_EXPIRED));
