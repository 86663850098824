import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as sentryUtils from 'utils/sentry';

export const SentryUtilsContext = createContext({});

export const useSentryUtils = () => {
    return useContext(SentryUtilsContext);
};

export const SentryUtilsProvider = ({ leaseTransaction = {}, community = {}, company = {}, children = null }) => {
    const logToSentry = (error) => {
        return sentryUtils.logToSentry(error, leaseTransaction, community, company);
    };

    return <SentryUtilsContext.Provider value={{ logToSentry }}>{children}</SentryUtilsContext.Provider>;
};

SentryUtilsProvider.propTypes = {
    leaseTransaction: PropTypes.object,
    community: PropTypes.object,
    company: PropTypes.object,
    children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state?.transaction || {},
    community: state?.configuration?.community || {},
    company: state?.configuration?.community?.company || {},
});

const actionsToProps = null;

export default connect(mapStateToProps, actionsToProps)(SentryUtilsProvider);
