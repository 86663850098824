import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';

import { bankingDataReceived } from 'reducers/banking';
import { MOCKY } from 'config';
import API from 'api/api';
import mock from './fixtures/mock-applicant';

const applicant = createSlice({
    name: 'applicant',
    initialState: null,
    reducers: {
        applicantReceived(state, action) {
            state = action.payload;
            return state;
        },
        applicantUpdated(state, action) {
            return produce(state, (draft) => {
                Object.assign(draft, action.payload);
            });
        },
    },
    extraReducers: {
        USER_LOGOUT: () => {
            return null;
        },
    },
});

const { actions, reducer } = applicant;
export const { applicantUpdated, applicantReceived } = actions;
export default reducer;

export const fetchApplicant = (transactionId) => async (dispatch, getState) => {
    const { transaction } = getState();

    let applicant;
    if (MOCKY) {
        applicant = mock;
    } else {
        applicant = await API.fetchApplicant(transactionId || transaction?.id);
    }

    dispatch(applicantReceived(applicant));
    dispatch(bankingDataReceived(applicant.financial_data));
    return applicant;
};

export const updateApplicant = (newData) => {
    return async (dispatch, getState) => {
        const { transaction } = getState();

        if (MOCKY) {
            dispatch(applicantUpdated(newData));
            return Promise.resolve({});
        }

        return API.putApplicant(transaction.id, newData).then((res) => {
            if (res.errors) {
                return res;
            }

            return dispatch(applicantUpdated(res));
        });
    };
};

// selectors
export const selectors = {};
