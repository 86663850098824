import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from 'emotion';

import { ROUTES } from 'constants/constants';
import captureRoute from 'utils/captureRoute';
import { prettyFormatPhoneNumber } from 'utils/misc';

import { H1, SpacedH3, P } from 'assets/styles';
import leaseVoided from 'assets/new-icons/task-checklist-writing.svg';
import { LEASE_TRANSACTION_TYPE_VACATE, getDocumentTypeLabelForTransaction } from 'constants/leaseTransaction';

export const Img = styled.img`
    padding-top: 14px;
    height: 157px;
`;

export const applicationUnit = css`
    color: #454b57;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding-top: 10px;
`;

export const LeaseVoidedPage = ({ leaseTransaction, configuration }) => {
    if (!leaseTransaction || !configuration) return null;

    const { unit } = leaseTransaction;
    const buildingName = configuration.community.building_name || configuration.community.normalized_street_address;
    const unitNumber = !!unit && !!unit.unit_number ? ` Unit ${unit.unit_number}` : '';

    const documentLabel =
        leaseTransaction?.type === LEASE_TRANSACTION_TYPE_VACATE
            ? getDocumentTypeLabelForTransaction(leaseTransaction)
            : 'Document';

    return (
        <>
            <H1>We&apos;re Revising Your {documentLabel}</H1>
            <SpacedH3>
                We&apos;re working on making some corrections to your document. Don&apos;t worry, we&apos;ll have the
                new {documentLabel.toLowerCase()} ready soon!
            </SpacedH3>
            <Img src={leaseVoided} />
            <div id="application-unit" className={applicationUnit}>
                {buildingName}
                {unitNumber}
            </div>
            <P margin="90px 0 0 0" bold>
                Call us at{' '}
                <a href={`tel:${configuration.community.contact_phone}`}>
                    {prettyFormatPhoneNumber(configuration.community.contact_phone)}
                </a>
                &nbsp;if you have any questions.
            </P>
        </>
    );
};

LeaseVoidedPage.propTypes = {
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    configuration: state.configuration,
});

export default connect(mapStateToProps)(captureRoute(LeaseVoidedPage, ROUTES.LEASE_VOIDED));
