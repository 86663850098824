import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { Card, CardSection } from 'assets/styles';

export const useStyles = makeStyles((theme) => ({
    checkBoxWrapper: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        display: 'none',

        '& svg': {
            '& path': {
                color: theme.palette.primary.main,
                fill: 'currentcolor',
            },
        },
    },
    renewalCard: {
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&.disabled': {
            background: `#F5F5F5`,
            pointerEvents: 'none',
            boxShadow:
                '0px 0px 2px rgba(0, 0, 0, 0.14),' +
                '0px 2px 2px rgba(0, 0, 0, 0.12),' +
                '0px 1px 3px rgba(0, 0, 0, 0.2)',
        },
        '&.selected': {
            background: theme.palette.primary.main + '14',
            border: `1px solid ${theme.palette.primary.main}`,
            '& $checkBoxWrapper': {
                display: 'block',
            },
        },
    },
    cardSection: {
        padding: '15px 35px 15px 25px',
    },
}));

export const LeaseOfferWrapper = ({
    selected = false,
    disabled = false,
    onClickHandler = (f) => f,
    children = null,
}) => {
    const classes = useStyles();
    return (
        <Card className={clsx(classes.renewalCard, { selected, disabled })} onClick={onClickHandler}>
            <CardSection className={classes.cardSection}>
                {children}
                <div className={classes.checkBoxWrapper}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 12C0 5.37267 5.3729 0 12 0C18.6276 0 24 5.37267 24 12C24 18.6273 18.6278 24 12 24C5.3729 24 0 18.6273 0 12ZM4.5148 12.8796L9.79865 18.1633L19.4851 8.47659L17.724 6.71546L9.79865 14.641L6.27594 11.1185L4.5148 12.8796Z" />
                    </svg>
                </div>
            </CardSection>
        </Card>
    );
};

LeaseOfferWrapper.propTypes = {
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    onClickHandler: PropTypes.func,
    children: PropTypes.node,
};

export default LeaseOfferWrapper;
