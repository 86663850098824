import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';

import API from 'api/api';
import { leaseTransactionType } from 'types';
import {
    APPLICATION_STATUS_DENIED,
    APPLICANT_ROLE_LABELS,
    APPLICATION_STATUSES_LABELS,
    APPLICATION_STATUSES_COLORS,
} from 'constants/constants';
import { LEASE_TRANSACTION_TYPES, LEASE_TRANSACTION_TYPE_APPLICATION } from 'constants/leaseTransaction';

import { getGenericErrorMessage } from 'constants/messages';
import * as routingHelpers from 'utils/routingHelpers';

import { fetchApplicant } from 'reducers/applicant';
import { fetchTransaction } from 'reducers/transaction';
import { actions as loaderActions } from 'reducers/loader';

import ActionButton from 'common-components/ActionButton/ActionButton';

export const ERROR_MESSAGE = getGenericErrorMessage('something went wrong');

export function LeaseTransactionAccordionContentWrapper({
    children = null,
    classes = null,
    leaseTransaction = null,
    invitee = null,
    applicant,
    fetchApplicant = (f) => f,
    fetchTransaction = (f) => f,
    toggleLoader = (f) => f,
    setError = (f) => f,
}) {
    const {
        id,
        role,
        type,
        isNew,
        status,
        active,
        is_managed,
        quote_id,
        quote_is_expired,
        original_client_quote,
        is_price_update_completed,
        is_month_to_month_update,
    } = leaseTransaction;

    const showCTAButton = (active || isNew || status === APPLICATION_STATUS_DENIED) && !is_managed;
    const typeLabel = is_month_to_month_update ? 'Month-to-Month' : LEASE_TRANSACTION_TYPES[type];
    const statusLabel = APPLICATION_STATUSES_LABELS[status];
    const statusColor = APPLICATION_STATUSES_COLORS[status];
    const CTALabel = isNew || invitee ? 'Start Application' : `Go To ${typeLabel}`;
    const CTAVariant = isNew || invitee ? 'contained' : 'outlined';

    const originalClientQuoteIsExpired = original_client_quote?.is_expired || false;
    let showQuoteExpiredError = false;
    if (quote_is_expired) {
        showQuoteExpiredError = true;
    } else if (originalClientQuoteIsExpired && !is_price_update_completed) {
        showQuoteExpiredError = true;
    }

    const createApplicantRole = useCallback(async () => {
        try {
            toggleLoader(true);

            const response = await API.createApplicantRole(invitee.id);
            if (response?.errors) {
                return setError(ERROR_MESSAGE);
            }

            await fetchTransaction(id);
            await fetchApplicant(id);
            return routingHelpers.goToLeaseTransaction(leaseTransaction);
        } catch {
            return setError(ERROR_MESSAGE);
        } finally {
            toggleLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createLeaseTransaction = useCallback(async () => {
        try {
            toggleLoader(true);

            const response = await API.postLeaseTransaction({
                type: LEASE_TRANSACTION_TYPE_APPLICATION,
                unit: leaseTransaction.unit,
                move_in_date: leaseTransaction.move_in_date,
                lease_term: leaseTransaction.lease_term,
                lease_settings: leaseTransaction.lease_settings,
                original_client_quote_id: quote_id || null,
            });

            if (['ExistingActiveApplicationError', 'ExistingActiveLeaseError'].includes(response?.error_type)) {
                const errors = response?.errors?._schema;
                return setError(errors ? errors[0] : ERROR_MESSAGE);
            } else if (response?.errors) {
                return setError(ERROR_MESSAGE);
            }

            await fetchTransaction(response.lease_transaction_id);
            await fetchApplicant(response.lease_transaction_id);
            return routingHelpers.goToLeaseTransaction({
                id: response.lease_transaction_id,
                lease_settings: response.lease_settings,
            });
        } catch {
            setError(ERROR_MESSAGE);
        } finally {
            toggleLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLeaseTransactionClick = async () => {
        if (is_month_to_month_update) {
            return routingHelpers.goToMonthToMonthConfirmation(leaseTransaction);
        }
        if (invitee) {
            createApplicantRole();
        } else if (isNew) {
            createLeaseTransaction();
        } else {
            await fetchTransaction(id);
            await fetchApplicant(id);
            return routingHelpers.goToLeaseTransaction(leaseTransaction);
        }
    };

    return (
        <div className={classes.accordionContent}>
            {showQuoteExpiredError && (
                <p className={classes.errorNotice}>
                    <ErrorIcon />
                    <span>Your quote has expired and pricing has updated.</span>
                </p>
            )}
            {!!applicant?.failed_payment && (
                <p className={classes.errorNotice}>
                    <ErrorIcon />
                    <span>Outstanding balance due to failed payment.</span>
                </p>
            )}
            {invitee && (
                <p className={classes.errorNotice}>
                    <ErrorIcon />
                    <span>You’ve been invited to apply for this unit.</span>
                </p>
            )}
            <Typography className={classes.typography} variant="body1">
                Status: <span style={{ color: statusColor }}>{statusLabel}</span>
            </Typography>
            {children}
            <Typography className={classes.typography} variant="body1">
                Role: <span>{`${APPLICANT_ROLE_LABELS[role || invitee?.role]}`}</span>
            </Typography>
            {showCTAButton && (
                <ActionButton
                    variant={CTAVariant}
                    marginTop={20}
                    marginBottom={10}
                    onClick={handleLeaseTransactionClick}
                >
                    {CTALabel}
                </ActionButton>
            )}
        </div>
    );
}

LeaseTransactionAccordionContentWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    leaseTransaction: leaseTransactionType,
    invitee: PropTypes.object,
    isNew: PropTypes.bool,
    fetchApplicant: PropTypes.func.isRequired,
    fetchTransaction: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    toggleLoader: PropTypes.func.isRequired,
    applicant: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
});

const mapDispatchToProps = {
    fetchApplicant,
    fetchTransaction,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaseTransactionAccordionContentWrapper);
