import React from 'react';
import PropTypes from 'prop-types';

import { LEASE_TRANSACTION_TYPE_APPLICATION } from 'constants/leaseTransaction';
import { ROLE_GUARANTOR } from 'constants/constants';

import AddressForm from 'common-components/AddressForm/AddressForm';

export const ApplicantAddressForm = ({ applicant, leaseTransaction, showAutomatedAddress, errors, onSubmit }) => {
    let isInitialValid = false;
    const address = {
        address_street: applicant.address_street,
        address_city: applicant.address_city,
        address_state: applicant.address_state,
        address_postal_code: applicant.address_postal_code,
        address_line_2: applicant.address_line_2,
    };

    if (
        leaseTransaction.type !== LEASE_TRANSACTION_TYPE_APPLICATION &&
        leaseTransaction?.unit?.building &&
        !applicant.move_in_date &&
        applicant.role !== ROLE_GUARANTOR
    ) {
        isInitialValid = true;
        const building = leaseTransaction.unit.building;
        address.address_street = applicant.address_street || building.street_address;
        address.address_city = applicant.address_city || building.city;
        address.address_state = applicant.address_state || building.state;
        address.address_postal_code = applicant.address_postal_code || building.postal_code;
    }

    return (
        <AddressForm
            address={address}
            errors={errors}
            onSubmit={onSubmit}
            isInitialValid={isInitialValid}
            showAutomatedAddress={showAutomatedAddress}
        />
    );
};

ApplicantAddressForm.propTypes = {
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    errors: PropTypes.array,
    showAutomatedAddress: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
};

export default ApplicantAddressForm;
