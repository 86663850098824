import moment from 'moment';

export const getLeaseTermDatesLabels = ({ leaseTransaction, applicant, format = 'LL' }) => {
    const start = moment(applicant?.move_in_date || leaseTransaction?.lease_start_date);
    const end = moment(applicant?.move_out_date || leaseTransaction.lease_end_date);

    return `${moment(start).format('LL')} - ${moment(end).format(format)}`;
};

export const getOriginalQuoteExpired = ({ leaseTransaction, applicant }) => {
    return leaseTransaction?.original_client_quote?.is_expired && !applicant.is_price_update_completed;
};

export const getShowFees = (fee) => {
    return fee?.items?.length > 0 || false;
};
