export const getTermsDataFromLocalStorage = (leaseSettingsId, configuration) => {
    const terms = {};
    terms.terms_of_service_acceptance = JSON.parse(localStorage?.getItem(`accepted-platform-terms-${leaseSettingsId}`));

    terms.electronic_signature_acceptance = JSON.parse(
        localStorage?.getItem(`accepted-electronic-signature-terms-${leaseSettingsId}`)
    );

    terms.screening_criteria_acceptance = JSON.parse(
        localStorage?.getItem(`screening-criteria-acceptance-terms-${leaseSettingsId}`)
    );

    if (configuration.terms_of_service_document?.current_revision) {
        terms.terms_of_service_acceptance.agreement_document_revision =
            configuration.terms_of_service_document.current_revision.id;
    }

    return terms;
};

export const getTermsArrayFromLocalStorage = (leaseSettingsId, configuration) => {
    const terms = getTermsDataFromLocalStorage(leaseSettingsId, configuration);

    const termsArray = [];
    Object.keys(terms).forEach((key) => {
        const acceptedTerms = terms[key];
        acceptedTerms && termsArray.push(acceptedTerms);
    });

    return termsArray;
};
