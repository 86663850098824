import * as Yup from 'yup';

import { ALLOWED_CITY_NAMES } from 'constants/validation';

export const addressFieldsValidation = Yup.object().shape({
    search: Yup.string(),
    address_street: Yup.string()
        .required('Street is required')
        .matches(/^[0-9-]/, 'Street address must start with a number'),
    address_city: Yup.string()
        .required('City is required')
        .matches(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/, 'Invalid city')
        .test('test-city-address-allowed-cities', 'Please enter full city name', (city) => {
            if (!city) return;
            return ALLOWED_CITY_NAMES.find((c) => c.toLowerCase() === city.toLowerCase()) || city?.length > 2;
        })
        .test('test-city-address-only-special-characters', 'Invalid city', (city) => {
            if (!city) return;
            return !/^[^a-zA-Z0-9]+$/.test(city);
        }),
    address_state: Yup.string().required('State is required'),
    address_postal_code: Yup.string().required('Zip code is required'),
    address_line_2: Yup.string().test('not-duplicate-address', 'Duplicate address field', function (value) {
        const { address_street } = this.parent;

        if (!value) return true;

        return value !== address_street;
    }),
});

export const employerAddressFieldsValidation = Yup.object().shape({
    name: Yup.string().nullable().notRequired(),
    search: Yup.string(),
    address_street: Yup.string()
        .nullable()
        .notRequired()
        .matches(/^[0-9-]/, 'Street address must start with a number'),
    address_city: Yup.string()
        .nullable()
        .notRequired()
        .matches(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/, 'Invalid city')
        .test('test-city-address-allowed-cities', 'Please enter full city name', (city) => {
            if (!city) return true;
            return ALLOWED_CITY_NAMES.find((c) => c.toLowerCase() === city.toLowerCase()) || city?.length > 2;
        })
        .test('test-city-address-only-special-characters', 'Invalid city', (city) => {
            if (!city) return true;
            return !/^[^a-zA-Z0-9]+$/.test(city);
        }),
    address_state: Yup.string().nullable().notRequired(),
    address_postal_code: Yup.string().nullable().notRequired(),
    address_line_2: Yup.string().nullable().notRequired(),
});

export const vacateAddressFieldsValidation = Yup.object().shape({
    search: Yup.string(),
    address_street: Yup.string()
        .required('Street is required')
        .matches(/^[0-9-]/, 'Street address must start with a number'),
    address_city: Yup.string()
        .required('City is required')
        .matches(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/, 'Invalid city')
        .test('test-city-address-allowed-cities', 'Please enter full city name', (city) => {
            if (!city) return;
            return ALLOWED_CITY_NAMES.find((c) => c.toLowerCase() === city.toLowerCase()) || city?.length > 2;
        })
        .test('test-city-address-only-special-characters', 'Invalid city', (city) => {
            if (!city) return;
            return !/^[^a-zA-Z0-9]+$/.test(city);
        }),
    address_state: Yup.string().required('State is required'),
    address_postal_code: Yup.string().required('Zip code is required'),
    address_line_2: Yup.string(),
});

export const bankAccountFieldsValidation = Yup.object().shape({
    account_holder_name: Yup.string().required('Account holder name is a required field'),
    account_type: Yup.string().required('Account type is a required field'),
    account_number: Yup.string()
        .matches(/^\d+$/, 'Account number may only contain digits')
        .max(17, 'Account number may be no more than 17 digits long')
        .min(4, 'Account number should be at least 4 digits long')
        .required('Bank account number is a required field'),
    confirm_account_number: Yup.string()
        .matches(/^\d+$/, 'Confirm account number may only contain digits')
        .oneOf([Yup.ref('account_number'), null], 'Bank account numbers must match')
        .required('Confirm bank account number is a required field'),
    routing_number: Yup.string()
        .matches(/^\d+$/, 'Routing number may only contain digits')
        .length(9, 'Routing number must be 9 digits')
        .required('Routing number is a required field'),
    account_nickname: Yup.string().max(21, 'Account nickname may be no more than 21 characters long'),
});
