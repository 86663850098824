import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import {
    makeStyles,
    FormGroup,
    FormControlLabel as MaterialFormControlLabel,
    Checkbox,
    Typography,
    styled,
} from '@material-ui/core';

import API from 'api/api';
import { ROUTES, TOS_TYPE_NESTIO, TOS_TYPE_SCREENING_CRITERIA } from 'constants/constants';
import captureRoute from 'utils/captureRoute';
import { sessionIsValidForCommunityId } from 'utils/misc';
import { getTermsArrayFromLocalStorage } from 'utils/terms';
import useQuery from 'hooks/useQuery';

import { fetchTransaction } from 'reducers/transaction';
import { fetchApplicant } from 'reducers/applicant';
import { leaseTransactionPath } from 'reducers/renter-profile';
import { toggleLoader } from 'reducers/loader';

import ActionButton from 'common-components/ActionButton/ActionButton';
import UnauthenticatedPage from 'pages/Unauthenticated';
import { H1, Card, ScrollableTermsCardSection } from 'assets/styles';

const CONTENT_TYPE_HTML = 'html';

export const FormControlLabel = styled(MaterialFormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
    },
});

const useFormGroupClasses = makeStyles(() => ({
    root: {
        marginBottom: 40,
    },
}));

export function TermsPage({
    configuration,
    leaseSettingsId,
    isSignedIn,
    history,
    tosDocument,
    screeningCriteriaAgreement,
    fetchApplicant,
    fetchTransaction,
    userProfile,
    toggleLoader,
}) {
    const query = useQuery();
    const isRegistered = Boolean(query.get('registered'));
    const transactionId = query.get('transaction_id');
    const formGroupClasses = useFormGroupClasses();
    const [agreeElectronicSignature, setAgreeElectronicSignature] = useState(false);
    const [agreeTermsOfService, setAgreeTermsOfService] = useState(false);
    const disabled = !agreeTermsOfService || !agreeElectronicSignature;

    const forceScroll = configuration?.community?.company?.force_applicants_to_scroll_terms || false;
    const [enableCheckboxes, setEnableCheckboxes] = useState(!forceScroll);
    const scrollableTermsRef = useRef(null);
    const bottomOfTermsRef = useRef(null);

    useEffect(() => {
        toggleLoader(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAgree = useCallback(async () => {
        const now = Date.now();

        localStorage.setItem(
            `accepted-platform-terms-${leaseSettingsId}`,
            JSON.stringify({
                type: TOS_TYPE_NESTIO,
                context: { time: now },
                agreement_document_revision: tosDocument?.current_revision?.id,
            })
        );

        localStorage.setItem(
            `screening-criteria-acceptance-terms-${leaseSettingsId}`,
            JSON.stringify({
                type: TOS_TYPE_SCREENING_CRITERIA,
                context: { time: now },
                agreement_document_revision: screeningCriteriaAgreement?.current_revision?.id,
            })
        );

        if (!isRegistered) {
            history.push(ROUTES.SIGNUP);
        } else {
            if (transactionId) {
                await fetchApplicant(transactionId);
                const transaction = await fetchTransaction(transactionId);
                const termsArray = getTermsArrayFromLocalStorage(leaseSettingsId, configuration);
                await API.acceptTerms(transaction.id, { terms: termsArray });
                const newRoute =
                    userProfile?.completed_account_setup === false
                        ? ROUTES.ACCOUNT
                        : leaseTransactionPath(ROUTES.ADDRESS, { lease_transaction_id: transaction.id });
                history.replace(newRoute);
            } else {
                history.replace(ROUTES.ACCOUNT);
            }
        }
    }, [
        leaseSettingsId,
        tosDocument,
        screeningCriteriaAgreement,
        isRegistered,
        history,
        transactionId,
        fetchApplicant,
        fetchTransaction,
        configuration,
        userProfile,
    ]);

    const getIsElementVisible = (target, holder) => {
        if (!target || !holder) return;
        const { top, bottom, height } = target.getBoundingClientRect();
        const holderRect = holder.getBoundingClientRect();

        return top <= holderRect.top ? holderRect.top - top <= height : bottom - holderRect.bottom <= height;
    };

    const renderTos = useCallback(() => {
        const handleScroll = () => {
            if (!forceScroll || enableCheckboxes) return;

            if (getIsElementVisible(bottomOfTermsRef?.current, scrollableTermsRef?.current)) {
                setEnableCheckboxes(true);
            }
        };

        let content;
        if (tosDocument.current_revision.content_type === CONTENT_TYPE_HTML) {
            content = (
                <div
                    dangerouslySetInnerHTML={{
                        __html: tosDocument.current_revision.content,
                    }}
                />
            );
        } else {
            content = <p style={{ 'white-space': 'pre-wrap' }}>{tosDocument.current_revision.content}</p>;
        }

        return (
            <div data-testid="terms">
                <H1>Consent to Electronic Signature and Terms of Service</H1>
                <br />
                <Card>
                    <ScrollableTermsCardSection ref={scrollableTermsRef} onScroll={handleScroll}>
                        <div className="tos-container">
                            <em>
                                Last Updated:{' '}
                                {format(parseISO(tosDocument.current_revision.updated_at), 'MMMM dd, yyyy')}
                            </em>
                            {content}
                            <div ref={bottomOfTermsRef} />
                        </div>
                    </ScrollableTermsCardSection>
                </Card>
            </div>
        );
    }, [tosDocument, enableCheckboxes, forceScroll]);

    if (isSignedIn && !isRegistered) {
        return renderTos();
    }

    const agreeToTermsFromControlLabel = screeningCriteriaAgreement ? (
        <>
            I agree to the Terms of Service and{' '}
            <a
                href={screeningCriteriaAgreement.current_revision?.document_url}
                target="_blank"
                rel="noopener noreferrer"
            >
                {screeningCriteriaAgreement?.display_name || 'the Residency Selection Criteria'}
            </a>
        </>
    ) : (
        'I agree to the Terms of Service'
    );

    return (
        <UnauthenticatedPage isRegistered={isRegistered}>
            {renderTos()}
            <div data-testid="accept-terms">
                <Typography gutterBottom paragraph variant="body2" align="left">
                    Please read to the end of the document before you agree to them.
                </Typography>
                <FormGroup classes={formGroupClasses} row>
                    <FormControlLabel
                        label="I agree to Consent to Electronic Signature"
                        control={
                            <Checkbox
                                disabled={!enableCheckboxes}
                                checked={agreeElectronicSignature}
                                onChange={(e) => setAgreeElectronicSignature(e.target.checked)}
                            />
                        }
                    />
                    <FormControlLabel
                        label={agreeToTermsFromControlLabel}
                        control={
                            <Checkbox
                                disabled={!enableCheckboxes}
                                onChange={(e) => setAgreeTermsOfService(e.target.checked)}
                                checked={agreeTermsOfService}
                            />
                        }
                    />
                </FormGroup>
                <ActionButton onClick={onAgree} disabled={disabled}>
                    Agree and Continue
                </ActionButton>
            </div>
        </UnauthenticatedPage>
    );
}

TermsPage.propTypes = {
    configuration: PropTypes.object,
    leaseSettingsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isSignedIn: PropTypes.bool,
    history: PropTypes.object,
    tosDocument: PropTypes.object,
    screeningCriteriaAgreement: PropTypes.object,
    fetchApplicant: PropTypes.func,
    fetchTransaction: PropTypes.func,
    toggleLoader: PropTypes.func,
    userProfile: PropTypes.object,
};

const mapStateToProps = (state) => ({
    configuration: state.configuration,
    leaseSettingsId: state.siteConfig.basename,
    tosDocument: state.configuration.terms_of_service_document,
    screeningCriteriaAgreement: state.configuration.screening_criteria_agreement_document,
    isSignedIn: sessionIsValidForCommunityId(state.siteConfig.basename),
    userProfile: state.userProfile,
});

const mapActionsToProps = {
    fetchApplicant,
    fetchTransaction,
    toggleLoader,
};

export default connect(mapStateToProps, mapActionsToProps)(captureRoute(TermsPage, ROUTES.TERMS));
