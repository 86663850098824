import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { leaseTransactionType } from 'types';
import { getDateLabel } from 'pages/LeaseTransactions/utils/helpers';

function RenewalAndTransferAccordionContent({ classes, leaseTransaction }) {
    const { lease_start_date, lease_end_date, total_monthly_fees } = leaseTransaction;

    return (
        <>
            <Typography className={classes.typography} variant="body1">
                Lease Term: <span>{getDateLabel(lease_start_date)}</span>
                <span> - </span>
                <span>{getDateLabel(lease_end_date)}</span>
            </Typography>
            <Typography className={classes.typography} variant="body1">
                Monthly Rent: <span>{total_monthly_fees ? `$${total_monthly_fees}` : '---'}</span>
            </Typography>
        </>
    );
}

RenewalAndTransferAccordionContent.propTypes = {
    classes: PropTypes.object.isRequired,
    leaseTransaction: leaseTransactionType,
};

export default RenewalAndTransferAccordionContent;
