import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

import { getGenericErrorMessage } from 'constants/messages';

import Page from 'common-components/Page/Page';
import UnitCard from 'common-components/UnitCard/UnitCard';

import * as hooks from './hooks';

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'left',
    },
    unitCard: {
        marginBottom: 32,
    },
}));

export const createUnitToLeasesMap = (leases) => {
    const unitToLeasesMap = new Map();
    const currentDate = new Date(Date.now());

    if (!leases) {
        return unitToLeasesMap;
    }

    leases.forEach((lease) => {
        const { unit, lease_end_date, lease_start_date, lease_settings: leaseSettings } = lease;
        const unitKey = unit?.unit_number
            ? `${leaseSettings.community.display_name}, # ${unit.unit_number}`
            : leaseSettings.community.display_name;
        let active = false;
        let statusLabel;

        if (currentDate > new Date(lease_end_date)) {
            statusLabel = 'Past';
        } else if (currentDate < new Date(lease_start_date)) {
            statusLabel = 'Upcoming';
        } else {
            statusLabel = 'Current';
            active = true;
        }

        if (unitToLeasesMap.has(unitKey)) {
            unitToLeasesMap.get(unitKey).push({ ...lease, statusLabel, active });
        } else {
            unitToLeasesMap.set(unitKey, [{ ...lease, statusLabel, active }]);
        }
    });

    return unitToLeasesMap;
};

export const ERROR_MESSAGE = getGenericErrorMessage("we're having trouble obtaining your leases");

export function LeasesPage({ history }) {
    const classes = useStyles();
    const [error, setError] = useState(undefined);
    const { finished, data: leases, error: leasesError } = hooks.useLeases(ERROR_MESSAGE);

    const unitToLeasesMap = useMemo(() => createUnitToLeasesMap(leases), [leases]);

    const showEmptyState = !error && !leasesError && finished && unitToLeasesMap.size === 0;
    const notificationError = error || leasesError;
    const notification = notificationError && {
        type: 'error',
        messages: notificationError,
    };

    return (
        <Page className={classes.root} title="My Leases" notification={notification} loading={!finished}>
            {Array.from(unitToLeasesMap.keys()).map((key) => {
                return (
                    <UnitCard
                        key={key}
                        title={key}
                        leases={unitToLeasesMap.get(key)}
                        setError={setError}
                        history={history}
                    />
                );
            })}
            {showEmptyState && <Typography variant="h4" align="center">{`You don’t have any leases yet.`}</Typography>}
        </Page>
    );
}

LeasesPage.propTypes = {
    history: PropTypes.object.isRequired,
};

export default LeasesPage;
