import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import get from 'lodash/get';

import { ROUTES, RENTER_PROFILE_TYPE_CO_APPLICANTS } from 'constants/constants';
import { serializeDate } from 'utils/misc';

import { updateTransaction } from 'reducers/transaction';
import { actions as loaderActions } from 'reducers/loader';

import { BackLink } from 'common-components/BackLink/BackLink';
import InviteForm from 'common-components/InviteForm/InviteForm';
import { H1, H3 } from 'assets/styles';
import roommatesImage from 'assets/new-icons/streamlined-household.svg';
import withTransactionPath from 'utils/withTransactionPath';

const SpacedH3 = styled(H3)`
    margin-top: 15px;
    margin-bottom: 30px;
`;

export class EditDependentPage extends Component {
    state = { confirmSent: false, errors: null };

    updateDependant = (values, { setSubmitting, setErrors }) => {
        const serialized = Object.assign({}, values);
        const serializedBirthday = serializeDate(serialized.birthday);
        if (serializedBirthday) {
            serialized.birthday = serializedBirthday;
        }
        serialized.id = this.props.match.params.id;

        this.props.toggleLoader(true);

        this.props
            .updateTransaction({ dependents: [serialized] })
            .then((res) => {
                if (res.errors) {
                    const errorsObj = get(res, 'errors.dependents');
                    const errors = errorsObj && Object.values(errorsObj)[0];
                    errors
                        ? setErrors(errors)
                        : this.setState({
                              errors: ['There was an error updating your dependent. Please Try again.'],
                          });
                } else {
                    const url = this.props.getTransactionPath(
                        `${ROUTES.PROFILE_OPTIONS}#${RENTER_PROFILE_TYPE_CO_APPLICANTS}`
                    );
                    this.props.history.push(url);
                }
            })
            .catch(() => {
                this.setState({ errors: ['There was an error updating your dependent. Please Try again.'] });
            })
            .finally(() => {
                this.props.toggleLoader(false);
                setSubmitting(false);
            });
    };

    getDependent() {
        const dependent = this.props.leaseTransaction.dependents.find(
            ({ person_id }) => person_id === parseInt(this.props.match.params.id)
        );
        if (dependent) {
            return dependent;
        }

        return this.props.leaseTransaction.invitees.find((x) => x.id === parseInt(this.props.match.params.id));
    }

    render() {
        if (this.props.leaseTransaction == null) return null;
        const dependent = this.getDependent();

        return (
            <>
                <H1>Edit a Person</H1>
                <SpacedH3>Enter their info below.</SpacedH3>
                <img src={roommatesImage} alt="hand with smartphone in it" />
                <InviteForm
                    initialIsDependent={true}
                    disableTypeChange={true}
                    initialValues={dependent}
                    onSubmitDependent={this.updateDependant}
                    handleOnSubmit={() => {}}
                    displayedErrors={this.state.errors}
                    buttonText="Save Changes"
                />
                <BackLink
                    to={this.props.getTransactionPath(`${ROUTES.PROFILE_OPTIONS}#${RENTER_PROFILE_TYPE_CO_APPLICANTS}`)}
                />
            </>
        );
    }
}

EditDependentPage.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    leaseTransaction: PropTypes.object,
    toggleLoader: PropTypes.func,
    updateTransaction: PropTypes.func,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
});

const mapDispatchToProps = {
    updateTransaction,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(EditDependentPage));
