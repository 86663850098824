import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Divider, makeStyles } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { Info } from '@material-ui/icons';
import { css } from 'emotion';
import { Elements as StripeElementsWrapper } from 'react-stripe-elements';

import Page from 'common-components/Page/Page';
import Capsule from 'common-components/Capsule/Capsule';
import safeImage from 'assets/new-icons/savings-bank-cash.svg';
import AddBankAccount from '../../FeesAndDeposits/components/AddBankAccount';
import usePageVisibility from 'hooks/usePageVisibility';
import { fetchApplicant } from 'reducers/applicant';
import { toggleLoader } from 'reducers/loader';
import API from 'api/api';
import RemoveBankAccount from 'pages/FeesAndDeposits/components/RemoveBankAccount';
import BankAccountPendingVerification from 'pages/FeesAndDeposits/components/BankAccountPendingVerification';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { BANK_ACCOUNT_STATUS_PENDING_VERIFICATION, BANK_ACCOUNT_STATUS_VERIFICATION_FAILED } from 'constants/constants';

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'left',
    },
    unitCard: {
        marginBottom: 32,
    },
}));

const paymentMethod = css`
    margin: 22px 7px 22px 7px;
    font-size: 16px;
    line-height: 19px;
`;

const paymentMethodHeader = css`
    font-weight: 600;
`;
const paymentMethodOwner = css`
    margin-top: 10px;
`;
const paymentMethodStatus = css`
    color: #828796;
    margin-top: 10px;
    font-size: 14px;
`;
const paymentMethodTip = css`
    margin-left: 8px;
    vertical-align: middle;
`;
const paymentMethodRemove = css`
    text-decoration: underline;
    margin-top: 10px;
    color: #2b44ff;
    cursor: pointer;
    width: max-content;
    font-size: 14px;
`;
const paymentMethodVerify = css`
    text-decoration: underline !important;
    color: #2b44ff;
    cursor: pointer;
    margin-left: 12px;
`;

const paymentMethodInvalidMessage = css`
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #fb6d68;
    display: inline-flex;
    gap: 10px;
`;
export function PaymentMethodsPage({ configuration, applicant, leaseTransaction, fetchApplicant, toggleLoader }) {
    const isPageVisible = usePageVisibility();
    const [currentPage, setCurrentPage] = useState();
    const [selectedBankAccount, setSelectedBankAccount] = useState();
    const classes = useStyles();
    const communityName = configuration?.community?.display_name;

    const removePaymentMethod = async () => {
        try {
            toggleLoader(true);
            await API.Payments.removePaymentMethod(leaseTransaction.id, selectedBankAccount.id);
            await fetchApplicant();
        } catch (error) {
            console.error(error);
        } finally {
            toggleLoader(false);
            setCurrentPage();
        }
    };

    useEffect(() => {
        if (isPageVisible && applicant) {
            // When users close or switch away from the Stripe bank account verification tab we need to refresh
            // the applicant so that we fetch the verified bank accounts.
            fetchApplicant();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPageVisible, fetchApplicant]);

    const renderBankAccountActions = (bankAccount) => {
        switch (bankAccount.status) {
            case BANK_ACCOUNT_STATUS_VERIFICATION_FAILED:
                return (
                    <div className={paymentMethodInvalidMessage}>
                        <ErrorIcon />
                        <span>
                            Verification failed <strong>either</strong> due <strong>to</strong> invalid account details,
                            exceeding attempt limits, or failure to complete verification within 10 days. Please resolve
                            the issue before trying to add this account again.
                        </span>
                    </div>
                );
            case BANK_ACCOUNT_STATUS_PENDING_VERIFICATION:
                return (
                    <div className={paymentMethodStatus}>
                        Pending verification
                        <span className={paymentMethodTip}>
                            <Info
                                onClick={() => {
                                    setCurrentPage('bank_account_pending_verification');
                                }}
                                style={{
                                    color: '#828796',
                                    width: 16,
                                    cursor: 'pointer',
                                }}
                            />
                        </span>
                        <a
                            className={paymentMethodVerify}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={bankAccount.verification_url}
                        >
                            Verify Account
                        </a>
                    </div>
                );
            default:
                if (
                    applicant?.failed_payment &&
                    applicant.failed_payment.payment_method_id === bankAccount.payment_method_token
                ) {
                    return (
                        <div className={paymentMethodInvalidMessage}>
                            <ErrorIcon />
                            <span>
                                Payment made on {moment(applicant.failed_payment.payment_date).format('MM/DD/YYYY')}{' '}
                                failed due to an issue with this account. Please make sure your bank account information
                                is accurate and that you have sufficient funds available before retrying this payment
                                method.
                            </span>
                        </div>
                    );
                }
                return null;
        }
    };

    switch (currentPage) {
        case 'add_bank_account':
            return (
                <StripeElementsWrapper>
                    <AddBankAccount
                        handleClickBack={() => setCurrentPage()}
                        onBankAccountAdded={() => setCurrentPage()}
                        communityName={communityName}
                    />
                </StripeElementsWrapper>
            );
        case 'remove_bank_account':
            return (
                <RemoveBankAccount
                    onCancel={() => setCurrentPage()}
                    onConfirmRemove={removePaymentMethod}
                    bankAccountLabel={selectedBankAccount.label}
                />
            );
        case 'bank_account_pending_verification':
            return (
                <BankAccountPendingVerification
                    clickToAction={() => setCurrentPage()}
                    titleContent="Pending Verification"
                    bodyContent={
                        <>
                            Your bank account is pending verification. You should have received an email with
                            instructions sent to <strong>{applicant.email}</strong>
                        </>
                    }
                    clickToActionContent="Close"
                />
            );
        default:
            return (
                <Page className={classes.root} title="Payment Methods">
                    <Capsule
                        prefix={<img src={safeImage} alt="safe bank" />}
                        name="Bank Accounts"
                        label="Bank Accounts"
                        hideButton={true}
                        expansionPanel={
                            <div>
                                <Divider />
                                {!!applicant?.bank_accounts.length ? (
                                    applicant.bank_accounts.map((bank_account, idx) => (
                                        <Fragment key={idx}>
                                            <div className={paymentMethod}>
                                                <div className={paymentMethodHeader}>{bank_account.label}</div>
                                                <div className={paymentMethodOwner}>{bank_account.owner_name}</div>
                                                {renderBankAccountActions(bank_account)}
                                                <div
                                                    className={paymentMethodRemove}
                                                    data-testid="remove"
                                                    onClick={() => {
                                                        setSelectedBankAccount(bank_account);
                                                        setCurrentPage('remove_bank_account');
                                                    }}
                                                >
                                                    Remove
                                                </div>
                                            </div>
                                            <Divider />
                                        </Fragment>
                                    ))
                                ) : (
                                    <p style={{ color: '#828796' }}>You don’t have any saved bank accounts.</p>
                                )}
                                <ActionButton
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginTop: '22px' }}
                                    onClick={() => setCurrentPage('add_bank_account')}
                                >
                                    Add a Bank Account
                                </ActionButton>
                            </div>
                        }
                    />
                </Page>
            );
    }
}

PaymentMethodsPage.propTypes = {
    configuration: PropTypes.object.isRequired,
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    fetchApplicant: PropTypes.func.isRequired,
    toggleLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    configuration: state.configuration,
    applicant: state.applicant,
    leaseTransaction: state.transaction,
});

const mapDispatchToProps = {
    fetchApplicant,
    toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsPage);
