import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { CardRow } from 'assets/styles';
import { useRenewalOptionStyles } from 'pages/Renewal/styles';

export const RenewalOption = ({ price, leaseStart, leaseEnd, bestOffer, term, isLeaseOfferDisclaimer }) => {
    const classes = useRenewalOptionStyles();
    const getAsterisk = isLeaseOfferDisclaimer ? '*' : '';

    return (
        <>
            <CardRow className={classes.row}>
                <Typography>
                    <b>{term} Month Term</b>
                    {bestOffer && <span className={classes.bestOffer}>BEST OFFER</span>}
                </Typography>
            </CardRow>
            <CardRow className={classes.row}>
                <div className={classes.rentWrapper}>
                    <div className={classes.rentLabel}>
                        ${price} {getAsterisk}
                    </div>
                    <div className={classes.perMonthLabel}>per month</div>
                </div>
                <div className={classes.contentWrapper}>
                    <div>
                        <div className={classes.label}>Lease start</div>
                        <div className={classes.value}>{leaseStart}</div>
                    </div>
                    <div>
                        <div className={classes.label}>Lease end</div>
                        <div className={classes.value}>{leaseEnd}</div>
                    </div>
                </div>
            </CardRow>
        </>
    );
};

RenewalOption.propTypes = {
    price: PropTypes.string.isRequired,
    leaseStart: PropTypes.string.isRequired,
    leaseEnd: PropTypes.string.isRequired,
    term: PropTypes.number.isRequired,
    bestOffer: PropTypes.bool,
    isLeaseOfferDisclaimer: PropTypes.bool.isRequired,
};

export default RenewalOption;
