import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useLeaseSignEvent from 'pages/Application/components/SignLeaseView/hooks/useLeaseSignEvent';

const loadBlueMoonScript = () => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://cdn.bluemoonforms.com/launcher.js';
        script.async = true;

        script.onload = () => {
            resolve(window.BluemoonElements);
        };

        script.onerror = () => {
            reject(new Error('Failed to load the Bluemoon script.'));
        };

        document.body.appendChild(script);
    });
};

const BlueMoonLeaseView = ({
    applicantUpdated,
    getTransactionPath,
    history,
    leaseTransaction,
    setLoading,
    token,
    property_id,
    signer_id,
    security_pin,
}) => {
    const containerRef = useRef(null);

    const signHandler = () => {
        loadBlueMoonScript().then((BluemoonElements) => {
            const params = {
                signerId: signer_id,
                securityPin: security_pin,
                showSidebar: false,
                showDocumentPanel: false,
            };

            const bluemoonElements = new BluemoonElements(token, property_id.toString());
            const esignatureClientElement = bluemoonElements.load(
                containerRef.current.id,
                'bm-esignature-client',
                params
            );

            esignatureClientElement.addEventListener('signatureCompleted', handleSignEvent);
            esignatureClientElement.addEventListener('signatureCancelled', handleCloseEvent);

            return () => {
                esignatureClientElement.removeEventListener('signatureCompleted', handleSignEvent);
                esignatureClientElement.removeEventListener('signatureCancelled', handleCloseEvent);
            };
        });
    };

    const { handleSignEvent, handleCloseEvent } = useLeaseSignEvent({
        applicantUpdated,
        getTransactionPath,
        history,
        leaseTransaction,
        setLoading,
        signHandler,
    });

    return <div id="bluemoon-esignature-client-container" className="bluemoon-embedded" ref={containerRef}></div>;
};

BlueMoonLeaseView.propTypes = {
    applicantUpdated: PropTypes.func,
    getTransactionPath: PropTypes.func,
    history: PropTypes.object,
    leaseTransaction: PropTypes.object,
    property_id: PropTypes.number.isRequired,
    security_pin: PropTypes.string.isRequired,
    setLoading: PropTypes.func,
    signer_id: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
};

export default BlueMoonLeaseView;
