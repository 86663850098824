import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

import {
    ACTIVATED_APPLICATION_STATUSES,
    ACTIVE_APPLICATION_STATUSES,
    APPLICANT_ROLE_VALUES,
    APPLICATION_STATUSES,
    LEASE_INACTIVE_STATUSES,
} from 'constants/constants';
import { getGenericErrorMessage } from 'constants/messages';
import {
    LEASE_TRANSACTION_TYPE_APPLICATION,
    LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION,
    LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE,
    LEASE_TRANSACTION_TYPE_RENEWAL,
    LEASE_TRANSACTION_TYPE_TRANSFER,
    LEASE_TRANSACTION_TYPE_VACATE,
    PAST_LEASE_TRANSACTION_STATUSES,
} from 'constants/leaseTransaction';

import useScrollToElement from 'hooks/useScrollToElement';
import Page from 'common-components/Page/Page';

import * as hooks from './hooks';
import { actions as loaderActions } from 'reducers/loader';
import UnitCard from 'common-components/UnitCard/UnitCard';

import { APPLICATION_STATUS_COMPLETED } from 'constants/constants';

export const ERROR_MESSAGE = getGenericErrorMessage("we're having trouble obtaining your activity");

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'left',
    },
    unitCard: {
        marginBottom: 32,
    },
}));

const getActiveApplicationAsPrimary = (applicant, leaseTransactions) => {
    if (!leaseTransactions) return {};

    return leaseTransactions.reduce((acc, leaseTransaction) => {
        if (leaseTransaction.lease && LEASE_INACTIVE_STATUSES.includes(leaseTransaction.lease.status)) {
            return acc;
        }
        if (
            leaseTransaction.primary_applicant?.applicant_id === applicant.id &&
            leaseTransaction.role === APPLICANT_ROLE_VALUES.ROLE_PRIMARY_APPLICANT &&
            ACTIVATED_APPLICATION_STATUSES.includes(leaseTransaction.status)
        ) {
            if (acc.hasOwnProperty(leaseTransaction.community.id)) {
                acc[leaseTransaction.community.id].push(leaseTransaction);
            } else {
                acc[leaseTransaction.community.id] = [leaseTransaction];
            }
        }

        return acc;
    }, {});
};

const getActiveLeasesAsPrimary = (applicant, leaseTransactions) => {
    if (!leaseTransactions) return {};

    return leaseTransactions.reduce((acc, leaseTransaction) => {
        if (
            leaseTransaction.lease &&
            !LEASE_INACTIVE_STATUSES.includes(leaseTransaction.lease.status) &&
            leaseTransaction.lease.primary_applicant?.applicant_id === applicant.id
        ) {
            if (acc.hasOwnProperty(leaseTransaction.community.id)) {
                acc[leaseTransaction.community.id].push(leaseTransaction);
            } else {
                acc[leaseTransaction.community.id] = [leaseTransaction];
            }
        }
        return acc;
    }, {});
};

const getInvitationsData = (invitations, configuration) => {
    const leaseTransactionsInvitedTo = [];
    const inviteeToLeaseTransactionMap = {};
    const invalidInvitationsForCommunity = [];

    if (invitations) {
        invitations.forEach((invitee) => {
            leaseTransactionsInvitedTo.push(invitee.lease_transaction);
            inviteeToLeaseTransactionMap[invitee.lease_transaction.id] = {
                id: invitee.id,
                role: invitee.role,
            };
            if (invitee.lease_transaction.community.id === configuration.community?.id) {
                invalidInvitationsForCommunity.push(invitee.leaseTransaction);
            }
        });
    }
    return [leaseTransactionsInvitedTo, inviteeToLeaseTransactionMap, invalidInvitationsForCommunity];
};

const getAlreadyHasActiveAppForCommunityAsPrimaryError = (
    configuration,
    activeApplicationsAsPrimaryForCommunity,
    activeLeasesAsPrimaryForCommunity,
    accessingInvalidInvitation,
    startingNewApplicationWithConfiguration
) => {
    if (!configuration?.community || (!activeApplicationsAsPrimaryForCommunity && !activeLeasesAsPrimaryForCommunity)) {
        return;
    }

    if (!accessingInvalidInvitation && (!startingNewApplicationWithConfiguration || !configuration?.unit)) {
        return;
    }

    if (activeLeasesAsPrimaryForCommunity?.length > 0) {
        return activeLeasesAsPrimaryForCommunity[0].unit?.unit_number ? (
            <span>
                Oops, it looks like you already have an active lease for {configuration?.community?.display_name} for
                unit {activeLeasesAsPrimaryForCommunity[0].unit.unit_number}. Please call our office at{' '}
                {configuration?.community?.contact_phone} if you would like to start another application at{' '}
                {configuration?.community?.display_name}.
            </span>
        ) : (
            <span>
                Oops, it looks like you already have an active lease for {configuration?.community?.display_name}.
                Please call our office at {configuration?.community?.contact_phone} if you would like to start another
                application at {configuration?.community?.display_name}.
            </span>
        );
    }

    if (activeApplicationsAsPrimaryForCommunity?.length > 0) {
        return activeApplicationsAsPrimaryForCommunity[0].unit?.unit_number ? (
            <span>
                Oops, it looks like you already have an active application for {configuration?.community?.display_name}.
                Please continue your application for unit {activeApplicationsAsPrimaryForCommunity[0].unit.unit_number},
                or call our office at {configuration?.community?.contact_phone} if you would like to start another
                application at {configuration?.community?.display_name}.
            </span>
        ) : (
            <span>
                Oops, it looks like you already have an active application for {configuration?.community?.display_name}.
                Please continue your application, or call our office at {configuration?.community?.contact_phone} if you
                would like to start another application at {configuration?.community?.display_name}.
            </span>
        );
    }

    return null;
};

const getNewLeaseTransactionData = (configuration, leaseSettingsId) => {
    return {
        community: configuration?.community,
        unit: configuration?.unit,
        lease_term: configuration?.lease_term,
        lease_start_date: configuration?.move_in_date,
        quote_id: configuration?.quote_id || null,
        quote_is_expired: configuration?.quote_is_expired || false,
        lease_settings: leaseSettingsId,
        role: APPLICANT_ROLE_VALUES.ROLE_PRIMARY_APPLICANT,
        status: APPLICATION_STATUSES.APPLICATION_STATUS_NEW,
        type: LEASE_TRANSACTION_TYPE_APPLICATION,
        created_at: new Date(),
    };
};

/**
 * Filters lease transactions that applicants have received comms for and annotates them with their activity status
 *
 * Rules that determine wether or not a transaction is active (by type):
 *  - type: APPLICATION
 *  - active: status in progress, submittes or (conditionally) approved
 *  - inactive: any other status
 *
 *  - type: MID_LEASE_CHANGE, RENEWAL & TRANSFER
 *  - active: status NOT in completed, cancelled or denied AND changes are confirmed or guarantor is requested
 *  - inactive: status in completed, cancelled or denied AND changes are confirmed
 *
 *  - types: VACATE
 *  - active: status NOT in completed, cancelled or denied
 *  - inactive: status in completed, cancelled or denied
 *
 * @param leaseTransactions: Array of lease transactions
 * @return {array}: Array of lease transactions annotated with active property
 */
export const getActivePastLeaseTransaction = (applicant, leaseTransactions) => {
    const annotatedLeaseTransactions = [];

    if (!leaseTransactions) {
        return annotatedLeaseTransactions;
    }

    leaseTransactions.forEach((leaseTransaction) => {
        const { type, status, has_confirmed_changes, is_guarantor_requested } = leaseTransaction;

        if (type === LEASE_TRANSACTION_TYPE_APPLICATION) {
            if (ACTIVE_APPLICATION_STATUSES.includes(status)) {
                annotatedLeaseTransactions.push({ ...leaseTransaction, active: true });
            } else {
                annotatedLeaseTransactions.push({ ...leaseTransaction, active: false });
            }
        } else if (
            [
                LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE,
                LEASE_TRANSACTION_TYPE_TRANSFER,
                LEASE_TRANSACTION_TYPE_RENEWAL,
                LEASE_TRANSACTION_TYPE_LEASE_DOCUMENT_REGENERATION,
            ].includes(type) &&
            (has_confirmed_changes || is_guarantor_requested)
        ) {
            if (PAST_LEASE_TRANSACTION_STATUSES.includes(status)) {
                annotatedLeaseTransactions.push({ ...leaseTransaction, active: false });
            } else {
                annotatedLeaseTransactions.push({ ...leaseTransaction, active: true });
            }
        } else if (type === LEASE_TRANSACTION_TYPE_VACATE) {
            if (PAST_LEASE_TRANSACTION_STATUSES.includes(status)) {
                annotatedLeaseTransactions.push({ ...leaseTransaction, active: false });
            } else {
                annotatedLeaseTransactions.push({ ...leaseTransaction, active: true });
            }
        }
    });

    return annotatedLeaseTransactions;
};

const createUnitToLeaseTransactionsMap = (leaseTransactions) => {
    const unitsToLeaseTransactionMap = new Map();
    const sortedLeaseTransactions = leaseTransactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    if (sortedLeaseTransactions?.length) {
        const trxIndex = sortedLeaseTransactions.findIndex((trx) => trx?.active || trx?.is_month_to_month_update);
        if (
            trxIndex !== -1 &&
            sortedLeaseTransactions[trxIndex]?.is_month_to_month_update &&
            !sortedLeaseTransactions[trxIndex]?.end_date
        ) {
            sortedLeaseTransactions[trxIndex] = { ...sortedLeaseTransactions[trxIndex], active: true };
        }
    }

    sortedLeaseTransactions.forEach((leaseTransaction) => {
        const { unit, community } = leaseTransaction;
        let unitKey = unit?.unit_number ? `${community.display_name}, # ${unit.unit_number}` : community.display_name;
        if (unit?.id) {
            unitKey += `::${unit.id}`;
        }

        if (unitsToLeaseTransactionMap.has(unitKey)) {
            unitsToLeaseTransactionMap.get(unitKey).push(leaseTransaction);
        } else {
            unitsToLeaseTransactionMap.set(unitKey, [leaseTransaction]);
        }
    });

    return unitsToLeaseTransactionMap;
};

export function LeaseTransactionsPage({
    applicant,
    configuration,
    leaseSettingsId,
    startingNewApplicationWithConfiguration,
    match: {
        params: { unit_id: anchorToUnitId },
    },
    toggleLoader,
}) {
    const classes = useStyles();
    const [error, setError] = useState(undefined);
    const leaseTransactions = hooks.useLeaseTransactions(ERROR_MESSAGE);
    const invitees = hooks.useInvitations(ERROR_MESSAGE);
    const mtmData = leaseTransactions?.mtmData;
    const finishedLoading = leaseTransactions.finished && invitees.finished;

    useEffect(() => {
        toggleLoader(!finishedLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finishedLoading]);

    const unitCardRef = useScrollToElement();

    const activeApplicationsAsPrimary = useMemo(() => {
        return getActiveApplicationAsPrimary(applicant, leaseTransactions.data);
    }, [applicant, leaseTransactions.data]);

    const activeLeasesAsPrimary = useMemo(() => {
        return getActiveLeasesAsPrimary(applicant, leaseTransactions.data);
    }, [applicant, leaseTransactions.data]);

    const [leaseTransactionsInvitedTo, leaseTransactionToInviteeMap, invalidInvitationsForCommunity] = useMemo(() => {
        return getInvitationsData(invitees.data, configuration);
    }, [invitees.data, configuration]);

    const activeApplicationsAsPrimaryForCommunity = activeApplicationsAsPrimary[configuration.community?.id];
    const activeLeasesAsPrimaryForCommunity = activeLeasesAsPrimary[configuration.community?.id];
    const accessingInvalidInvitation = configuration.invitee && invalidInvitationsForCommunity?.length > 0;
    const alreadyHasActiveAppForCommunityAsPrimaryError = useMemo(() => {
        return getAlreadyHasActiveAppForCommunityAsPrimaryError(
            configuration,
            activeApplicationsAsPrimaryForCommunity,
            activeLeasesAsPrimaryForCommunity,
            accessingInvalidInvitation,
            startingNewApplicationWithConfiguration
        );
    }, [
        accessingInvalidInvitation,
        activeApplicationsAsPrimaryForCommunity,
        activeLeasesAsPrimaryForCommunity,
        configuration,
        startingNewApplicationWithConfiguration,
    ]);

    const mergedLeaseTransactions = useMemo(() => [...leaseTransactions.data], [leaseTransactions]);
    mergedLeaseTransactions.push(...leaseTransactionsInvitedTo);

    if (
        !activeApplicationsAsPrimaryForCommunity &&
        !activeLeasesAsPrimaryForCommunity &&
        startingNewApplicationWithConfiguration
    ) {
        mergedLeaseTransactions.push({
            ...getNewLeaseTransactionData(configuration, leaseSettingsId),
            isNew: true,
        });
    }

    const notificationError =
        error || leaseTransactions.error || invitees.error || alreadyHasActiveAppForCommunityAsPrimaryError;
    const notifications = [];
    if (notificationError) {
        notifications.push({
            type: 'error',
            messages: notificationError,
        });
    }

    const annotatedLeaseTransactions = useMemo(() => {
        const monthToMonthData = (mtmData || [])?.map((obj) => ({
            ...obj,
            is_month_to_month_update: true,
            status: APPLICATION_STATUS_COMPLETED,
            unit: obj?.lease?.unit,
            community: obj?.lease?.lease_settings?.community,
            lease_settings: obj?.lease?.lease_settings?.id,
            active: false,
        }));
        const leaseTransactions = getActivePastLeaseTransaction(applicant, mergedLeaseTransactions);

        return [...monthToMonthData, ...leaseTransactions];
    }, [applicant, mergedLeaseTransactions, mtmData]);

    const unitsToLeaseTransactionMap = useMemo(() => {
        return createUnitToLeaseTransactionsMap(annotatedLeaseTransactions);
    }, [annotatedLeaseTransactions]);

    const [showEmptyState] = useMemo(() => {
        return [!leaseTransactions.error && leaseTransactions.finished && unitsToLeaseTransactionMap.size === 0];
    }, [leaseTransactions.error, leaseTransactions.finished, unitsToLeaseTransactionMap]);

    return (
        <Page className={classes.root} title="My Activity" notifications={notifications} loading={!finishedLoading}>
            {Array.from(unitsToLeaseTransactionMap.keys()).map((key) => {
                const unitKey = key.split('::')[0];
                let unitId;
                // check if unitId is embedded in key
                if (key.split('::').length === 2) {
                    unitId = key.split('::')[1];
                }
                return (
                    <UnitCard
                        key={unitKey}
                        title={unitKey}
                        unitCardRef={unitId === anchorToUnitId ? unitCardRef : null}
                        className={classes.unitCard}
                        leaseTransactions={unitsToLeaseTransactionMap.get(key)}
                        leaseTransactionToInviteeMap={leaseTransactionToInviteeMap}
                        setError={setError}
                    />
                );
            })}
            {showEmptyState && (
                <Typography variant="h4" align="center">{`You don’t have any activity yet.`}</Typography>
            )}
        </Page>
    );
}

LeaseTransactionsPage.propTypes = {
    applicant: PropTypes.object.isRequired,
    configuration: PropTypes.object.isRequired,
    leaseSettingsId: PropTypes.string.isRequired,
    startingNewApplicationWithConfiguration: PropTypes.bool,
    unitId: PropTypes.number,
    match: PropTypes.object,
    toggleLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    applicant: state.userProfile,
    configuration: state.configuration,
    leaseSettingsId: state.siteConfig.basename,
    startingNewApplicationWithConfiguration: !state.configuration?.invitee && Boolean(state.siteConfig.hash),
});

const mapDispatchToProps = {
    toggleLoader: loaderActions.toggleLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaseTransactionsPage);
