import { useLayoutEffect, useState } from 'react';

const themeMatches = (darkModeQuery) => darkModeQuery.matches;

export function useDarkMode() {
    const [isDark, setDarkmode] = useState(false);
    const darkModeQuery = window?.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)') : false;

    useLayoutEffect(() => {
        if (!darkModeQuery) return;

        const colorSchemeEventListener = () => {
            setDarkmode(themeMatches(darkModeQuery));
        };

        setDarkmode(themeMatches(darkModeQuery));

        if (darkModeQuery.addEventListener) {
            darkModeQuery.addEventListener('change', colorSchemeEventListener);
        }

        // Cleanup
        return () => darkModeQuery.removeEventListener('change', colorSchemeEventListener);
    }, [darkModeQuery]);

    return isDark;
}
