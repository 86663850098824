import {
    FINANCIAL_STREAM_STATUS_INCOMPLETE,
    FINANCIAL_STREAM_PLAID_RISK_SCORE_HIGH,
    FINANCIAL_STREAM_PLAID_RISK_SCORE_MEDIUM,
} from 'constants/constants';
import { prettyCurrency } from 'utils/misc';

const UNABLE_TO_BE_VERIFIED_ERRORS = [
    'Inaccurate/incomplete information',
    'Poor document quality',
    'Unsupported document type',
];
const HIGH_FRAUD_RISK_ERRORS = UNABLE_TO_BE_VERIFIED_ERRORS;
const MEDIUM_FRAUD_RISK_ERROR = [UNABLE_TO_BE_VERIFIED_ERRORS[0]];

export const getIncompleteFinancialSourceWarning = (source, isAsset) => {
    const sourceTypeLabel = isAsset ? 'asset' : 'income';
    const {
        adjusted_amount = null,
        status = null,
        contains_password_protected_document = false,
        is_plaid = false,
        plaid_is_unable_to_be_verified = false,
        plaid_has_fraud_risk = false,
        plaid_risk_score_label = null,
        funnel_risk_signals = [],
    } = source;

    if (contains_password_protected_document) {
        return (
            <>
                This {sourceTypeLabel} was unable to be verified due to:
                <ul>
                    <li>Password protected document</li>
                </ul>
            </>
        );
    }

    if (is_plaid && status === FINANCIAL_STREAM_STATUS_INCOMPLETE) {
        if (plaid_is_unable_to_be_verified) {
            return (
                <>
                    This {sourceTypeLabel} was unable to be verified. <br />
                    Common reasons include:
                    <ul>
                        {UNABLE_TO_BE_VERIFIED_ERRORS.map((e, i) => (
                            <li key={i}>{e}</li>
                        ))}
                    </ul>
                </>
            );
        }

        if (plaid_has_fraud_risk && plaid_risk_score_label === FINANCIAL_STREAM_PLAID_RISK_SCORE_HIGH) {
            return (
                <>
                    This {sourceTypeLabel} was unable to be verified. <br />
                    Common reasons include:
                    <ul>
                        {HIGH_FRAUD_RISK_ERRORS.map((e, i) => (
                            <li key={i}>{e}</li>
                        ))}
                    </ul>
                </>
            );
        }

        if (
            (plaid_has_fraud_risk && plaid_risk_score_label === FINANCIAL_STREAM_PLAID_RISK_SCORE_MEDIUM) ||
            funnel_risk_signals
        ) {
            const errors = [];
            funnel_risk_signals && errors.push(...funnel_risk_signals);
            plaid_has_fraud_risk && errors.push(...MEDIUM_FRAUD_RISK_ERROR);

            return (
                <>
                    This income was unable to be verified due to:
                    <ul>
                        {errors.map((e, i) => (
                            <li key={i}>{e}</li>
                        ))}
                    </ul>
                </>
            );
        }
    }

    if (status !== FINANCIAL_STREAM_STATUS_INCOMPLETE) return null;

    if (adjusted_amount) {
        if (isAsset) {
            return `The documents for this asset source show a value of ${prettyCurrency(adjusted_amount)}.`;
        } else {
            return `The documents for this income source show earnings of ${prettyCurrency(adjusted_amount)}/year.`;
        }
    }

    return `This ${sourceTypeLabel} source has been marked as having incorrect or insufficient documents.`;
};
