import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { MILESTONE_APPLICANT_SUBMITTED, ROUTES } from 'constants/constants';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import withTransactionPath from 'utils/withTransactionPath';
import { fetchApplicant, updateApplicant } from 'reducers/applicant';
import { selectors as renterProfileSelectors } from 'reducers/renter-profile';
import hooks from 'pages/Banking/bankingHooks';

import BackLink from 'common-components/BackLink/BackLink';
import StatedIncomeAndAssetsForm from 'pages/Banking/components/StatedIncomeAndAssetsForm';
import { H1, SpacedH3 } from 'assets/styles';
import piggyBank from 'assets/new-icons/saving-piggy-coins.svg';

const useStyles = makeStyles(() => ({
    imageContainer: {
        marginBottom: 35,
        marginTop: 35,
        '& img': {
            height: 105,
            width: 105,
        },
    },
}));

export function StatedIncomeAndAssetsPage({
    applicant,
    getTransactionPath,
    prevPage,
    fetchApplicant,
    updateApplicant,
}) {
    const classes = useStyles();
    const context = hooks.useBankingContext();
    const [errors, setErrors] = useState(null);

    const handleSubmit = async (values, { setErrors: setFormErrors, setSubmitting }) => {
        setErrors(null);
        context.toggleLoader(true);

        const data = {
            stated_income: String(values.stated_income).replace(/,/g, ''),
            stated_assets: String(values.stated_assets).replace(/,/g, ''),
        };

        try {
            const response = await updateApplicant(data);
            if (response.errors) {
                if (response.errors.error) {
                    return setErrors([GENERIC_ERROR_MESSAGE]);
                }
                return setFormErrors(response.errors);
            }

            const updatedApplicant = await fetchApplicant();

            const applicantSubmittedApplication = !!updatedApplicant.events?.find(
                ({ event, lease_transaction }) =>
                    event === MILESTONE_APPLICANT_SUBMITTED && lease_transaction === applicant.lease_transaction_id
            );

            let nextRoute = ROUTES.SCREENING;
            if (!updatedApplicant.is_income_and_employment_completed) {
                nextRoute = ROUTES.EMPLOYER_DETAILS;
            } else if (applicantSubmittedApplication) {
                nextRoute = ROUTES.APP_COMPLETE;
            }

            context.history.push(getTransactionPath(nextRoute));
        } catch (e) {
            setErrors([GENERIC_ERROR_MESSAGE]);
        } finally {
            context.toggleLoader(false);
            setSubmitting(false);
        }
    };

    if (!applicant) {
        return null;
    }

    return (
        <>
            <H1>Add Your Income and Assets</H1>
            <SpacedH3>
                Providing your income and/or assets will help us ensure that you qualify for this apartment. We may also
                request for additional proof if necessary.
            </SpacedH3>
            <div className={classes.imageContainer}>
                <img src={piggyBank} alt="piggy bank" width={100} height={100} />
            </div>
            <StatedIncomeAndAssetsForm applicant={applicant} errors={errors} onSubmit={handleSubmit} />
            <BackLink to={prevPage} />
        </>
    );
}

StatedIncomeAndAssetsPage.propTypes = {
    applicant: PropTypes.object,
    configuration: PropTypes.object,
    leaseTransaction: PropTypes.object,
    prevPage: PropTypes.string,
    fetchApplicant: PropTypes.func.isRequired,
    updateApplicant: PropTypes.func.isRequired,
    getTransactionPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    configuration: state.configuration,
    leaseTransaction: state.transaction,
    prevPage: renterProfileSelectors.selectPrevRoute(state),
});

const mapDispatchToProps = {
    updateApplicant,
    fetchApplicant,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(StatedIncomeAndAssetsPage));
