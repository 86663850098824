import { css } from 'emotion';

export const viewPaymentTerms = css`
    color: #2b44ff;
    cursor: pointer;
    text-decoration: underline;
    padding-left: 5px;
`;

export const termsDiv = css`
    margin-bottom: 20px;
    margin-top: 44px;
    font-size: 14px;
`;
