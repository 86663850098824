import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, FormLabel, makeStyles } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import * as Yup from 'yup';

import { ROUTES } from 'constants/constants';
import withTransactionPath from 'utils/withTransactionPath';
import { useIdentityVerification } from '../IdentityVerificationContext';

import Page from 'common-components/Page/Page';
import ActionButton from 'common-components/ActionButton/ActionButton';
import BackLink from 'common-components/BackLink/BackLink';
import pageImageSrc from 'assets/new-icons/single-neutral-id-card.svg';

export const ERROR_MESSAGE = 'Oops, we had trouble submitting your answers. Try again.';

const useStyles = makeStyles(() => ({
    pageImage: {
        width: '147px',
        height: '118px',
    },
    questionList: {
        fontSize: '16px',
        paddingLeft: '24px',
    },
    questionListItem: {
        paddingLeft: '7px',
        marginBottom: '39px',
        '&:last-child': {
            marginBottom: '0px',
        },
    },
    questionLabel: {
        textAlign: 'left',
        color: '#000000',
        fontSize: '16px',
        lineHeight: '19px',
        marginBottom: '17px',
    },
}));

export const validationSchema = (questions) => {
    const shape = questions?.reduce((accumulator, question) => {
        accumulator[question['key']] = Yup.string().required();
        return accumulator;
    }, {});
    return Yup.object().shape(shape);
};

export const formatAnswers = (formData) => {
    const answers = [];
    for (const key in formData) {
        if (!!formData[key]) {
            answers.push({
                question_key: key,
                answer_key: formData[key],
            });
        }
    }

    return answers;
};

export function QuestionsPage({ getTransactionPath, leaseTransaction }) {
    const classes = useStyles();
    const { identityVerification, error, evaluateQuestion } = useIdentityVerification();
    const { questions } = identityVerification;

    const backLinkTo = getTransactionPath(ROUTES.FEES_AND_DEPOSITS, {
        lease_transaction_id: leaseTransaction.id,
    });
    const pageNotification = error && {
        type: 'error',
        messages: error,
    };

    const initialValues = useMemo(() => {
        return questions?.reduce((accumulator, question) => {
            accumulator[question['key']] = '';
            return accumulator;
        }, {});
    }, [questions]);

    const handleSubmit = useCallback(async (values, { setSubmitting }) => {
        const answers = formatAnswers(values);
        await evaluateQuestion({ answers, error: ERROR_MESSAGE });
        setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!questions) {
        return null;
    }

    return (
        <Page
            title="Let’s Verify Your Identity!"
            subTitle="We need some help verifying your identity. Answer the following questions."
            image={{
                src: pageImageSrc,
                className: classes.pageImage,
            }}
            notification={pageNotification}
        >
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema(questions)}
            >
                {({ dirty, isSubmitting, isValid }) => {
                    const disableSubmit = !isValid || !dirty || isSubmitting;

                    return (
                        <Form noValidate autoComplete="off">
                            <ol className={classes.questionList}>
                                {questions.map((question) => (
                                    <li className={classes.questionListItem} key={question['key']}>
                                        <FormLabel component="div" className={classes.questionLabel}>
                                            {question['question']}
                                        </FormLabel>
                                        <Field margin="normal" name={question['key']} component={RadioGroup}>
                                            {question['choices'] &&
                                                question['choices'].map((choice) => (
                                                    <FormControlLabel
                                                        value={choice['key']}
                                                        key={choice['key']}
                                                        control={<Radio disabled={isSubmitting} />}
                                                        label={choice['display_name']}
                                                        disabled={isSubmitting}
                                                    />
                                                ))}
                                        </Field>
                                    </li>
                                ))}
                            </ol>
                            <ActionButton marginTop={48} disabled={disableSubmit} marginBottom={20}>
                                Submit
                            </ActionButton>
                            <BackLink to={backLinkTo} />
                        </Form>
                    );
                }}
            </Formik>
        </Page>
    );
}

QuestionsPage.propTypes = {
    getTransactionPath: PropTypes.func.isRequired,
    leaseTransaction: PropTypes.object.isRequired,
};

export default withTransactionPath(QuestionsPage);
