import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';

import { MOCKY } from 'config';
import API from 'api/api';
import mock from './fixtures/mock-user-profile';

const userProfile = createSlice({
    name: 'userProfile',
    initialState: null,
    reducers: {
        userProfileReceived(state, action) {
            state = action.payload;
            return state;
        },
        userProfileUpdated(state, action) {
            return produce(state, (draft) => {
                Object.assign(draft, action.payload);
            });
        },
    },
    extraReducers: {
        USER_LOGOUT: () => {
            return null;
        },
    },
});

const { actions, reducer } = userProfile;
export const { userProfileUpdated, userProfileReceived } = actions;
export default reducer;

export const fetchUserProfile = () => {
    return async (dispatch) => {
        let applicant;
        if (MOCKY) {
            applicant = mock;
        } else {
            applicant = await API.fetchUserProfile();
        }

        dispatch(userProfileReceived(applicant));
        return applicant;
    };
};

export const updateUserProfile = (newData) => {
    return (dispatch) => {
        if (MOCKY) {
            dispatch(userProfileUpdated(newData));
            return Promise.resolve({});
        }
        return API.putUserProfile(newData).then((res) => {
            if (res.errors) {
                return res;
            }

            return dispatch(userProfileUpdated(res));
        });
    };
};

// selectors
export const selectors = {};
