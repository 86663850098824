import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import Button from '@material-ui/core/Button';
import Cancel from '@material-ui/icons/Cancel';

import {
    RENTAL_OPTIONS_PETS_DOGS,
    RENTAL_OPTIONS_PETS_CATS,
    RENTAL_OPTIONS_PETS_OTHER,
    PET_RENTAL_OPTION_TYPE_TO_LABEL_MAP,
} from 'constants/constants';
import { PET_PLACEHOLDER } from 'pages/RenterProfile/pages/PetsPage';
import { petTypeContainer, petTypeLabel, petButtonRoot, petTypeLabelHeader } from './PetTypeSelectStyles';

const cancelButton = css`
    color: #828796;
    cursor: pointer;
`;

function PetTypeSelect({ petOption = {}, rentalOptions = [], onDelete = (f) => f, onChange = (f) => f }) {
    const petTypeOptions = rentalOptions.map(({ leasing_category }) => leasing_category);
    const showCancelled = petOption?.key !== PET_PLACEHOLDER.key || Object.keys(petOption).length !== 2;

    const sortedPetTypeOptions = [RENTAL_OPTIONS_PETS_DOGS, RENTAL_OPTIONS_PETS_CATS, RENTAL_OPTIONS_PETS_OTHER].filter(
        (petType) => petTypeOptions.includes(petType)
    );

    return (
        <Fragment>
            <div className={petTypeLabelHeader}>
                <span className={petTypeLabel}>Type</span>
                {showCancelled && (
                    <Cancel role="button" style={{ fontSize: 17 }} className={cancelButton} onClick={onDelete} />
                )}
            </div>
            <div className={petTypeContainer}>
                {sortedPetTypeOptions.map((type) => {
                    const label = PET_RENTAL_OPTION_TYPE_TO_LABEL_MAP[type];
                    if (petOption.pet_type === type) {
                        return (
                            <Button key={type} variant="contained" color="primary" classes={{ root: petButtonRoot }}>
                                {label}
                            </Button>
                        );
                    }

                    return (
                        <Button
                            key={type}
                            classes={{ root: petButtonRoot }}
                            variant="outlined"
                            onClick={() => onChange(type)}
                        >
                            {label}
                        </Button>
                    );
                })}
            </div>
        </Fragment>
    );
}

PetTypeSelect.propTypes = {
    petOption: PropTypes.object,
    rentalOptions: PropTypes.array,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
};

export default PetTypeSelect;
