import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ROUTES } from 'constants/constants';
import API from 'api/api';
import captureRoute from 'utils/captureRoute';
import ActionButton from 'common-components/ActionButton/ActionButton';
import house from 'assets/new-icons/real-estate-house-love.svg';
import handshakeImage from 'assets/new-icons/business-contract-handshake.svg';
import Page from 'common-components/Page/Page';
import {
    LEASE_TRANSACTION_TYPE_APPLICATION,
    LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE,
    LEASE_TRANSACTION_TYPE_RENEWAL,
    LEASE_TRANSACTION_TYPE_TRANSFER,
    LEASE_TRANSACTION_TYPE_VACATE,
} from 'constants/leaseTransaction';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    image: {
        margin: '10px 0 5px 0',
        height: '200px',
        width: '200px',
    },
    buttonsContainer: {
        paddingTop: '35px',
        minHeight: '100px',
    },
}));

export function LeaseExecutedPage(props) {
    const classes = useStyles();
    const [url, setUrl] = useState('');
    const [hasDocument, setHasDocument] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!props.leaseTransaction) return;
        if (!props.leaseTransaction.documents_sent) {
            setHasDocument(false);
            setUrl('');
            return;
        }
        (async () => {
            setIsLoading(true);

            const response = await API.leaseDocumentUrl(
                props.leaseTransaction.id,
                props.leaseTransaction.signable_doc_type
            );

            if (response.error_type === undefined) {
                setUrl(response.url);
                setHasDocument(true);
            } else {
                setHasDocument(false);
                setUrl('');
            }

            setIsLoading(false);
        })();
    }, [props.leaseTransaction]);

    const { title, subtitle, buttonText, pageImage } = useMemo(() => {
        return getCopy(props.leaseTransaction, classes, hasDocument);
    }, [props.leaseTransaction, classes, hasDocument]);

    const unit = props.unit;
    const community = props.community;
    const resAppPortalUrl = props.applicant?.res_app_portal_url || null;

    if (!unit || !community) return null;

    return (
        <Page title={title} subTitle={subtitle} image={pageImage}>
            <div>
                <Typography variant="subtitle2" component="p">
                    {community.display_name} Unit {unit.unit_number}
                </Typography>
            </div>
            {!isLoading && hasDocument && (
                <div className={classes.buttonsContainer}>
                    <ActionButton href={url} marginTop={17} variant="outlined">
                        {buttonText}
                    </ActionButton>
                </div>
            )}
            {!isLoading && resAppPortalUrl && (
                <div className={classes.buttonsContainer}>
                    <ActionButton target="_blank" href={resAppPortalUrl}>
                        Go to Resident Portal
                    </ActionButton>
                </div>
            )}
        </Page>
    );
}

const getCopy = (leaseTransaction, styleClasses, hasDocument) => {
    let subtitle = '';
    let title = '';
    let buttonText = 'View Lease';
    let pageImage = { src: house, className: styleClasses.image };

    const defaultCopy = {
        title,
        subtitle,
        buttonText,
        pageImage,
    };

    if (!leaseTransaction) {
        return defaultCopy;
    }

    if (leaseTransaction.type === LEASE_TRANSACTION_TYPE_RENEWAL) {
        title = `Your Lease Renewal is Complete!`;
        subtitle = `You’re all set and we’re so excited you chose to stay!`;
    }

    if (leaseTransaction.type === LEASE_TRANSACTION_TYPE_APPLICATION) {
        title = `Welcome To Your New Home!`;
        subtitle = `Your application has been completed and we can't wait for you to move in.`;
    }

    if (leaseTransaction.type === LEASE_TRANSACTION_TYPE_VACATE) {
        title = `Your Notice to Vacate is Complete`;
        subtitle = `You’re all set. We hope to see you again soon.`;
        if (!hasDocument) {
            subtitle = `We have sent you an email with information regarding your notice to vacate`;
        }
        buttonText = `View Your Notice to Vacate`;
        pageImage = { src: handshakeImage, className: styleClasses.image };
    }

    if ([LEASE_TRANSACTION_TYPE_TRANSFER, LEASE_TRANSACTION_TYPE_MIDLEASE_CHANGE].includes(leaseTransaction.type)) {
        title = `Welcome To Your New Home!`;
        subtitle = `You’re all set and we can’t wait for you to move in!`;
    }

    return {
        title,
        subtitle,
        buttonText,
        pageImage,
    };
};

LeaseExecutedPage.propTypes = {
    unit: PropTypes.object,
    community: PropTypes.object,
    leaseTransaction: PropTypes.object,
    applicant: PropTypes.object,
};

const mapStateToProps = (state) => ({
    unit: state.transaction && state.transaction.unit,
    leaseTransaction: state.transaction,
    applicant: state.applicant,
    community: state.configuration && state.configuration.community,
});

export default connect(mapStateToProps)(captureRoute(LeaseExecutedPage, ROUTES.LEASE_EXECUTED));
