import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { leaseTransactionType } from 'types';
import { getDateLabel } from 'pages/LeaseTransactions/utils/helpers';

function ApplicationAccordionContent({ classes, leaseTransaction }) {
    const { lease_term, lease_start_date, total_monthly_fees } = leaseTransaction;

    return (
        <>
            <Typography className={classes.typography} variant="body1">
                Move In Date: <span>{getDateLabel(lease_start_date)}</span>
            </Typography>
            <Typography className={classes.typography} variant="body1">
                Lease Terms: <span>{lease_term ? `${lease_term} months` : '---'}</span>
            </Typography>
            <Typography className={classes.typography} variant="body1">
                Monthly Rent: <span>{total_monthly_fees ? `$${total_monthly_fees}` : '---'}</span>
            </Typography>
        </>
    );
}

ApplicationAccordionContent.propTypes = {
    classes: PropTypes.object.isRequired,
    leaseTransaction: leaseTransactionType,
};

export default ApplicationAccordionContent;
