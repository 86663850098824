import React from 'react';
import { makeStyles, Divider, Typography } from '@material-ui/core';

import ActionButton from 'common-components/ActionButton/ActionButton';
import PropTypes from 'prop-types';

import Page from 'common-components/Page/Page';

const useStyles = makeStyles(() => ({
    root: {
        margin: '10px 0px 0px 0px',
    },
    textContainer: {
        margin: '20px 0 0 0',
        textAlign: 'left',
    },
}));

export const SelfScreeningInstructionsView = ({
    onSubmit = () => {},
    onCancel = () => {},
    contactPhone = '',
    disclaimer = '',
}) => {
    const classes = useStyles();
    return (
        <Page title="Provide Your Own Screening Report" className={classes.root}>
            <Divider />
            <div className={classes.textContainer}>
                <Typography data-testid="screening-instructions-contact" variant="body1">
                    Please call us at <a href={`tel:${contactPhone}`}>{contactPhone}</a> for instructions on how to
                    provide your screening report.
                </Typography>
                <br />
                <br />
                <Typography data-testid="screening-instructions-disclaimer" variant="body2">
                    {disclaimer}
                </Typography>
                <ActionButton
                    data-testid="screening-instructions-continue-button"
                    onClick={onSubmit}
                    marginTop={25}
                    marginBottom={20}
                >
                    Got It, Continue
                </ActionButton>
                <ActionButton
                    data-testid="screening-instructions-cancel-button"
                    onClick={onCancel}
                    variant="outlined"
                    marginBottom={20}
                >
                    Cancel
                </ActionButton>
            </div>
        </Page>
    );
};

SelfScreeningInstructionsView.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    contactPhone: PropTypes.string,
    disclaimer: PropTypes.string,
};

export default SelfScreeningInstructionsView;
