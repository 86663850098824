import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'emotion';
import clsx from 'clsx';

import API from 'api/api';
import { ROUTES } from 'constants/constants';
import captureRoute from 'utils/captureRoute';

import Page from 'common-components/Page/Page';
import { ACTION_BUTTON, DocumentNoticeButton } from 'common-components/DocumentNoticeButton/DocumentNoticeButton';
import statusFolder from 'assets/new-icons/task-checklist.svg';

export const PageImg = css`
    width: 149.6px !important;
    height: 142.87px !important;
`;

export const applicationUnit = css`
    color: #454b57;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding-top: 10px;
`;

export const ApplicationDeniedPage = (props) => {
    const [notifications, setNotifications] = useState([]);
    const { leaseTransaction, configuration, applicant } = props;
    if (!leaseTransaction || !configuration || !applicant) return null;

    const { unit } = leaseTransaction;

    const buildingName = configuration.community.building_name || configuration.community.normalized_street_address;
    const unitNumber = !!unit && !!unit.unit_number ? ` Unit ${unit.unit_number}` : '';

    const setPageError = (error) => {
        if (!error) {
            return setNotifications([]);
        }
        setNotifications([
            {
                type: 'error',
                messages: [error],
            },
        ]);
    };

    return (
        <Page
            className={clsx({ 'hide-element': false })}
            title="Application Denied"
            subTitle="Unfortunately, we were unable to approve your application."
            image={{
                src: statusFolder,
                className: PageImg,
            }}
            notifications={notifications}
        >
            <div id="application-unit" className={applicationUnit}>
                {buildingName}
                {unitNumber}
            </div>
            <DocumentNoticeButton
                componentType={ACTION_BUTTON}
                marginTop={50}
                fetchDocument={() => {
                    return API.fetchAANDocument(leaseTransaction.id);
                }}
                setPageError={setPageError}
            >
                Learn Why
            </DocumentNoticeButton>
        </Page>
    );
};

ApplicationDeniedPage.propTypes = {
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,
    applicant: PropTypes.object,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    configuration: state.configuration,
    applicant: state.applicant,
});

export default connect(mapStateToProps)(captureRoute(ApplicationDeniedPage, ROUTES.APP_DENIED));
