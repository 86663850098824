import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ThemeProvider } from '@material-ui/core/styles';

import { useDarkMode } from 'utils/darkMode';
import { createTheme } from 'assets/theme';
import image from 'assets/new-icons/cloud-removed-streamlined.svg';
import { SpacedH1, SpacedH6, Spacer } from 'assets/styles';

const centerAlign = css`
    text-align: center;
    padding: 20px 20% 25px 20%;
    max-width: 400px;
    margin: 110px auto;
`;

const Img = styled.img`
    width: 155px;
    height: auto;
`;

export default function CriticalErrorPage() {
    const dark_mode = useDarkMode();
    const normalizedTheme = createTheme({ dark_mode });

    return (
        <ThemeProvider theme={normalizedTheme}>
            <div className={centerAlign}>
                <SpacedH1>System Unavailable</SpacedH1>
                <SpacedH6>Oops! We&apos;re having some trouble connecting to our system. Check back soon.</SpacedH6>
                <Spacer height={25} />
                <Img alt="system error" src={image} />
            </div>
        </ThemeProvider>
    );
}

CriticalErrorPage.propTypes = {
    theme: PropTypes.object,
};
