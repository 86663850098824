import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles, Typography, List, ListItem, ListItemText, Divider } from '@material-ui/core';
import clsx from 'clsx';

import auth from 'utils/auth';
import * as hooks from './hooks';
import { actions as loaderActions } from 'reducers/loader';
import { getGenericErrorMessage } from 'constants/messages';
import { LEASE_TRANSACTION_TYPES } from 'constants/leaseTransaction';
import { REPORT_TYPE_CREDIT, REPORT_TYPE_CRIMINAL, REPORT_TYPE_EVICTION, REPORT_TYPES } from 'constants/report';
import Page from 'common-components/Page/Page';

export const LOADING_REPORTS_ERROR_MESSAGE = getGenericErrorMessage(
    "we're having trouble obtaining your screening reports"
);
export const DOWNLOAD_REPORT_ERROR_MESSAGE = getGenericErrorMessage(
    "we're having trouble downloading the selected document"
);

const useStyles = makeStyles((theme) => ({
    root: {},
    listItemRoot: {
        padding: '12px 0px',
    },
    listItemTextRoot: {
        margin: '0 0',
    },
    listItemTextPrimary: {
        textDecoration: 'underline',
        color: theme.palette.link,
        cursor: 'pointer',
        '& a': {
            textDecoration: 'underline',
            color: theme.palette.link,
        },
    },
    listItemTextPrimaryDisabled: {
        '& a': {
            color: '#D8D8D8',
            cursor: 'not-allowed',
        },
    },
}));

export const getDisableReportDownload = (wormholedUser, report) => {
    if (!wormholedUser) return false;
    if (wormholedUser.can_view_online_leasing_sensitive_data_when_wormholed) return false;

    if (report.type === REPORT_TYPE_CREDIT && !wormholedUser.can_view_credit_reports) {
        return true;
    }
    if (report.type === REPORT_TYPE_EVICTION && !wormholedUser.can_view_eviction_reports) {
        return true;
    }
    if (report.type === REPORT_TYPE_CRIMINAL && !wormholedUser.can_view_criminal_reports) {
        return true;
    }

    return false;
};

export const ScreeningReportsPage = ({ toggleLoader = () => {} }) => {
    const classes = useStyles();
    const {
        data: reports,
        loading: gettingReportsLoading,
        finished: gettingReportsFinished,
        error: gettingReportsError,
    } = hooks.useScreeningReports();
    const { loading: downloadReportLoading, error: downloadReportError, downloadReport } = hooks.useDownloadReport();

    const wormholedUser = auth.getWormholedUser();

    useEffect(() => {
        toggleLoader(gettingReportsLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gettingReportsLoading]);

    useEffect(() => {
        toggleLoader(downloadReportLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadReportLoading]);

    const notifications = [];
    if (gettingReportsError) {
        notifications.push({
            type: 'error',
            messages: LOADING_REPORTS_ERROR_MESSAGE,
        });
    }
    if (downloadReportError) {
        notifications.push({
            type: 'error',
            messages: DOWNLOAD_REPORT_ERROR_MESSAGE,
        });
    }

    return (
        <Page title="Screening Reports" className={classes.root} notifications={notifications}>
            {gettingReportsFinished && !gettingReportsError && reports.length === 0 ? (
                <Typography variant="h4" align="center">
                    You don’t have any screening reports available.
                </Typography>
            ) : (
                reports.length > 0 && (
                    <List>
                        <Divider />
                        {reports.map((report) => {
                            const disableDownload = getDisableReportDownload(wormholedUser, report);

                            return (
                                <ListItem
                                    key={report.id}
                                    classes={{
                                        root: classes.listItemRoot,
                                    }}
                                    divider
                                >
                                    <ListItemText
                                        classes={{
                                            primary: clsx(classes.listItemTextPrimary, {
                                                [classes.listItemTextPrimaryDisabled]: disableDownload,
                                            }),
                                            root: classes.listItemTextRoot,
                                        }}
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        primary={<a>{`${REPORT_TYPES[report.type]} Report`}</a>}
                                        primaryTypographyProps={{
                                            onClick: () => !disableDownload && downloadReport(report),
                                        }}
                                        secondary={`${
                                            LEASE_TRANSACTION_TYPES[report.lease_transaction_type]
                                        } - ${moment(report.created_at).format('MMM DD, YYYY')}`}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                )
            )}
        </Page>
    );
};

ScreeningReportsPage.propTypes = {
    toggleLoader: PropTypes.func,
};

const mapStateToProps = null;

const mapDispatchToProps = {
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningReportsPage);
