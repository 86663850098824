import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROUTES } from 'constants/constants';
import { prettyFormatPhoneNumber, sessionIsValidForCommunityId } from 'utils/misc';
import captureRoute from 'utils/captureRoute';

import { H1, SpacedH3, link } from 'assets/styles';
import docLookup from 'assets/new-icons/app-window-disabled.svg';
import UnauthenticatedPage from 'pages/Unauthenticated';

export const Img = styled.img`
    padding-top: 10px;
    margin-bottom: 76px;
`;

export const ApplicationUnavailablePage = ({ configuration, isLoggedIn }) => {
    if (!configuration) {
        return null;
    }

    const Wrapper = isLoggedIn ? React.Fragment : UnauthenticatedPage;

    return (
        <Wrapper>
            <H1>Application Not Available</H1>
            <SpacedH3>
                This application is no longer available. If you&apos;d still like to apply, contact us for a new link.
            </SpacedH3>
            <Img src={docLookup} />
            <SpacedH3>
                Please call us at &nbsp;
                <a style={{ fontWeight: 600 }} href={`tel:${configuration.community.contact_phone}`}>
                    {prettyFormatPhoneNumber(configuration.community.contact_phone)}
                </a>
                &nbsp;if you have any questions.
            </SpacedH3>
            <Link to={isLoggedIn ? ROUTES.LEASE_TRANSACTIONS : ROUTES.LOGIN} className={link}>
                {isLoggedIn ? 'Go to My Activity' : 'Sign in'}
            </Link>
        </Wrapper>
    );
};

ApplicationUnavailablePage.propTypes = {
    isLoggedIn: PropTypes.bool,
    configuration: PropTypes.object,
};

const mapStateToProps = (state) => ({
    isLoggedIn: sessionIsValidForCommunityId(state.siteConfig.basename),
    configuration: state.configuration,
});

export default connect(mapStateToProps)(captureRoute(ApplicationUnavailablePage, ROUTES.APPLICATION_UNAVAILABLE));
