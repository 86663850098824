import { css } from 'emotion';

export const nameContainer = css`
    text-align: left;
`;

export const inviteeContact = css`
    color: #828796;
`;

export const link = css`
    display: inline-block;
    color: #2b44ff;
    text-decoration: underline !important;
`;
