import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Info from '@material-ui/icons/Info';
import { Typography } from '@material-ui/core';

import { CustomButton } from 'common-components/ActionButton/styles';
import SimplePopover from 'common-components/SimplePopover/SimplePopover';
import { infoIconRoot } from 'assets/styles';
import { anchor, contentContainer, label, prefix, paperRoot, container, description, errorColor } from './styles';

function Capsule(props) {
    return (
        <div className={container}>
            <div id={props.name} className={anchor} />
            <div className={paperRoot}>
                {props.error && (
                    <div style={{ display: 'flex', margingBottom: 10 }}>
                        <Info
                            classes={{ root: infoIconRoot }}
                            style={{ color: '#FB6D68', width: 20, marginRight: 4 }}
                        />
                        <Typography variant="body2" align="left" className={errorColor}>
                            {props.error}
                        </Typography>
                    </div>
                )}
                <div className={contentContainer}>
                    <div className={prefix}>{props.prefix}</div>
                    <div className={label}>
                        {props.label}&nbsp;
                        {props.tip && (
                            <SimplePopover text={props.tip}>
                                <Info classes={{ root: infoIconRoot }} style={{ color: '#828796', width: 16 }} />
                            </SimplePopover>
                        )}
                    </div>
                </div>
                <Typography variant="body2" align="left" className={description}>
                    {props.description}
                </Typography>
                {props.expansionPanel}
                {!props.hideButton && (
                    <>
                        {props.limitReached ? (
                            <Typography variant="body2" className={description}>
                                {`You’ve reached the maximum number of ${props.name}s our community allows`}
                            </Typography>
                        ) : (
                            <Link to={props.route}>
                                <CustomButton variant="outlined" color="primary" fullWidth>
                                    {props.buttonLabel}
                                </CustomButton>
                            </Link>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

Capsule.defaultProps = {
    limitReached: false,
    hideButton: false,
};

Capsule.propTypes = {
    prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    label: PropTypes.string,
    description: PropTypes.string,
    buttonLabel: PropTypes.string,
    route: PropTypes.string,
    error: PropTypes.string,
    expansionPanel: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    limitReached: PropTypes.bool,
    name: PropTypes.string,
    tip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hideButton: PropTypes.bool,
};

export default Capsule;
