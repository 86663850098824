import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { useRenewalOptionStyles } from 'pages/Renewal/styles';
import PropTypes from 'prop-types';

export const VacateOption = ({ buttonText }) => {
    const classes = useRenewalOptionStyles();

    return (
        <div className={clsx(classes.row, classes.vacateRow)}>
            <Typography>
                <b>{buttonText}</b>
            </Typography>
        </div>
    );
};

export default VacateOption;

VacateOption.propTypes = {
    buttonText: PropTypes.string,
};
