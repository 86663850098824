import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import GenericFormMessage from 'common-components/GenericFormMessage/GenericFormMessage';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { P, Spacer, SpacedH1 } from 'assets/styles';
import { Typography } from '@material-ui/core';

const Divider = styled.hr`
    border-style: none;
    border-bottom: 2px solid #eeeeee;
    margin: 20px -20px 22px;
`;

export class ResetApplicantFinancials extends Component {
    state = { errorSubmitting: false, financialSource: null, submitting: false };

    handleStartOverClick = async () => {
        this.setState({ submitting: true });
        try {
            await this.props.onStartOverClick();
        } finally {
            this.setState({ submitting: false });
        }
    };

    render() {
        return (
            <>
                <SpacedH1>Start Income Verification Over</SpacedH1>
                <Divider />
                {this.state.errorSubmitting && (
                    <GenericFormMessage
                        type="error"
                        messages={[
                            'Oops! We had some trouble removing your financial records. Try again in a little bit.',
                        ]}
                    />
                )}
                <div className="text-left">
                    <Typography variant="body">
                        <strong>Are you sure you want to start over?</strong>
                    </Typography>
                    <br />
                    <Spacer height={20} />
                    <P>
                        Starting over means that you will lose any income sources or assets that have already been
                        added.
                    </P>
                </div>
                <ActionButton
                    disabled={this.state.submitting}
                    onClick={this.handleStartOverClick}
                    marginBottom={20}
                    marginTop={150}
                >
                    Start Over
                </ActionButton>
                <ActionButton onClick={this.props.onCancel} variant="outlined" marginBottom={20}>
                    Cancel
                </ActionButton>
            </>
        );
    }
}

ResetApplicantFinancials.propTypes = {
    onStartOverClick: PropTypes.func,
    onCancel: PropTypes.func,
    leaseTransaction: PropTypes.object.isRequired,
};

export default ResetApplicantFinancials;
