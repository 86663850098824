import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ROUTES } from 'constants/constants';
import { prettyFormatPhoneNumber } from 'utils/misc';
import captureRoute from 'utils/captureRoute';

import UnauthenticatedPage from 'pages/Unauthenticated';
import { SpacedH1, SpacedH6 } from 'assets/styles';
import tooslow from 'assets/new-icons/real-estate-house-magnifying-glass.svg';
import { Typography } from '@material-ui/core';

export const Img = styled.img`
    padding-top: 10px;
    margin-bottom: 76px;
`;

export const UnitUnavailablePage = ({ configuration, leaseTransaction }) => {
    if (!configuration) {
        return null;
    }
    const unit = configuration?.unit || leaseTransaction?.unit;
    if (!unit) {
        return null;
    }
    const Wrapper = leaseTransaction ? React.Fragment : UnauthenticatedPage;

    return (
        <Wrapper>
            <SpacedH1>Unfortunately, {unit.unit_number} is no longer available.</SpacedH1>
            <SpacedH6>Don&apos;t worry, we can help you find a similar one!</SpacedH6>
            <Img src={tooslow} alt="house with magnifiying glass" />
            <br />
            <Typography variant="body" align="center">
                Call us at{' '}
                <a href={`tel:${configuration.community.contact_phone}`}>
                    {prettyFormatPhoneNumber(configuration.community.contact_phone)}
                </a>
                &nbsp;so we can discuss other options.
            </Typography>
        </Wrapper>
    );
};

UnitUnavailablePage.propTypes = {
    leaseTransaction: PropTypes.object,
    configuration: PropTypes.object,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    configuration: state.configuration,
});

export default connect(mapStateToProps)(captureRoute(UnitUnavailablePage, ROUTES.UNIT_UNAVAILABLE));
