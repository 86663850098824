import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import castArray from 'lodash/castArray';
import { CheckCircle as SuccessIcon, Warning as WarningIcon, Error as ErrorIcon } from '@material-ui/icons';
import clsx from 'clsx';

const container = css`
    margin-top: 16px;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    display: inline-flex;
    gap: 10px;
`;

const darkGreen = css`
    color: #67c18b;
`;
const darkRed = css`
    color: #fb6d68;
`;
const darkYellow = css`
    color: #fac700;
`;

const getIcon = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
        case 'success':
            return SuccessIcon;
        case 'warning':
            return WarningIcon;
        case 'error':
            return ErrorIcon;
    }
};

const getIconClass = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
        case 'success':
            return darkGreen;
        case 'warning':
            return darkYellow;
        case 'error':
            return darkRed;
    }
};

export default function GenericMessage(props) {
    if (!props.messages) return null;
    const Icon = getIcon(props.type);
    const iconClass = getIconClass(props.type);

    return (
        <div className={clsx(container, { [props.className]: props.className })}>
            {Icon && <Icon className={iconClass} />}
            <span>
                {castArray(props.messages).map((message) => {
                    return (
                        <Fragment key={message}>
                            {message} <br />
                        </Fragment>
                    );
                })}
            </span>
        </div>
    );
}

GenericMessage.defaultProps = {
    type: 'success',
};

GenericMessage.propTypes = {
    className: PropTypes.string,
    messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    type: PropTypes.oneOf(['success', 'error', 'warning']),
};
