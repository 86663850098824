import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'emotion';

import API from 'api/api';
import {
    ROUTES,
    REPORT_POLL_INTERVAL,
    TOS_TYPE_FINICITY,
    FINANCIAL_SITUATION_VERIFICATION_PROVIDER_PLAID,
    PLAID_PRODUCTS,
    PLAID_INCOME_BANK,
} from 'constants/constants';

import withTransactionPath from 'utils/withTransactionPath';
import withSentryUtils from 'utils/withSentryUtils';

import BankVerifying from 'pages/Banking/components/BankVerifying';
import VerifyIncome from 'pages/Banking/components/VerifyIncome';
import LinkBankAccountDetails from 'pages/Banking/components/LinkBankAccountDetails';
import BankingContext from 'pages/Banking/BankingContext';
import PlaidLink from '../components/PlaidLink';

const finicityContainer = css`
    height: calc(100vh - 66px);
    max-height: 750px;
    max-width: 450px;
    margin: -15px -20px -45px -20px;
`;

export class ConnectBankPage extends React.Component {
    constructor() {
        super();
        this.state = {
            showFinicityIframe: false,
            showPlaidIframe: false,
            showLinkBankAccountDetails: false,
            errors: null,
            isLoading: false,
            loadingReport: false,
            incomeSource: PLAID_INCOME_BANK,
        };
    }

    componentWillUnmount() {
        clearInterval(window.fetchReportsInterval);
        try {
            if (window.finicityConnect) {
                window.finicityConnect.destroy();
            }
        } catch (e) {
            console.error(e);
        }
    }

    handleGetPlaidBankIncome = async (publicToken, reportType, plaidProduct) => {
        try {
            await API.getPlaidIncomeAndAssets(this.props.leaseTransaction.id, publicToken, reportType, plaidProduct);
            let response = await API.fetchPlaidReports(this.props.leaseTransaction.id, reportType);
            while (response.status === 202) {
                await new Promise((resolve) => setTimeout(resolve, REPORT_POLL_INTERVAL));
                response = await API.fetchPlaidReports(this.props.leaseTransaction.id, reportType);
            }

            await this?.context?.refreshFinancialSources?.();
            this.props.history.push(this.props.getTransactionPath(ROUTES.INCOME_VERIFICATION_SUMMARY));
        } catch {
            this.setState({
                showPlaidIframe: false,
                isLoading: false,
                errors: ['There was an error generating your income and assets report. Please try again.'],
            });
        }
    };

    handleFetchReports = () => {
        API.fetchFinicityReports(this.props.leaseTransaction.id).then((res) => {
            if (!res || res.status === 202) return;
            clearInterval(window.fetchReportsInterval);
            this?.context?.refreshFinancialSources?.().then(() => {
                this.props.history.push(this.props.getTransactionPath(ROUTES.INCOME_VERIFICATION_SUMMARY));
            });
        });
    };

    openPlaidIframe = (plaidProduct = PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_BANK) => {
        /* eslint-disable */
        this.setState({
            isLoading: true,
            showPlaidIframe: true,
            showLinkBankAccountDetails: false,
            plaidProduct: plaidProduct,
            errors: null,
        });
    };

    updateState = (newState) => {
        this.setState(newState);
    };

    openFinicityIframe = () => {
        /* eslint-disable no-unused-expressions */
        this.setState({
            isLoading: true,
            showLinkBankAccountDetails: false,
        });
        this.context.toggleLoader?.(true);

        const leaseTransaction = this.props.leaseTransaction;

        API.createFinicityUrl(leaseTransaction.id)
            .then((res) => {
                if (!res || !res.link) {
                    this.context.toggleLoader?.(false);

                    return this.setState({
                        showFinicityIframe: false,
                        errors: ['There was a problem with the request. Please try again.'],
                    });
                }
                this.setState(
                    {
                        showFinicityIframe: true,
                        errors: null,
                    },
                    () =>
                        window.finicityConnect.launch(res.link, {
                            selector: '#finicity-container',
                            overlay: 'rgba(255,255,255, 0)',
                            success: (data) => {
                                // for testing - fake bank = finbank profiles a; fake bank creds= user:demo pw:profile_2
                                if (data.code === 200) {
                                    this.setState({
                                        showFinicityIframe: null,
                                        errors: null,
                                        loadingReport: true,
                                        isLoading: false,
                                    });

                                    API.generateFinicityReports(leaseTransaction.id)
                                        .then(() => {
                                            window.fetchReportsInterval = window.setInterval(
                                                this.handleFetchReports,
                                                REPORT_POLL_INTERVAL
                                            );
                                        })
                                        .catch(() => {
                                            this.setState({
                                                showFinicityIframe: false,
                                                isLoading: false,
                                                errors: [
                                                    'There was an error generating your income and assets report. Please try again.',
                                                ],
                                            });
                                        });
                                } else {
                                    this.setState({
                                        showFinicityIframe: false,
                                        errors: ['There was an error accessing your information. Please try again.'],
                                        isLoading: false,
                                    });
                                }
                            },
                            cancel: () => {
                                this.setState({ showFinicityIframe: false, isLoading: false });
                            },
                            error: () => {
                                this.setState({
                                    showFinicityIframe: false,
                                    errors: ['There was an error attempting to get your records. Please try again.'],
                                    isLoading: false,
                                });
                            },
                            route: (event) => {
                                if (event.data && event.data.screen === 'search') {
                                    const body = {
                                        type: TOS_TYPE_FINICITY,
                                        context: {
                                            time: Date.now(),
                                        },
                                    };
                                    if (leaseTransaction) {
                                        API.acceptTerms(leaseTransaction.id, body).catch(() => {
                                            this.setState({
                                                showFinicityIframe: false,
                                                errors: [
                                                    'There was an error accepting the terms of use. Please try again.',
                                                ],
                                                isLoading: false,
                                            });
                                        });
                                    }
                                }
                            },
                        })
                );
            })
            .finally(() => {
                this.context.toggleLoader?.(false);
            });
    };

    openVerificationProviderIframe = () => {
        if (
            this.props.configuration.financial_situation_verification_provider ===
            FINANCIAL_SITUATION_VERIFICATION_PROVIDER_PLAID
        ) {
            this.setState({
                showLinkBankAccountDetails: true,
            });
        } else {
            this.openFinicityIframe();
        }
    };

    reportNoIncomeAssets = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        this.context.toggleLoader(true);
        // Handle reporting no income/assets
        const formData = new FormData();
        formData.append('report_no_income_assets', 'True');

        try {
            await API.submitFinancialSource(this.props.leaseTransaction.id, formData);
            await this?.context?.refreshFinancialSources?.();
            this.props.history.push(ROUTES.INCOME_VERIFICATION_SUMMARY);
        } catch (e) {
            this.props.logToSentry(e);
        }

        this.setState({ isLoading: false });
        this.context.toggleLoader(false);
    };

    render() {
        if (!this.props.applicant) {
            return <div />;
        }
        if (this.state.showFinicityIframe) {
            return <div className={finicityContainer} id="finicity-container" />;
        }
        if (this.state.showPlaidIframe) {
            return (
                <PlaidLink
                    leaseTransactionId={this.props.leaseTransaction?.id}
                    plaidProduct={this.state.plaidProduct}
                    updateState={this.updateState}
                    handleOnSuccessCallback={this.handleGetPlaidBankIncome}
                    onClose={() =>
                        this.setState({
                            showPlaidIframe: false,
                            isLoading: false,
                            plaidProduct: PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_BANK,
                        })
                    }
                />
            );
        }
        if (this.state.showLinkBankAccountDetails) {
            return <LinkBankAccountDetails openPlaidIframe={this.openPlaidIframe} updateState={this.updateState} />;
        }
        if (this.state.loadingReport) {
            return <BankVerifying />;
        }
        return (
            <VerifyIncome
                configuration={this.props.configuration}
                leaseTransaction={this.props.leaseTransaction}
                disabled={this.state.isLoading}
                errors={this.state.errors}
                openVerificationProviderIframe={this.openVerificationProviderIframe}
                reportNoIncomeAssets={this.reportNoIncomeAssets}
            />
        );
    }
}

ConnectBankPage.propTypes = {
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    history: PropTypes.object,
    refreshFinancialSources: PropTypes.func,
    getTransactionPath: PropTypes.func,
    logToSentry: PropTypes.func,
    configuration: PropTypes.object,
};

const mapStateToProps = (state) => ({
    applicant: state.applicant,
    leaseTransaction: state.transaction,
    configuration: state.configuration,
});

const mapDispatchToProps = {};

ConnectBankPage.contextType = BankingContext;

export default connect(mapStateToProps, mapDispatchToProps)(withTransactionPath(withSentryUtils(ConnectBankPage)));
