import styled from '@emotion/styled';
import { css } from 'emotion';

export const FolderImage = styled.img`
    width: 89px;
    height: 85px;
`;

export const BulbImage = styled.img`
    width: 46px;
    height: 42px;
`;

export const statusBlurb = css`
    color: #454b57;
    font-size: 14px;
`;

export const gridContainer = css`
    padding: 20px 0 20px 0;
`;
