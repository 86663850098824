import React from 'react';
import PropTypes from 'prop-types';

import { PLAID_PRODUCTS } from 'constants/constants';

import ActionButton from 'common-components/ActionButton/ActionButton';
import { blackLinkRoot, arrowIcon } from 'assets/styles';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import withTransactionPath from 'utils/withTransactionPath';
import { useBankingOptionStyles } from 'assets/styles';

export const LinkBankAccountDetails = ({ openPlaidIframe, updateState }) => {
    const classes = useBankingOptionStyles();

    return (
        <>
            <div className={classes.header}>Link Bank Account</div>
            <br />
            <div className={classes.subTitle}>Link your bank account</div>
            <span className={classes.pillbox}>INSTANT VERIFICATION</span>
            <span className={classes.pillbox}>SECURE</span>
            <br />
            <p className={classes.body}>Select your bank, log in, and confirm the account(s) you want to link.</p>
            <br />
            <br />

            <div>
                <ActionButton
                    onClick={() => openPlaidIframe(PLAID_PRODUCTS.INCOME_VERIFICATION_WITH_BANK)}
                    marginBottom={0}
                >
                    Continue
                </ActionButton>
                <div className={classes.footer}>You’ll be redirected to Plaid, our financial data provider.</div>
                <br />
                <br />
                <span
                    className={blackLinkRoot}
                    onClick={() => {
                        updateState({ showLinkBankAccountDetails: false });
                    }}
                >
                    <ArrowBackIos classes={{ root: arrowIcon }} /> Go Back
                </span>
            </div>
        </>
    );
};

LinkBankAccountDetails.propTypes = {
    openPlaidIframe: PropTypes.func,
    updateState: PropTypes.func,
};

export default withTransactionPath(LinkBankAccountDetails);
