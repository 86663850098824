import API from 'api/api';
import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import _ from 'lodash';

const lease = createSlice({
    name: 'lease',
    initialState: null,
    reducers: {
        leaseReceived(state, action) {
            state = action.payload;
            return state;
        },
        leaseUpdated(state, action) {
            return produce(state, (draft) => {
                Object.assign(draft, action.payload);
            });
        },
    },
    extraReducers: {
        USER_LOGOUT: () => {
            return null;
        },
    },
});

const { actions, reducer } = lease;
export const { leaseReceived, leaseUpdated } = actions;
export default reducer;

export const fetchLease = (id) => {
    return async (dispatch, getState) => {
        const { lease } = getState();
        id = id || lease?.id;

        const response = await API.fetchLease(id);
        if (!_.isEmpty(response.errors)) {
            throw Error('Lease not found');
        }

        dispatch(leaseReceived(response));
        return response;
    };
};
