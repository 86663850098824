import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ROUTES } from 'constants/constants';
import { PRICE_UPDATE } from 'constants/pageComplete';
import { GENERIC_ERROR_MESSAGE } from 'constants/messages';
import { getOriginalQuoteExpired } from 'utils/leaseTransaction';
import withRelativeRoutes from 'utils/withRelativeRoutes';

import { selectors as renterProfileSelectors } from 'reducers/renter-profile';
import { pageComplete } from 'reducers/renter-profile';
import { actions as loaderActions } from 'reducers/loader';

import Page from 'common-components/Page/Page';
import PaymentTerms from 'common-components/PaymentTerms/PaymentTerms';
import ActionButton from 'common-components/ActionButton/ActionButton';
import { PaymentDetailsCard } from 'common-components/PaymentDetails';
import { P, leasingPricingDisclaimer } from 'assets/styles';
import { termsDiv, viewPaymentTerms } from './PaymentDetailsPageStyles';
import { prettyFormatPhoneNumber } from 'utils/misc';

export const PaymentDetailsPage = ({
    leaseTransaction = null,
    applicant = null,
    configuration = {},
    basename = null,
    pageComplete = (f) => f,
    toggleLoader = (f) => f,
}) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState('summary');

    const { community = {} } = configuration;
    const { contact_phone: contactPhone = null } = community;
    const prettyPhoneNumber = prettyFormatPhoneNumber(contactPhone);

    if (!leaseTransaction || !leaseTransaction.lease_start_date) {
        return null;
    }

    const unitNumber = leaseTransaction.unit?.unit_number;
    const communityName = leaseTransaction.community?.display_name;
    const leaseStartDate = leaseTransaction.lease_start_date;
    const hasReceipt = applicant && applicant.receipt;
    const receiptNotPaidByApplicant = hasReceipt && applicant.receipt.paid_by !== applicant.id;
    const holdingDepositAmount =
        parseFloat(leaseTransaction.selected_rental_options?.['holding-deposit']?.[0]?.quoted_deposit_amount) || 0;
    const hasLeasingPricingDisclaimer = !!configuration?.leasing_pricing_disclaimer;
    const originalQuoteExpired = getOriginalQuoteExpired({ leaseTransaction, applicant });

    const handleVerifyPriceUpdate = async () => {
        setErrorMsg(null);
        toggleLoader(true);
        setIsLoading(true);

        try {
            await pageComplete(PRICE_UPDATE);
            window.location.href = `${window.location.origin}/${basename}/transaction/${leaseTransaction.id}`;
        } catch {
            setErrorMsg(GENERIC_ERROR_MESSAGE);
        } finally {
            toggleLoader(false);
            setIsLoading(false);
        }
    };

    // When the applicant's receipt got paid by someone else, it means that the applicant would not have
    // accepted any payment terms. In that case we mark canProceedToPayment to false so we obtain the community's
    // terms, instead of the applicant accepted ones.
    // In addition, the applicant should not have the ability to hit any CTA in the PaymentTerms comp. Hense viewOnly.
    if (currentPage === 'terms') {
        return (
            <PaymentTerms
                handleClickBack={() => setCurrentPage('summary')}
                holdingDepositAmount={holdingDepositAmount}
                unitNumber={unitNumber}
                communityName={communityName}
                leaseStartDate={leaseStartDate}
                leaseTransaction={leaseTransaction}
                canProceedToPayment={receiptNotPaidByApplicant}
                viewOnly={receiptNotPaidByApplicant}
            />
        );
    }

    const notifications = [];
    if (errorMsg) {
        notifications.push({
            type: 'error',
            messages: errorMsg,
        });
    }

    if (originalQuoteExpired) {
        // let message
        notifications.push({
            type: 'warning',
            messages: (
                <span>
                    Your quote has expired and the rate may have updated. Please call us at{' '}
                    <a href={`tel:${contactPhone}`}>{prettyPhoneNumber}</a> if you have any questions.
                </span>
            ),
        });
    }

    return (
        <Page
            title={!originalQuoteExpired ? 'Payment Details' : 'Price has Updated'}
            subTitle={!originalQuoteExpired && `Here’s the breakdown for unit ${unitNumber}`}
            notifications={notifications}
        >
            <PaymentDetailsCard onError={() => setErrorMsg(GENERIC_ERROR_MESSAGE)} />
            {hasLeasingPricingDisclaimer && (
                <div data-testid="leasing-pricing-disclaimer" className={leasingPricingDisclaimer}>
                    {configuration.leasing_pricing_disclaimer}
                </div>
            )}
            {hasReceipt && (
                <div className={termsDiv}>
                    <P fontSize={14}>
                        <span role="button" onClick={() => setCurrentPage('terms')} className={viewPaymentTerms}>
                            Payment Terms
                        </span>
                    </P>
                </div>
            )}
            {originalQuoteExpired && (
                <ActionButton marginTop={40} disabled={isLoading} onClick={handleVerifyPriceUpdate}>
                    Got it, Continue with Application
                </ActionButton>
            )}
        </Page>
    );
};

PaymentDetailsPage.propTypes = {
    leaseTransaction: PropTypes.object,
    applicant: PropTypes.object,
    configuration: PropTypes.object,
    basename: PropTypes.string,
    pageComplete: PropTypes.func,
    toggleLoader: PropTypes.func,
};

const mapStateToProps = (state) => ({
    leaseTransaction: state.transaction,
    applicant: state.applicant,
    configuration: state.configuration,
    nextRoute: renterProfileSelectors.selectNextRoute(state),
    basename: state.siteConfig.basename,
});

const mapActionsToProps = {
    pageComplete,
    toggleLoader: loaderActions.toggleLoader,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withRelativeRoutes(PaymentDetailsPage, ROUTES.PAYMENT_DETAILS));
