import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
    makeStyles,
    Drawer,
    AppBar,
    Toolbar,
    Divider,
    IconButton,
    Popover,
    MenuList,
    MenuItem,
    Box,
    Typography,
} from '@material-ui/core';
import { Menu as MenuIcon, ExpandMore as ExpandMoreIcon, ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import clsx from 'clsx';

import withTransactionPath from 'utils/withTransactionPath';
import auth from 'utils/auth';
import { getShowFees } from 'utils/leaseTransaction';
import { getPaymentBreakdownFeesDueAtMoveInLabel } from 'utils/paymentBreakdown';

import { APPLICATION_STATUSES, ROUTES } from 'constants/constants';
import { actions } from 'reducers/store';
import { DIRECT_ROUTES, selectors as profileSelectors } from 'reducers/renter-profile';
import { fetchPayments } from 'reducers/payments';
import { fetchPaymentBreakdown } from 'reducers/paymentBreakdown';

import ProgressBar from './ProgressBar';
import BannerLogo from './BannerLogo';
import NavStepper from './NavStepper';
import { drawerContent } from './styles';
import { H3 } from 'assets/styles';

const WORMHOLE_BANNER_HEIGHT = 60;

const useStyles = makeStyles((theme) => ({
    list: {
        width: 300,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    appBar: {
        backgroundColor: !theme.darkMode ? '#ffffff' : theme.palette.primary.main,
        color: !theme.darkMode ? '#000000' : theme.palette.primary.contrastText,
    },
    wormholeBanner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: WORMHOLE_BANNER_HEIGHT,
        backgroundColor: '#FDB128',
        fontSize: 18,
        fontWeight: 700,
        color: 'white',
    },
    toolbar: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 76,
    },
    bannerLogo: {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 'auto',
        pointerEvents: 'none',
    },
    drawerMenuTrigger: {
        color: !theme.darkMode ? 'black' : 'white',
    },
    profileMenuTrigger: {
        borderRadius: '35px',
        color: !theme.darkMode ? 'black' : 'white',
    },
    profileMenuList: {
        width: 170,

        // Divider
        '& hr': {
            backgroundColor: '#eee',
            margin: '4px 8px',
        },

        // Menu Items
        '& li': {
            whiteSpace: 'normal',
        },
    },
    section: {
        margin: '24px 16px',
        marginBottom: 0,
        paddingBottom: 24,
        borderBottom: '1px solid #eee',
    },
    communitySection: {
        display: 'flex',
        flexFlow: 'column',

        '& :first-child': {
            fontSize: 18,
            fontWeight: 600,
        },
        '& :nth-child(2)': {
            fontSize: 14,
        },
    },
    paymentSection: {
        '& ul': {
            margin: '10px 0 10px 0',
            padding: '0',
            listStyleType: 'none',
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: 'none',
    },
    initials: {
        color: '#828796',
        backgroundColor: '#EFEFEF',
        borderRadius: 50,
        textAlign: 'center',
        fontSize: 17,
        height: 40,
        width: 40,
        lineHeight: '40px',
        margin: '0 10px 10px 0',
    },
    avatar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        height: 30,
        backgroundColor: 'transparent',
        border: !theme.darkMode ? '2px solid black' : '2px solid white',
        borderRadius: '50%',
        color: !theme.darkMode ? 'black' : 'white',
        fontSize: 12,
    },
    goBack: {
        color: !theme.darkMode ? 'black' : 'white',
        borderRadius: '35px',

        '& span': {
            fontSize: 14,
            fontWeight: 600,
        },
    },
}));

const getWormholedUserName = (wormholedUser) => {
    if (!wormholedUser) return null;

    const { first_name, last_name, username } = wormholedUser;
    if (first_name && last_name) {
        return `${first_name} ${last_name}`;
    } else {
        return username;
    }
};

export function NavDrawer({
    history = {},
    userProfile = null,
    applicant = null,
    leaseTransaction = null,
    config = null,
    paymentBreakdown = {},
    leaseTransactionFees = null,
    navRoutes = [],
    currentRoute = null,
    initialPage = null,
    children = null,
    applicantStillFinishingApplication = false,
    guarantorRequested = false,
    canAccessRoute = (f) => f,
    logout = (f) => f,
    getTransactionPath = (f) => f,
    fetchPayments = (f) => f,
    fetchPaymentBreakdown = (f) => f,
}) {
    const classes = useStyles();
    const menuState = usePopupState({ variant: 'popover', popupId: 'menu' });
    const [open, setOpen] = useState(false);
    const initials = `${userProfile?.first_name?.charAt(0)}${userProfile?.last_name?.charAt(0)}`.toUpperCase();
    const unitNumber = leaseTransaction?.unit?.unit_number;
    const communityName = leaseTransaction?.community?.display_name;
    const wormholedUser = auth.getWormholedUser();
    const wormholedUserName = getWormholedUserName(wormholedUser);
    const showApplicationDrawer = applicant && !DIRECT_ROUTES.includes(history.location.pathname);
    const showGoBack = history.length > 2 && !showApplicationDrawer;
    const acceptACHPayments = config?.community?.company.accept_ach_payments_from_applicants;
    const showPaymentBreakdown = !!canAccessRoute(ROUTES.PAYMENT_DETAILS);
    const resAppPortalUrl = applicant?.res_app_portal_url || null;
    const { data: paymentData } = paymentBreakdown;
    const showApplicationFees = getShowFees(leaseTransactionFees);
    const showMonthlyFees = getShowFees(paymentData?.monthly_fees_no_prorate);
    const progressBarPercentage = useMemo(() => {
        if (!(currentRoute && navRoutes)) return 0;

        if (leaseTransaction?.status === APPLICATION_STATUSES.APPLICATION_STATUS_COMPLETED) return 100;

        let trimmedRoute;
        if (leaseTransaction) {
            trimmedRoute = currentRoute.replace(leaseTransaction.id, ':lease_transaction_id');
        } else {
            // prevents having errors when the leaseTransaction is not defined yet
            trimmedRoute = currentRoute.replace(/\/transaction\/\d+\//, '/transaction/:lease_transaction_id/');
        }

        for (let i = 0; i < navRoutes.length; i++) {
            const route = navRoutes[i];
            if (route.value.includes(trimmedRoute)) return Math.floor(((i + 1) * 100) / navRoutes.length);
        }
        return 0;
    }, [navRoutes, currentRoute, leaseTransaction]);

    useEffect(() => {
        if (open) {
            fetchPayments();
            if (showPaymentBreakdown) {
                fetchPaymentBreakdown();
            }
        }
    }, [open, showPaymentBreakdown, fetchPayments, fetchPaymentBreakdown]);

    const getShouldHistoryPush = useCallback(
        (route) => {
            return history?.location?.pathname !== route;
        },
        [history]
    );

    const handleGoBackClick = useCallback(() => {
        history.goBack();
    }, [history]);

    const handleDrawerOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleNavItemClicked = useCallback(
        (route) => {
            menuState.close();

            if (getShouldHistoryPush(route)) {
                history.push(route);
            }
        },
        [menuState, history, getShouldHistoryPush]
    );

    const handleResidentPortalClick = useCallback(() => {
        menuState.close();

        window.open(resAppPortalUrl, '_blank');
    }, [menuState, resAppPortalUrl]);

    const handleLogoutClick = useCallback(() => {
        menuState.close();
        localStorage.clear();
        logout();
        history.push(ROUTES.LOGIN);
    }, [menuState, logout, history]);

    if (!userProfile) return null;

    return (
        <>
            <AppBar color="primary" position="fixed" className={classes.appBar} id="top-navbar">
                {wormholedUser && (
                    <div className={classes.wormholeBanner}>{`You are logged in as ${wormholedUserName}`}</div>
                )}
                <Toolbar
                    className={classes.toolbar}
                    style={{ justifyContent: showApplicationDrawer || showGoBack ? 'space-between' : 'flex-end' }}
                >
                    {showApplicationDrawer && (
                        <IconButton className={classes.drawerMenuTrigger} onClick={handleDrawerOpen} edge="start">
                            <MenuIcon />
                        </IconButton>
                    )}
                    {!showApplicationDrawer && showGoBack && (
                        <IconButton className={classes.goBack} onClick={handleGoBackClick} edge="start">
                            <ArrowBackIosIcon />
                            <span>Go Back</span>
                        </IconButton>
                    )}
                    <BannerLogo className={classes.bannerLogo} />
                    <IconButton className={classes.profileMenuTrigger} {...bindTrigger(menuState)} edge="end">
                        <div className={classes.avatar}>{initials}</div>
                        <ExpandMoreIcon fontSize="small" />
                    </IconButton>
                    <Popover
                        {...bindPopover(menuState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <MenuList className={classes.profileMenuList}>
                            <MenuItem onClick={() => handleNavItemClicked(ROUTES.ACCOUNT)}>Account</MenuItem>
                            <Divider />
                            <MenuItem onClick={() => handleNavItemClicked(ROUTES.LEASE_TRANSACTIONS)}>
                                My Activity
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => handleNavItemClicked(ROUTES.LEASES)}>My Leases</MenuItem>
                            <Divider />
                            {acceptACHPayments && (
                                <>
                                    <MenuItem onClick={() => handleNavItemClicked(ROUTES.PAYMENT_METHODS)}>
                                        Payment Methods
                                    </MenuItem>
                                    <Divider />
                                </>
                            )}
                            {config?.allow_screening_reports_retrieval && (
                                <>
                                    <MenuItem onClick={() => handleNavItemClicked(ROUTES.SCREENING_REPORTS)}>
                                        Screening Reports
                                    </MenuItem>
                                    <Divider />
                                </>
                            )}
                            {resAppPortalUrl && (
                                <>
                                    <MenuItem
                                        data-testid="resident-portal-menu-item"
                                        onClick={handleResidentPortalClick}
                                    >
                                        Go to Resident Portal
                                    </MenuItem>
                                    <Divider />
                                </>
                            )}
                            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
                        </MenuList>
                    </Popover>
                </Toolbar>
                {showApplicationDrawer && <ProgressBar percent={progressBarPercentage} />}
            </AppBar>
            {showApplicationDrawer && (
                <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
                    <div className={classes.list} role="presentation">
                        <Box className={clsx(classes.section, classes.communitySection)}>
                            <span>{communityName}</span>
                            {unitNumber && <span>{`Unit ${unitNumber}`}</span>}
                        </Box>
                        {showPaymentBreakdown && paymentData && leaseTransactionFees && (
                            <Box className={clsx(classes.section, classes.paymentSection)}>
                                <H3>Payments</H3>
                                <ul>
                                    {showApplicationFees && (
                                        <li>
                                            <b>${leaseTransactionFees.total}</b>
                                            {` due at application`}
                                        </li>
                                    )}
                                    <li>
                                        <b>${paymentData.total_fees_per_month}</b>
                                        {` ${getPaymentBreakdownFeesDueAtMoveInLabel(paymentData, leaseTransaction)}`}
                                    </li>
                                    {showMonthlyFees && (
                                        <li>
                                            <b data-testid="monthly-fees">
                                                ${paymentData?.monthly_fees_no_prorate.total}
                                            </b>
                                            {` monthly rent`}
                                        </li>
                                    )}
                                </ul>
                                <Link to={getTransactionPath(ROUTES.PAYMENT_DETAILS)}>
                                    <Typography variant="body" variantMapping={{ body: 'span' }}>
                                        Payment Details
                                    </Typography>
                                </Link>
                            </Box>
                        )}
                        <Box className={classes.section}>
                            <NavStepper
                                history={history}
                                applicant={applicant}
                                leaseTransaction={leaseTransaction}
                                config={config}
                                navRoutes={navRoutes}
                                currentRoute={currentRoute}
                                initialPage={initialPage}
                                applicantStillFinishingApplication={applicantStillFinishingApplication}
                                guarantorRequested={guarantorRequested}
                                handleDrawerClose={handleDrawerClose}
                                getTransactionPath={getTransactionPath}
                            />
                        </Box>
                        <Box className={clsx(classes.section, classes.footer)}>
                            <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>
                                <Typography variant="body" variantMapping={{ body: 'span' }}>
                                    Privacy
                                </Typography>
                            </Link>
                            <Link target="_blank" to={ROUTES.TERMS}>
                                <Typography variant="body" variantMapping={{ body: 'span' }}>
                                    Terms of Use
                                </Typography>
                            </Link>
                            <Link target="_blank" to={ROUTES.FAQ}>
                                <Typography variant="body" variantMapping={{ body: 'span' }}>
                                    FAQs
                                </Typography>
                            </Link>
                        </Box>
                    </div>
                </Drawer>
            )}
            <main>
                <div className={classes.drawerHeader} />
                <div className={drawerContent} style={{ marginTop: wormholedUser ? WORMHOLE_BANNER_HEIGHT : 0 }}>
                    {children}
                </div>
            </main>
        </>
    );
}

NavDrawer.propTypes = {
    history: PropTypes.object,
    userProfile: PropTypes.object,
    applicant: PropTypes.object,
    leaseTransaction: PropTypes.object,
    config: PropTypes.object,
    leaseTransactionFees: PropTypes.object,
    paymentBreakdown: PropTypes.object,
    navRoutes: PropTypes.array,
    currentRoute: PropTypes.string,
    initialPage: PropTypes.string,
    children: PropTypes.any,
    applicantStillFinishingApplication: PropTypes.bool,
    guarantorRequested: PropTypes.bool,
    canAccessRoute: PropTypes.func,
    logout: PropTypes.func,
    getTransactionPath: PropTypes.func,
    fetchPayments: PropTypes.func,
    fetchPaymentBreakdown: PropTypes.func,
};

const mapStateToProps = (state) => ({
    userProfile: state.userProfile,
    applicant: state.applicant,
    leaseTransaction: state.transaction,
    config: state.configuration,
    leaseTransactionFees: profileSelectors.leaseTransactionFees(state),
    paymentBreakdown: state.paymentBreakdown,
    navRoutes: profileSelectors.selectNav(state),
    currentRoute: state.siteConfig.currentRoute,
    initialPage: profileSelectors.selectInitialPage(state),
    applicantStillFinishingApplication: profileSelectors.selectApplicantStillFinishingApplication(state),
    guarantorRequested: profileSelectors.selectGuarantorRequested(state),
    canAccessRoute: (route) => profileSelectors.canAccessRoute(state, route),
});

const mapDispatchToProps = {
    logout: actions.logout,
    fetchPayments,
    fetchPaymentBreakdown,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTransactionPath(NavDrawer)));
